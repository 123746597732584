import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import { HIDE_NAVIGATION_ID } from './HeaderMobileNavigationWrapper.util';

export const containerCSS = css`
  overflow: hidden;
`;

export const containerInnerCSS = css`
  transition: max-height 0.3s ease-out;
  max-height: 60px;

  .${HIDE_NAVIGATION_ID} & {
    overflow: hidden;
    max-height: 0;
  }

  ${mq[1]} {
    .${HIDE_NAVIGATION_ID} & {
      overflow: visible;
      max-height: unset;
      opacity: 1;
    }
  }
`;
