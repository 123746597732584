import { FormElementType } from '../../../types/form-element.type';
import { QuestionType } from '../../../types/question.type';
import {
  type WorkflowSectionSchema,
  type SectionContainer,
  type SectionSchema,
  SectionPresentationType,
} from '../../../types/section.type';
import { getAllQuestionsKeys } from '../dynamic-defaults.util';
import { getBooleanAnswers, getOtherTypesAnswers } from './answers.util';
import type { Answers } from '../../../types/answer.type';
import type { QuestionElementContent } from '../../../types/form-element.type';
import type { PageResponse } from '../../../types/page.type';

export const getNestedQuestionsKeysFromSectionContainer = (container: SectionContainer): string[] =>
  container.elements.reduce((acc: string[], element) => {
    const keys = element.sub_elements.map((subElement) => (subElement.content as QuestionElementContent).key);
    return [...acc, ...keys];
  }, []);

const getNestedCheckboxQuestionsKeysFromSectionContainer = (container: SectionContainer): string[] =>
  container.elements.reduce((acc: string[], element) => {
    const keys = element.sub_elements
      .filter(
        (subElement) =>
          subElement.kind === FormElementType.Question &&
          (subElement.content as QuestionElementContent).type === QuestionType.Checkbox
      )
      .map((subElement) => (subElement.content as QuestionElementContent).key);
    return [...acc, ...keys];
  }, []);

export const getCheckboxKeysFromSectionContainer = (container: SectionContainer): string[] =>
  container.elements
    .filter(
      (element) =>
        element.kind === FormElementType.Question &&
        (element.content as QuestionElementContent).type === QuestionType.Checkbox
    )
    .map((element) => (element.content as QuestionElementContent).key);

export const getCheckboxQuestionsKeys = (sections: SectionSchema[]): string[] => {
  return sections.reduce((acc: string[], section: SectionSchema) => {
    if (section.presentation_type === SectionPresentationType.Workflow) {
      const keys = (section as WorkflowSectionSchema).containers
        .map((container) => [
          ...getCheckboxKeysFromSectionContainer(container),
          ...getNestedCheckboxQuestionsKeysFromSectionContainer(container),
        ])
        .flat();
      return [...acc, ...keys];
    }
    return acc;
  }, []);
};

export const prepareWorkflowDefaultAnswers = (pageResponse: PageResponse): Answers => {
  if (!pageResponse.answers) {
    return {};
  }

  const checkboxKeys = getCheckboxQuestionsKeys(pageResponse.page.sections);
  const allKeys = getAllQuestionsKeys(pageResponse.page.sections);
  const pageResponseAnswers = { ...pageResponse.answers };
  const defaultAnswers = { ...pageResponse.default_answers };
  const dynamicDefaults = { ...pageResponse.dynamic_defaults };

  const booleanAnswers = getBooleanAnswers(checkboxKeys, pageResponseAnswers, defaultAnswers, dynamicDefaults);
  const otherTypesAnswers = getOtherTypesAnswers(allKeys, pageResponseAnswers, defaultAnswers, dynamicDefaults);

  const answers = {
    ...pageResponseAnswers,
    ...booleanAnswers,
    ...otherTypesAnswers,
  };

  return { ...pageResponse.default_answers, ...answers };
};
