import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const columnsWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const itemCSS = css`
  margin-top: 8px;
  flex-basis: 100%;
  color: ${theme.colors.gray80};
  ${mq[0]} {
    margin-top: 12px;
    flex-basis: calc(50% - 6px);
  }
`;

export const singleItemCSS = css`
  margin-top: 8px;
  color: ${theme.colors.gray80};
  ${mq[0]} {
    margin-top: 12px;
  }
`;

export const buttonCSS = css`
  margin-top: 24px;
`;
