import type { DigitalProfileQuote } from '../../../../types/quote.type';

export enum NoQuoteMessageReason {
  NoQuotes = 'no_quotes',
  AutoOnly = 'auto_only',
  personPolicyholder = 'person_policyholder',
  noQuotesForCandidate = 'no_quotes_for_candidate',
}

export const isHomeQuotePresent = (quotes: DigitalProfileQuote[]): boolean =>
  quotes.some((quote) =>
    ['home', 'condo', 'fire', 'renters', 'manufactured_or_mobile_home'].includes(quote.policy_type)
  );

export const getNoQuoteMessageReason = (quotes?: DigitalProfileQuote[], isSoftStopNotEnabled?: boolean): string => {
  if (!quotes?.length && isSoftStopNotEnabled) {
    return NoQuoteMessageReason.noQuotesForCandidate;
  }

  if (!quotes?.length) {
    return NoQuoteMessageReason.NoQuotes;
  }

  const autoOnly = quotes?.every((quote) => quote.policy_type === 'auto');
  if (autoOnly) {
    return NoQuoteMessageReason.AutoOnly;
  }

  return '';
};
