import type { SessionNavigationItem } from '../types/session.type';

export const isFirstSection = (
  navigation: SessionNavigationItem[] = [],
  pageKey: string = '',
  sectionKey: string | null = ''
): boolean => {
  const page = navigation.find((navigationItem) => navigationItem.key === pageKey);
  if (!page) {
    return false;
  }

  return page.sections[0].key === sectionKey;
};

export const isCurrentKnockOutSection = (currentSectionKey: string | null): boolean => {
  return typeof currentSectionKey === 'string' && currentSectionKey.includes('ko_questions');
};
