import { RELATIONSHIP_VARIANTS } from '../../../../constants/schema-hardcoded-keys';
import {
  PERSON_AGE_VALIDATION,
  REQUIRED_VALIDATION,
} from '../../../../questionsForm/utils/question-validations/question-validations.util';
import { FormElementTag, FormElementType } from '../../../../types/form-element.type';
import { QuestionType } from '../../../../types/question.type';
import { ValidationType } from '../../../../types/validation.type';
import type { Driver } from '../../../../types/driver.type';
import type { FormElementSchema, QuestionElementContent } from '../../../../types/form-element.type';

export const DRIVER_FORM_TYPE = 'driver';
export const NEW_DRIVER_FORM_KEY = 'new_driver';

const FORM_DRIVER_SCHEMA: Array<FormElementSchema<QuestionElementContent>> = [
  {
    key: 'date_of_birth_element',
    kind: FormElementType.Question,
    width: 6,
    position: 3,
    tags: [FormElementTag.TrackForbidden],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'date_of_birth',
      label: 'Date of Birth',
      type: QuestionType.Date,
      variants: [],
      validations: [REQUIRED_VALIDATION, ...PERSON_AGE_VALIDATION],
    },
  },
  {
    key: 'gender_element',
    kind: FormElementType.Question,
    width: 6,
    position: 4,
    tags: [],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'gender',
      label: 'Gender',
      type: QuestionType.SingleSelect,
      variants: [
        {
          label: 'Male',
          value: 'male',
          description: null,
          icon_url: null,
        },
        {
          label: 'Female',
          value: 'female',
          description: null,
          icon_url: null,
        },
        {
          label: 'Non-binary',
          value: 'nonbinary',
          description: null,
          icon_url: null,
        },
      ],
      validations: [REQUIRED_VALIDATION],
    },
  },
  {
    key: 'marital_status_element',
    kind: FormElementType.Question,
    width: 6,
    position: 5,
    tags: [],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'marital_status',
      label: 'Marital Status',
      type: QuestionType.SingleSelect,
      variants: [
        {
          label: 'Single',
          value: 'single',
          description: null,
          icon_url: null,
        },
        {
          label: 'Married',
          value: 'married',
          description: null,
          icon_url: null,
        },
        {
          label: 'Widowed',
          value: 'widowed',
          description: null,
          icon_url: null,
        },
        {
          label: 'Divorced',
          value: 'divorced',
          description: null,
          icon_url: null,
        },
        {
          label: 'Separated',
          value: 'separated',
          description: null,
          icon_url: null,
        },
        {
          label: 'Domestic Partnership',
          value: 'domestic_partnership',
          description: null,
          icon_url: null,
        },
      ],
      validations: [
        REQUIRED_VALIDATION,
        {
          key: ValidationType.Min,
          value: 1000,
          message: 'The minimum value is 1000 miles per year',
        },
        {
          key: ValidationType.Max,
          value: 99999,
          message: 'The maximum value is 99,999 miles per year',
        },
      ],
    },
  },
  {
    key: 'relationship_element',
    kind: FormElementType.Question,
    width: 6,
    position: 6,
    tags: [],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'kind',
      label: 'Relationship to policyholder',
      type: QuestionType.SingleSelect,
      variants: [...RELATIONSHIP_VARIANTS],
      validations: [REQUIRED_VALIDATION],
    },
  },
];

export const NEW_DRIVER_FORM_SCHEMA: Array<FormElementSchema<QuestionElementContent>> = [
  {
    key: 'first_name_element',
    kind: FormElementType.Question,
    width: 6,
    position: 1,
    tags: [],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'first_name',
      label: 'First Name',
      type: QuestionType.Text,
      variants: [],
      placeholder: 'first name',
      validations: [REQUIRED_VALIDATION],
    },
  },
  {
    key: 'last_name_element',
    kind: FormElementType.Question,
    width: 6,
    position: 2,
    tags: [FormElementTag.TrackForbidden],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'last_name',
      label: 'Last Name',
      placeholder: 'last name',
      type: QuestionType.Text,
      variants: [],
      validations: [REQUIRED_VALIDATION],
    },
  },
  ...FORM_DRIVER_SCHEMA,
];

export const PREFILLED_DRIVER_FORM_SCHEMA: Array<FormElementSchema<QuestionElementContent>> = [...FORM_DRIVER_SCHEMA];

export const sortDrivers = (drivers: Driver[], primaryInsuredGid: string): Driver[] => {
  const filteredDrivers = drivers.filter((driver) => driver.gid !== primaryInsuredGid);
  const primaryInsured = drivers.find((driver) => driver.gid === primaryInsuredGid);
  return primaryInsured ? [primaryInsured, ...filteredDrivers] : filteredDrivers;
};
