import type { SerializedStyles } from '@emotion/react';
import type { ButtonHTMLAttributes, ReactNode } from 'react';

export enum ButtonSize {
  Normal = 'NORMAL',
  Large = 'LARGE',
}

export type ButtonVariant = 'default' | 'secondary' | 'inverted' | 'text' | 'text_inverted';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: ButtonSize;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
  fullWidth?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
  customCSS?: SerializedStyles | Array<SerializedStyles | undefined>;
}
