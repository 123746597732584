import { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useFeatureFlags } from '../../../../api/feature-flags';
import { usePage } from '../../../../api/page';
import { useSession } from '../../../../api/session';
import SectionForm from '../../../../questionsForm/components/SectionForm/SectionForm';
import { getCurrentSectionKey } from '../../../../utils/current-form-keys.util';
import { getVisibleSections } from '../QuestionsForm/QuestionsForm.util';
import type { QuestionFormContentProps } from './QuestionFormContent.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { WorkflowSectionSchema } from '../../../../types/section.type';
import type { FC } from 'react';

const QuestionFormContent: FC<QuestionFormContentProps> = ({ onSubmit, onSubmitContainerByClick }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const { data: featureFlags } = useFeatureFlags(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const currentSectionKey = getCurrentSectionKey(sessionData);
  const { data: pageData } = usePage<WorkflowSectionSchema>(gid, currentPageKey);

  const sections = getVisibleSections(pageData?.page.sections ?? [], currentSectionKey);

  return (
    <Fragment>
      {sections.map((s, index) => (
        <SectionForm
          key={s.key}
          sectionKey={s.key}
          actionLabel={s.action_label}
          containers={s.containers}
          isFirst={index === 0}
          isCurrent={s.key === currentSectionKey}
          isLastWorkflowSection={sections[sections.length - 1]?.key === s.key}
          onSubmitContainerByClick={onSubmitContainerByClick}
          onSubmit={onSubmit}
          featureFlags={featureFlags?.feature_flags ?? []}
        />
      ))}
    </Fragment>
  );
};

export default QuestionFormContent;
