import { FormProvider, useForm } from 'react-hook-form';
import Button from '../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import SessionLockedForm from '../SessionLockedForm/SessionLockedForm';
import { SESSION_LOCKED_FORM_ID } from '../SessionLockedForm/SessionLockedForm.util';
import { buttonsContainerCSS } from './UnlockCardContent.style';
import type { UnlockCardProps } from './UnlockCardContent.type';
import type { UnlockSessionData } from '../../../../types/session.type';
import type { FC } from 'react';

const UnlockCardContent: FC<UnlockCardProps> = ({ onCallMaticAgent }) => {
  const methods = useForm<UnlockSessionData>({
    shouldUnregister: false,
    shouldFocusError: false,
  });

  return (
    <>
      <h2>Pick up where you left off</h2>
      <p>For security purposes, we have paused your session. To finalize quote, please confirm your identity below.</p>

      <FormProvider {...methods}>
        <SessionLockedForm />
      </FormProvider>

      <div css={buttonsContainerCSS}>
        <Button variant="secondary" fullWidth size={ButtonSize.Large} onClick={onCallMaticAgent}>
          Talk to an Advisor
        </Button>
        <Button
          fullWidth
          size={ButtonSize.Large}
          type="submit"
          form={SESSION_LOCKED_FORM_ID}
          disabled={methods.formState.isSubmitting}
        >
          Resume
        </Button>
      </div>
    </>
  );
};

export default UnlockCardContent;
