import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import SwitchInput from './SwitchInput/SwitchInput';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const SwitchQuestion: FC<SpecificQuestionProps> = ({ questionKey, hint, isDisabled, gridWidth, dense }) => (
  <QuestionWrapper
    questionKey={questionKey}
    hideLabel
    hint={hint}
    isDisabled={isDisabled}
    gridWidth={gridWidth}
    inputProps={{
      name: questionKey,
      isDisabled,
      inputId: questionKey,
      dense,
    }}
    inputComponent={SwitchInput}
  />
);

export default SwitchQuestion;
