import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicPrimaryInsured } from '../../../../api/public/primary-insured';
import { useQuestionsFormState } from '../../../../pages/questions/contexts/questions-context';
import { setQuestionsKeys } from '../../../../pages/questions/contexts/questions-context/actions';
import Button from '../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import { noopAsync } from '../../../../shared/utils/noop';
import { sectionFormActionsCSS } from '../../../../styles/form.style';
import { FormElementType } from '../../../../types/form-element.type';
import ContainerForm from '../../ContainerForm/ContainerForm';
import { containerCSS, scrollPointCSS } from '../SectionForm.style';
import { DOB_CONTAINER_KEY, getElementsSchema } from './SectionAuthForm.util';
import type { SectionAuthFormProps } from './SectionAuthForm.type';
import type { QuestionElementContent } from '../../../../types/form-element.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const SectionAuthForm: FC<SectionAuthFormProps> = ({ customCSS, section, showError = false, onSubmit = noopAsync }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: publicSessionPrimaryInsuredData } = usePublicPrimaryInsured(gid);
  const [isSubmitting, setIsSubmitting] = useState<boolean>();

  const { dispatch } = useQuestionsFormState();

  const elementsSchema = useMemo(
    () =>
      getElementsSchema({
        firstName: publicSessionPrimaryInsuredData?.primary_insured.first_name ?? '',
        hasError: showError,
      }),
    [publicSessionPrimaryInsuredData?.primary_insured.first_name, showError]
  );

  const onSectionSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    await onSubmit();
    setIsSubmitting(false);
  };

  useEffect(() => {
    const questionsKeys = elementsSchema
      .map((el) => (el.kind === FormElementType.Question ? (el.content as QuestionElementContent).key : ''))
      .filter((k) => k !== '');

    if (questionsKeys.length > 0) {
      dispatch(setQuestionsKeys(questionsKeys));
    }
  }, [elementsSchema, dispatch]);

  return (
    <div css={containerCSS}>
      <div css={scrollPointCSS} id={`section-${section.key}`} />
      <ContainerForm
        customCSS={customCSS}
        isLineHidden
        containerKey={DOB_CONTAINER_KEY}
        isFirstContainerOnPage
        isLastContainerOnPage
        visibleElements={elementsSchema}
      />

      <div css={sectionFormActionsCSS}>
        <Button type="button" fullWidth size={ButtonSize.Large} isLoading={isSubmitting} onClick={onSectionSubmit}>
          {section.action_label}
        </Button>
      </div>
    </div>
  );
};

export default SectionAuthForm;
