import { useParams } from 'react-router-dom';
import { useBrowserTabTitle } from '../../hooks/use-browser-tab-title';
import useTrackFullStorySession from '../../hooks/use-track-full-story-session';
import { fullHeightPageCSS } from '../../styles/layout.style';
import ActiveAdminButton from '../ActiveAdminButton/ActiveAdminButton';
import OLBFooter from '../OLBFooter/OLBFooter';
import OLBHeader from '../OLBHeader/OLBHeader';
import { mainFullHeightWrapperCSS, layoutContainerCSS, fixedHeaderCSS } from './PageContainer.style';
import type { PageContainerProps } from './PageContainer.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const PageContainer: FC<PageContainerProps> = ({
  customCSS,
  children,
  layoutVariant,
  allowTabTitleUpdate = false,
  allowFullStoryTracking = false,
  showFooter = true,
  showHeader = true,
  subHeaderComponent,
}) => {
  const { gid } = useParams() as DefaultLocation;
  useBrowserTabTitle(gid, allowTabTitleUpdate);
  useTrackFullStorySession(gid, allowFullStoryTracking);

  return (
    <div css={fullHeightPageCSS}>
      <ActiveAdminButton gid={gid} />
      {showHeader && <OLBHeader customCSS={fixedHeaderCSS} subHeaderComponent={subHeaderComponent} />}
      <main css={[mainFullHeightWrapperCSS, layoutContainerCSS(layoutVariant), customCSS]}>{children}</main>
      {showFooter && <OLBFooter />}
    </div>
  );
};

export default PageContainer;
