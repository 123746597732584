const sensitiveQuestionsKeys = [
  /person_phone/,
  /vehicle_[0-9]_vin/,
  /driver_[0-9]_license_number/,
  /auto_policy_notification_phone_number/,
  /progressive_snapshot_program_phone/,
];

const isSensitiveQuestion = (questionKey: string): boolean => {
  return sensitiveQuestionsKeys.some((key) => key.test(questionKey));
};

export default isSensitiveQuestion;
