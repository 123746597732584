import { Fragment, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import type { PageFormWrapperProps } from './PageFormWrapper.type';
import type { FC } from 'react';

const PageFormWrapper: FC<PageFormWrapperProps> = ({ children, defaultValues }) => {
  const methods = useForm({
    defaultValues,
    shouldUnregister: false,
    shouldFocusError: false,
    mode: 'onTouched',
  });

  useEffect(() => {
    methods.reset(defaultValues, { keepDirty: true, keepTouched: true });
  }, [methods, defaultValues]);

  return (
    <Fragment>
      <FormProvider {...methods}>{children}</FormProvider>
      {/* TODO: Has issue with rerendering after package update */}
      {/* {import.meta.env.DEV && <DevTool control={methods.control} />} */}
    </Fragment>
  );
};

export default PageFormWrapper;
