import { useEffect, useState } from 'react';
import TooltipQuestionButton from '../../../shared/components/TooltipQuestionButton/TooltipQuestionButton';
import {
  containerCSS,
  indicatorCSS,
  indicatorWithAnimationCSS,
  tooltipButtonCSS,
} from './TextBoxNavigationPoint.style';
import type { TextBoxNavigationPointProps } from './TextBoxNavigationPoint.type';
import type { FC } from 'react';

const TextBoxNavigationPoint: FC<TextBoxNavigationPointProps> = ({
  customCSS,
  elementKey,
  heading,
  description,
  enableAnimation = false,
}) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (enableAnimation) {
      const timerId = setTimeout(() => {
        setAnimate(true);
      }, 400);

      return () => clearTimeout(timerId);
    }
  }, [enableAnimation]);

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={[indicatorCSS, enableAnimation && indicatorWithAnimationCSS(animate)]} />
      <div>
        <span>{heading}</span>{' '}
        {description && (
          <TooltipQuestionButton customCSS={tooltipButtonCSS} id={elementKey} content={description} size={24} />
        )}
      </div>
    </div>
  );
};

export default TextBoxNavigationPoint;
