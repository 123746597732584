import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text14CSS, text20CSS, text32CSS, text24CSS, text12CSS } from '../../../../../styles/typography.style';
import { textCSS } from '../../../../checkout/components/CheckoutHomePage/CheckoutBindStep/CheckoutBindStep.style';
import type { SerializedStyles } from '@emotion/react';

export const scheduleCallCSS = css`
  padding: 16px 24px;
  width: 100%;
  color: ${theme.colors.white};
  align-self: end;

  ${mq[0]} {
    ${textCSS};
    height: 60px;
    width: auto;
    padding: 13px 24px;
    ${text20CSS};
    border-width: 4px;
    color: ${theme.colors.white};
    align-self: center;
  }
`;

export const infoMessageCSS = css`
  margin-top: 24px;
  margin-bottom: 0;
  display: block;
  text-align: center;

  ${mq[0]} {
    margin-bottom: 0;
  }
`;

export const tooltipButtonCSS = css`
  background-color: transparent;
  color: ${theme.colors.azure50};
  text-decoration: underline;
  display: inline !important;
  padding: 0;
`;

export const badgesCSS = css`
  position: absolute;
  left: 50%;
  top: -15px;
  transform: translate(-50%, 0);
  display: flex;
  gap: 12px;

  ${mq[0]} {
    top: -19px;
    gap: 16px;
  }
`;

export const badgeCSS = css`
  ${text12CSS};
  line-height: 16px;
  font-weight: 700;
  padding: 6px 12px;
  color: ${theme.colors.white};
  border-radius: 40px;
  text-align: center;
  white-space: nowrap;

  ${mq[0]} {
    ${text14CSS};
    line-height: 20px;
    padding: 8px 16px;
  }
`;

export const savingsBadgeCSS = css`
  background-color: ${theme.colors.positiveGreen27};
`;

export const bundleBadgeCSS = css`
  background-color: ${theme.colors.violet};
`;

export const bundleCardCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  border-color: ${theme.colors.gray30} drop-shadow(2px 4px 0px rgba(0, 0, 0, 0.12));
  width: fit-content;
  border-radius: 4px;
`;

export const activeBundleButtonCSS = css`
  font-weight: 700;
  color: ${theme.colors.azure50};
  background-color: ${theme.colors.azure95};
  border: 2px solid ${theme.colors.azure62};
`;

export const bundleButtonCSS = (isActive: boolean): SerializedStyles => css`
  cursor: pointer;
  border-style: solid;
  padding: 12px 7px;
  color: ${theme.colors.black};
  font-variant-numeric: lining-nums;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  background-color: ${theme.colors.white};
  border-color: ${theme.colors.white};
  min-width: 160px;

  ${mq[0]} {
    min-width: 200px;
    padding: 12px 20px;
  }

  &:hover {
    background-color: ${theme.colors.azure95};
    border: 2px solid ${theme.colors.azure62};
  }
  ${isActive && activeBundleButtonCSS};
`;

export const dividerCSS = css`
  margin: 0 12px;
  border-left-color: ${theme.colors.gray10};
  border-left-width: 2px;

  ${mq[0]} {
    margin: 0 40px;
  }
`;

export const priceDescriptionCSS = css`
  ${text14CSS};
  color: ${theme.colors.gray60};
  white-space: nowrap;
  font-weight: 400;
`;

export const deductibleCSS = css`
  font-weight: 700;
  padding-right: 32px;
  line-height: 32px;
  padding-bottom: 10px;
  ${text24CSS};

  ${mq[0]} {
    padding-right: 24px;
  }
`;

export const premiumCSS = css`
  font-weight: 700;
  line-height: 40px;
  padding-bottom: 10px;
  ${text32CSS};
`;

export const bundleToggleWrapperCSS = css`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const pricesInformationCSS = css`
  display: flex;
  flex-grow: 1;
  align-items: end;
  justify-content: space-around;
  padding-right: 0;
  padding-bottom: 32px;

  ${mq[1]} {
    ${text32CSS};
    font-weight: 700;
    padding-right: 24px;
    padding-bottom: 0;
    align-items: end;
    justify-content: right;
  }
`;

export const policyInfoCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
  flex-direction: column;

  ${mq[1]} {
    flex-direction: row;
  }
`;

export const carrierLogoCSS = css`
  min-width: 150px;
  padding-bottom: 24px;
  justify-content: center;

  img {
    max-width: 180px;
    max-height: 60px;
  }

  ${mq[1]} {
    padding-bottom: 0;
  }
`;

export const bundleOffCSS = css`
  border-radius: 4px 0 0 4px;
`;

export const bundleOnCSS = css`
  border-radius: 0 4px 4px 0;
`;

export const policyInfoWrapperCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 32px;
  flex-wrap: wrap;
  padding-bottom: 32px;

  ${mq[0]} {
    padding-top: 0;
    padding-bottom: 32px; // 24
  }
`;

export const yearCSS = css`
  ${text20CSS};
  ${mq[0]} {
    ${text32CSS};
  }
`;

export const coveragesCSS = css`
  padding-top: 20px;
  padding-bottom: 32px;

  ${mq[0]} {
    padding-top: 16px;
    padding-bottom: 48px;
  }
`;
