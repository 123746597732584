import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { textCSS, text20CSS, text24CSS, text40CSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  ${textCSS};
  padding-top: 36px;

  ${mq[0]} {
    padding-top: 0;
    ${text20CSS};
    text-align: center;
    padding: 0px 40px 80px;
  }
`;

export const rowWrapperCSS = css`
  display: flex;
  margin-bottom: 32px;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: space-between;

  ${mq[0]} {
    position: unset;
    margin-bottom: 100px;
    flex-direction: row;
  }
`;

export const headingWrapperCSS = css`
  min-width: 61%;
  font-weight: bold;
  text-align: left;
  ${text24CSS};
`;

export const subHeaderCSS = css`
  ${text20CSS};

  ${mq[0]} {
    ${text24CSS};
    max-width: 550px;
  }
`;

export const headingCSS = css`
  ${text24CSS};
  font-weight: 700;
  margin-bottom: 12px;

  ${mq[0]} {
    ${text40CSS};
    margin-bottom: 20px;
  }
`;

export const descriptionWrapperCSS = css`
  color: ${theme.colors.gray60};

  ${mq[0]} {
    margin-top: 0;
    text-align: left;
    max-width: 260px;
  }
`;

export const cardWrapperCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column-reverse;
  margin-top: 12px;
  padding: 16px;

  ${mq[0]} {
    text-align: left;
    flex-direction: row;
    padding: 40px;
  }

  img {
    max-width: 86%;
    margin-bottom: 10px;
  }
`;

export const itemCSS = css`
  margin-bottom: 20px;
  ${textCSS};
  color: ${theme.colors.gray60};

  ${mq[0]} {
    ${text20CSS};
    margin-bottom: 32px;
    max-width: 520px;
    margin-right: 40px;
  }
`;

export const itemHeadingCSS = (isSecond?: boolean): SerializedStyles => css`
  color: ${theme.colors.black};
  margin-bottom: 12px;
  font-weight: bold;
  ${text20CSS};

  ${mq[0]} {
    ${text24CSS};
  }

  ${isSecond &&
  css`
    ${textCSS};

    ${mq[0]} {
      ${text20CSS};
    }
  `};
`;

export const subHeadingCSS = css`
  font-weight: bold;
`;

export const imageCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const dividerCSS = css`
  ${mq[0]} {
    margin: 0 30px;
  }
`;
