import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';

export const headingCSS = css`
  padding: 2px 0;
  margin-bottom: 10px;
  ${mq[0]} {
    padding: 6px 0;
    margin-bottom: 20px;
  }
`;

export const workflowCSS = css`
  position: relative;
  padding-top: 45px;
`;

export const inputCSS = css`
  margin-bottom: 20px;
  width: 100%;
`;

export const radioWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  ${mq[0]} {
    flex-wrap: nowrap;
  }
`;
