import { addYears, isAfter, isBefore, isValid, parseISO } from 'date-fns';
import { arrayHasValueInArray } from '../shared/utils/array-helpers.util';
import { VisibilityOperation } from '../types/visibility-condition.type';
import { normalizeDateValue } from './date.util';
import type { Answers, AnswerValue } from '../types/answer.type';
import type { VisibilityValue, VisibilityCondition } from '../types/visibility-condition.type';

const operations: Record<VisibilityOperation, (actual: AnswerValue, expected: VisibilityValue) => boolean> = {
  [VisibilityOperation.Equal]: (actual, expected) => actual === expected,
  [VisibilityOperation.NotEqual]: (actual, expected) => actual !== expected,
  [VisibilityOperation.Filled]: (actual) => !!actual,
  [VisibilityOperation.NotFilled]: (actual) => !actual,
  [VisibilityOperation.LessThan]: (actual, expected) => Number(actual as string) < Number(expected as string),
  [VisibilityOperation.GreaterThan]: (actual, expected) => Number(actual as string) > Number(expected as string),
  [VisibilityOperation.OneOf]: (actual, expected) => {
    if (
      (typeof actual === 'string' || Array.isArray(actual)) &&
      (typeof expected === 'string' || Array.isArray(expected))
    ) {
      const visibilityValue = typeof expected === 'string' ? expected.split(', ') : expected;

      return typeof actual === 'string'
        ? visibilityValue.includes(actual)
        : arrayHasValueInArray(visibilityValue, actual);
    }

    return false;
  },
  [VisibilityOperation.NotOneOf]: (actual, expected) => {
    if (
      (typeof actual === 'string' || Array.isArray(actual)) &&
      (typeof expected === 'string' || Array.isArray(expected))
    ) {
      const visibilityValue = typeof expected === 'string' ? expected.split(', ') : expected;

      return typeof actual === 'string'
        ? !visibilityValue.includes(actual)
        : !arrayHasValueInArray(visibilityValue, actual);
    }

    return false;
  },
  [VisibilityOperation.Before]: (actual, expected) => {
    const isoDateValue = typeof actual === 'string' ? parseISO(normalizeDateValue(actual)) : '';
    const isoDateExpected = typeof expected === 'string' ? parseISO(expected) : '';
    return isValid(isoDateValue) && isValid(isoDateExpected)
      ? isBefore(isoDateValue as Date, isoDateExpected as Date)
      : false;
  },
  [VisibilityOperation.After]: (actual, expected) => {
    const isoDateValue = typeof actual === 'string' ? parseISO(normalizeDateValue(actual)) : '';
    const isoDateExpected = typeof expected === 'string' ? parseISO(expected) : '';
    return isValid(isoDateValue) && isValid(isoDateExpected)
      ? isAfter(isoDateValue as Date, isoDateExpected as Date)
      : false;
  },
  [VisibilityOperation.NewerThanYears]: (actual, expected) => {
    const isoDateValue = typeof actual === 'string' ? parseISO(normalizeDateValue(actual)) : '';
    const expectedYearDate = addYears(Date.now(), -Number(expected));
    return isValid(isoDateValue) ? isAfter(isoDateValue as Date, expectedYearDate) : false;
  },
  [VisibilityOperation.OlderThanYears]: (actual, expected) => {
    const isoDateValue = typeof actual === 'string' ? parseISO(normalizeDateValue(actual)) : '';
    const expectedYearDate = addYears(Date.now(), -Number(expected));
    return isValid(isoDateValue) ? isBefore(isoDateValue as Date, expectedYearDate) : false;
  },
};

const validate = (operation: VisibilityOperation, actual: AnswerValue, expected: VisibilityValue): boolean => {
  return operations[operation](actual, expected);
};

const isVisibleByAnswers = (answers: Answers, orConditions?: VisibilityCondition[][]): boolean => {
  if (!orConditions?.length) return true;

  return orConditions.some((andConditions: VisibilityCondition[]) =>
    andConditions.every((c) => validate(c.operation, answers[c.source], c.value))
  );
};

export default isVisibleByAnswers;
