import axios from 'axios';
import SMARTY_STREETS from '../../constants/smarty-streets';
import type { SuggestionResponse } from '../../types/smarty-streets.type';

interface IStreetService {
  suggest: (search: string) => Promise<SuggestionResponse>;
  suggestSecondary: (search: string, selected: string) => Promise<SuggestionResponse>;
}

const streetService: IStreetService = {
  suggest: async (search) =>
    await axios.get(SMARTY_STREETS.smartyStreetsAutocompleteApi, {
      method: 'GET',
      params: {
        key: SMARTY_STREETS.smartyStreetsWebKey,
        include_only_states: 'allstates',
        search,
      },
    }),
  suggestSecondary: async (search, selected) =>
    await axios.get(SMARTY_STREETS.smartyStreetsAutocompleteApi, {
      method: 'GET',
      params: {
        key: SMARTY_STREETS.smartyStreetsWebKey,
        include_only_states: 'allstates',
        search,
        selected,
      },
    }),
};

export default streetService;
