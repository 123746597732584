import { Modal as MuiModal } from '@mui/material';
import { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { recaptchaModalWrapperCSS } from './RecaptchaModal.style';
import type { RecaptchaModalProps } from './RecaptchaModal.type';
import type { FC } from 'react';

const RecaptchaModal: FC<RecaptchaModalProps> = ({ isOpen, onVisibilityChange, siteKey }) => {
  const recaptchaRef = useRef(null);

  return (
    <MuiModal open={isOpen} css={recaptchaModalWrapperCSS}>
      <div>
        <ReCAPTCHA ref={recaptchaRef} size="normal" sitekey={siteKey} type="image" onChange={onVisibilityChange} />
      </div>
    </MuiModal>
  );
};

export default RecaptchaModal;
