import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../api/page';
import { usePublicFlowConfig } from '../../../../api/public/flow-config';
import { useSession } from '../../../../api/session';
import FlowNavigation from '../../../../components/FlowNavigation/FlowNavigation';
import { prepareWorkflowDefaultAnswers } from '../../../../questionsForm/utils/answers/workflow-schema-answers.util';
import { extraLayoutPaddingCSS, innerLayoutContainerCSS, layoutNavigationCSS } from '../../../../styles/layout.style';
import { PageKeyHome } from '../../../../types/page.type';
import { StrategyType } from '../../../../types/public-flow-config.type';
import { Flows } from '../../../../types/route-params.type';
import { QuestionsFormProvider } from '../../contexts/questions-context/reducer';
import ContactReminderModal from '../ContactReminderModal/ContactReminderModal';
import PageFormWrapper from '../PageFormWrapper/PageFormWrapper';
import QuestionsForm from '../QuestionsForm/QuestionsForm';
import { questionFormCSS } from './QuestionsPage.style';
import type { QuestionsPageProps } from './QuestionsPage.type';
import type { Answers } from '../../../../types/answer.type';
import type { PageKey } from '../../../../types/page.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const QuestionsPage: FC<QuestionsPageProps> = ({ customCSS }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const { data: flowConfigData } = usePublicFlowConfig(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData } = usePage(gid, currentPageKey);
  const [defaultAnswers, setDefaultAnswers] = useState<Answers>({});
  const sessionStrategy = flowConfigData?.flow_config.strategy;
  const renderContactReminderModal =
    false && sessionStrategy === StrategyType.DigitalProfile && currentPageKey === PageKeyHome.PropertyInformation;

  const previousPageKey = useRef<PageKey | undefined>(undefined);

  useEffect(() => {
    if (pageData?.answers && previousPageKey.current !== currentPageKey) {
      const defaultAnswers = prepareWorkflowDefaultAnswers(pageData);
      setDefaultAnswers(defaultAnswers);
      previousPageKey.current = currentPageKey;
    }
  }, [pageData, currentPageKey]);

  return (
    <div css={customCSS}>
      {flow === Flows.Auto && (
        <div css={layoutNavigationCSS}>
          <FlowNavigation />
        </div>
      )}
      <PageFormWrapper defaultValues={defaultAnswers}>
        <QuestionsFormProvider>
          <QuestionsForm
            customCSS={[
              innerLayoutContainerCSS(flow === Flows.Auto),
              extraLayoutPaddingCSS,
              questionFormCSS(flow === Flows.Auto),
            ]}
          />
        </QuestionsFormProvider>
      </PageFormWrapper>
      {renderContactReminderModal && <ContactReminderModal currentPageKey={currentPageKey} />}
    </div>
  );
};

export default QuestionsPage;
