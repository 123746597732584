import type { SerializedStyles } from '@emotion/react';
import type { ReactNode } from 'react';

export interface HeaderMobileNavigationWrapperProps {
  customCSS?: SerializedStyles;
  getHeaderElement?: () => HTMLElement | null;
  children: ReactNode;
}

export enum ScrollDirection {
  Up = 'up',
  Down = 'down',
}
