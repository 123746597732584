import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useCreateFullStorySession = (
  gid?: string
): UseBaseMutationResult<{ data: null }, HTTPResponse, { url: string }> =>
  useMutation({
    mutationFn: ({ url }: { url: string }): Promise<{ data: null }> => {
      if (gid) {
        return api.post(getApiUrl(API_PATH.fullStories, gid), { body: { data: { url } } });
      }

      return Promise.resolve({ data: null });
    },
    throwOnError: false,
  });

export default useCreateFullStorySession;
