import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PencilIcon from '../../../../../assets/pencil.svg?react';
import InfoFormBlock from '../../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import Question from '../../../../../questionsForm/components/Question/Question';
import { scrollToError } from '../../../../../questionsForm/utils/questions-form.util';
import Button from '../../../../../shared/components/Button/Button';
import Modal from '../../../../../shared/components/Modal/Modal';
import { noopAsync } from '../../../../../shared/utils/noop';
import { containerCSS, iconCSS, labelCSS, modalCSS } from './QuoteDetailsEditButton.style';
import type { QuoteDetailsEditButtonProps } from './QuoteDetailsEditButton.type';
import type { FC } from 'react';

const QuoteDetailsEditButton: FC<QuoteDetailsEditButtonProps> = ({
  customCSS,
  policyExpirationDate,
  questions = [],
  onSubmit = noopAsync,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<Record<string, string>>({
    shouldUnregister: true,
    shouldFocusError: false,
    mode: 'onTouched',
    defaultValues: {},
  });

  useEffect(() => {
    if (questions.length) {
      const defaultValues = questions.reduce<Record<string, string>>((acc, question) => {
        if (question.answer) {
          acc[question.questionKey] = question.answer;
        }
        return acc;
      }, {});
      methods.reset(defaultValues);
    }
  }, [methods, questions]);

  const getInfoBlockContent = (): string => {
    const message =
      'Effective date must be later than the current date and not later than the prior policy expiration date.';
    return policyExpirationDate
      ? `Your current policy <strong>expires on ${policyExpirationDate}</strong>. ${message}`
      : message;
  };

  const handleCloseModal = (): void => {
    if (isSubmitting) return;

    setIsOpen(false);
  };

  const handleUpdate = async (answers: Record<string, string>): Promise<void> => {
    setIsSubmitting(true);
    await onSubmit(answers);
    setIsSubmitting(false);
    setIsOpen(false);
  };

  const handleButtonClick = (): void => {
    setIsOpen(true);
  };

  return (
    <>
      <Button customCSS={[containerCSS, customCSS]} variant="text" onClick={handleButtonClick}>
        <PencilIcon css={iconCSS} /> <span css={labelCSS}>Edit</span>
      </Button>
      <Modal
        customCSS={modalCSS}
        title="Edit basic details"
        isOpen={isOpen}
        onClose={handleCloseModal}
        actionButtons={[
          { text: 'Cancel', variant: 'secondary', disabled: isSubmitting, onClick: handleCloseModal },
          {
            text: 'Update',
            type: 'submit',
            isLoading: isSubmitting,
            onClick: methods.handleSubmit(handleUpdate, scrollToError),
          },
        ]}
      >
        <InfoFormBlock content={getInfoBlockContent()}></InfoFormBlock>
        <FormProvider {...methods}>
          <form id="quote-details-edit-form">
            {questions.map(({ answer, ...props }) => (
              <Question key={props.questionKey} {...props} />
            ))}
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default QuoteDetailsEditButton;
