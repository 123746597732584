import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

const formWithNavigationCSS = css`
  padding-left: 18px;
  ${mq[0]} {
    padding-left: ${theme.navigationWidthTablet + 38}px;
  }
  ${mq[1]} {
    padding-left: ${theme.navigationWidth + 38}px;
  }
`;

const formWithoutNavigationCSS = css`
  padding-left: ${theme.sizes.questionNavDotPadding[0]}px;
  margin-left: auto;
  margin-right: auto;
  ${mq[0]} {
    padding-left: ${theme.sizes.questionNavDotPadding[1]}px;
  }
`;

export const questionFormCSS = (withNavigation?: boolean): SerializedStyles => css`
  ${mq[0]} {
    ${withNavigation &&
    css`
      max-width: 580px;
    `}
  }
  ${withNavigation ? formWithNavigationCSS : formWithoutNavigationCSS};
`;
