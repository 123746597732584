import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { TabsVariant } from './Tabs.type';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  .MuiTabs-indicator {
    display: none;
  }
`;

const roundedTabCSS = css`
  border: 2px solid transparent;
  border-radius: 16px;
  margin-right: 8px;
  margin-bottom: 8px;

  &.Mui-selected {
    font-weight: 700;
    color: ${theme.colors.azure50};
    background-color: ${theme.colors.azure95};
    border-color: ${theme.colors.azure50};
  }
  &:hover {
    background-color: ${theme.colors.azure95};
  }
`;
const rectangleTabCSS = css`
  margin-right: 4px;
  &.Mui-selected {
    font-weight: 700;
    color: ${theme.colors.white};
    background-color: ${theme.colors.azure50};

    &:after {
      position: absolute;
      content: 'aaa';
      width: 16px;
      height: 16px;
      background-color: ${theme.colors.errorRed};
      transform: rotate(45deg);
      bottom: -10px;
    }
  }
  &:hover {
    background-color: ${theme.colors.azure62};
    color: ${theme.colors.white};
  }
`;

export const tabCSS = (variant: TabsVariant): SerializedStyles => css`
  background-color: ${theme.colors.gray5};
  color: ${theme.colors.black};
  text-transform: none;
  font-variant-numeric: lining-nums;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;

  ${variant === TabsVariant.ROUNDED ? roundedTabCSS : rectangleTabCSS};
  margin-bottom: 0;
`;

export const scrollIconCSS = (end = false): React.CSSProperties => ({
  transform: end ? 'rotate(-90deg)' : 'rotate(90deg)',
  width: '16px',
});

export const tabsCSS = css`
  .MuiTouchRipple-root {
    display: none;
  }
  .MuiTabs-scrollButtons {
    &:hover {
      background-color: ${theme.colors.gray5};
    }
    &:active {
      background-color: ${theme.colors.gray10};
    }
  }
`;
