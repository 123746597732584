import { RadioGroupVariant } from '../../../../shared/components/RadioGroup/RadioGroup.type';
import { FormElementTag } from '../../../../types/form-element.type';

export const getRadioGroupVariant = (formElementTags: FormElementTag[]): RadioGroupVariant => {
  if (formElementTags.includes(FormElementTag.RadioList)) {
    return RadioGroupVariant.List;
  }

  if (formElementTags.includes(FormElementTag.RadioPhotoGrid)) {
    return RadioGroupVariant.PhotoGrid;
  }

  return RadioGroupVariant.Default;
};
