import { useRef } from 'react';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../../constants/full-story';
import { focusHelperCSS, inputCSS, inputWrapperCSS } from '../../../../../shared/components/Input/Input.style';
import noop from '../../../../../shared/utils/noop';
import { isValueSpecificType } from '../../../../utils/answers/answers.util';
import isSensitiveQuestion from '../../../../utils/is-sensitive-question.util';
import {
  getHiddenQuestionValidationRules,
  getValidationRules,
} from '../../../../utils/question-validations/question-validations.util';
import { PHONE_PATTERN_VALIDATION } from '../PhoneQuestions.util';
import { normalizePhoneValue } from './PhoneInput.util';
import type { PhoneInputProps } from './PhoneInput.type';
import type { FC } from 'react';

const DEFAULT_MASK = '(999) 999-9999';
const HIDDEN_MASK = '(\\*\\*\\*) \\*\\*\\*-9999';

const PhoneInput: FC<PhoneInputProps> = ({
  name,
  isDisabled,
  hasError = false,
  inputId,
  placeholder,
  validations = [],
  trackingForbidden = false,
  dense = false,
  onValidatePreviousInputs = noop,
}) => {
  const isAsteriskCleared = useRef(false);
  const notClearedSensitiveQuestion = isSensitiveQuestion(name) && !isAsteriskCleared.current;

  const getValue = (value: string): string => {
    if (notClearedSensitiveQuestion) {
      isAsteriskCleared.current = true;
      return value.includes('*') ? '' : value;
    }

    return normalizePhoneValue(value);
  };

  return (
    <Controller
      name={name}
      render={({ field: { onChange, value, onBlur, name, ref }, fieldState: { isDirty, isTouched } }) => {
        const safeValue = isValueSpecificType<string>(value, ['string']) ? value : '';
        const isPrefilled = Boolean(value && !isTouched);
        return (
          <div css={inputWrapperCSS}>
            <MaskedInput
              className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
              css={inputCSS(hasError, isDisabled, false, isPrefilled, dense)}
              id={inputId}
              mask={isDirty || !safeValue.includes('*') ? DEFAULT_MASK : HIDDEN_MASK}
              name={name}
              type="tel"
              onChange={(e) => {
                onChange({ target: { value: getValue(e.target.value) } });
              }}
              value={safeValue}
              disabled={isDisabled}
              onBlur={() => {
                onBlur();
                if (isValueSpecificType<string>(value, ['string']) && value.trim().length > 0) {
                  onValidatePreviousInputs();
                }
              }}
              inputRef={(r: HTMLInputElement) => {
                ref(r);
              }}
              placeholder={placeholder}
              autoComplete="off"
            />
            <div css={focusHelperCSS} className="input-focus-helper" />
          </div>
        );
      }}
      rules={
        notClearedSensitiveQuestion
          ? getHiddenQuestionValidationRules(validations)
          : getValidationRules([PHONE_PATTERN_VALIDATION, ...validations])
      }
      defaultValue=""
    />
  );
};

export default PhoneInput;
