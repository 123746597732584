import { useEffect, useState } from 'react';
import { SubscriptionEventMessage } from '../../types/socket.type';
import type { SubscriptionEventData } from '../../types/socket.type';

export const useSlowQuotesFinishedSubscription = (
  gid: string,
  webSocketUrl?: string
): { isSlowQuotesFinished: boolean } => {
  const [isSlowQuotesFinished, setIsSlowQuotesFinished] = useState(false);

  useEffect(() => {
    let websocket: WebSocket;

    if (webSocketUrl && gid) {
      websocket = new WebSocket(webSocketUrl);

      websocket.onopen = () => {
        websocket.send(
          JSON.stringify({
            command: 'subscribe',
            identifier: JSON.stringify({
              channel: 'SessionChannel',
              session_gid: gid,
            }),
          })
        );
      };

      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data) as SubscriptionEventData;

        if (data.message?.message === SubscriptionEventMessage.SlowQuotingFinished) {
          setIsSlowQuotesFinished(true);
        }
      };
    }

    return () => {
      if (websocket) {
        websocket.close();
      }
    };
  }, [gid, webSocketUrl]);

  return { isSlowQuotesFinished };
};
