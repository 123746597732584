import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { checkboxIconHoveredCSS } from '../Checkbox/CheckboxIcon/CheckboxIcon.style';
import { focusHelperCSS } from '../Input/Input.style';
import { CounterInputVariant } from './CounterInput.type';
import type { SerializedStyles } from '@emotion/react';

const containerErrorCSS = css`
  border-color: ${theme.colors.errorRed};
  box-shadow: inset 0 0 0 1px ${theme.colors.errorRed};

  &:hover {
    border-color: ${theme.colors.errorRed};
  }
`;

export const containerCSS = (
  variant?: CounterInputVariant,
  hasError?: boolean,
  isFocused?: boolean,
  isChecked?: boolean,
  isPrefilled?: boolean
): SerializedStyles => css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${isPrefilled ? theme.colors.azure50 : theme.colors.black};
  box-shadow: inset 0 0 0 1px ${isPrefilled ? theme.colors.azure50 : 'transparent'};
  border-radius: ${theme.sizes.borderRadius}px;
  padding: 9px;
  position: relative;
  box-sizing: border-box;
  height: ${theme.sizes.controlHeight}px;

  &:hover {
    border-color: ${isPrefilled ? theme.colors.azure50 : theme.colors.azure62};

    .checkbox-input-mark {
      ${checkboxIconHoveredCSS(hasError)};
    }
  }

  ${hasError && containerErrorCSS};

  ${variant === CounterInputVariant.WithCheckbox && containerWithCheckboxCSS(isChecked, hasError)}

  ${isFocused &&
  css`
    border-color: ${theme.colors.black};
    box-shadow: none;

    &:hover {
      border-color: ${theme.colors.black};
    }
  `}
`;

export const counterWrapperCSS = css`
  position: relative;
`;

const getBorderColor = (focused?: boolean, hasError?: boolean): string => {
  if (focused) {
    return hasError ? theme.colors.errorRed : theme.colors.azure50;
  }
  return 'transparent';
};

export const activeOutlineCSS = (focused?: boolean, hasError?: boolean): SerializedStyles => css`
  ${focusHelperCSS};
  left: -4px;
  right: -4px;
  top: -4px;
  bottom: -4px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-color: ${getBorderColor(focused, hasError)};
  z-index: -1;
`;

const containerWithCheckboxCSS = (isChecked?: boolean, hasError?: boolean): SerializedStyles => css`
  position: relative;
  flex-direction: column;
  justify-content: space-evenly;
  height: auto;
  min-height: 196px;
  cursor: pointer;
  text-align: center;
  ${isChecked &&
  css`
    box-shadow: inset 0 0 0 1px ${hasError ? theme.colors.errorRed : theme.colors.azure50};
    border-color: ${hasError ? theme.colors.errorRed : theme.colors.azure50};

    &:hover {
      border-color: ${hasError ? theme.colors.errorRed : theme.colors.azure50};
    }
  `};
`;
