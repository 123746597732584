export interface IObject {
  [key: string]: string | number | boolean | IObject | undefined | null;
}

export const compactObject = (val: IObject): IObject => {
  return Object.keys(val).reduce((acc: IObject, key) => {
    const value = val[key];
    if (Boolean(value) && value !== null) acc[key] = typeof value === 'object' ? compactObject(value) : value;
    return acc;
  }, {});
};

export const isObjectEmpty = (value: Record<string, unknown>): boolean => {
  return Object.keys(value).length === 0 && value.constructor === Object;
};
