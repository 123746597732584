import { isTrackForbidden } from '../../../utils/form-element-tags.util';
import { QuestionWrapper } from '../../QuestionWrapper/QuestionWrapper';
import QuestionInput from '../QuestionInput/QuestionInput';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const TextInputQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  placeholder,
  isDisabled,
  validations,
  label,
  hideLabel,
  description,
  icon,
  hint,
  workflowHeading,
  gridWidth,
  dense,
  tags = [],
}) => (
  <QuestionWrapper
    questionKey={questionKey}
    label={label}
    hideLabel={hideLabel}
    hint={hint}
    description={description}
    icon={icon}
    workflowHeading={workflowHeading}
    isDisabled={isDisabled}
    gridWidth={gridWidth}
    inputProps={{
      name: questionKey,
      inputId: questionKey,
      placeholder,
      isDisabled,
      validations,
      trackingForbidden: isTrackForbidden(tags),
      dense,
    }}
    inputComponent={QuestionInput}
  />
);

export default TextInputQuestion;
