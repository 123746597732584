import { ClickAwayListener, Tooltip as MuiTooltip } from '@mui/material';
import { useId, useState } from 'react';
import CloseIcon from '../../../assets/close.svg?react';
import { muiTooltipCSS, tooltipArrowCSS } from '../../../styles/common.style';
import theme from '../../../styles/theme';
import noop from '../../utils/noop';
import { buttonCSS } from '../Button/Button.style';
import { TooltipTheme } from '../Tooltip/Tooltip.type';
import { tooltipContentCSS, tooltipCSS, closeIconCSS } from './Toggletip.style';
import type { ToggletipProps } from './Toggletip.type';
import type { FC } from 'react';

const Toggletip: FC<ToggletipProps> = ({
  id,
  children,
  content,
  customCSS,
  onClick = noop,
  showByDefault = false,
  displayButton = true,
}) => {
  const [showToggletip, setShowToggletip] = useState(showByDefault);
  const generatedId = useId();
  const key = id ?? generatedId;

  const onToggletipClickOutside = (): void => {
    if (showToggletip) {
      setShowToggletip((prevState) => !prevState);
    }
  };

  const toggletipProps = {
    id: `tooltip-${key}`,
    arrow: true,
    componentsProps: {
      tooltip: { sx: { ...muiTooltipCSS(TooltipTheme.Light), ...customCSS?.toggletipCSS } },
      arrow: { sx: { ...tooltipArrowCSS(TooltipTheme.Light) } },
    },
    title: (
      <ClickAwayListener onClickAway={onToggletipClickOutside}>
        <div>
          <CloseIcon
            onClick={() => setShowToggletip((prevState) => !prevState)}
            color={theme.colors.gray60}
            css={closeIconCSS}
            width={16}
          />
          <div css={tooltipContentCSS}>{content}</div>
        </div>
      </ClickAwayListener>
    ),
    open: showToggletip,
    disableFocusListener: true,
    disableHoverListener: true,
  };

  const onChildrenClicked = (): void => {
    setShowToggletip((prevState) => !prevState);
    onClick();
  };

  return (
    <MuiTooltip {...toggletipProps}>
      {displayButton ? (
        <button type="button" css={[buttonCSS(false), tooltipCSS, customCSS?.buttonCSS]} onClick={onChildrenClicked}>
          {children}
        </button>
      ) : (
        <div css={[tooltipCSS, customCSS?.buttonCSS]}>{children}</div>
      )}
    </MuiTooltip>
  );
};

export default Toggletip;
