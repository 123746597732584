import { css } from '@emotion/react';
import { indicatorLineCSS } from '../../../styles/form.style';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text20CSS, text24CSS, text32CSS, text40CSS, textCSS } from '../../../styles/typography.style';
import { IconSize } from './TextFormBlock.type';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (hasHiddenLine?: boolean, isPaddingBottomHidden?: boolean): SerializedStyles => css`
  padding-bottom: ${isPaddingBottomHidden ? 0 : '48px'};
  position: relative;
  flex-basis: 100%;

  &:before {
    ${indicatorLineCSS};
    ${
      hasHiddenLine &&
      css`
        background-color: ${theme.colors.white};
      `
    };
  }

  ${mq[0]} {
    padding-bottom: ${isPaddingBottomHidden ? 0 : '56px'}
    margin-right: ${theme.sizes.inputHorizontalSpacing}px;
  }
`;

export const containerInnerCSS = css`
  display: flex;
`;

export const blockIconCSS = (iconSize?: IconSize): SerializedStyles => css`
  margin-right: 24px;
  ${mq[0]} {
    ${iconSize === IconSize.Small && smallBlockIconCSS};
    ${iconSize === IconSize.Default && defaultBlockIconCSS};
  }
`;

export const defaultBlockIconCSS = css`
  height: 120px;
  margin-right: 34px;
`;

export const smallBlockIconCSS = css`
  width: 72px;
  height: 72px;
  margin-right: 12px;
  position: relative;
  top: 10px;
`;

export const headingCSS = css`
  ${text32CSS};
  line-height: 36px;
  font-weight: 700;
  vertical-align: middle;
  ${mq[0]} {
    ${text40CSS};
  }
`;

export const headingIconCSS = css`
  margin-right: 8px;
  vertical-align: middle;
  ${mq[0]} {
    height: 40px;
    margin-right: 20px;
  }
`;

export const subHeadingCSS = css`
  ${textCSS};
  font-weight: 700;
  margin-bottom: 6px;
  ${mq[0]} {
    ${text24CSS};
    line-height: 32px;
    margin-bottom: 8px;
  }
`;

export const descriptionCSS = css`
  ${textCSS};
  margin-top: 4px;
  ${mq[0]} {
    ${text20CSS};
    margin-top: 8px;
  }
`;
