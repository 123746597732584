import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAcceptedDisclosures } from '../../../../api/disclosures';
import { useMutateAnswers, usePage } from '../../../../api/page';
import { useMutateSession, useSession } from '../../../../api/session';
import InfoIcon from '../../../../assets/info.svg?react';
import { SEGMENT } from '../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../constants/query-cache-keys';
import usePhoneNumber from '../../../../hooks/use-phone-number';
import { useSegment } from '../../../../hooks/use-segment';
import QuestionPageHeading from '../../../../questionsForm/components/QuestionPageHeading/QuestionPageHeading';
import { getElementByType } from '../../../../questionsForm/utils/form-element.util';
import { scrollToError } from '../../../../questionsForm/utils/questions-form.util';
import Button from '../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import { FormElementType, type FormElementSchema } from '../../../../types/form-element.type';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../../../utils/current-form-keys.util';
import { getPathByPageType } from '../../../../utils/route-path.util';
import isVisibleByAnswers from '../../../../utils/visibility-conditions.util';
import DiscountsFormLoading from '../DiscountsFormLoading/DiscountsFormLoading';
import {
  buttonArrowCSS,
  disclosureCSS,
  disclosuresContainerCSS,
  discoverMoreButtonCSS,
  discoverMoreWrapperCSS,
  formActionsCSS,
  formCSS,
  formInnerCSS,
  headingCSS,
  hintCSS,
  infoIconCSS,
} from './DiscountsForm.style';
import {
  ALWAYS_TO_SHOW_DISCOUNTS_NUMBER,
  LIMIT_DISCOUNTS_ON_SCREEN,
  getDisclosuresToRender,
  interpolateDisclosureCTA,
  prepareDisclosuresForSubmit,
  prepareDiscountAnswersForSubmit,
} from './DiscountsForm.util';
import type { DiscountsFormProps } from './DiscountsForm.types';
import type { Answers } from '../../../../types/answer.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { SectionContainer, WorkflowSectionSchema } from '../../../../types/section.type';
import type { SessionResponse } from '../../../../types/session.type';
import type { FC } from 'react';

const DiscountsForm: FC<DiscountsFormProps> = ({ customCSS }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const currentSectionKey = sessionData?.session.current_section_key;
  const { data: pageData } = usePage<WorkflowSectionSchema>(gid, currentPageKey);
  const { data: acceptedDisclosures } = useAcceptedDisclosures(gid);
  const { phoneNumber } = usePhoneNumber();

  const section = pageData?.page.sections[0];
  const sectionContainersElementsWithoutDisclosures = useMemo(
    () =>
      section?.containers
        ? section.containers.reduce<FormElementSchema[]>(
            (acc, curr) => [...acc, ...curr.elements.filter((e) => e.kind !== FormElementType.Disclosure)],
            []
          )
        : [],
    [section?.containers]
  );

  const disclosuresToRender = getDisclosuresToRender(section?.containers, acceptedDisclosures?.data);

  const elementsWithoutDisclosuresLength = sectionContainersElementsWithoutDisclosures.length;

  const { mutateAsync: mutateAnswers } = useMutateAnswers(gid);
  const { mutateAsync: mutateSession } = useMutateSession(gid);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const pageViewedTracked = useRef(false);

  const { track, page } = useSegment();

  const { handleSubmit, formState } = useFormContext();
  const pageAnswers = useWatch();

  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    if (currentPageKey && !pageViewedTracked.current) {
      pageViewedTracked.current = true;
      page(currentPageKey);
    }
  }, [currentPageKey, page]);

  const checkNextPage = (updatedSessionData: SessionResponse): void => {
    const nextCurrentPage = getCurrentNavigationPage(updatedSessionData);
    if (nextCurrentPage) {
      track(SEGMENT.events.pageCompleted, {
        page: currentPageKey,
      });
      navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
    }
  };

  const onDiscoverMoreClick = (): void => {
    setIsShowMore(true);
    track(SEGMENT.events.discoverMoreDiscountsClicked);
  };

  const onSubmit = async (data: Answers): Promise<void> => {
    track(SEGMENT.events.answersSubmitted, {
      section_key: currentSectionKey,
    });

    const disclosuresToUpdate = prepareDisclosuresForSubmit(disclosuresToRender);
    const dataToUpdate = prepareDiscountAnswersForSubmit(data, section?.containers as SectionContainer[]);

    const { answers } = await mutateAnswers({ ...dataToUpdate, ...disclosuresToUpdate });
    queryClient.setQueryData([QUERY_CACHE_KEYS.page, gid, currentPageKey], {
      ...pageData,
      answers,
    });
    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);

    checkNextPage(newSessionData);
  };

  const getVisibleSubElements = useCallback(
    (element: FormElementSchema): FormElementSchema => ({
      ...element,
      sub_elements: element.sub_elements.filter((subElement: FormElementSchema) =>
        isVisibleByAnswers(pageAnswers, subElement.visibility_conditions)
      ),
    }),
    [pageAnswers]
  );

  const visibleElements = useMemo(() => {
    const elements = sectionContainersElementsWithoutDisclosures
      .filter((e) => isVisibleByAnswers(pageAnswers, e.visibility_conditions))
      .map((e) => getVisibleSubElements(e));

    return !isShowMore && elementsWithoutDisclosuresLength > LIMIT_DISCOUNTS_ON_SCREEN
      ? elements.slice(0, ALWAYS_TO_SHOW_DISCOUNTS_NUMBER)
      : elements;
  }, [
    elementsWithoutDisclosuresLength,
    isShowMore,
    sectionContainersElementsWithoutDisclosures,
    pageAnswers,
    getVisibleSubElements,
  ]);

  if (!pageData || !section) {
    return (
      <div css={customCSS}>
        <DiscountsFormLoading />
      </div>
    );
  }

  return (
    <div css={customCSS}>
      {pageData?.page.content_heading && (
        <QuestionPageHeading
          heading={pageData.page.content_heading}
          description={pageData.page.content_description ?? ''}
          icon={pageData.page.content_icon_url ?? ''}
          customCSS={headingCSS}
        />
      )}

      <fieldset css={formCSS}>
        <div data-testid="form-inner" css={[formInnerCSS]}>
          {visibleElements.map((e) => getElementByType({ element: e }))}
        </div>
        {elementsWithoutDisclosuresLength > LIMIT_DISCOUNTS_ON_SCREEN && !isShowMore && (
          <div css={discoverMoreWrapperCSS}>
            <Button
              type="button"
              customCSS={discoverMoreButtonCSS}
              size={ButtonSize.Large}
              variant="secondary"
              onClick={onDiscoverMoreClick}
            >
              <div css={buttonArrowCSS} />
              Discover More Discounts
            </Button>
          </div>
        )}
      </fieldset>

      <div css={hintCSS}>
        <InfoIcon css={infoIconCSS} />
        <span>
          State specific rules apply. <strong>Documentation may be required</strong> after purchase to maintain
          discount.
        </span>
      </div>

      <div css={formActionsCSS}>
        <Button
          type="button"
          fullWidth
          size={ButtonSize.Large}
          isLoading={formState.isSubmitting}
          onClick={handleSubmit(onSubmit, scrollToError)}
        >
          {section.action_label}
        </Button>
      </div>

      <div css={disclosuresContainerCSS}>
        {disclosuresToRender.map((e) => (
          <div
            css={disclosureCSS}
            key={e.key}
            dangerouslySetInnerHTML={{
              __html: interpolateDisclosureCTA(e.content.text, [
                { key: '{{cta_text}}', value: section.action_label },
                { key: '{{agent_phone}}', value: phoneNumber },
              ]),
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default DiscountsForm;
