import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import { text20CSS, text32CSS, text48CSS, textCSS } from '../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (withIcon?: boolean): SerializedStyles => css`
  display: flex;
  padding-bottom: 32px;
  align-items: center;
  margin-left: ${withIcon ? '-24px' : 0};
  ${mq[0]} {
    margin-left: 0;
    padding-bottom: 49px;
    align-items: start;
  }
`;

export const skipButtonCSS = css`
  margin-left: 0;
  margin-top: 10px;

  ${mq[1]} {
    margin-left: 15px;
    margin-top: 5px;
  }
`;

export const iconCSS = css`
  margin-right: 12px;
  margin-top: 8px;
  width: 48px;
  ${mq[0]} {
    margin-right: 12px;
    margin-top: 8px;
    width: 72px;
  }
`;

export const headingCSS = css`
  ${text32CSS};
  line-height: 40px;
  font-weight: 700;
  display: flex;
  align-items: center;
  ${mq[0]} {
    ${text48CSS};
    line-height: 60px;
    font-weight: 900;
  }
`;

export const descriptionCSS = css`
  ${textCSS};
  margin-top: 4px;
  ${mq[0]} {
    ${text20CSS};
    line-height: 32px;
    margin-top: 2px;
  }
`;
