import { css } from '@emotion/react';
import footerConfetti from '../../../assets/footer-confetti.svg';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text12CSS, text14CSS } from '../../../styles/typography.style';

export const footerCSS = css`
  background-color: ${theme.colors.gray5};
  padding: 40px 0;
  position: relative;
  ${mq[0]} {
    padding: 56px 0;
  }
`;

export const confettiCSS = css`
  position: absolute;
  content: '';
  left: 0;
  right: 0;
  top: -${theme.sizes.confettiHeight[0] / 2}px;
  height: ${theme.sizes.confettiHeight[0]}px;
  background-image: url(${footerConfetti});
  background-position: center;
  background-repeat: repeat-x;
  background-size: cover;
  ${mq[0]} {
    top: -${theme.sizes.confettiHeight[1] / 2}px;
    height: ${theme.sizes.confettiHeight[1]}px;
  }
  ${mq[1]} {
    top: -${theme.sizes.confettiHeight[2] / 2}px;
    height: ${theme.sizes.confettiHeight[2]}px;
    background-size: auto, contain;
  }
`;

export const disclosuresCSS = css`
  ${text12CSS};
  line-height: 16px;
  font-weight: 400;
  margin-bottom: 28px;
  ${mq[0]} {
    ${text14CSS};
  }
`;

export const logoCSS = css`
  display: block;
  width: 212px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;
  ${mq[0]} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const subtitleCSS = css`
  font-size: 20px;
  line-height: 1.4;
  font-weight: bold;
  margin-bottom: 24px;
`;

export const copyrightCSS = css`
  font-size: 16px;
  line-height: 1.25;
  margin-top: 20px;
  ${mq[0]} {
    margin-top: 0;
  }
`;

export const footerRowCSS = css`
  display: flex;
  flex-direction: column-reverse;
  ${mq[0]} {
    justify-content: space-between;
    flex-direction: row;
  }
`;
