import { Fragment } from 'react';
import { activeElementResetCSS } from '../../../../shared/shared.style';
import { ApiErrorFailureReasons } from '../../../../utils/error.util';
import format from '../../../../utils/format.util';
import { phoneUrlInOptionCSS } from './ErrorAgentPageContent.style';
import type { ReactNode } from 'react';

const getChatMessage = (
  isHome?: boolean,
  failureReason?: ApiErrorFailureReasons
): { title: string; description: ReactNode } => ({
  title: 'We’re going to need to chat.',
  description: (
    <Fragment>
      Your {isHome ? 'home' : 'auto'} is unique and requires a specialist to find you a policy.
      <strong> A Matic agent will be reaching out soon</strong> to help you find a carrier who will cover the needs of
      your {isHome ? 'home' : 'auto'}
      {failureReason === ApiErrorFailureReasons.PRIMARY_INSURED_DISQUALIFIED ? ':' : '.'}
    </Fragment>
  ),
});

export const getFailureMessage = (
  failureReason?: ApiErrorFailureReasons,
  isHome?: boolean,
  withAllFunnelButton?: boolean,
  carrierName?: string,
  leadDisqualified?: boolean
): { title: string; description: ReactNode } => {
  switch (failureReason) {
    case ApiErrorFailureReasons.TIME_OUT:
      return {
        title: 'Our experts are ready to assist you.',
        description: (
          <Fragment>
            For security purposes, we need to expire sessions after 30 days of inactivity. A Matic agent will be
            reaching out shortly <strong>to help you finish purchasing your policy.</strong>
          </Fragment>
        ),
      };
    case ApiErrorFailureReasons.AUTHORIZATION_NOT_PASSED:
      return {
        title: 'Our experts are ready to assist you.',
        description: (
          <Fragment>
            We were not able to verify your date of birth. Don’t worry, a Matic agent will be reaching out shortly to
            <strong> assist in your policy purchase.</strong>
          </Fragment>
        ),
      };
    case ApiErrorFailureReasons.BIND_NOT_SUCCESSFUL:
      return {
        title: 'An unexpected error occurred',
        description: (
          <Fragment>
            We stored all your progress but <strong>did not proceed with any payments. </strong>
            <p>
              A Matic agent will be reaching out shortly <strong>to help finish your policy purchase.</strong>
            </p>
          </Fragment>
        ),
      };
    case ApiErrorFailureReasons.RC2_NOT_SUCCESSFUL:
      return withAllFunnelButton
        ? {
            title: 'Let’s find you a better fit.',
            description: (
              <>
                <p>Based on the details of your home, {carrierName} is unable to provide coverage.</p>
                <p>
                  Talk to one of our licensed agents to review your options, or explore other quotes online for
                  potential savings.
                </p>
              </>
            ),
          }
        : getChatMessage(isHome, failureReason);
    case ApiErrorFailureReasons.PRIMARY_INSURED_DISQUALIFIED:
      return !leadDisqualified
        ? {
            title: 'It looks like we might have a better fit for you.',
            description: (
              <>
                Everyone&apos;s situation is unique! We believe we&apos;ve identified{' '}
                <strong>a better fit for your situation</strong> that will help cover you in the following areas:
              </>
            ),
          }
        : {
            title: 'We’re sorry, but we cannot write a policy for your home at this time.',
            description:
              'If the status below changes, we can help you find a carrier that’s a great fit for you and your home.',
          };
    default:
      return {
        title: 'An unexpected error occurred',
        description: (
          <Fragment>
            <strong>You can still purchase your policy.</strong> A Matic agent will be reaching out to you shortly to
            help finalize the policy.
          </Fragment>
        ),
      };
  }
};

export const getButtonOptions = (phoneNumber: string): Array<{ key: string; label: ReactNode }> => {
  return [
    {
      key: 'phone',
      label: (
        <a css={[activeElementResetCSS, phoneUrlInOptionCSS]} href={`tel:${phoneNumber}`}>
          Call {format.phone(phoneNumber)}
        </a>
      ),
    },
    { key: 'schedule_call', label: 'Schedule a Call' },
  ];
};
