import { createContext, useMemo, useReducer } from 'react';
import { StoreActions } from './actions';
import type { QuestionsFormActionsType } from './actions';
import type { FC, ReactNode } from 'react';

export interface QuestionsState {
  questionsKeys: string[];
}
interface Props {
  children: ReactNode;
}

const initialState: QuestionsState = {
  questionsKeys: [],
};

const StoreContext = createContext<QuestionsState>(initialState);
const DispatchContext = createContext({});

const questionsFormReducer = (state = initialState, action: QuestionsFormActionsType): QuestionsState => {
  switch (action.type) {
    case StoreActions.SET_QUESTIONS_KEYS:
      return {
        ...state,
        questionsKeys: [...action.payload],
      };
    default:
      return state;
  }
};

const QuestionsFormProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(questionsFormReducer, initialState);

  const dispatchContext = useMemo(() => dispatch, [dispatch]);
  const stateContext = useMemo(() => state, [state]);

  return (
    <>
      <DispatchContext.Provider value={dispatchContext}>
        <StoreContext.Provider value={stateContext}>{children}</StoreContext.Provider>
      </DispatchContext.Provider>
    </>
  );
};

export { DispatchContext, StoreContext, QuestionsFormProvider };
