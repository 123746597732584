import { css } from '@emotion/react';
import theme from '../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const listItemWrapperCSS = css`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.black};
  border-radius: ${theme.sizes.borderRadius}px;
  padding: 20px 90px 20px 20px;
  gap: 16px;
`;

export const iconCSS = (isDisabled = false, hasError?: boolean): SerializedStyles => css`
  color: ${hasError && !isDisabled ? theme.colors.errorRed : theme.colors.gray60};
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const titleCSS = css`
  position: relative;
  margin-right: 32px;
  text-align: left;
`;

export const checkIconCSS = css`
  position: absolute;
  top: calc(50% - 8px);
  right: -24px;
  background-color: ${theme.colors.positiveGreen27};
  width: 8px;
  padding: 4px;
  border-radius: 50%;
`;

export const loaderCSS = css`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

export const buttonCSS = css`
  position: absolute;
  right: 20px;
  font-weight: 700;
  line-height: 38px;
`;

export const formTitleCSS = css`
  font-weight: 700;
  padding-bottom: 18px;
  display: flex;
  align-items: center;
`;

export const errorMessageCSS = css`
  color: ${theme.colors.errorRed};
  margin-top: -10px;
`;
