import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text20CSS, text24CSS, text40CSS, textCSS } from '../../../../../styles/typography.style';

export const headingCSS = css`
  text-align: center;
  max-width: 804px;
  width: 100%;
`;

export const headingTextCSS = css`
  ${text24CSS};
  ${mq[0]} {
    ${text40CSS};
  }
  font-weight: 700;
`;

export const headingSubTextCSS = css`
  color: ${theme.colors.gray80};
  ${textCSS};
  margin: 12px 0 0;
  ${mq[0]} {
    ${text20CSS};
    margin: 4px 0 0;
  }
`;

export const infoBlockCSS = css`
  margin: 24px 0 0;
  text-align: center;
  ${mq[0]} {
    margin: 24px 0 0;
  }
`;
