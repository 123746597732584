import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import { RadioGroupVariant } from './RadioGroup.type';
import type { SerializedStyles } from '@emotion/react';

const RIGHT_MARGIN = 16;

export const containerCSS = css`
  display: flex;
  flex-wrap: wrap;
  margin-right: -${RIGHT_MARGIN}px;
  margin-bottom: -12px;
`;

const radioItemDefaultCSS = css`
  width: calc(50% - ${RIGHT_MARGIN}px);
`;

const radioItemListCSS = css`
  width: calc(100% - ${RIGHT_MARGIN}px);
`;

const radioItemPhotoGridCSS = css`
  width: calc(100% - ${RIGHT_MARGIN}px);
  ${mq[0]} {
    width: calc(33.33% - ${RIGHT_MARGIN}px);
  }
`;

const radioItemMap = {
  [RadioGroupVariant.Default]: radioItemDefaultCSS,
  [RadioGroupVariant.List]: radioItemListCSS,
  [RadioGroupVariant.PhotoGrid]: radioItemPhotoGridCSS,
};

export const radioItemCSS = (groupVariant: RadioGroupVariant): SerializedStyles => css`
  margin-right: ${RIGHT_MARGIN}px;
  margin-bottom: 12px;
  ${radioItemMap[groupVariant]}
`;
