import { css } from '@emotion/react';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text14CSS, text24CSS, text32CSS, textCSS } from '../../../../styles/typography.style';

export const sessionLockedWrapperCSS = css`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: url(${SIRV_IMAGES.blurredBackgroundMobile});
  background-repeat: no-repeat;
  background-size: cover;

  h2 {
    text-align: center;
    margin: 0;
    ${text24CSS};
    line-height: 32px;
  }

  p {
    text-align: center;
    font-weight: 500;
    ${text14CSS};
    line-height: 20px;
    margin-top: 4px;
  }

  ${mq[0]} {
    align-items: center;
    background-image: url(${SIRV_IMAGES.blurredBackgroundDesktop});

    h2 {
      ${text32CSS};
    }

    p {
      margin-top: 8px;
      ${textCSS};
    }
  }
`;

export const cardCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 610px;
  padding: 24px 16px 16px;
  box-sizing: border-box;
  border-color: ${theme.colors.gray30};
  box-shadow: 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-radius: 0;

  ${mq[0]} {
    padding: 40px;
    border-radius: 8px;
  }
`;
