import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';
import { text20CSS, text32CSS, text48CSS } from '../../styles/typography.style';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const iconCSS = css`
  margin-bottom: 8px;
  width: 152px;
  height: 152px;

  ${mq[0]} {
    margin-bottom: 29px;
    width: 216px;
    height: 216px;
  }
`;

export const headerCSS = css`
  ${text32CSS};
  font-weight: 700;
  margin-bottom: 24px;
  ${mq[0]} {
    margin-bottom: 32px;
    ${text48CSS};
  }
`;

export const subheaderCSS = css`
  margin-bottom: 32px;
  ${mq[0]} {
    margin-bottom: 24px;
    ${text20CSS};
  }
`;

export const infoBlockCSS = css`
  max-width: 328px;
  margin-top: 24px;
  text-align: left;
  ${mq[0]} {
    margin-top: 32px;
    width: 560px;
    max-width: 560px;
  }
`;
