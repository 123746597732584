import { REQUIRED_VALIDATION } from '../../../../questionsForm/utils/question-validations/question-validations.util';
import { FormElementType } from '../../../../types/form-element.type';
import { QuestionType } from '../../../../types/question.type';
import { ValidationType } from '../../../../types/validation.type';
import type { VehicleData } from './VehiclesListContainer.type';
import type { FormElementSchema, QuestionElementContent } from '../../../../types/form-element.type';
import type { DefaultValues } from 'react-hook-form';

export const VEHICLE_FORM_TYPE = 'vehicle';
export const NEW_VEHICLE_FORM_KEY = 'new_vehicle';

const FORM_VEHICLE_SCHEMA: Array<FormElementSchema<QuestionElementContent>> = [
  {
    key: 'ownership_element',
    kind: FormElementType.Question,
    width: 12,
    position: 2,
    tags: [],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'ownership',
      label: 'Do you own this vehicle?',
      type: QuestionType.SingleSelect,
      variants: [
        {
          label: 'Own - paid in full',
          value: 'own',
          description: null,
          icon_url: null,
        },
        {
          label: 'Will Own in the Future — Still making payments',
          value: 'loan',
          description: null,
          icon_url: null,
        },
        {
          label: 'Leased',
          value: 'lease',
          description: null,
          icon_url: null,
        },
      ],
      validations: [REQUIRED_VALIDATION],
    },
  },
  {
    key: 'usage_element',
    kind: FormElementType.Question,
    width: 6,
    position: 3,
    tags: [],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'usage',
      label: 'How do you use this vehicle?',
      type: QuestionType.SingleSelect,
      variants: [
        {
          label: 'Commute (to and from work or school)',
          value: 'work',
          description: null,
          icon_url: null,
        },
        {
          label: 'Pleasure',
          value: 'pleasure',
          description: null,
          icon_url: null,
        },
        {
          label: 'Business',
          value: 'business',
          description: null,
          icon_url: null,
        },
      ],
      validations: [REQUIRED_VALIDATION],
    },
  },
  {
    key: 'annual_mileage_element',
    kind: FormElementType.Question,
    width: 6,
    position: 4,
    tags: [],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'annual_mileage',
      label: 'Annual mileage',
      type: QuestionType.Number,
      hint: 'On average in the US people drive 9,000 miles per year.',
      placeholder: 'Miles per year',
      variants: [],
      validations: [
        REQUIRED_VALIDATION,
        {
          key: ValidationType.Min,
          value: 1000,
          message: 'The minimum value is 1000 miles per year',
        },
        {
          key: ValidationType.Max,
          value: 99999,
          message: 'The maximum value is 99,999 miles per year',
        },
        {
          key: ValidationType.OnlyNaturalNumbers,
          value: true,
          message: 'This field should contain only natural numbers',
        },
      ],
    },
  },
];

export const NEW_VEHICLE_FORM_SCHEMA: Array<FormElementSchema<QuestionElementContent>> = [
  {
    key: 'vehicle_element',
    kind: FormElementType.Question,
    width: 12,
    position: 1,
    tags: [],
    sub_elements: [],
    visibility_conditions: [],
    feature_condition: null,
    content: {
      key: 'vehicle',
      label: 'Which vehicle do you want to insure?',
      type: QuestionType.VehicleSelect,
      variants: [],
      placeholder: 'Start typing the year, make, model, and style',
      validations: [REQUIRED_VALIDATION],
    },
  },
  ...FORM_VEHICLE_SCHEMA,
];

export const VEHICLES_DEFAULT_ANSWERS: DefaultValues<VehicleData> = {
  annual_mileage: '9000',
  vehicle: undefined,
  ownership: undefined,
  usage: undefined,
};

export const PREFILLED_VEHICLE_FORM_SCHEMA: Array<FormElementSchema<QuestionElementContent>> = [...FORM_VEHICLE_SCHEMA];
