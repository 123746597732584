import CheckIcon from '../../../../../assets/check-mark.svg?react';
import { checkIconCSS, containerCSS, infoBlockCSS, titleCSS } from './CalendlyAutoScheduledMessage.style';
import type { CalendlyAutoScheduledMessageProps } from './CalendlyAutoScheduledMessage.type';
import type { FC } from 'react';

const CalendlyAutoScheduledMessage: FC<CalendlyAutoScheduledMessageProps> = ({ customCSS }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <div css={titleCSS}>What to prepare for the call</div>
      <div>This is what our agents will need to finalize your policy.</div>
      <div css={infoBlockCSS}>
        <CheckIcon css={checkIconCSS} />
        <span>Driver’s license of everyone of driving age in your home</span>
      </div>
    </div>
  );
};

export default CalendlyAutoScheduledMessage;
