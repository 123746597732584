import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.white};
  z-index: 999;
`;

export const loaderCSS = css`
  padding: 0 16px;
  margin-top: 60px;

  ${mq[0]} {
    margin-top: 40px;
  }
`;

export const imageCSS = css`
  width: 264px;
  height: 167px;

  ${mq[0]} {
    width: 393px;
    height: 248px;
  }
`;
