import { useCallback, useContext } from 'react';
import { DispatchContext, StoreContext } from './reducer';
import type { QuestionsState } from './reducer';

interface QuestionsFormStateType {
  state: QuestionsState;
  dispatch: (action: any) => void;
}

// @TODO refactor all logic to zustand
export const useQuestionsFormState = (): QuestionsFormStateType => {
  const _dispatch = useContext(DispatchContext);
  const state = useContext(StoreContext);

  if (state === undefined) {
    throw new Error('useQuestionsFormState must be used within a QuestionsFormProvider');
  }

  const dispatch = useCallback(
    (action: any): void => {
      action(_dispatch);
    },
    [_dispatch]
  );

  return { state, dispatch };
};
