import ForeverLockIcon from '../../../../assets/forever-lock-session.svg?react';
import Button from '../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import { containerCSS, foreverLockedIconCSS, startOverMessageCSS } from './ForeverLockedCardContent.style';
import type { ForeverLockedCardProps } from './ForeverLockedCardContent.type';
import type { FC } from 'react';

const ForeverLockedCardContent: FC<ForeverLockedCardProps> = ({ customCSS, onActionClick }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <ForeverLockIcon css={foreverLockedIconCSS} />
      <h2>Let’s continue together!</h2>
      <div css={startOverMessageCSS}>
        <p>Unfortunately you’ve used up all of your attempts.</p>
        <p>You can schedule a call with our agent to finalise your quote.</p>
      </div>
      <Button fullWidth size={ButtonSize.Large} onClick={onActionClick}>
        Call a Matic’s Agent
      </Button>
    </div>
  );
};

export default ForeverLockedCardContent;
