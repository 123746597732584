import { FormElementTag } from '../../../../types/form-element.type';
import type { CheckboxVariant } from '../../../../shared/components/Checkbox/Checkbox.type';
import type { GridWidth } from '../../../../types/form-element.type';

export const getCheckboxVariant = (
  isMobile: boolean,
  gridWidth?: GridWidth | null,
  tags: FormElementTag[] = []
): CheckboxVariant => {
  switch (true) {
    case !isMobile && !!gridWidth && gridWidth < 6:
      return 'narrow';
    case tags.includes(FormElementTag.CheckboxVertical):
      return 'vertical';
    case tags.includes(FormElementTag.SimpleCheckbox):
      return 'simple';
    default:
      return 'default';
  }
};
