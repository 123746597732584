import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';

export const loaderCSS = css`
  padding: 24px 20px 56px;
  ${mq[0]} {
    padding-top: 50px;
    padding-bottom: 160px;
  }
`;
