import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { ServicersResponse } from '../../types/servicer.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useServicers = (): QueryObserverResult<ServicersResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.servicers],
    queryFn: async () => await api.get<HTTPResponse<ServicersResponse>>(API_PATH.servicers).then((res) => res.data),
  });

export default useServicers;
