import { containerCSS, subtitleCSS, titleCSS } from './QuestionsGroupLabel.style';
import type { QuestionsGroupLabelProps } from './QuestionsGroupLabel.type';
import type { FC } from 'react';

const QuestionsGroupLabel: FC<QuestionsGroupLabelProps> = ({ customCSS, title, subtitle, isSubElement = false }) => {
  return (
    <div css={[containerCSS(isSubElement), customCSS]}>
      <div css={titleCSS(isSubElement)}>{title}</div>
      {subtitle && <div css={subtitleCSS}>{subtitle}</div>}
    </div>
  );
};

export default QuestionsGroupLabel;
