import noop from '../../utils/noop';
import RadioButton from './RadioButton/RadioButton';
import { RadioButtonVariant } from './RadioButton/RadioButton.type';
import { containerCSS, radioItemCSS } from './RadioGroup.style';
import { RadioGroupVariant } from './RadioGroup.type';
import ShowMoreRadioButton from './ShowMoreRadioButton/ShowMoreRadioButton';
import type { RadioGroupProps } from './RadioGroup.type';
import type { FC } from 'react';

const OPTIONS_LIMIT = 4;

const RadioGroup: FC<RadioGroupProps> = ({
  customCSS,
  name,
  value,
  options,
  disabled,
  componentRef,
  variant = RadioGroupVariant.Default,
  hasError,
  onBlur = noop,
  onChange = noop,
}) => {
  if (!options) {
    throw new Error('Radio group: options must be provided.');
  }

  const getButtonVariant = (): RadioButtonVariant => {
    switch (variant) {
      case RadioGroupVariant.PhotoGrid:
        return RadioButtonVariant.Photo;
      default:
        return RadioButtonVariant.Default;
    }
  };

  return (
    <div css={[containerCSS, customCSS]}>
      {options
        .filter((_, i) => i < OPTIONS_LIMIT)
        .map((o, i) => (
          <RadioButton
            customCSS={radioItemCSS(variant)}
            index={i}
            key={o.value}
            name={name}
            label={o.label}
            value={o.value}
            description={o.description}
            imageUrl={o.imageUrl}
            ref={i === 0 && componentRef ? componentRef : undefined}
            disabled={disabled}
            checked={value === o.value}
            variant={getButtonVariant()}
            hasError={hasError}
            onBlur={onBlur}
            onChange={(event) => {
              onChange(event);
              event.target.blur();
            }}
          />
        ))}
      {
        <ShowMoreRadioButton
          customCSS={radioItemCSS(variant)}
          index={OPTIONS_LIMIT + 1}
          name={name}
          selectedValue={value}
          options={options.filter((_, i) => i >= OPTIONS_LIMIT)}
          disabled={disabled}
          buttonVariant={getButtonVariant()}
          boldLabel={!!options[0].description}
          hasError={hasError}
          onBlur={onBlur}
          onChange={(value: string) => onChange({ target: { value } } as any)}
        />
      }
    </div>
  );
};

export default RadioGroup;
