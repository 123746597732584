import { css, type SerializedStyles } from '@emotion/react';
import theme from '../../../../styles/theme';

export const containerCSS = (moveTo?: number): SerializedStyles => css`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${moveTo ? -moveTo : 0}px;
  background-color: ${theme.colors.white};
  border-bottom: 1px solid ${theme.colors.gray10};
  z-index: -2;
  transition: bottom 0.3s ease-in-out;
`;
