import PageContainer from '../../components/PageContainer/PageContainer';
import SkipToNextPageWrapper from '../../components/SkipToNextPageWrapper/SkipToNextPageWrapper';
import AutoInformationPage from './components/AutoInformationPage/AutoInformationPage';
import type { FC } from 'react';

const AutoInformationLayout: FC = () => (
  <PageContainer
    showFooter={false}
    allowFullStoryTracking
    allowTabTitleUpdate
    subHeaderComponent={<SkipToNextPageWrapper />}
  >
    <AutoInformationPage />
  </PageContainer>
);

export default AutoInformationLayout;
