import { css } from '@emotion/react';
import checkboxMark from '../../../../../assets/check-mark.svg';
import { activeElementResetCSS } from '../../../../../shared/shared.style';
import theme from '../../../../../styles/theme';
import { text14CSS, text24CSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  display: flex;
  justify-content: space-between;
`;

export const titleCSS = (isDisabled?: boolean, isSkipped?: boolean, hideNumber?: boolean): SerializedStyles => css`
  position: relative;
  padding-left: ${hideNumber ? '0' : '24'}px;
  ${text24CSS};
  font-weight: 700;
  color: ${isDisabled ?? isSkipped ? theme.colors.gray30 : theme.colors.black};
  text-decoration-line: ${isSkipped ? 'line-through' : 'none'};
  span {
    vertical-align: baseline;
  }
`;

export const stepNumberCSS = css`
  position: absolute;
  left: 0;
  top: calc(50% - 34 / 2);
`;

export const completedMarkCSS = css`
  display: inline-block;
  content: '';
  width: 16px;
  height: 16px;
  margin-left: 8px;
  border-radius: 50%;
  background: ${theme.colors.positiveGreen94} url(${checkboxMark}) no-repeat center center;
  background-size: 8px;
  border-color: ${theme.colors.positiveGreen94};
`;

export const buttonCSS = css`
  ${activeElementResetCSS};
  ${text14CSS};
  line-height: 34px;
  color: ${theme.colors.azure50};
  font-weight: 700;
  padding-left: 12px;
  display: flex;
  align-items: center;
  &:focus,
  &:hover {
    color: ${theme.colors.azure68};
  }
  transition: color 0.3s;
`;

export const buttonIconCSS = css`
  width: 13px;
  height: 13px;
  margin-right: 6px;
`;

export const singleIconButtonCSS = css`
  width: 20px;
  height: 20px;
  padding: 6px 0 6px 12px;
`;
