import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { CoInsured, CoInsuredResponse } from '../../types/co-insured.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useCreateCoInsured = (
  gid: string
): UseBaseMutationResult<CoInsuredResponse, HTTPResponse, Omit<CoInsured, 'gid'>> => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (data) =>
      await api
        .post<HTTPResponse<CoInsuredResponse>>(getApiUrl(API_PATH.coInsureds, gid), {
          body: { data },
        })
        .then((res) => res.data),
    onSuccess: async () => {
      await client.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.coInsureds] });
    },
  });
};

export default useCreateCoInsured;
