import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { Driver } from '../../types/driver.type';
import type { Vehicle } from '../../types/vehicle.type';
import type { QueryObserverResult } from '@tanstack/react-query';

export interface VehiclesAndDriversResponse {
  vehicles: Vehicle[];
  drivers: Driver[];
}

const useVehiclesAndDriversData = (gid: string): QueryObserverResult<VehiclesAndDriversResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.vehiclesAndDriversData],
    queryFn: async () =>
      await api
        .get<HTTPResponse<VehiclesAndDriversResponse>>(getApiUrl(API_PATH.vehiclesAndDriversData, gid))
        .then((res) => res.data),
  });

export default useVehiclesAndDriversData;
