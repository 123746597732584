import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text20CSS, text24CSS } from '../../../styles/typography.style';

export const itemCSS = css`
  padding: 16px 0;
`;

export const buttonCSS = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
`;

export const arrowCSS = css`
  flex: 0 0 24px;
  height: 10px;
  position: relative;
  &::after,
  &::before {
    background-color: ${theme.colors.azure50};
    content: '';
    display: block;
    height: 2px;
    position: absolute;
    top: 50%;
    transition: transform 0.25s ease;
    width: 10px;
  }
  &::before {
    left: 4px;
    transform: rotate(45deg);
  }
  &::after {
    right: 4px;
    transform: rotate(-45deg);
  }
  [aria-expanded='true'] & {
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
`;

export const panelCSS = css`
  color: ${theme.colors.gray60};
  margin-top: 12px;
`;

export const titleCSS = css`
  ${mq[0]} {
    ${text20CSS};
  }
  ${mq[0]} {
    ${text24CSS};
  }
`;
