import { css } from '@emotion/react';
import { arrowCSS as accordionArrowCSS } from '../../../../../shared/components/Accordion/Accordion.style';
import { activeElementResetCSS } from '../../../../../shared/shared.style';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text32CSS, textCSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  padding: 8px 16px 24px;
  ${mq[0]} {
    padding: 16px 20px 24px;
  }
`;

export const summaryHeroCSS = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${mq[1]} {
    align-items: center;
    justify-content: right;
  }
`;

export const logoCSS = css`
  margin-top: 8px;
  ${mq[1]} {
    margin-top: 0;
  }
`;

export const dividerCSS = css`
  display: none;
  ${mq[1]} {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const annualPremiumBoxCSS = css`
  text-align: right;
  ${mq[1]} {
    text-align: left;
  }
`;

export const annualPremiumValueCSS = css`
  color: ${theme.colors.positiveGreen27};
  ${text32CSS};
  line-height: 40px;
  font-weight: 900;

  span {
    ${textCSS};
  }
`;

export const annualPremiumLabelCSS = css`
  margin-top: 2px;
  font-weight: 500;
`;

export const expandButtonCSS = css`
  ${activeElementResetCSS};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px 16px 12px;
  border-top: 1px solid ${theme.colors.gray10};
`;

export const expandLabelCSS = css`
  font-weight: 700;
  color: ${theme.colors.azure50};
`;

export const arrowCSS = css`
  ${accordionArrowCSS};
  margin-left: 8px;
  &:before,
  &:after {
    background-color: ${theme.colors.azure50};
  }
`;

export const rowsContainerCSS = css`
  margin-top: 20px;
  ${mq[0]} {
    padding-left: 8px;
    padding-right: 8px;
  }
`;

export const loadingCSS = css`
  margin-bottom: 12px;
`;

export const rowCSS = css`
  margin-bottom: 12px;
  font-weight: 500;
  border-color: ${theme.colors.gray30};
  span {
    color: ${theme.colors.gray80};
  }
  & span:last-of-type {
    font-weight: 700;
  }
`;

export const effectiveDateCSS = css`
  ${rowCSS};
  margin-top: 25px;
  margin-bottom: 0;
  & span:first-of-type {
    font-weight: 600;
    color: ${theme.colors.black};
  }
`;

export const additionalDiscountCSS = css`
  padding: 16px;
  background-color: ${theme.colors.positiveGreen94};
`;
