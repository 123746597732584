import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import { text20CSS, text40CSS } from '../../../../styles/typography.style';

export const containerCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 46px;
  ${mq[0]} {
    ${text40CSS};
    text-align: center;
  }
`;
