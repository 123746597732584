import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { PublicConfigResponse } from '../../types/public-config.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const usePublicConfig = (throwOnError: boolean = true): QueryObserverResult<PublicConfigResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.config],
    queryFn: async () =>
      await api.get<HTTPResponse<PublicConfigResponse>>(API_PATH.public.config).then((res) => res.data),
    throwOnError,
  });

export default usePublicConfig;
