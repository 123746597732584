import logo from '../../../assets/logo.svg';
import noop from '../../utils/noop';
import Container from '../Container/Container';
import {
  footerCSS,
  copyrightCSS,
  logoCSS,
  subtitleCSS,
  disclosuresCSS,
  footerRowCSS,
  confettiCSS,
} from './Footer.style';
import FooterLinks from './FooterLinks/FooterLinks';

import type { FooterProps } from './Footer.type';
import type { FC } from 'react';

const Footer: FC<FooterProps> = ({
  copy,
  phone,
  withConfetti = false,
  denseContainer = false,
  onSocialLinkClick = noop,
  customCSS,
}) => {
  return (
    <footer css={[customCSS, footerCSS]}>
      {withConfetti && <div data-testid="footer-confetti" css={confettiCSS} />}
      <Container dense={denseContainer}>
        {copy && <div css={disclosuresCSS}>{copy}</div>}
        <img src={logo} css={logoCSS} alt="Matic Insurance" loading="lazy" />
        <div css={subtitleCSS}>
          We are a Digital Insurance Agency That Empowers You to Simplify Your Whole World of Insurance
        </div>
        <div css={footerRowCSS}>
          <div css={copyrightCSS}>© 2014 – {new Date().getFullYear()} Matic Insurance Services, Inc.</div>
          <div>
            <FooterLinks phone={phone} onSocialLinkClick={onSocialLinkClick} />
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
