import SkeletonLoader from '../../../../shared/components/SkeletonLoader/SkeletonLoader';
import type { FC } from 'react';

const ListLoader: FC = () => (
  <>
    <SkeletonLoader absoluteHeight={64} width={100} />
    <SkeletonLoader absoluteHeight={64} width={100} />
  </>
);

export default ListLoader;
