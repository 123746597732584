import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { AcceptedDisclosuresResponse } from '../../types/disclosure.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useAcceptedDisclosures = (gid?: string): QueryObserverResult<AcceptedDisclosuresResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.acceptedDisclosures, gid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<AcceptedDisclosuresResponse>>(getApiUrl(API_PATH.acceptedDisclosures, gid as string))
        .then((response) => response),
    enabled: !!gid,
  });

export default useAcceptedDisclosures;
