import { containerCSS, dotCSS } from './LoadingDots.style';
import type { LoadingDotsProps } from './LoadingDots.type';
import type { FC } from 'react';

const STEPS = [0, 0.15, 0.3, 0.45, 0.6, 0.75, 1, 1.15, 1.3];

const LoadingDots: FC<LoadingDotsProps> = ({ customCSS, steps = STEPS }) => {
  return (
    <div css={[containerCSS, customCSS]} role="status" aria-label="Loading">
      {steps.map((s) => (
        <div key={s} css={dotCSS(s)} />
      ))}
    </div>
  );
};

export default LoadingDots;
