import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublicSession } from '../../../../api/public/session';
import { useMutateUnlockSession } from '../../../../api/session';
import ErrorIcon from '../../../../assets/error-icon.svg?react';
import { SEGMENT } from '../../../../constants/analytics';
import ROUTES from '../../../../constants/routes';
import { useSegment } from '../../../../hooks/use-segment';
import Question from '../../../../questionsForm/components/Question/Question';
import { FormElementTag } from '../../../../types/form-element.type';
import { isInternalTechnicalError } from '../../../../utils/error.util';
import { MAX_UNLOCK_ATTEMPTS } from '../SessionLocked/SessionLocked.util';
import { formCSS, submitErrorCSS } from './SessionLockedForm.style';
import { SESSION_LOCKED_FORM_ID, SESSION_LOCKED_INPUTS } from './SessionLockedForm.util';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { UnlockSessionData } from '../../../../types/session.type';
import type { FC } from 'react';

const SessionLockedForm: FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const navigate = useNavigate();

  const { handleSubmit } = useFormContext<UnlockSessionData>();

  const { track } = useSegment();

  const { data: publicSessionData } = usePublicSession(gid);
  const { mutateAsync: unlockSession } = useMutateUnlockSession(gid);

  const onSubmit = async (data: UnlockSessionData): Promise<void> => {
    try {
      await unlockSession(data);
      track(SEGMENT.events.recoverySessionSucceed);
      navigate(ROUTES.flowAndGid.replace(':flow', flow).replace(':gid', gid));
    } catch (error) {
      track(SEGMENT.events.recoverySessionFailed, {
        attempts_count: publicSessionData?.session.unlock_attempts_count ?? 1,
      });

      if (isInternalTechnicalError(error)) {
        navigate(ROUTES.flowAndGid.replace(':flow', flow).replace(':gid', gid));
      }
    }
  };

  if (!publicSessionData?.session) return null;

  const attemptsLeft = MAX_UNLOCK_ATTEMPTS - publicSessionData.session.unlock_attempts_count;
  const showAttemptsLeft = attemptsLeft < MAX_UNLOCK_ATTEMPTS;

  return (
    <>
      {showAttemptsLeft && (
        <div css={submitErrorCSS}>
          <ErrorIcon />
          <div>
            <p>Sorry, it seems that one of the fields you entered is incorrect.</p>
            <p>
              You have <span>{attemptsLeft}</span> attempt{attemptsLeft === 1 ? '' : 's'} remaining to resume your
              session.
            </p>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} id={SESSION_LOCKED_FORM_ID} css={formCSS}>
        {SESSION_LOCKED_INPUTS.map((input) => (
          <Question {...input} key={input.questionKey} tags={[FormElementTag.TrackForbidden]} />
        ))}
      </form>
    </>
  );
};

export default SessionLockedForm;
