import CheckIcon from '../../assets/check-mark.svg?react';
import WarningIcon from '../../assets/warning.svg?react';
import SIRV_IMAGES from '../../constants/sirv-images';
import useResponsive from '../../hooks/use-responsive';
import { Card } from '../../shared/components/Card/Card';
import SirvImage from '../../shared/components/SirvImage/SirvImage';
import SpamAndFreeSecure from '../SpamAndFreeSecure/SpamAndFreeSecure';
import {
  cardCSS,
  mainImageCSS,
  subtitleCSS,
  titleCSS,
  buttonsWrapperCSS,
  messageCSS,
  checkIconCSS,
  warningIconCSS,
} from './ErrorPageMessage.style';
import { ErrorReasonsIcon } from './ErrorPageMessage.type';
import type { ErrorPageMessageProps } from './ErrorPageMessage.type';
import type { ReactElement, FC } from 'react';

const ErrorPageMessage: FC<ErrorPageMessageProps> = ({
  children,
  title,
  description,
  errorReasons = [],
  errorReasonsIcon,
  hideSpamFreeAndSecure,
}) => {
  const { isMobile } = useResponsive();

  const renderMessage = (message: string): ReactElement => (
    <div css={messageCSS} key={message}>
      {errorReasonsIcon === ErrorReasonsIcon.Warning ? (
        <WarningIcon css={warningIconCSS} />
      ) : (
        <CheckIcon css={checkIconCSS} />
      )}
      {message}
    </div>
  );

  return (
    <>
      <SirvImage
        customCSS={mainImageCSS}
        url={SIRV_IMAGES.ladyAgent}
        width={isMobile ? 137 : 216}
        alt="Woman with headphones"
      />
      <div css={titleCSS}>{title}</div>
      <div css={subtitleCSS}>{description}</div>

      {!!errorReasons.length && (
        <Card customCSS={cardCSS}>{errorReasons.map((reason) => renderMessage(reason.message))}</Card>
      )}
      {!!children && (
        <div>
          <div css={buttonsWrapperCSS}>{children}</div>
          {!hideSpamFreeAndSecure && <SpamAndFreeSecure />}
        </div>
      )}
    </>
  );
};

export default ErrorPageMessage;
