import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

interface CreateQuoteRequestOptions {
  bindAttempt?: boolean;
}

const useCreateQuoteRequest = (
  gid: string
): UseBaseMutationResult<Record<never, never>, HTTPResponse, CreateQuoteRequestOptions> => {
  return useMutation({
    mutationFn: async (options: CreateQuoteRequestOptions) =>
      await api
        .post<HTTPResponse<Record<never, never>>>(getApiUrl(API_PATH.quoteRequests, gid), {
          body: {
            data: { bind_attempt: options.bindAttempt ?? false },
          },
        })
        .then((res) => res.data),
  });
};

export default useCreateQuoteRequest;
