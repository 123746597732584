import HomeQuoteCoverageGroup from '../HomeQuoteCoverageGroup/HomeQuoteCoverageGroup';
import { containerCSS } from './HomeQuoteCoverages.style';
import { generateCoveragesGroup } from './HomeQuoteCoverages.util';
import type { HomeQuoteCoveragesProps } from './HomeQuoteCoverages.type';
import type { FC } from 'react';

const HomeQuoteCoverages: FC<HomeQuoteCoveragesProps> = ({ customCSS, coverages }) => {
  const groups = generateCoveragesGroup(coverages);

  return (
    <div css={[containerCSS, customCSS]}>
      {groups.map((group) => (
        <HomeQuoteCoverageGroup key={group.assetGid} group={group} />
      ))}
    </div>
  );
};

export default HomeQuoteCoverages;
