import { css } from '@emotion/react';
import { textCSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  width: 124px;
`;

export const labelCSS = css`
  ${textCSS};
  font-weight: 700;
  margin-bottom: 4px;
`;

export const loadingLabelCSS = css`
  margin-bottom: 4px;
`;
