import { PaymentMethod } from '../../../../../types/public-flow-config.type';
import type { STRIPE_CREDIT_CARD_ANSWER_KEY } from '../StripeCardPaymentForm/StripeCardPaymentForm.util';

export interface CheckoutSecondStepData {
  [STRIPE_CREDIT_CARD_ANSWER_KEY]: string;
}

export const PAYMENT_METHOD_QUESTION_KEY = 'billing/payment_method';

export const PAYMENT_METHOD_QUESTION = {
  key: 'payment_method',
  placeholder: '- choose option -',
  options: [
    {
      label: 'Credit Card',
      value: PaymentMethod.direct_bill,
    },
    {
      label: 'Escrow Account',
      value: PaymentMethod.escrow,
    },
  ],
};

export const ESCROW_STEP_TITLE = 'Mortgage Info & Contact Info';
export const DIRECT_BILL_STEP_TITLE = 'Payment Information';
export const PAYMENT_METHOD_STEP_TITLE = 'Payment Method';
