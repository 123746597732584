import { css } from '@emotion/react';
import { maxCalendlyLikeWidthContainerCSS } from '../../../../../styles/common.style';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  width: 100%;
`;

export const quoteCardCSS = css`
  width: calc(100% + ${theme.pageMarginHorizontal.mobile * 2}px);

  ${mq[0]} {
    width: 100%;
  }
`;

export const cardCSS = css`
  ${maxCalendlyLikeWidthContainerCSS};
  margin: 0 auto 20px auto;
  box-sizing: border-box;

  ${mq[0]} {
    margin: 0 auto 20px auto;
  }
`;

export const quoteWrapperCSS = css`
  padding: 0;
  ${mq[0]} {
    padding: 0;
  }
`;
