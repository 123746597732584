import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text20CSS, text24CSS } from '../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const headerCSS = css`
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  font-weight: bold;
  margin-bottom: 4px;
  border: unset;
  background-color: transparent;
  ${text20CSS};

  ${mq[0]} {
    ${text24CSS};
    line-height: 32px;
  }
`;

export const headerImageCSS = css`
  max-width: 24px;
  max-height: 24px;
  margin-right: 8px;
`;

const expandedCSS = css`
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
`;

export const accordionArrowCSS = (expanded: boolean): SerializedStyles => css`
  flex: 0 0 24px;
  height: 10px;
  width: 12px;
  position: absolute;
  right: 0;
  &::after,
  &::before {
    background-color: ${theme.colors.azure50};
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    top: 50%;
    transition: transform 0.25s ease;
    width: 10px;
  }
  &::before {
    left: 4px;
    transform: rotate(45deg);
  }
  &::after {
    right: 4px;
    transform: rotate(-45deg);
  }
  ${expanded && expandedCSS};
`;

export const contentCSS = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const coverageCSS = css`
  width: 100%;
  margin-top: 12px;
  ${mq[1]} {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 33px);
    margin-top: 16px;
  }
`;
