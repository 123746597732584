import { useState, type FC, useEffect } from 'react';
import PageLoader from '../PageLoader/PageLoader';
import { HEADERS, INFO_BLOCK_CONTENT, SUBHEADERS } from './QuotePageLoader.util';
import type { QuotePageLoaderProps } from './QuotePageLoader.type';

const QuotePageLoader: FC<QuotePageLoaderProps> = ({ customCSS, customImageCSS, imgUrl, showBundleMessage }) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setIndex((prevIndex) => {
        if (prevIndex === HEADERS.length - 1) {
          clearInterval(timer);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, 15000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <PageLoader
      customCSS={customCSS}
      customImageCSS={customImageCSS}
      imgUrl={imgUrl}
      header={HEADERS[index]}
      subheader={SUBHEADERS[index]}
      infoBlockContent={showBundleMessage ? INFO_BLOCK_CONTENT : null}
    />
  );
};

export default QuotePageLoader;
