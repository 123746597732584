import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import {
  arrowCSS,
  buttonCSS,
  itemCSS,
  panelCSS,
  paymentFeeCSS,
  paymentRowCSS,
  paymentTimeCSS,
  paymentValueCSS,
  titleCSS,
} from './PaymentsSchedule.style';
import type { PaymentsScheduleProps } from './PaymentsSchedule.type';
import type { FC } from 'react';

const ACCORDION_ITEM_ID = '1';

const PaymentsSchedule: FC<PaymentsScheduleProps> = ({ customCSS, paymentSchedule = [] }) => {
  return (
    <Accordion css={customCSS} allowZeroExpanded preExpanded={[ACCORDION_ITEM_ID]}>
      <AccordionItem css={itemCSS} uuid={ACCORDION_ITEM_ID}>
        <AccordionItemHeading>
          <AccordionItemButton css={buttonCSS}>
            <span css={titleCSS}>{paymentSchedule.length} Installments Breakdown</span>
            <div css={arrowCSS} />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel css={panelCSS}>
          {paymentSchedule.map((p, index) => (
            <div key={p.timeStamp} css={paymentRowCSS(index === 0)}>
              <div css={paymentTimeCSS}>{p.timeStamp}</div>
              <div>
                <span css={paymentFeeCSS}>{p.fee}</span>
                <span css={paymentValueCSS}>{p.value}</span>
              </div>
            </div>
          ))}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default PaymentsSchedule;
