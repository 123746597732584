import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import SCHEMA_KEYS from '../../../constants/schema-hardcoded-keys';
import { visuallyHiddenCSS } from '../../../shared/shared.style';
import { checkoutComponentsHeadingCSS } from '../../../styles/form.style';
import isVisibleByAnswers from '../../../utils/visibility-conditions.util';
import Question from '../Question/Question';
import { containerCSS } from './BillingInformationFormComponent.style';
import type { BillingInformationFormComponentProps } from './BillingInformationFormComponent.type';
import type { FC } from 'react';

const BillingInformationFormComponent: FC<BillingInformationFormComponentProps> = ({
  customCSS,
  index,
  heading,
  questions,
  answers,
}) => {
  const { watch, setValue } = useFormContext();
  const paymentTypeValue = watch(SCHEMA_KEYS.billingPaymentType);

  useEffect(() => {
    if (paymentTypeValue) {
      setValue(SCHEMA_KEYS.billingPaymentPlan, null);
    }
  }, [paymentTypeValue, setValue]);

  return (
    <fieldset css={[containerCSS, customCSS]}>
      <legend css={visuallyHiddenCSS}>{heading}</legend>
      <div css={checkoutComponentsHeadingCSS}>
        {index + 1}. {heading}
      </div>
      {questions
        .filter((q) => isVisibleByAnswers(answers, q.visibility_conditions))
        .map(({ key, ...rest }) => (
          <Question key={key} {...rest} workflowHeading={heading} questionKey={key} />
        ))}
    </fieldset>
  );
};

export default BillingInformationFormComponent;
