import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text14CSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const questionBlockCSS = css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;

  ${mq[0]} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;
  }
`;

export const questionInfoCSS = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  ${mq[0]} {
    flex: 50%;
  }
`;

export const descriptionCSS = css`
  ${text14CSS};
  color: ${theme.colors.gray60};
  margin-top: 16px;

  ${mq[0]} {
    margin-top: 0;
  }
`;

export const titleCSS = css`
  font-weight: 700;
  margin-bottom: 8px;
`;

export const imageCSS = css`
  margin-right: 16px;
`;

export const inputWrapperCSS = css`
  height: 44px;
  width: 100%;
  margin-top: 16px;

  ${mq[0]} {
    width: 200px;
    margin-left: 40px;
    margin-top: 0;
  }
`;

export const formCSS = (isSwitchComponent: boolean): SerializedStyles => css`
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;

  ${mq[0]} {
    text-align: ${isSwitchComponent ? 'right' : 'left'};
    width: 200px;
    position: absolute;
  }
`;
