import type { AnswerValue } from './answer.type';

export enum DefaultConditionOperation {
  Filled = 'filled',
  NotFilled = 'not_filled',
  NewerThanYears = 'newer_than_years',
  OlderThanYears = 'older_than_years',
  MoreThanDaysFromToday = 'more_than_days_from_today',
  LessThanDaysFromToday = 'less_than_days_from_today',
}

export type DynamicDefaultConditionValue = AnswerValue | boolean;

export interface DefaultCondition {
  operation: DefaultConditionOperation;
  source: string;
  value: DynamicDefaultConditionValue;
}

export const enum DynamicDefaultTypes {
  Value = 'value',
  Datapoint = 'datapoint',
  Function = 'function',
}

export const enum DynamicDefaultFunctionParamTypes {
  Static = 'static',
  Datapoint = 'datapoint',
}

export interface DynamicDefaultsFunctionParam {
  value: AnswerValue;
  type: DynamicDefaultFunctionParamTypes;
}

type DatapointKey = string;

export const enum DynamicDefaultFunction {
  OneDayAfterDate = 'get_one_day_after_date',
  DateInWeeksFromToday = 'get_weeks_from_today',
  YearInYearsFromToday = 'get_years_from_today',
}

export interface DynamicDefault {
  type: DynamicDefaultTypes;
  value: AnswerValue | DynamicDefaultFunction | DatapointKey;
  params?: DynamicDefaultsFunctionParam[];
  conditions?: DefaultCondition[][];
}

export type DynamicDefaults = Record<string, DynamicDefault[]>;
