import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from '../../api/session';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader';
import { getCurrentNavigationPage } from '../../utils/current-form-keys.util';
import { getPathByPageType } from '../../utils/route-path.util';
import type { PageTypeCheckGuardProps } from './PageTypeCheckGuard.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const PageTypeCheckGuard: FC<PageTypeCheckGuardProps> = ({ children, pageType }) => {
  const navigate = useNavigate();
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const [pageTypeConfirmed, setPageTypeConfirmed] = useState<boolean>();

  useEffect(() => {
    if (sessionData) {
      const currentPage = getCurrentNavigationPage(sessionData);
      if (currentPage?.page_type === pageType) {
        setPageTypeConfirmed(true);
      } else {
        navigate(getPathByPageType(currentPage?.page_type, gid, flow), { replace: true });
      }
    }
  }, [pageTypeConfirmed, navigate, gid, flow, pageType, sessionData]);

  if (!sessionData || !pageTypeConfirmed) {
    return <FullPageLoader />;
  }
  return <>{children}</>;
};

export default PageTypeCheckGuard;
