import { loadStripe } from '@stripe/stripe-js';
import { questionHasAnswer } from '../../../../../questionsForm/utils/answers/answers.util';
import type { Address } from '../../../../../types/address.type';
import type { Answers, AnswerValue } from '../../../../../types/answer.type';
import type { Stripe } from '@stripe/stripe-js';

export const STRIPE_CREDIT_CARD_ANSWER_KEY = 'billing/card_details_tokenized';

export const getStripePromise = (stripeKey?: string): Promise<Stripe | null> | null => {
  if (stripeKey) {
    return loadStripe(stripeKey);
  }
  return null;
};

export const removeEmptyAnswers = (
  answers?: Record<string, Address | string | undefined | Record<string, never>>
): Answers => {
  if (!answers) {
    return {};
  }

  const answersWithValue: Answers = {};
  Object.keys(answers).forEach((key) => {
    const value = answers[key];
    if (questionHasAnswer(value)) {
      answersWithValue[key] = value as AnswerValue;
    }
  });
  return answersWithValue;
};
