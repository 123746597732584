import type { SerializedStyles } from '@emotion/react';

export interface CounterInputProps {
  customCSS?: SerializedStyles;
  variant: CounterInputVariant;
  label?: string;
  iconUrl?: string;
  inputId: string;
  value?: string | number;
  disabled?: boolean;
  hasError?: boolean;
  isPrefilled?: boolean;
  componentRef?: (instance: any) => void;
  onBlur?: () => void;
  onChange?: (value: number | '') => void;
}

export enum CounterInputVariant {
  Default = 'default',
  WithCheckbox = 'withCheckbox',
}
