import { containerCSS } from './Container.style';
import type { ContainerProps } from './Container.type';
import type { FC } from 'react';

const Container: FC<ContainerProps> = ({
  isAriaLivePolite,
  customCSS,
  fullscreen = false,
  dense = false,
  children,
}) => {
  return (
    <div {...(isAriaLivePolite && { 'aria-live': 'polite' })} css={[containerCSS(fullscreen, dense), customCSS]}>
      {children}
    </div>
  );
};

export default Container;
