import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text20CSS, text24CSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  padding: 20px 16px;

  ${mq[0]} {
    padding: 30px 30px 40px;
  }
`;

export const titleCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 8px;

  ${mq[0]} {
    ${text24CSS};
  }
`;

export const infoBlockCSS = css`
  position: relative;
  margin-top: 16px;
  border-radius: 4px;
  background: ${theme.colors.azure95};
  padding: 12px 12px 12px 34px;
  font-weight: 700;
  text-align: left;

  ${mq[0]} {
    padding: 12px 24px 12px 46px;
    display: inline-block;
    margin-top: 20px;
  }
`;

export const checkIconCSS = css`
  position: absolute;
  top: calc(50% - 8px);
  left: 12px;
  background-color: ${theme.colors.positiveGreen27};
  width: 8px;
  padding: 4px;
  border-radius: 50%;

  ${mq[0]} {
    left: 24px;
  }
`;
