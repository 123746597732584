import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text24CSS, text32CSS } from '../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  position: relative;
  font-weight: 700;
  ${text24CSS};
  margin-bottom: 12px;
  flex-basis: 100%;

  ${mq[0]} {
    margin-bottom: 20px;
    margin-right: ${theme.sizes.inputHorizontalSpacing}px;
  }

  ${mq[1]} {
    ${text32CSS};
  }
`;

const INDICATOR_TOP_OFFSET = {
  mobile: 18 - theme.sizes.indicatorMobile / 2,
  tablet: 8,
  desktop: 14,
};

export const indicatorCSS = css`
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.gray60};
  border-radius: 50%;
  content: '';
  height: ${theme.sizes.indicatorMobile}px;
  left: -23.5px;
  position: absolute;
  top: ${INDICATOR_TOP_OFFSET.mobile}px;
  width: ${theme.sizes.indicatorMobile}px;
  z-index: 1;

  ${mq[0]} {
    height: ${theme.sizes.indicatorDesktop}px;
    left: -38px;
    top: ${INDICATOR_TOP_OFFSET.tablet}px;
    width: ${theme.sizes.indicatorDesktop}px;
  }

  ${mq[1]} {
    top: ${INDICATOR_TOP_OFFSET.desktop}px;
  }
`;

export const indicatorWithAnimationCSS = (animate: boolean): SerializedStyles => css`
  transition: top 0.3s ease-in-out 0.2s;
  top: ${animate ? INDICATOR_TOP_OFFSET.mobile : INDICATOR_TOP_OFFSET.mobile + 80}px;

  ${mq[0]} {
    top: ${animate ? INDICATOR_TOP_OFFSET.tablet : INDICATOR_TOP_OFFSET.tablet + 80}px;
  }

  ${mq[1]} {
    top: ${animate ? INDICATOR_TOP_OFFSET.desktop : INDICATOR_TOP_OFFSET.desktop + 80}px;
  }
`;

export const tooltipButtonCSS = css`
  vertical-align: baseline;
`;
