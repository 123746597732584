export const CO_INSURED_QUESTION_HINT =
  'If you don’t add a spouse, significant other or family member to your policy, you will ' +
  'be <b>the only one able</b> to make claims or changes to your policy.';

export const CO_INSURED_QUESTION_KEY = 'add-co-insured-question';

export const CO_INSURED_QUESTION_OPTIONS = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
];
