import { useState } from 'react';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../../constants/full-story';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import { noopAsync } from '../../../../../shared/utils/noop';
import format from '../../../../../utils/format.util';
import { buttonCSS, columnsWrapperCSS, itemCSS, singleItemCSS } from './CheckoutMortgagePreview.style';
import type { CheckoutMortgagePreviewProps } from './CheckoutMortgagePreview.type';
import type { FC } from 'react';

const CheckoutMortgagePreview: FC<CheckoutMortgagePreviewProps> = ({
  customCSS,
  landerName,
  loanNumber,
  personEmail,
  personNumber,
  personAddress,
  showButton,
  actionLabel,
  onButtonClick = noopAsync,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onActionClick = async (): Promise<void> => {
    setIsLoading(true);
    await onButtonClick();
    setIsLoading(false);
  };

  return (
    <div css={customCSS}>
      <div css={columnsWrapperCSS}>
        <div css={itemCSS}>
          <strong>Mortgage Lender:</strong> {landerName}
        </div>
        <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
          <strong>Loan Number:</strong> {loanNumber}
        </div>
        <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
          <strong>Email:</strong> {personEmail}
        </div>
        <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
          <strong>Phone Number:</strong> {format.phone(personNumber)}
        </div>
      </div>
      <div css={singleItemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
        <strong>Mailing address:</strong> {format.address(personAddress)}
      </div>

      {showButton && (
        <Button customCSS={buttonCSS} fullWidth size={ButtonSize.Large} isLoading={isLoading} onClick={onActionClick}>
          {actionLabel}
        </Button>
      )}
    </div>
  );
};

export default CheckoutMortgagePreview;
