import QuoteCoverageGroup from '../../QuoteCoverageGroup/QuoteCoverageGroup';
import { groupCSS } from './AutoQuoteCoverages.style';
import { getCoveragesGroups } from './AutoQuoteCoverages.util';
import type { AutoQuoteCoveragesProps } from './AutoQuoteCoverages.type';
import type { FC } from 'react';

const AutoQuoteCoverages: FC<AutoQuoteCoveragesProps> = ({ customCSS, coverages, assets }) => {
  if (!coverages.length) {
    return null;
  }

  const groups = getCoveragesGroups(coverages, assets);

  return (
    <div css={customCSS}>
      {groups.map((group, i) => (
        <QuoteCoverageGroup key={group.assetGid} customCSS={groupCSS(i === 0)} group={group} />
      ))}
    </div>
  );
};

export default AutoQuoteCoverages;
