import { css } from '@emotion/react';
import { formInputFocusOutlineCSS } from '../../../../styles/form.style';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (hasError: boolean = false, isDisabled: boolean): SerializedStyles => css`
  background: transparent;
  padding-right: 20px;
  cursor: pointer;
  transition: border-color 300ms ease-in-out;
  height: 64px;

  svg {
    transition: color 300ms ease-in-out;
  }
  &:before {
    ${formInputFocusOutlineCSS};
  }
  ${hasError &&
  css`
    border-color: ${theme.colors.errorRed};
    box-shadow: inset 0 0 0 1px ${theme.colors.errorRed};
  `}

  &:focus {
    outline: none;
    border-color: ${theme.colors.black};
    box-shadow: none;
    svg {
      color: ${theme.colors.gray60};
    }
    &:before {
      border-color: ${hasError ? theme.colors.errorRed : theme.colors.azure62};
    }
  }
  &:hover {
    border-color: ${hasError ? theme.colors.errorRed : theme.colors.azure62};
    svg {
      color: ${hasError ? theme.colors.errorRed : theme.colors.azure62};
    }
  }
  ${isDisabled &&
  css`
    background: ${theme.colors.gray10};
    &:hover {
      border-color: ${theme.colors.black};
      svg {
        color: ${theme.colors.gray60};
      }
    }
    &:focus {
      outline: none;
      border-color: ${theme.colors.black};
      box-shadow: none;
      svg {
        color: ${theme.colors.gray60};
      }
      &:before {
        border-color: transparent;
      }
    }
  `}
`;

export const formCardCSS = (hasError = false): SerializedStyles => css`
  position: relative;
  padding: 20px;
  border-color: ${hasError ? theme.colors.errorRed : theme.colors.gray30};
  border-width: 2px;
`;

export const formWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;

  ${mq[0]} {
    margin-right: -${theme.sizes.inputHorizontalSpacing}px};
  }
`;

export const actionButtonsCSS = css`
  display: flex;
  gap: 12px;
  justify-content: flex-end;
  margin-top: 12px;
`;
