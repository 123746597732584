import { css } from '@emotion/react';
import { text20CSS, text24CSS, text48CSS } from '../../../../../styles/typography.style';

export const containerCSS = css``;

export const subheaderCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: -5px;
`;

export const headerCSS = css`
  ${text48CSS};
  line-height: 60px;
  font-weight: 900;
  margin-bottom: 16px;
`;

export const headerSuffixCSS = css`
  ${text24CSS};
  font-weight: 700;
`;

export const buttonCSS = css`
  min-width: 148px;
`;
