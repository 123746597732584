import SIRV_IMAGES from '../../../../../constants/sirv-images';
import useResponsive from '../../../../../hooks/use-responsive';
import { Card } from '../../../../../shared/components/Card/Card';
import Divider from '../../../../../shared/components/Divider/Divider';
import SirvImage from '../../../../../shared/components/SirvImage/SirvImage';
import {
  containerCSS,
  headingCSS,
  rowWrapperCSS,
  headingWrapperCSS,
  itemCSS,
  descriptionWrapperCSS,
  cardWrapperCSS,
  itemHeadingCSS,
  subHeadingCSS,
  subHeaderCSS,
  imageCSS,
  dividerCSS,
} from './SoftStopPage.style';
import type { SoftStopPageProps } from './SoftStopPage.type';
import type { FC } from 'react';

const SoftStopPage: FC<SoftStopPageProps> = ({ userName }) => {
  const { isMobile } = useResponsive();

  return (
    <div css={containerCSS} data-testid="no-quotes-soft-stop">
      <div css={rowWrapperCSS}>
        <div css={headingWrapperCSS}>
          <div css={headingCSS}> {userName}, we&apos;re sorry!</div>
          <div css={subHeaderCSS}>
            We don&apos;t seem to have any home insurance options for you in our network at the moment.
          </div>
        </div>

        <Divider customCSS={dividerCSS} vertical={isMobile} height={180} width={350} />
        <div css={descriptionWrapperCSS}>
          Reasons for this include the age of your home, your property&apos;s natural disaster risk, or limited carrier
          availability in your area.
        </div>
      </div>
      <div>
        <div css={subHeadingCSS}>What should I do next...</div>
        <Card customCSS={cardWrapperCSS}>
          <div>
            <div css={itemCSS}>
              <div css={itemHeadingCSS()}>
                Keep an eye out for our RateReview<sup>®</sup> alerts
              </div>
              Matic continues to work for you. If a home insurance option comes available in our carrier network,
              we&apos;ll notify you through our rate monitoring service.
            </div>
            <div css={itemCSS}>
              <div css={itemHeadingCSS(true)}>Check your local options</div>
              You can also work with state-run providers and local agencies to understand your home insurance options.
            </div>
          </div>
          <SirvImage
            customCSS={imageCSS}
            url={SIRV_IMAGES.documentWithPlantMagnifier}
            width={360}
            height={215}
            alt=""
          />
        </Card>
      </div>
    </div>
  );
};

export default SoftStopPage;
