import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { QuoteRequestResponse, QuoteType } from '../../types/quote.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useLastQuoteRequest = <T = QuoteType>(
  gid?: string,
  enabled = true
): QueryObserverResult<QuoteRequestResponse<T>> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.quoteRequest, gid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<QuoteRequestResponse<T>>>(getApiUrl(API_PATH.quoteLastRequests, gid as string))
        .then((res) => res.data),
    enabled: enabled && !!gid,
  });

export default useLastQuoteRequest;
