import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text20CSS, textCSS } from '../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

const simpleQuestionLabelCSS = css`
  ${mq[0]} {
    margin: 0 0 16px;
  }
`;

export const containerCSS = (isSubElement = false): SerializedStyles => css`
  flex-basis: 100%;
  margin-top: 12px;
  margin-bottom: 12px;

  ${mq[0]} {
    margin-top: 28px;
    margin-bottom: 32px;
    margin-right: ${theme.sizes.inputHorizontalSpacing}px;
  }

  ${isSubElement && simpleQuestionLabelCSS};
`;

export const titleCSS = (isSubElement = false): SerializedStyles => css`
  ${isSubElement ? textCSS : text20CSS};
  font-weight: ${isSubElement ? 500 : 700};
`;

export const subtitleCSS = css`
  margin-top: 8px;
`;
