import { css } from '@emotion/react';
import { indicatorLineCSS } from '../../../../styles/form.style';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const formCSS = (hasHiddenLine: boolean, clearBottomSpacing?: boolean): SerializedStyles => css`
  appearance: none;
  border: none;
  margin: 0;
  padding: 0 0 ${clearBottomSpacing ? 0 : theme.sizes.formHolderBottomSpaceMobile}px;
  position: relative;

  &:before {
    ${indicatorLineCSS};
    height: ${hasHiddenLine ? 0 : 100}%;
    transition: height 500ms ease-in-out 300ms;
  }

  ${mq[0]} {
    padding-bottom: ${clearBottomSpacing ? 0 : theme.sizes.formHolderBottomSpaceDesktop}px;
  }
`;

export const formInnerScrollCSS = css`
  position: relative;
  top: 0;
  opacity: 1;
`;

export const scrollPointCSS = css`
  position: absolute;
  top: calc(var(--header-height) * -1 - 20px);

  ${mq[0]} {
    top: calc(var(--header-height) * -1);
  }
`;

export const navigationPointCSS = css`
  margin-right: 0;

  ${mq[0]} {
    margin-right: 0;
  }
`;

export const listWrapperCSS = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
`;

export const iconCSS = css`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  color: ${theme.colors.gray60};
`;

export const messageCSS = (isWarning: boolean): SerializedStyles => css`
  background-color: ${isWarning ? theme.colors.warningYellow94 : theme.colors.azure95};
  margin-bottom: 0;
  ${mq[0]} {
    margin-bottom: 0;
  }
`;
