import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const headerCSS = (isTestMode: boolean, withNavigation: boolean): SerializedStyles => css`
  position: relative;
  background-color: ${theme.colors.white};
  padding-top: ${isTestMode ? theme.header.testModePadding : 8}px;
  padding-bottom: 8px;
  min-height: var(--header-height);
  box-sizing: border-box;
  transition: min-height 0.5s ease-in-out;
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: ${theme.colors.white};
    border-bottom: ${withNavigation ? 1 : 0}px solid ${theme.colors.gray10};
    z-index: -1;
  }
  ${mq[0]} {
    padding-top: ${isTestMode ? theme.header.testModePadding : 12}px;
    padding-bottom: 12px;
  }
`;

export const headerInnerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const logoContainerCSS = (reverseLogo: boolean): SerializedStyles => css`
  display: flex;
  align-items: center;
  flex-direction: ${reverseLogo ? 'row-reverse' : 'row'};
`;

export const dividerCSS = css`
  margin: 0 12px;
  ${mq[0]} {
    margin: 0 20px;
  }
`;

export const carrierLogoCSS = css`
  max-width: 205px;
`;

export const testModeUpdatedGlobalStylesCSS = (isTestMode: boolean, hasNavigation: boolean): SerializedStyles => css`
  :root {
    --header-height: ${theme.header.headerHeightMobile +
    (isTestMode ? theme.header.testModePadding : 0) +
    (hasNavigation ? theme.header.navigationHeightMobile : 0)}px;

    ${mq[0]} {
      --header-height: ${theme.header.headerHeightDesktop +
      (isTestMode ? theme.header.testModePadding : 0) +
      (hasNavigation ? theme.header.navigationHeightTablet : 0)}px;
    }

    ${mq[1]} {
      --header-height: ${theme.header.headerHeightDesktop + (isTestMode ? theme.header.testModePadding : 0)}px;
    }
  }
`;
