import type { SerializedStyles } from '@emotion/react';
import type { ReactElement } from 'react';

export enum InfoFormBlockType {
  info = 'info',
  warning = 'warning',
}

export interface InfoFormBlockProps {
  customCSS?: SerializedStyles;
  content: string | ReactElement;
  iconUrl?: string;
  type?: InfoFormBlockType;
  gridMode?: boolean;
}
