import SIRV_IMAGES from '../../constants/sirv-images';
import InfoFormBlock from '../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import LoadingDots from '../../shared/components/LoadingDots/LoadingDots';
import { containerCSS, headerCSS, iconCSS, infoBlockCSS, subheaderCSS } from './PageLoader.style';
import type { PageLoaderProps } from './PageLoader.type';
import type { FC } from 'react';

const PageLoader: FC<PageLoaderProps> = ({
  customCSS,
  header,
  subheader,
  imgUrl,
  infoBlockContent,
  customImageCSS,
}) => {
  return (
    <div css={[containerCSS, customCSS]}>
      {imgUrl && <img css={[iconCSS, customImageCSS]} src={imgUrl} alt="Loader" />}
      {header && <div css={headerCSS}>{header}</div>}
      {subheader && <div css={subheaderCSS}>{subheader}</div>}
      <LoadingDots />
      {infoBlockContent && (
        <InfoFormBlock customCSS={infoBlockCSS} iconUrl={SIRV_IMAGES.infoIcon} content={infoBlockContent} />
      )}
    </div>
  );
};

export default PageLoader;
