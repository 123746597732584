import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';

export const buttonsWrapperCSS = css`
  margin-top: 24px;
  text-align: center;
`;

export const backCSS = css`
  min-width: 100px;
  margin-right: 12px;
  height: 44px;
  ${mq[0]} {
    min-width: 120px;
    margin-right: 25px;
    height: 60px;
  }
`;

export const submitCSS = css`
  min-width: 203px;
  height: 44px;
  ${mq[0]} {
    min-width: 330px;
    height: 60px;
  }
`;
