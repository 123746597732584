import { isArraysEqual } from '../../../shared/utils/array-helpers.util';
import { ANSWER_BOOLEAN_VALUE, type AnswerValue, type Answers } from '../../../types/answer.type';
import { getDynamicDefaultValue } from '../dynamic-defaults.util';
import type { DynamicDefaults } from '../../../types/dynamic-defaults.type';

export const isAnswersEqual = (answers: Answers, oldAnswers: Answers): boolean =>
  Object.keys(answers).every((key) => {
    switch (true) {
      case typeof answers[key] !== typeof oldAnswers[key]:
        return false;
      case typeof answers[key] === 'string':
      case typeof answers[key] === 'number':
      case answers[key] === null:
      case typeof answers[key] === 'boolean':
        return answers[key] === oldAnswers[key];
      case Array.isArray(answers[key]):
        return isArraysEqual<string>(answers[key] as string[], oldAnswers[key] as string[]);
      default:
        return false;
    }
  });

export const getBooleanAnswers = (
  keys: string[],
  answers: Answers,
  defaultAnswers: Answers,
  dynamicDefaults: DynamicDefaults
): Answers => {
  keys.forEach((key) => {
    let value: AnswerValue = ANSWER_BOOLEAN_VALUE.no;
    if (answers[key]) {
      value = answers[key];
    } else if (defaultAnswers[key]) {
      value = defaultAnswers[key];
    } else if (dynamicDefaults[key]) {
      value = getDynamicDefaultValue(answers, dynamicDefaults[key]);
    }
    answers[key] = value;
  });
  return { ...answers };
};

export const convertAnswerToBoolean = (value?: AnswerValue): boolean => {
  switch (value) {
    case ANSWER_BOOLEAN_VALUE.yes:
      return true;
    case ANSWER_BOOLEAN_VALUE.no:
      return false;
    default:
      return false;
  }
};

export const getOtherTypesAnswers = (
  keys: string[],
  answers: Answers,
  defaultAnswers: Answers,
  dynamicDefaults: DynamicDefaults
): Answers => {
  keys.forEach((key) => {
    let value: AnswerValue = '';
    if (questionHasAnswer(answers[key])) {
      value = answers[key];
    } else if (questionHasAnswer(defaultAnswers[key])) {
      value = defaultAnswers[key];
    } else if (dynamicDefaults[key]) {
      value = getDynamicDefaultValue(answers, dynamicDefaults[key]);
    }
    answers[key] = value;
  });
  return { ...answers };
};

export const questionHasAnswer = (answerValue?: AnswerValue | Record<string, never>): boolean => {
  switch (true) {
    case answerValue === null:
      return false;
    case typeof answerValue === 'string':
      return answerValue !== '';
    case Array.isArray(answerValue):
      return answerValue.length > 0;
    case typeof answerValue === 'object' && answerValue !== null:
      return Object.values(answerValue).some((value) => !!value);
    case typeof answerValue === 'number':
      return true;
    case typeof answerValue === 'boolean':
      return true;
    default:
      return false;
  }
};

export const isValueSpecificType = <Type extends AnswerValue>(
  value?: AnswerValue,
  types: Array<'string' | 'number' | 'null' | 'array' | 'address'> = []
): value is Type => {
  return (
    (types.includes('string') && typeof value === 'string') ||
    (types.includes('number') && typeof value === 'number') ||
    (types.includes('array') && Array.isArray(value)) ||
    (types.includes('null') && value === null) ||
    (types.includes('address') &&
      typeof value === 'object' &&
      value !== null &&
      Object.hasOwn(value, 'zip') &&
      Object.hasOwn(value, 'city'))
  );
};
