import { css } from '@emotion/react';
import theme from '../../../../styles/theme';
import { text14CSS } from '../../../../styles/typography.style';
import { activeElementResetCSS } from '../../../shared.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const headingCSS = (hasDetails?: boolean): SerializedStyles => css`
  ${activeElementResetCSS};
  align-items: center;
  display: flex;
  font-size: ${hasDetails ? 16 : 14}px;
  font-weight: bold;
  text-align: left;
`;

export const iconCSS = css`
  margin-right: 8px;
  max-height: 18px;
`;

export const editCSS = css`
  height: 12px;
  margin-left: 6px;
  width: 12px;
  color: ${theme.colors.gray80};
`;

export const subheadingCSS = css`
  ${text14CSS};
  font-weight: normal;
  margin-left: 26px;
`;
