import type { FeatureCondition } from './feature_condition.type';
import type { FormComponentSchema } from './form-component.type';
import type { FormElementSchema } from './form-element.type';
import type { VisibilityCondition } from './visibility-condition.type';

export enum SectionPresentationType {
  Workflow = 'workflow',
  Custom = 'custom',
  Authorization = 'authorization',
}

export type SectionSchema = WorkflowSectionSchema | CustomSectionSchema | AuthSectionSchema;

export interface WorkflowSectionSchema extends BaseSectionSchema {
  containers: SectionContainer[];
}

export interface CustomSectionSchema extends BaseSectionSchema {
  components: FormComponentSchema[];
}

export type AuthSectionSchema = BaseSectionSchema;

export interface BaseSectionSchema {
  key: string;
  action_label: string;
  presentation_type: SectionPresentationType;
}

export interface SectionContainer {
  key: string;
  position: number;
  elements: FormElementSchema[];
  visibility_conditions: VisibilityCondition[][];
  feature_condition: FeatureCondition | null;
}
