import ROUTES from '../constants/routes';
import isGid from '../shared/utils/is-gid.util';
import { PageTypes } from '../types/page.type';
import { Flows } from '../types/route-params.type';
import type { PolicyType } from '../types/public-flow-config.type';

export const getPathByPageType = (type?: PageTypes, gid?: string, flow?: PolicyType | Flows): string => {
  if (!gid || !isGid(gid) || !flow || !isValidFlowValue(flow)) {
    return ROUTES.problem;
  }

  switch (type) {
    case PageTypes.QuestionForm:
      return ROUTES.questions.replace(':gid', gid).replace(':flow', flow);
    case PageTypes.Discounts:
      return ROUTES.discounts.replace(':gid', gid).replace(':flow', flow);
    case PageTypes.QuoteCustomization:
      return ROUTES.quote.replace(':gid', gid).replace(':flow', flow);
    case PageTypes.Billing:
      return ROUTES.checkout.replace(':gid', gid).replace(':flow', flow);
    case PageTypes.Congratulations:
      return ROUTES.congratulations.replace(':gid', gid).replace(':flow', flow);
    case PageTypes.AutoInformationForm:
      return ROUTES.autoInformation.replace(':gid', gid).replace(':flow', flow);
    default:
      return ROUTES.problem;
  }
};

export const getRouteParamsFromLocationPath = (path: string): { gid: string | null; flow: Flows | null } => {
  const pathArray = path.split('/');
  const possibleFlow = pathArray[1];
  const possibleGid = pathArray[2];

  return {
    gid: isGid(possibleGid) ? possibleGid : null,
    flow: isValidFlowValue(possibleFlow) ? (possibleFlow as Flows) : null,
  };
};

export const isValidFlowValue = (flow: string = ''): boolean => {
  // @ts-expect-error should check if flow is in Flows type
  return [Flows.Auto, Flows.Home, Flows.Condo].includes(flow);
};
