import SkeletonLoader from '../../../../../shared/components/SkeletonLoader/SkeletonLoader';
import {
  infoBlockCSS,
  inputCSS,
  labelCSS,
  radioWrapperCSS,
  singleStepHeadingCSS,
  stepHeadingCSS,
} from './CheckoutHomeFormLoading.style';
import type { CheckoutHomeFormLoadingProps } from './CheckoutHomeFormLoading.type';
import type { FC } from 'react';

const CheckoutHomeFormLoading: FC<CheckoutHomeFormLoadingProps> = ({ customCSS }) => {
  return (
    <div css={customCSS} role="status" aria-label="Loading">
      <SkeletonLoader customCSS={stepHeadingCSS} absoluteHeight={32} minWidth={60} maxWidth={90} />

      <SkeletonLoader customCSS={infoBlockCSS} absoluteHeight={112} width={100} />

      <SkeletonLoader customCSS={labelCSS} absoluteHeight={16} minWidth={40} maxWidth={80} />
      <div css={radioWrapperCSS}>
        <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />
        <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />
      </div>

      <SkeletonLoader customCSS={singleStepHeadingCSS} absoluteHeight={32} minWidth={60} maxWidth={90} />

      <SkeletonLoader customCSS={singleStepHeadingCSS} absoluteHeight={32} minWidth={60} maxWidth={90} />
    </div>
  );
};

export default CheckoutHomeFormLoading;
