import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import { text14CSS, text20CSS, textCSS, text24CSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;

  ${mq[0]} {
    flex-direction: row;
    padding: 32px 29px;
    margin: 30px 90px;
    align-items: center;
  }
`;
export const headingCSS = css`
  font-weight: 700;
  ${text20CSS};
  margin-bottom: 5px;
  margin-top: 16px;

  ${mq[0]} {
    ${text24CSS};
    line-height: 32px;
    margin-top: 0;
  }
`;
export const subHeadingCSS = css`
  ${text14CSS};
  line-height: 20px;

  ${mq[0]} {
    ${textCSS};
  }
`;
export const iconCSS = css`
  flex: 1;
  height: 80px;
  margin-right: 0;

  ${mq[0]} {
    flex: 60%;
    height: 102px;
    margin-right: 30px;
  }
`;

export const buttonCSS = css`
  width: 100%;
  margin-top: 16px;

  ${mq[0]} {
    margin-top: 20px;
    padding: 15px 12px;
  }
`;
