const MATIC_WEBSITE = 'https://matic.com';

const EXTERNAL_URLS = {
  maticWebsite: MATIC_WEBSITE,
  websiteConsumers: `${MATIC_WEBSITE}/for-consumers`,
  websiteOriginators: `${MATIC_WEBSITE}/for-originators`,
  websiteServicers: `${MATIC_WEBSITE}/for-servicers`,
  websitePrivacyPolicy: `${MATIC_WEBSITE}/privacy-policy`,
  websiteLicenseNumbers: `${MATIC_WEBSITE}/license-numbers`,
  websiteTerms: `${MATIC_WEBSITE}/terms-of-use`,
  socialTwitter: 'https://twitter.com/maticinsurance',
  socialLinkedin: 'https://www.linkedin.com/company/matic-insurance',
  socialFacebook: 'https://www.facebook.com/maticinsurance',
  socialInstagram: 'https://www.instagram.com/maticinsurance',
};

export default EXTERNAL_URLS;
