import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (height = 0, width = 0, isVertical = false): SerializedStyles => css`
  content: '';
  height: ${height}px;
  ${!isVertical &&
  css`
    border-left: 1px solid ${theme.colors.gray30};
  `}

  ${isVertical &&
  css`
    height: 30px;
    width: ${width}px;
    border-top: 1px solid ${theme.colors.gray30};
    margin-top: 30px;
  `}
`;
