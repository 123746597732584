export const removeCents = (price: string | number): string => {
  return String(price).split('.')[0];
};

export const getCents = (price: string | number): string => {
  const priceNumber = Number(price);
  if (!priceNumber || Number.isInteger(priceNumber)) {
    return '00';
  }
  const numRound = Math.round(priceNumber * 100) / 100;

  const decimalStr = numRound.toString().split('.')[1];

  return decimalStr ? (decimalStr.length === 1 ? `${decimalStr}0` : decimalStr) : '00';
};
