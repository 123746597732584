import MailIcon from '../../../../../assets/mail.svg?react';
import PhoneIcon from '../../../../../assets/phone.svg?react';
import { CONTACT_EMAIL } from '../../../../../constants/contacts';
import SIRV_IMAGES from '../../../../../constants/sirv-images';
import Anchor from '../../../../../shared/components/Anchor/Anchor';
import { Card } from '../../../../../shared/components/Card/Card';
import SirvImage from '../../../../../shared/components/SirvImage/SirvImage';
import Format from '../../../../../utils/format.util';
import {
  contactBlockCSS,
  containerCSS,
  descriptionCSS,
  headerTextCSS,
  iconCSS,
  infoBlockCSS,
  linkCSS,
  maticTeamCSS,
} from './MaticHelpBlock.style';
import type { MaticHelpBlockProps } from './MaticHelpBlock.type';
import type { FC } from 'react';

const MaticHelpBlock: FC<MaticHelpBlockProps> = ({ customCSS, phoneNumber, onPhoneClick }) => {
  return (
    <Card customCSS={[containerCSS, customCSS]}>
      <div css={infoBlockCSS}>
        <SirvImage
          customCSS={maticTeamCSS}
          url={SIRV_IMAGES.maticTeam}
          height={96}
          width={96}
          alt="team-members-smile"
        />
        <div>
          <div css={headerTextCSS}>The Matic Team</div>
          <div css={descriptionCSS}>
            We look forward to connecting with you. In the meantime, if you need immediate assistance please contact us.
          </div>
        </div>
      </div>
      <div css={contactBlockCSS}>
        <div css={headerTextCSS}>Have questions?</div>
        <Anchor customCSS={linkCSS} href={`tel:${phoneNumber}`} onClick={onPhoneClick}>
          <PhoneIcon css={iconCSS} />
          {Format.phone(phoneNumber)}
        </Anchor>
        <Anchor customCSS={linkCSS} href={`mailto:${CONTACT_EMAIL}`}>
          <MailIcon css={iconCSS} />
          {CONTACT_EMAIL}
        </Anchor>
      </div>
    </Card>
  );
};

export default MaticHelpBlock;
