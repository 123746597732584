import SirvImage from '../../../shared/components/SirvImage/SirvImage';
import { containerCSS, iconCSS } from './InfoFormBlock.style';
import { InfoFormBlockType } from './InfoFormBlock.type';
import type { InfoFormBlockProps } from './InfoFormBlock.type';
import type { FC } from 'react';

// TODO: Maybe we need separate Form element and InfoBlock that we use outside of Form
const InfoFormBlock: FC<InfoFormBlockProps> = ({
  customCSS,
  iconUrl,
  content,
  type = InfoFormBlockType.info,
  gridMode,
}) => {
  return (
    <div css={[containerCSS(type, gridMode, !!iconUrl), customCSS]}>
      {iconUrl && <SirvImage customCSS={iconCSS} url={iconUrl} width={24} alt="" />}
      {typeof content === 'string' ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content}
    </div>
  );
};

export default InfoFormBlock;
