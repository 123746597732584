import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text24CSS } from '../../../../../styles/typography.style';

export const callRibbonCSS = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 36px;
  background-color: ${theme.colors.azure50};
  color: ${theme.colors.white};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--header-height);
  font-weight: 700;

  ${mq[0]} {
    height: 56px;
  }
`;

export const linkCSS = css`
  margin-left: 0;
  border: none;
  color: ${theme.colors.white};
  margin-left: 5px;
  display: flex;
  align-items: center;

  ${mq[0]} {
    ${text24CSS};
    margin-left: 20px;
  }
`;

export const iconCSS = css`
  padding-right: 8px;

  ${mq[0]} {
    width: 22px;
    height: 22px;
  }
`;
