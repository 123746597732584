import CheckboxInputMark from '../../../../assets/checkbox-input-mark.svg?react';
import { containerCSS, iconCSS } from './CheckboxIcon.style';
import type { CheckboxIconProps } from './CheckboxIcon.type';
import type { FC } from 'react';

const CheckboxIcon: FC<CheckboxIconProps> = ({
  customCSS,
  checked = false,
  disabled = false,
  hasError = false,
  testId = 'checkbox-icon',
}) => {
  return (
    <span
      className="checkbox-input-mark"
      css={[containerCSS(checked, disabled, hasError), customCSS]}
      data-testid={testId}
    >
      <CheckboxInputMark css={iconCSS(checked)} />
    </span>
  );
};

export default CheckboxIcon;
