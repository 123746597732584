import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../../constants/api';
import QUERY_CACHE_KEYS from '../../../constants/query-cache-keys';
import { getApiUrl } from '../../../utils/api.util';
import api from '../../api';
import type { HTTPResponse } from '../../../types/api.type';
import type { PublicCustomerJourneyResponse } from '../../../types/public-customer-journey.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const usePublicCustomerJourney = (gid?: string): QueryObserverResult<PublicCustomerJourneyResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.publicCustomerJourney, gid],
    queryFn: async () =>
      gid
        ? await api
            .get<HTTPResponse<PublicCustomerJourneyResponse>>(getApiUrl(API_PATH.public.customerJourney, gid))
            .then((res) => res.data)
        : undefined,
    enabled: Boolean(gid),
  });

export default usePublicCustomerJourney;
