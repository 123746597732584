import { css } from '@emotion/react';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  margin-bottom: 80px;
`;

export const dividerCSS = css`
  width: 100%;
  border-top: 1px solid ${theme.colors.gray10};
  margin: 32px 0;
`;
