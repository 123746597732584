import PageContainer from '../../components/PageContainer/PageContainer';
import QuestionsPage from './components/QuestionsPage/QuestionsPage';
import type { FC } from 'react';

const QuestionsLayout: FC = () => (
  <PageContainer showFooter={false} allowFullStoryTracking allowTabTitleUpdate>
    <QuestionsPage />
  </PageContainer>
);

export default QuestionsLayout;
