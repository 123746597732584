import { useQuery } from '@tanstack/react-query';
import QUERY_CACHE_KEYS from '../../../constants/query-cache-keys';
import publicSessionService from '../session/public-session.service';
import type { PublicPrimaryInsuredResponse } from '../../../types/public-session.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const usePublicPrimaryInsured = (
  gid?: string,
  throwOnError: boolean = true
): QueryObserverResult<PublicPrimaryInsuredResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.publicPrimaryInsured, gid],
    queryFn: gid ? async () => await publicSessionService.getPublicPrimaryInsured(gid) : undefined,
    enabled: Boolean(gid),
    throwOnError,
  });

export default usePublicPrimaryInsured;
