import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 76px;
  margin-bottom: 60px;

  ${mq[0]} {
    padding-top: 116px;
  }
`;
