import type { SerializedStyles } from '@emotion/react';

export enum LoaderSizeEnum {
  Small = 'small',
  Medium = 'medium',
}
export interface LoaderDotProps {
  inverted: boolean;
  colored: boolean;
  size: LoaderSizeEnum;
}

export interface LoaderProps extends Partial<LoaderDotProps> {
  customCSS?: SerializedStyles;
  fullPage?: boolean;
  size?: LoaderSizeEnum;
}
