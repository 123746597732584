import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { DriversResponse } from '../../types/driver.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useDrivers = (gid: string): QueryObserverResult<DriversResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.drivers, gid],
    queryFn: async () =>
      await api.get<HTTPResponse<DriversResponse>>(getApiUrl(API_PATH.drivers, gid)).then((res) => res.data),
  });

export default useDrivers;
