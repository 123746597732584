import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';

export const infoBlockCSS = css`
  margin-top: 16px;
  margin-bottom: 0;
  ${mq[0]} {
    margin-bottom: 0;
  }
`;

export const formCSS = css`
  margin-top: 16px;
  ${mq[0]} {
    margin-top: 24px;
  }
`;

export const previewCSS = css`
  margin-top: 16px;
`;
