export interface HTTPResponse<T = unknown> {
  data: T;
  status: 'success' | 'fail';
  message?: string;
}

export enum HTTPMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  head = 'HEAD',
  delete = 'DELETE',
}

export interface ApiCallParams {
  headers?: any;
  body?: any;
  params?: any;
}

export type ApiCall = <T = unknown>(url: string, params?: ApiCallParams) => Promise<T>;

export interface Api {
  get: ApiCall;
  post: ApiCall;
  put: ApiCall;
  patch: ApiCall;
  head: ApiCall;
  delete: ApiCall;
}
