import PageContainer from '../../components/PageContainer/PageContainer';
import DiscountsPage from './components/DiscountsPage/DiscountsPage';
import type { FC } from 'react';

const DiscountsLayout: FC = () => {
  return (
    <PageContainer showFooter={false} allowFullStoryTracking allowTabTitleUpdate>
      <DiscountsPage />
    </PageContainer>
  );
};

export default DiscountsLayout;
