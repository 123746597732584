import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { UnlockSessionResponse, UnlockSessionData } from '../../types/session.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useMutateUnlockSession = (
  gid: string
): UseBaseMutationResult<UnlockSessionResponse, HTTPResponse, UnlockSessionData> => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (data) =>
      await api
        .post<HTTPResponse<UnlockSessionResponse>>(getApiUrl(API_PATH.unlockSession, gid), {
          body: { data },
        })
        .then((res) => res.data),
    onSuccess: async () => {
      client.clear();
    },
    onError: async () => {
      await client.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.publicSession, gid] });
    },
    throwOnError: false,
  });
};

export default useMutateUnlockSession;
