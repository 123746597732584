import { Tabs as MuiTabs, Tab } from '@mui/material';
import { useState } from 'react';
import ScrollIcon from '../../../assets/arrow-down.svg?react';
import { containerCSS, tabCSS, tabsCSS, scrollIconCSS } from './Tabs.style';
import { TabsVariant } from './Tabs.type';
import type { TabPanelProps, TabsProps } from './Tabs.type';
import type { FC, SyntheticEvent } from 'react';

const TabPanel: FC<TabPanelProps> = ({ value, index, content }) => (
  <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
    {value === index && content}
  </div>
);

const Tabs: FC<TabsProps> = ({ customCSS, tabs, variant = TabsVariant.ROUNDED }) => {
  const [value, setValue] = useState(0);
  const handleChange = (_: SyntheticEvent, newValue: number): void => setValue(newValue);

  return (
    <div css={[containerCSS, customCSS]}>
      <MuiTabs
        onChange={handleChange}
        value={value}
        variant="scrollable"
        scrollButtons
        css={tabsCSS}
        allowScrollButtonsMobile
        slots={{
          StartScrollButtonIcon: ScrollIcon,
          EndScrollButtonIcon: ScrollIcon,
        }}
        slotProps={{
          startScrollButtonIcon: {
            style: scrollIconCSS(),
          },
          endScrollButtonIcon: {
            style: scrollIconCSS(true),
          },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab value={index} key={index} css={tabCSS(variant)} label={tab.label} disableRipple disableFocusRipple />
        ))}
      </MuiTabs>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index} content={tab.content} />
      ))}
    </div>
  );
};

export default Tabs;
