import SIRV_IMAGES from '../../../constants/sirv-images';
import SirvImage from '../SirvImage/SirvImage';
import { containerCSS, imageCSS } from './Logo.style';
import type { LogoProps } from './Logo.type';
import type { FC } from 'react';

const Logo: FC<LogoProps> = ({ carrierLogo, carrierName = 'Carrier', width = 200, height, customCSS }) => {
  const props = {
    url: SIRV_IMAGES.maticLogo,
    width,
    height,
    alt: 'Matic logo',
  };

  if (carrierLogo) {
    props.url = carrierLogo;
    props.width = width;
    props.alt = carrierName;
  }

  return (
    <div css={[containerCSS, customCSS]}>
      <SirvImage customCSS={imageCSS} {...props} />
    </div>
  );
};

export default Logo;
