import { css } from '@emotion/react';
import { formInputFocusOutlineCSS } from '../../../../styles/form.style';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text14CSS } from '../../../../styles/typography.style';
import { RADIO_BUTTON_CLASSNAMES } from './RadioButton.util';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (isDisabled?: boolean): SerializedStyles => css`
  position: relative;
  background-color: ${isDisabled ? theme.colors.gray10 : theme.colors.white};
  cursor: pointer;
  display: block;
  height: auto;
  ${isDisabled &&
  css`
    pointer-events: none;
  `};
`;

export const inputCSS = (hasError?: boolean): SerializedStyles => css`
  height: 0;
  outline: none;
  position: absolute;
  width: 0;

  &:checked {
    + .${RADIO_BUTTON_CLASSNAMES.inner} {
      border-color: ${theme.colors.azure50};
      box-shadow: inset 0 0 0 1px ${theme.colors.azure50};

      .${RADIO_BUTTON_CLASSNAMES.dot}:before {
        border-color: ${theme.colors.azure50};
        background-color: ${theme.colors.azure50};
      }
      .${RADIO_BUTTON_CLASSNAMES.image} {
        opacity: 1;
      }
    }
  }

  &:focus {
    + .${RADIO_BUTTON_CLASSNAMES.inner} {
      border-color: ${theme.colors.black};
      box-shadow: inset 0 0 0 1px transparent;

      &:before {
        ${formInputFocusOutlineCSS};
        border-color: ${hasError ? theme.colors.errorRed : theme.colors.azure50};
      }
    }
  }
`;

export const contentInnerCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const nestedContentCSS = css`
  margin-top: 16px;
  ${mq[0]} {
    margin-top: 24px;
  }
`;

export const iconCSS = (isOtherOption: boolean): SerializedStyles => css`
  margin-left: 8px;
  height: ${isOtherOption ? 34 : 64}px;
`;

export const labelContainerCSS = css`
  padding-left: 38px;
`;

export const labelCSS = (bold: boolean): SerializedStyles => css`
  font-weight: ${bold ? 700 : 500};
`;

/* Shared styles */
export const radioContainerInnerCSS = (hasError?: boolean): SerializedStyles => css`
  position: relative;
  padding: 16px 20px;
  min-height: 58px;
  box-sizing: border-box;
  border: 1px solid ${hasError ? theme.colors.errorRed : theme.colors.black};
  box-shadow: inset 0 0 0 1px ${hasError ? theme.colors.errorRed : 'transparent'};
  border-radius: ${theme.sizes.borderRadius}px;
  height: 100%;

  &:hover {
    border-color: ${hasError ? theme.colors.errorRed : theme.colors.azure62};

    .${RADIO_BUTTON_CLASSNAMES.dot}:before {
      border-color: ${hasError ? theme.colors.errorRed : theme.colors.azure68};
    }
  }
`;

export const radioButtonDotCSS = (mobileAdaptive?: boolean): SerializedStyles => css`
  position: absolute;
  content: '';
  border: 1px solid ${theme.colors.black};
  border-radius: 50%;
  height: ${mobileAdaptive ? 14 : 18}px;
  width: ${mobileAdaptive ? 14 : 18}px;
  background-color: ${theme.colors.white};
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    width: ${mobileAdaptive ? 8 : 12}px;
    height: ${mobileAdaptive ? 8 : 12}px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid transparent;
    border-radius: 50%;
  }
  ${mq[0]} {
    height: 18px;
    width: 18px;
    &:before {
      height: 12px;
      width: 12px;
    }
  }
`;

export const radioIconCSS = css`
  height: 64px;
  opacity: 0.5;
  transition: opacity 0.2s ease;
  z-index: 1;
`;

export const radioDescriptionCSS = css`
  margin-top: 4px;
  ${text14CSS};
  line-height: 20px;
  color: ${theme.colors.gray60};
`;
