import QuestionIcon from '../../../../assets/grey-question.svg?react';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../constants/full-story';
import useResponsive from '../../../../hooks/use-responsive';
import { Card } from '../../../../shared/components/Card/Card';
import DashedRow from '../../../../shared/components/DashedRow/DashedRow';
import SirvImage from '../../../../shared/components/SirvImage/SirvImage';
import Tooltip from '../../../../shared/components/Tooltip/Tooltip';
import { UNKNOWN_POLICY_NUMBER } from '../../../../types/policy.type';
import format from '../../../../utils/format.util';
import { formatPlurality } from '../../../../utils/string-helpers';
import {
  carrierNameCSS,
  containerCSS,
  contentCSS,
  contentItemCSS,
  dashedRowCSS,
  headerCSS,
  headerDividerCSS,
  logoCSS,
  unknownPolicyNumberCSS,
} from './PolicySummaryDetails.style';
import type { PolicySummaryDetailsProps } from './PolicySummaryDetails.types';
import type { FC } from 'react';

const PolicySummaryDetails: FC<PolicySummaryDetailsProps> = ({ customCSS, policy, carrier, renderSummaryList }) => {
  const { isMobile } = useResponsive();

  const { showDate, showNumber, showTerm } = renderSummaryList;
  const unknownPolicyNumberContent = (
    <>
      Not Yet Issued
      <Tooltip
        customCSS={{ buttonCSS: unknownPolicyNumberCSS }}
        content={
          <>
            You will receive an email from <span css={carrierNameCSS}>{carrier.name}</span> in 24 hours with your policy
            number.
          </>
        }
      >
        <QuestionIcon />
      </Tooltip>
    </>
  );

  const policyNumberContent =
    policy.policy_number === UNKNOWN_POLICY_NUMBER ? unknownPolicyNumberContent : policy.policy_number;

  return (
    <Card customCSS={[containerCSS, customCSS]}>
      <div css={headerCSS}>
        <SirvImage
          customCSS={logoCSS}
          url={carrier.logo_url}
          width={200}
          height={isMobile ? 30 : 50}
          alt={`${carrier.name} logo`}
        />
        <div css={headerDividerCSS} />
        <span>Policy Details:</span>
      </div>
      <div css={contentCSS}>
        {showNumber && (
          <div css={contentItemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
            <DashedRow customCSS={dashedRowCSS} label={'Policy Number:'} content={policyNumberContent} />
          </div>
        )}
        {showDate && (
          <div css={contentItemCSS}>
            <DashedRow
              customCSS={dashedRowCSS}
              label={'Policy Start Date:'}
              content={format.date(policy.effective_date)}
            />
          </div>
        )}
        {showTerm && (
          <div css={contentItemCSS}>
            <DashedRow
              customCSS={dashedRowCSS}
              label={'Policy Term:'}
              content={
                policy.policy_term < 12
                  ? formatPlurality('Month', policy.policy_term)
                  : formatPlurality('Year', policy.policy_term / 12)
              }
            />
          </div>
        )}
      </div>
    </Card>
  );
};

export default PolicySummaryDetails;
