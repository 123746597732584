import SkeletonLoader from '../../../../shared/components/SkeletonLoader/SkeletonLoader';
import { loaderWrapperCSS } from './ErrorAgentLoader.style';
import type { FC } from 'react';

const ErrorAgentLoader: FC = () => (
  <div css={loaderWrapperCSS}>
    <SkeletonLoader absoluteHeight={140} absoluteWidth={140} />
    <SkeletonLoader absoluteHeight={60} absoluteWidth={250} />
    <SkeletonLoader absoluteHeight={50} absoluteWidth={350} />
    <SkeletonLoader absoluteHeight={30} absoluteWidth={100} />
  </div>
);

export default ErrorAgentLoader;
