import { Fragment } from 'react';
import NavigationItemDetails from '../../shared/components/Navigation/NavigationItemDetails/NavigationItemDetails';
import type { FlowNavigationDetailsProps } from './FlowNavigationDetails.type';
import type { FC } from 'react';

const FlowNavigationDetails: FC<FlowNavigationDetailsProps> = ({ sections, trackForbidden }) => {
  return (
    <Fragment>
      {sections.map(({ key, icon_url: iconUrl, title }) => (
        <NavigationItemDetails
          key={key}
          iconSrc={iconUrl ?? ''}
          header={title ?? ''}
          hasSensitiveData={trackForbidden}
        />
      ))}
    </Fragment>
  );
};

export default FlowNavigationDetails;
