import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { PublicFlowConfigResponse } from '../../types/public-flow-config.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useSessionFlowConfig = (gid?: string): QueryObserverResult<PublicFlowConfigResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.sessionConfig, gid],
    queryFn: gid
      ? async () => await api.get<HTTPResponse>(getApiUrl(API_PATH.sessionFlowConfig, gid)).then((res) => res.data)
      : undefined,
    enabled: Boolean(gid),
  });

export default useSessionFlowConfig;
