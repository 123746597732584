import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 24px;

  ${mq[0]} {
    flex-direction: row;
    align-items: center;
    padding: 32px 24px;
    gap: 0;
  }
`;

export const detailsColCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const timeItemCSS = css`
  color: ${theme.colors.gray60};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.25;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
  }
`;

export const buttonCSS = css`
  min-width: 190px;
`;
