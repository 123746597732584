import { CustomComponentPresentationType } from '../../../types/custom-components.type';
import { ComponentTypes } from '../../../types/form-component.type';
import { isOptionalCoverageComponent } from '../../../utils/schema.util';
import { getBooleanAnswers, getOtherTypesAnswers } from './answers.util';
import type { Answers } from '../../../types/answer.type';
import type { FormComponentSchema, QuestionCollectionSchema, WorkflowSchema } from '../../../types/form-component.type';
import type { PageResponse } from '../../../types/page.type';
import type { CustomSectionSchema } from '../../../types/section.type';

const CUSTOM_DEFAULT_ANSWER_KEY = 'default_answer_value';

export const getSwitchCoverageQuestionsKeys = (sections: CustomSectionSchema[]): string[] => {
  return sections.reduce((acc: string[], section: CustomSectionSchema) => {
    if (section.components.length) {
      const keys = section.components
        .filter(
          (component) =>
            isOptionalCoverageComponent(component) &&
            component.presentation_type === CustomComponentPresentationType.Switch
        )
        .map((el) => el.key);

      return [...acc, ...keys];
    }
    return acc;
  }, []);
};

export const getAllComponentsKeys = (sections: CustomSectionSchema[]): string[] =>
  sections.reduce(
    (acc: string[], section: CustomSectionSchema) =>
      section.components.length ? [...acc, ...section.components.map((el) => el.key)] : acc,
    []
  );

export const getCustomComponentsDefaultAnswers = (sections: CustomSectionSchema[]): Answers => {
  return sections.reduce(
    (accSection, section) =>
      section.components
        ? {
            ...accSection,
            ...section.components.reduce<Answers>((acc, curr) => {
              if (CUSTOM_DEFAULT_ANSWER_KEY in curr) {
                return curr.default_answer_value ? { ...acc, [curr.key]: curr.default_answer_value } : acc;
              }
              return acc;
            }, {}),
          }
        : accSection,
    {}
  );
};

export const isQuestionCollectionComponent = (
  component: FormComponentSchema
): component is QuestionCollectionSchema => {
  return component.type === ComponentTypes.QuestionCollection;
};

export const getQuestionCollectionTypeComponents = (sections: CustomSectionSchema[]): QuestionCollectionSchema[] =>
  sections.reduce(
    (acc: WorkflowSchema[], section: CustomSectionSchema) => [
      ...acc,
      ...section.components
        .filter((component) => isQuestionCollectionComponent(component))
        .map((component) => component as QuestionCollectionSchema),
    ],
    []
  );

export const prepareCustomAnswers = (pageResponse: PageResponse<CustomSectionSchema>): Answers => {
  if (!pageResponse.answers) {
    return {};
  }

  const pageResponseAnswers = { ...pageResponse.answers };
  const defaultAnswers = {
    ...getCustomComponentsDefaultAnswers(pageResponse.page.sections),
  };
  const dynamicDefaults = { ...pageResponse.dynamic_defaults };

  const allKeys = getAllComponentsKeys(pageResponse.page.sections);
  const switchKeys = getSwitchCoverageQuestionsKeys(pageResponse.page.sections);

  const booleanAnswers = getBooleanAnswers(switchKeys, pageResponseAnswers, defaultAnswers, dynamicDefaults);
  const otherTypesAnswers = getOtherTypesAnswers(allKeys, pageResponseAnswers, defaultAnswers, dynamicDefaults);

  const answers = {
    ...pageResponseAnswers,
    ...booleanAnswers,
    ...otherTypesAnswers,
  };

  return { ...defaultAnswers, ...answers };
};
