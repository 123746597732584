export const AUTOMATION_TEST_ID = {
  bundleQuotePage: {
    premium: 'bundle-quote-premium',
    homeAnnualPremium: 'bundle-quote-home-annual-premium',
    homeDwelling: 'bundle-quote-home-dwelling',
    homeDeductible: 'bundle-quote-home-deductible',
    autoAnnualPremium: 'bundle-quote-auto-annual-premium',
    autoDeductible: 'bundle-quote-auto-deductible',
    autoLiability: 'bundle-quote-auto-liability',
    homeSavings: 'bundle-quote-home-savings',
  },
};
