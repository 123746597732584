import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  background-color: ${theme.colors.gray10};

  ${mq[0]} {
    background-color: ${theme.colors.azure95};
  }
`;

export const iconCSS = css`
  display: none;
  width: 24px;
  height: 24px;
  color: ${theme.colors.gray60};
  margin-bottom: 16px;
  ${mq[0]} {
    display: block;
  }
`;

export const headerCSS = css`
  display: flex;
  margin-top: 4px;
  font-weight: 700;
  justify-content: space-between;
  align-items: center;
  ${mq[0]} {
    display: none;
  }
`;

export const imageCSS = css`
  width: 80px;
  height: 80px;
  transform: scaleX(-1);
  filter: grayscale(100%);
`;

export const buttonCSS = css`
  font-weight: 700;
  margin-top: 16px;
`;
