import { css } from '@emotion/react';
import { indicatorLineCSS } from '../../../styles/form.style';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const formCSS = (
  hasHiddenLine: boolean,
  clearBottomSpacing?: boolean,
  withAnimation?: boolean
): SerializedStyles => css`
  appearance: none;
  border: none;
  margin: 0;
  padding: 0 0 ${clearBottomSpacing ? 0 : theme.sizes.formHolderBottomSpaceMobile}px;
  position: relative;

  &:before {
    ${indicatorLineCSS};
    height: ${hasHiddenLine ? 0 : 100}%;
    ${withAnimation &&
    css`
      transition: height 500ms ease-in-out 300ms;
    `}
  }

  ${mq[0]} {
    margin-right: -${theme.sizes.inputHorizontalSpacing}px;
    padding-bottom: ${clearBottomSpacing ? 0 : theme.sizes.formHolderBottomSpaceDesktop}px;
  }
`;

export const scrollPointCSS = css`
  position: absolute;
  top: calc(var(--header-height) * -1 - 20px);

  ${mq[0]} {
    top: calc(var(--header-height) * -1);
  }
`;

export const formInnerCSS = (animationEnabled: boolean, animate: boolean): SerializedStyles => css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${animationEnabled && formInnerScrollCSS(animate)};
`;

export const formInnerScrollCSS = (animate: boolean): SerializedStyles => css`
  position: relative;
  top: ${animate ? 0 : -100}px;
  opacity: ${animate ? 1 : 0};
  transition:
    opacity 300ms ease-in 100ms,
    top 300ms ease-in;
`;

export const disclosureCSS = (isFirstDisclosure: boolean, isLastDisclosure: boolean): SerializedStyles => css`
  margin-top: ${isFirstDisclosure ? 8 : 16}px;
  margin-bottom: ${isLastDisclosure ? 16 : 0}px;
  ${mq[0]} {
    margin-top: ${isFirstDisclosure ? 28 : 16}px;
    margin-bottom: ${isLastDisclosure ? 12 : 0}px;
  }
`;
