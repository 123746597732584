import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const buttonCSS = (isLarge = false): SerializedStyles => css`
  position: relative;
  padding-right: 40px;

  ${mq[0]} {
    padding-right: ${isLarge ? 60 : 40}px;
  }
`;

export const selectIndicatorCSS = (isOpen: boolean, isLarge = false): SerializedStyles => css`
  position: absolute;
  top: calc(50% - 8px);
  right: 12px;
  width: 16px;
  height: 16px;

  ${isOpen &&
  css`
    transform: rotate(180deg);
  `}
  ${mq[0]} {
    width: ${isLarge ? 24 : 16}px;
    height: ${isLarge ? 24 : 16}px;
    right: ${isLarge ? 24 : 12}px;
    top: calc(50% - ${isLarge ? 12 : 8}px);
  }
`;

export const menuCSS = (width: number | string = 'auto'): SerializedStyles => css`
  .MuiPaper-root {
    margin-top: 12px;
  }

  .MuiList-root {
    width: ${width};
    min-width: 200px;

    li {
      padding: 8px 16px;
      font-size: 16px;
      line-height: 20px;

      &:hover {
        background-color: ${theme.colors.gray10};
      }
    }
  }
`;
