import { dashedRowCSS } from './DashedRow.style';
import type { DashedRowProps } from './DashedRow.type';
import type { FC } from 'react';

const DashedRow: FC<DashedRowProps> = ({ label, content, customCSS, testId }) => {
  return (
    <div data-testid={testId} css={[dashedRowCSS, customCSS]}>
      <span>{label}</span> <span>{content}</span>
    </div>
  );
};

export default DashedRow;
