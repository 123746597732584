import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import useDigitalProfileUtmParams from '../../api/hooks/use-digital-profile-utm-params';
import { usePage } from '../../api/page';
import publicSessionService from '../../api/public/session/public-session.service';
import { sessionService } from '../../api/session';
import { SEGMENT } from '../../constants/analytics';
import { CONTACT_NUMBER } from '../../constants/contacts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import ROUTES from '../../constants/routes';
import SIRV_IMAGES from '../../constants/sirv-images';
import { useSegment } from '../../hooks/use-segment';
import Footer from '../../shared/components/Footer/Footer';
import Header from '../../shared/components/Header/Header';
import isGid from '../../shared/utils/is-gid.util';
import { PolicyType } from '../../types/public-flow-config.type';
import { SessionStatus } from '../../types/session.type';
import analytics from '../../utils/analytics.util';
import { captureException } from '../../utils/error.util';
import { getPathByPageType } from '../../utils/route-path.util';
import PageLoader from '../PageLoader/PageLoader';
import { loaderCSS } from './SessionInit.style';
import { InitSessionSearchParams } from './SessionInit.type';
import type { SessionInitState } from './SessionInit.type';
import type { SessionResponse } from '../../types/session.type';
import type { FC } from 'react';

const SessionInit: FC = () => {
  const [searchParams] = useSearchParams();
  const personGid = searchParams.get(InitSessionSearchParams.PersonGid) ?? '';
  const assetGid = searchParams.get(InitSessionSearchParams.AssetGid) ?? '';
  const quoteGid = searchParams.get(InitSessionSearchParams.QuoteGid) ?? '';
  const engagementGid = searchParams.get(InitSessionSearchParams.EngagementGid) ?? '';
  const testMode = searchParams.get(InitSessionSearchParams.testMode) ?? '';
  const stubMode = searchParams.get(InitSessionSearchParams.stubMode) ?? '';
  const dpSource = searchParams.get(InitSessionSearchParams.dpSource) ?? '';
  const dpMedium = searchParams.get(InitSessionSearchParams.dpMedium) ?? '';
  const dpCampaign = searchParams.get(InitSessionSearchParams.dpCampaign) ?? '';

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const [sessionState, setSessionState] = useState<SessionInitState>({
    isLoading: true,
  });
  const isSessionPending = sessionState.sessionStatus === SessionStatus.pending;
  const currentPageKey = isSessionPending ? sessionState.currentPageKey : undefined;

  const { track } = useSegment();

  const { data: pageData, isPending: isPagePending } = usePage(sessionState.sessionGid, currentPageKey);
  const queryParams = useDigitalProfileUtmParams();

  useEffect(() => {
    const call = async (): Promise<void> => {
      let sessionData: SessionResponse | undefined;
      let sessionPrimaryInsuredData;
      let publicSessionData;
      let publicFlowConfig;
      try {
        sessionData = await sessionService.initSession({
          personGid,
          assetGid,
          quoteGid: isGid(quoteGid) ? quoteGid : undefined,
          engagementGid: isGid(engagementGid) ? engagementGid : undefined,
          testMode: testMode === 'true',
          stubMode: stubMode === 'true',
          dpSource,
          dpMedium,
          dpCampaign,
        });

        publicSessionData = await publicSessionService.getPublicSession(
          sessionData.session.gid,
          queryParams as Record<string, string>
        );
        publicFlowConfig = await publicSessionService.getPublicFlowConfig(sessionData.session.gid);
        sessionPrimaryInsuredData = await publicSessionService.getPublicPrimaryInsured(sessionData.session.gid);
        analytics.identify(sessionPrimaryInsuredData.primary_insured.gid, {
          email: analytics.generateTrackEmail(sessionPrimaryInsuredData.primary_insured.gid),
        });
        Sentry.setUser({ email: analytics.generateTrackEmail(sessionPrimaryInsuredData.primary_insured.gid) });
        track(SEGMENT.events.flowStarted, {
          session_gid: sessionData.session.gid,
          carrier_key: publicFlowConfig.flow_config.carrier_key,
          strategy: publicFlowConfig.flow_config.strategy,
          policy_type: publicFlowConfig.flow_config.policy_type,
          flow_type: publicFlowConfig.flow_config.policy_type === PolicyType.Auto ? PolicyType.Auto : PolicyType.Home,
        });
      } catch (error) {
        captureException(error, 'session_init');
        navigate(ROUTES.problem);
      }
      if (sessionData && publicFlowConfig) {
        queryClient.setQueryData([QUERY_CACHE_KEYS.session, sessionData.session.gid], sessionData);
        setSessionState({
          isLoading: false,
          sessionGid: sessionData.session.gid,
          sessionStatus: publicSessionData?.session.status,
          policyType: publicFlowConfig.flow_config.policy_type,
          currentPageKey: sessionData.session.current_page_key,
        });
      }
    };

    if (
      !isGid(personGid) ||
      !isGid(assetGid) ||
      (quoteGid && !isGid(quoteGid)) ||
      (engagementGid && !isGid(engagementGid))
    ) {
      let errorMessage = `GID is not correct.`;
      if (!isGid(personGid)) {
        errorMessage += ` personGid: ${personGid}`;
      }
      if (!isGid(assetGid)) {
        errorMessage += ` assetGid: ${assetGid}`;
      }
      if (quoteGid && !isGid(quoteGid)) {
        errorMessage += ` quoteGid: ${quoteGid}`;
      }
      if (engagementGid && !isGid(engagementGid)) {
        errorMessage += ` engagementGid: ${engagementGid}`;
      }
      navigate(ROUTES.problem, { state: { errorToReport: new Error(errorMessage) } });
    } else {
      call();
    }
  }, [
    assetGid,
    dpCampaign,
    dpSource,
    dpMedium,
    engagementGid,
    personGid,
    quoteGid,
    stubMode,
    testMode,
    queryClient,
    track,
    navigate,
    queryParams,
  ]);

  if (sessionState.sessionGid && sessionState.policyType && !isSessionPending) {
    return (
      <Navigate
        to={ROUTES.sessionLocked.replace(':flow', sessionState.policyType).replace(':gid', sessionState.sessionGid)}
      />
    );
  }

  if (sessionState.isLoading || isPagePending) {
    return (
      <div>
        <Header phoneNumber={CONTACT_NUMBER} showContacts fullscreen />
        <PageLoader
          customCSS={loaderCSS}
          header="Sit tight, as we get things ready"
          subheader={
            <span>
              Matic is preparing your quote application. This process could take <strong>up to 5 seconds.</strong>
            </span>
          }
          imgUrl={SIRV_IMAGES.documentLoader}
        />
        <Footer phone={CONTACT_NUMBER} denseContainer />
      </div>
    );
  }

  return (
    <Navigate
      to={getPathByPageType(pageData?.page.page_type, sessionState.sessionGid, sessionState.policyType)}
      replace
    />
  );
};

export default SessionInit;
