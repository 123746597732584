import { css } from '@emotion/react';
import { maxCalendlyLikeWidthContainerCSS } from '../../../../../styles/common.style';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const cardCSS = css`
  ${maxCalendlyLikeWidthContainerCSS};
  margin: 0 0 24px 0;
  box-sizing: border-box;

  ${mq[0]} {
    margin: 0 0 24px 0;
  }
`;

export const showQuoteCardCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 0 rgba(0, 0, 0, 0.12);
  border-color: ${theme.colors.gray30};
  padding-top: 24px;
  padding-bottom: 24px;
  width: 100%;
  max-width: 804px;
  margin-top: 32px;

  ${mq[0]} {
    padding-top: 35px;
    padding-bottom: 35px;
  }
`;
