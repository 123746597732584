import { useState, useCallback } from 'react';
import ChevronIcon from '../../../../assets/chevron.svg';
import { activeElementResetCSS } from '../../../shared.style';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';
import {
  chevronCSS,
  loadingLineCSS,
  navigationCSS,
  navigationIndicatorCSS,
  navigationNameCSS,
} from './NavigationItem.style';
import type { NavigationItemProps } from './NavigationItem.type';
import type { FC } from 'react';

const NavigationItem: FC<NavigationItemProps> = ({
  name,
  key_name: keyName,
  isCompleted = false,
  isCurrent = false,
  isUnavailable = false,
  isLoading = false,
  asyncDetailsComponent: AsyncDetailsComponent,
  details,
  onItemClick,
}) => {
  const [expanded, setExpanded] = useState(true);
  const [detailsHasData, setDetailsHasData] = useState(false);
  const renderExpandButton = (AsyncDetailsComponent && detailsHasData) ?? details;

  const onExpand = (): void => {
    setExpanded((expanded) => !expanded);
  };

  const onDetailsReady = useCallback(() => {
    setDetailsHasData(true);
  }, [setDetailsHasData]);

  return (
    <div css={navigationCSS(isCompleted)} data-testid="navigation-block">
      <div css={navigationIndicatorCSS(isCompleted, isCurrent)} data-testid="navigation-indicator" />
      {isLoading ? (
        <SkeletonLoader customCSS={loadingLineCSS} absoluteHeight={20} minWidth={70} maxWidth={90} />
      ) : (
        <button
          css={navigationNameCSS(!isUnavailable)}
          aria-current={isCurrent ? 'step' : undefined}
          disabled={isUnavailable || isLoading}
          data-testid="section-button"
          onClick={onItemClick}
        >
          {name}
        </button>
      )}

      {renderExpandButton && (
        <button onClick={onExpand} data-testid="expand-button" css={activeElementResetCSS} aria-expanded={expanded}>
          <img src={ChevronIcon} alt={`More on ${name}`} css={chevronCSS(expanded)} data-testid="navigation-chevron" />
        </button>
      )}
      {AsyncDetailsComponent && expanded && <AsyncDetailsComponent keyName={keyName} onDataReady={onDetailsReady} />}
      {expanded && details}
    </div>
  );
};

export default NavigationItem;
