import type { Person } from '../../../../../types/person.type';

export const STEP_TITLE = 'Payment information';

export const hasFullPersonData = (primaryInsured: Person): boolean => {
  return Boolean(primaryInsured?.email && primaryInsured.phone && primaryInsured.mailing_address?.gid);
};

export const getStripeKey = (stripeIntegrations?: Record<string, string>, carrierKey?: string): string => {
  if (!carrierKey) {
    return '';
  }
  return stripeIntegrations?.[`${carrierKey}_token`] ?? '';
};
