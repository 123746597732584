import { isValid, parseISO, differenceInDays, differenceInYears, set, isBefore, startOfDay } from 'date-fns';
import { toCamelCase } from '../../../shared/utils/to-camel-case.util';
import { ValidationType } from '../../../types/validation.type';
import type { AddressAnswer, AnswerValue } from '../../../types/answer.type';
import type {
  CustomValidations,
  HardcodedValidations,
  ValidateFn,
  Validation,
  ValidationRules,
  ValidationValue,
} from '../../../types/validation.type';
import type { Validate } from 'react-hook-form';

export const REQUIRED_VALIDATION = {
  key: ValidationType.Required,
  value: true,
  message: 'This field is required',
};

export const PERSON_AGE_VALIDATION = [
  {
    key: ValidationType.MaxDaysFromNow,
    value: 0,
    message: 'Please check your date of birth',
  },
  {
    key: ValidationType.MaxYears,
    value: 150,
    message: 'Please check your date of birth',
  },
];

const DEFAULT_VALIDATION_MSG = 'Validation failed';

const STATIC_RULES: ValidationType[] = [
  ValidationType.Required,
  ValidationType.Min,
  ValidationType.Max,
  ValidationType.MinLength,
  ValidationType.MaxLength,
  ValidationType.Pattern,
];

interface ValidationDates {
  now: number;
  valueToCompare: number;
  date: Date;
}

const isValidDate: ValidateFn = (value) =>
  !value || typeof value !== 'string' || isValid(parseISO(value || '')) || 'Please enter a valid date';

const isValidAddress: ValidateFn = (value) => {
  if (value && !!(value as AddressAnswer).line1) {
    return true;
  }
  return 'Please search and select the address from the list.';
};

const isNotEmptyString: ValidateFn = (value) =>
  typeof value === 'string' ? (value ? !!value.trim() || 'This value cannot consist of empty spaces' : true) : true;

const getValidationDates = (answerValue: AnswerValue, validationValue?: ValidationValue): ValidationDates => {
  return {
    now: startOfDay(Date.now()).getTime(),
    valueToCompare: typeof validationValue === 'number' ? validationValue : parseInt(validationValue as string),
    date: parseISO((answerValue as string) || ''),
  };
};

const minDaysFromNow: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: minDays, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInDays(date, now) >= minDays) || message;
};

const maxDaysFromNow: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: maxDays, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInDays(date, now) <= maxDays) || message;
};

const minDaysBeforeNow: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: minDays, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInDays(now, date) >= minDays) || message;
};

const maxDaysBeforeNow: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: maxDays, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInDays(now, date) <= maxDays) || message;
};

const minYears: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: minYr, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInYears(now, date) >= minYr) || message;
};

const maxYears: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: maxYr, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInYears(now, date) <= maxYr) || message;
};

const isValidMonthValue = (value: number): boolean => value >= 1 && value <= 12;

const cardExpirationDate: ValidateFn = (answerValue, _, message = DEFAULT_VALIDATION_MSG) => {
  if (typeof answerValue !== 'string' || answerValue.length < 6) {
    return message;
  }
  const now = Date.now();
  const cardMonth = +answerValue.slice(0, 2);
  const cardYear = +answerValue.slice(2, 6);
  const cardDate = set(now, {
    month: cardMonth,
    year: cardYear,
  });

  return (isValidMonthValue(cardMonth) && isBefore(now, cardDate)) || message;
};

const onlyNaturalNumbers: ValidateFn = (answerValue, _, message = DEFAULT_VALIDATION_MSG) => {
  if (typeof answerValue !== 'string') {
    return message;
  }
  const pattern = /^\d+$/;
  return pattern.test(answerValue) || message;
};

export const hardcodedValidations: Record<HardcodedValidations, ValidateFn> = {
  isValidDate,
  isValidAddress,
  isNotEmptyString,
};

export const customValidations: Record<CustomValidations, ValidateFn> = {
  minDaysFromNow,
  maxDaysFromNow,
  minDaysBeforeNow,
  maxDaysBeforeNow,
  minYears,
  maxYears,
  cardExpirationDate,
  onlyNaturalNumbers,
};

export const getValidationRules = (
  validations?: Validation[],
  existingValidateFns: Record<string, Validate<AnswerValue, any>> = {}
): ValidationRules => {
  if (!validations?.length) {
    return { validate: { ...existingValidateFns } };
  }
  return validations.reduce(
    (acc, { key, value, message }) => {
      switch (true) {
        case key === ValidationType.Required:
          return {
            ...acc,
            [key]: message,
          };
        case key === ValidationType.Pattern:
          return {
            ...acc,
            [key]: {
              value: new RegExp(value as string),
              message,
            },
          };
        case STATIC_RULES.includes(key):
          return {
            ...acc,
            [toCamelCase(key)]: { value, message },
          };
        default: {
          const validationFnKey = toCamelCase(key);
          const validationFn = customValidations[validationFnKey as CustomValidations];
          if (!validationFn) {
            return acc;
          }
          return {
            ...acc,
            validate: {
              ...acc.validate,
              [validationFnKey]: (answerValue: AnswerValue) => validationFn(answerValue, value, message),
            },
          };
        }
      }
    },
    {
      validate: { ...existingValidateFns },
    }
  );
};

/**
 * Clear all validations except required to allow "*" in value
 * */
export const getHiddenQuestionValidationRules = (validations?: Validation[]): ValidationRules => {
  const requiredRule = validations?.find((rule) => rule.key === ValidationType.Required);
  return requiredRule ? { [requiredRule.key]: requiredRule.message } : {};
};
