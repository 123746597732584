import PencilIcon from '../../../../assets/pencil.svg';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../constants/full-story';
import Wrapper from '../../Wrapper/Wrapper';
import { containerCSS, editCSS, headingCSS, iconCSS, subheadingCSS } from './NavigationItemDetails.style';
import type { NavigationItemDetailsProps } from './NavigationItemDetails.type';
import type { FC } from 'react';

const NavigationItemDetails: FC<NavigationItemDetailsProps> = ({
  customCSS,
  iconSrc,
  testId = '',
  hasSensitiveData = false,
  header = '',
  details,
  onDetailClick,
}) => {
  const detailsToRender = Array.isArray(details) ? details : details ? [details] : [];
  const getDetails = (): string[] => {
    if (!details) {
      return [];
    }
    return Array.isArray(details) ? details : [details];
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <Wrapper
        tag={onDetailClick ? 'button' : 'div'}
        data-testid={testId}
        css={headingCSS(!!detailsToRender.length)}
        {...(onDetailClick ? { onClick: onDetailClick } : {})}
      >
        <img src={iconSrc} alt="" css={iconCSS} />
        <span className={hasSensitiveData ? FS_SENSITIVE_DATA_CLASS.mask : ''}>{header}</span>
        {onDetailClick && <img src={PencilIcon} alt="Edit" css={editCSS} />}
      </Wrapper>
      {getDetails().map((detail) => (
        <div key={detail} css={subheadingCSS}>
          {detail}
        </div>
      ))}
    </div>
  );
};

export default NavigationItemDetails;
