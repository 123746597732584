import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useCreateAllFunnelFlow = (gid: string, enabled: boolean = true): QueryObserverResult<{ url: string }> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.allFunnelUrl, gid],
    queryFn: async () =>
      await api.post<HTTPResponse<{ url: string }>>(getApiUrl(API_PATH.allFunnelFlow, gid)).then((res) => res.data),
    enabled,
  });

export default useCreateAllFunnelFlow;
