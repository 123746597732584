import { useQueryClient } from '@tanstack/react-query';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutateAnswers, usePage } from '../../../../../api/page';
import { useMutateSession, useSession } from '../../../../../api/session';
import HomeIcon from '../../../../../assets/home-finalize.svg?react';
import { SEGMENT } from '../../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import useResponsive from '../../../../../hooks/use-responsive';
import { useSegment } from '../../../../../hooks/use-segment';
import { prepareWorkflowDefaultAnswers } from '../../../../../questionsForm/utils/answers/workflow-schema-answers.util';
import { innerLayoutContainerCSS } from '../../../../../styles/layout.style';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../../../../utils/current-form-keys.util';
import { getPathByPageType } from '../../../../../utils/route-path.util';
import { resetScroll } from '../../../../../utils/scroll.util';
import PageFormWrapper from '../../../../questions/components/PageFormWrapper/PageFormWrapper';
import KnockoutQuestions from '../KnockoutQuestions/KnockoutQuestions';
import { containerCSS, headingWrapperCSS, subheaderCSS, headerCSS, iconCSS } from './KnockoutQuestionsForm.style';
import { prepareKnockoutAnswersForSubmit } from './KnockoutQuestionsForm.util';
import type { KnockoutQuestionsFormProps } from './KnockoutQuestionsForm.type';
import type { Answers } from '../../../../../types/answer.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { WorkflowSectionSchema } from '../../../../../types/section.type';
import type { SessionResponse } from '../../../../../types/session.type';
import type { FC } from 'react';

const KnockoutQuestionsForm: FC<KnockoutQuestionsFormProps> = ({ customCSS }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);

  const { isMobile } = useResponsive();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currentPageKey = sessionData?.session.current_page_key;
  const currentSectionKey = sessionData?.session.current_section_key;
  const { data: pageData } = usePage(gid, currentPageKey);

  const { mutateAsync: mutateSession, isPending: isMutateSessionPending } = useMutateSession(gid);
  const { mutateAsync: mutateAnswers, isPending: isMutateAnswersPending } = useMutateAnswers(gid);

  const { track } = useSegment();

  const [step, setStep] = useState(0);

  const [defaultAnswers, setDefaultAnswers] = useState<Answers>({});

  useEffect(() => {
    if (pageData?.answers) {
      const answers = prepareWorkflowDefaultAnswers(pageData);
      setDefaultAnswers(answers);
    }
  }, [pageData]);

  const onSubmit = async (answers: Answers): Promise<void> => {
    track(SEGMENT.events.answersSubmitted, {
      section_key: currentSectionKey,
    });

    const knockoutAnswers = prepareKnockoutAnswersForSubmit(answers, schema.containers);

    await mutateAnswers(knockoutAnswers);

    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_section_key: sessionData?.session.current_section_key,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);
    const nextCurrentPage = getCurrentNavigationPage(newSessionData);

    navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
  };

  const handleButtonClick = (delta: number): void => {
    const newStep = step + delta;
    setStep(newStep);
    resetScroll();
  };

  const isPending = isMutateSessionPending || isMutateAnswersPending;

  const schema = pageData?.page.sections[0] as WorkflowSectionSchema;
  if (!schema) {
    return null;
  }

  return (
    <div css={[innerLayoutContainerCSS(false), containerCSS, customCSS]}>
      <div css={headingWrapperCSS}>
        <HomeIcon css={iconCSS} />
        <div>
          <div css={headerCSS}>Before we finalize...</div>
          <div css={subheaderCSS}>
            {isMobile ? (
              <Fragment>
                Do any of <strong>these scenarios</strong> apply to you?
              </Fragment>
            ) : (
              <Fragment>
                <strong>Click the scenarios</strong> that apply to you:
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <PageFormWrapper defaultValues={defaultAnswers}>
        <KnockoutQuestions
          sectionKey={schema.key}
          container={schema.containers[step]}
          step={step}
          isLoading={isPending}
          isDisabled={isPending}
          onSubmit={onSubmit}
          onButtonClick={handleButtonClick}
        />
      </PageFormWrapper>
    </div>
  );
};

export default KnockoutQuestionsForm;
