import SIRV_IMAGES from '../../../../constants/sirv-images';

export const CONGRATULATIONS_ARTICLES = [
  {
    title: '8 Types of Homeowners Insurance Policies to Know',
    imageSrc: SIRV_IMAGES.blogPost4,
    href: 'https://matic.com/blog/8-types-of-homeowners-insurance-policies-to-know/',
    imageAlt: 'blog-image-4',
    tags: ['home'],
  },
  {
    title: 'Should You Use an Escrow Account To Pay Your Home Insurance?',
    href: 'https://matic.com/blog/escrow-account-home-insurance/',
    imageSrc: SIRV_IMAGES.blogPost5,
    imageAlt: 'blog-image-5',
    tags: ['home'],
  },
  {
    title: 'How To Lower Your Monthly Escrow',
    href: 'https://matic.com/blog/how-to-lower-your-monthly-escrow/',
    imageSrc: SIRV_IMAGES.blogPost6,
    imageAlt: 'blog-image-6',
    tags: ['home'],
  },
];
