import { useRef } from 'react';
import useScrollPoint from '../../../hooks/use-scroll-point';
import { containerCSS } from './HeaderScrollContainer.style';
import type { HeaderScrollContainerProps } from './HeaderScrollContainer.type';
import type { FC } from 'react';

const HeaderScrollContainer: FC<HeaderScrollContainerProps> = ({ customCSS, children }) => {
  const isPointScrolled = useScrollPoint(140);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerHeight = containerRef.current?.clientHeight ?? 60;

  return (
    <div ref={containerRef} css={[containerCSS(isPointScrolled ? containerHeight : 0), customCSS]}>
      {children}
    </div>
  );
};

export default HeaderScrollContainer;
