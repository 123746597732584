import { css } from '@emotion/react';
import theme from '../../../../styles/theme';
import { activeElementResetCSS } from '../../../shared.style';
import type { SerializedStyles } from '@emotion/react';

export const buttonCSS = css`
  ${activeElementResetCSS};
  vertical-align: middle;
  height: 32px;
  transition: opacity 0.3s ease;
  img {
    width: 32px;
    height: 32px;
  }
  &:hover {
    opacity: 0.5;
  }
`;

export const inputCSS = (hasError?: boolean): SerializedStyles => css`
  text-align: center;
  border: 1px solid ${theme.colors.black};
  border-radius: ${theme.sizes.borderRadius}px;
  outline: unset;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${theme.colors.white};
  color: ${hasError ? theme.colors.errorRed : theme.colors.black};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  font-variant: lining-nums;
  appearance: none;
`;

export const inputWrapperCSS = css`
  margin-left: 24px;
  margin-right: 24px;
  width: 60px;
  height: 40px;
`;
