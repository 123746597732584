import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import type { ButtonVariant } from './Button.type';
import type { SerializedStyles } from '@emotion/react';

const buttonColors = (bgc: string, bc: string, c: string): SerializedStyles => css`
  background-color: ${bgc};
  border-color: ${bc};
  color: ${c};
`;

const largeButtonStyles = css`
  ${mq[0]} {
    font-size: ${theme.typography.fontSizeLarge};
    line-height: ${theme.typography.lineHeightLarge};
    padding: 12px 24px;
    border-width: 4px;
  }
`;

const textButtonStyles = css`
  padding: 0;
  border: none;
  border-radius: 0;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
`;

const getButtonColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', theme.colors.azure50);
    case 'secondary':
      return buttonColors(theme.colors.white, theme.colors.azure50, theme.colors.azure50);
    case 'inverted':
      return buttonColors(theme.colors.white, theme.colors.white, theme.colors.azure50);
    default:
      return buttonColors(theme.colors.azure50, theme.colors.azure50, theme.colors.white);
  }
};

const getDisabledColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', theme.colors.gray60);
    case 'secondary':
      return buttonColors(theme.colors.white, theme.colors.gray30, theme.colors.gray60);
    default:
      return buttonColors(theme.colors.gray10, theme.colors.gray10, theme.colors.gray60);
  }
};

const getActiveFocusColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
      return buttonColors('transparent', 'transparent', theme.colors.azure50);
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', theme.colors.azure68);
    default:
      return buttonColors(theme.colors.azure50, theme.colors.azure50, theme.colors.white);
  }
};

const getHoverColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
      return buttonColors('transparent', 'transparent', theme.colors.azure68);
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', theme.colors.azure95);
    default:
      return buttonColors(theme.colors.azure68, theme.colors.azure68, theme.colors.white);
  }
};

const getButtonVariant = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return textButtonStyles;
    default:
      return css``;
  }
};

export const buttonCSS = (
  isLarge: boolean,
  variant: ButtonVariant = 'default',
  fullWidth = false,
  hasIcon = false
): SerializedStyles => css`
  ${getButtonColors(variant)};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  line-height: ${theme.typography.lineHeight};
  font-weight: 700;
  border-radius: ${theme.sizes.borderRadius}px;
  border-style: solid;
  border-width: 2px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  max-width: 100%;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  padding: 10px 12px;
  position: relative;
  ${hasIcon &&
  css`
    display: inline-flex;
    align-items: center;
  `}
  ${fullWidth &&
  css`
    width: 100%;
  `}
  ${getButtonVariant(variant)};

  &:hover {
    ${getHoverColors(variant)}
  }

  &:active,
  &:focus {
    ${getActiveFocusColors(variant)}
  }

  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    ${getDisabledColors(variant)}
  }
  ${isLarge && largeButtonStyles}
`;

export const loaderWrapperCSS = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const iconCSS = (isLarge = false): SerializedStyles => css`
  display: flex;
  margin-right: 12px;

  svg {
    width: 16px;
    height: 16px;
  }

  ${mq[0]} {
    svg {
      width: ${isLarge ? 24 : 16}px;
      height: ${isLarge ? 24 : 16}px;
    }
  }
`;
