import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useContactFlows } from '../../../../../api/contact-flows';
import SkeletonLoader from '../../../../../shared/components/SkeletonLoader/SkeletonLoader';
import { ContactFlowRequester } from '../../../../../types/contact-flows';
import QuotePageCalendlyBlock from '../QuotePageCalendlyBlock/QuotePageCalendlyBlock';
import QuotePageScheduledCallBlock from '../QuotePageScheduledCallBlock/QuotePageScheduledCallBlock';
import ScheduledTimeBlock from '../ScheduledTimeBlock/ScheduledTimeBlock';
import { loadingContainerCSS } from './QuotePageScheduleCall.style';
import { ViewState } from './QuotePageScheduleCall.type';
import type { QuotePageScheduleCallProps } from './QuotePageScheduleCall.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC, ReactElement } from 'react';

const QuotePageScheduleCall: FC<QuotePageScheduleCallProps> = ({
  heading,
  hideHeading,
  callScheduledInfoBlock,
  onCallScheduled,
}) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: contactFlowData, isPending: isContactFlowPending } = useContactFlows(gid);

  const lastContactFlow = contactFlowData?.contact_flows[0];

  const [state, setState] = useState<ViewState>(ViewState.Pending);

  useEffect(() => {
    if (!isContactFlowPending && contactFlowData) {
      const newState = lastContactFlow ? ViewState.Preview : ViewState.Schedule;
      setState(newState);
    }
  }, [isContactFlowPending, lastContactFlow, contactFlowData]);

  const getCallScheduledDetailsBlock = (): ReactElement | null => {
    if (lastContactFlow) {
      return (
        <ScheduledTimeBlock
          timeZoneName={lastContactFlow.engagement_timezone}
          scheduledTime={lastContactFlow.run_at}
          onActionButtonClick={() => setState(ViewState.Preview)}
        />
      );
    }

    return null;
  };

  if (state === ViewState.Pending) {
    return (
      <div css={loadingContainerCSS}>
        <SkeletonLoader absoluteHeight={32} minWidth={60} maxWidth={80} centered />
        <SkeletonLoader absoluteHeight={48} minWidth={70} maxWidth={90} centered />
        <SkeletonLoader absoluteHeight={360} width={100} />
      </div>
    );
  }

  return state === ViewState.Preview && lastContactFlow ? (
    <QuotePageScheduledCallBlock
      hideHeading={hideHeading}
      timeZoneName={lastContactFlow.engagement_timezone}
      scheduledTime={lastContactFlow.run_at}
      callScheduledInfoBlock={callScheduledInfoBlock}
      allowReschedule={lastContactFlow.requester === ContactFlowRequester.Customer}
      onScheduleAnotherTimeClick={() => setState(ViewState.Schedule)}
    />
  ) : (
    <QuotePageCalendlyBlock
      heading={heading}
      hideHeading={hideHeading}
      callScheduledInfoBlock={callScheduledInfoBlock}
      callScheduledDetailsBlock={getCallScheduledDetailsBlock()}
      onCallScheduled={onCallScheduled}
    />
  );
};

export default QuotePageScheduleCall;
