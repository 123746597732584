import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';

export const containerCSS = css`
  font-weight: 700;
`;

export const labelCSS = css`
  vertical-align: middle;
`;

export const iconCSS = css`
  vertical-align: middle;
  margin-right: 4px;
  width: 16px;
  height: 16px;
`;

export const modalCSS = css`
  max-width: 400px;
  ${mq[0]} {
    max-width: 400px;
  }
`;
