import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { LoanResponse, LoanPayload } from '../../types/loan.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useMutateLoan = (
  gid: string
): UseBaseMutationResult<LoanResponse, HTTPResponse, LoanPayload & { gid: string }> => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const { gid: loanGid, ...rest } = data;

      return await api
        .patch<HTTPResponse<LoanResponse>>(getApiUrl(API_PATH.loan, gid, loanGid), {
          body: { data: rest },
        })
        .then((res) => res.data);
    },
    onSuccess: async (data) => client.setQueryData([QUERY_CACHE_KEYS.loan, gid], data),
  });
};

export default useMutateLoan;
