import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../../constants/api';
import QUERY_CACHE_KEYS from '../../../constants/query-cache-keys';
import { getApiUrl } from '../../../utils/api.util';
import api from '../../api';
import type { HTTPResponse } from '../../../types/api.type';
import type { PublicErrorsResponse } from '../../../types/public-session.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const usePublicErrors = (gid?: string): QueryObserverResult<PublicErrorsResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.publicErrors, gid],
    queryFn: gid
      ? async () =>
          await api
            .get<HTTPResponse<PublicErrorsResponse>>(getApiUrl(API_PATH.public.errors, gid))
            .then((res) => res.data)
      : undefined,
    enabled: Boolean(gid),
  });

export default usePublicErrors;
