import ReactSelect from 'react-select';
import noop from '../../utils/noop';
import { selectCSS } from './Select.style';
import { DEFAULT_SELECT_OPTIONS, filterOptions } from './Select.util';
import { MenuList, Option, DropdownIndicator, Control } from './SelectParts.utils';
import type { MultiSelectProps, MultiSelectValue } from './Select.type';
import type { FC } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';

const MultiSelect: FC<MultiSelectProps> = ({
  customCSS,
  inputId,
  ariaLabel,
  options,
  onValidEntry = noop,
  placeholder,
  components = {},
  menuShouldBlockScroll = false,
  hasError,
  onBlur,
  value,
  styles,
  dense,
  isPrefilled,
  isSearchable = false,
  isDisabled = false,
  otherOptionKey,
  onValidatePreviousInputs = noop,
}) => {
  if (!options) {
    throw new Error('MultiSelect Component: options must be provided.');
  }

  const selectValue = value.reduce<MultiSelectValue[]>((acc, v) => {
    const valueObj = options.find((o) => o.value === v);
    return valueObj ? [...acc, { label: valueObj.label, value: String(valueObj.value) }] : [...acc];
  }, []);

  const onChange = (data: unknown): void => {
    const multiSelectValue = data as OnChangeValue<MultiSelectValue, true>;
    onValidEntry(multiSelectValue?.map((o) => o.value) || []);
  };

  return (
    <ReactSelect
      css={[selectCSS(hasError, isDisabled, dense, isPrefilled), customCSS]}
      {...DEFAULT_SELECT_OPTIONS}
      inputId={inputId}
      aria-label={ariaLabel}
      options={options}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      value={selectValue}
      onChange={(e) => {
        onChange(e);
        onValidatePreviousInputs();
      }}
      onBlur={onBlur}
      menuShouldBlockScroll={menuShouldBlockScroll}
      styles={styles}
      isMulti
      closeMenuOnSelect={false}
      filterOption={(candidate, input) => filterOptions(candidate, input, otherOptionKey)}
      components={{
        MenuList,
        Option,
        DropdownIndicator,
        Control,
        ...components,
      }}
    />
  );
};

export default MultiSelect;
