const FLOW = `/:flow`;
const FLOW_AND_GID = `${FLOW}/:gid`;

const ROUTES = {
  flow: FLOW,
  init: '/init',
  flowAndGid: FLOW_AND_GID,
  questions: `${FLOW_AND_GID}/questions`,
  autoInformation: `${FLOW_AND_GID}/auto-information`,
  quote: `${FLOW_AND_GID}/quote`,
  checkout: `${FLOW_AND_GID}/checkout`,
  congratulations: `${FLOW_AND_GID}/congratulations`,
  discounts: `${FLOW_AND_GID}/discounts`,
  policySold: `${FLOW_AND_GID}/policy-sold`,
  sessionLocked: `${FLOW_AND_GID}/session-locked`,
  agent: `${FLOW_AND_GID}/agent`,
  errorDisqualified: `${FLOW_AND_GID}/error-disqualified`,
  problem: `/problem`,
  sessionScheduleCall: `${FLOW_AND_GID}/schedule-call`,
  scheduleCall: `/schedule-call`,

  invalid: '/*',
};

export default ROUTES;
