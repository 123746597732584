import { Global } from '@emotion/react';
import { Fragment, useCallback, useRef } from 'react';
import useResponsive from '../../../hooks/use-responsive';
import Container from '../Container/Container';
import Divider from '../Divider/Divider';
import Logo from '../Logo/Logo';
import TestModeIndicator from '../TestModeIndicator/TestModeIndicator';
import Wrapper from '../Wrapper/Wrapper';
import {
  headerCSS,
  headerInnerCSS,
  logoContainerCSS,
  dividerCSS,
  carrierLogoCSS,
  testModeUpdatedGlobalStylesCSS,
} from './Header.style';
import HeaderContacts from './HeaderContacts/HeaderContacts';
import HeaderMobileNavigationWrapper from './HeaderMobileNavigationWrapper/HeaderMobileNavigationWrapper';
import HeaderScrollContainer from './HeaderScrollContainer/HeaderScrollContainer';
import type { HeaderProps } from './Header.type';
import type { FC } from 'react';

const Header: FC<HeaderProps> = ({
  wrapperTag = 'header',
  carrierLogo,
  carrierName,
  showContacts,
  phoneNumber,
  reverseLogo,
  fullscreen,
  children,
  navigation,
  subHeader,
  customCSS,
  isTestMode = false,
  denseContainer = false,
  onPhoneClick,
}) => {
  const { isMobile, isDesktop } = useResponsive();
  const wrapperRef = useRef<HTMLElement>(null);

  const getCarrierLogoHeight = (): number => {
    return isMobile ? 30 : 40;
  };

  const getHeaderElement = useCallback((): HTMLElement | null => {
    return wrapperRef.current;
  }, []);

  return (
    <Wrapper
      ref={wrapperRef}
      tag={wrapperTag}
      css={[headerCSS(isTestMode, !!navigation), customCSS]}
      data-testid="header"
    >
      {(isTestMode || navigation) && <Global styles={testModeUpdatedGlobalStylesCSS(isTestMode, !!navigation)} />}

      {isTestMode && <TestModeIndicator />}
      <Container customCSS={headerInnerCSS} dense={denseContainer} fullscreen={fullscreen}>
        <div css={logoContainerCSS(!!reverseLogo)}>
          {carrierLogo && (
            <Fragment>
              <Logo
                customCSS={carrierLogoCSS}
                carrierLogo={carrierLogo}
                carrierName={carrierName}
                height={getCarrierLogoHeight()}
              />
              <Divider customCSS={dividerCSS} height={isMobile ? 28 : 48} />
            </Fragment>
          )}
          <Logo width={isMobile ? 73 : 105} />
        </div>

        {!!navigation && isDesktop && navigation}

        {children ?? (showContacts && <HeaderContacts phoneNumber={phoneNumber} onPhoneClick={onPhoneClick} />)}
      </Container>
      {!!navigation && !isDesktop && (
        <HeaderMobileNavigationWrapper getHeaderElement={getHeaderElement}>{navigation}</HeaderMobileNavigationWrapper>
      )}

      {subHeader && <HeaderScrollContainer>{subHeader}</HeaderScrollContainer>}
    </Wrapper>
  );
};

export default Header;
