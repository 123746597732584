import { css } from '@emotion/react';
import theme from '../../../styles/theme';

export const loaderContainerCSS = css`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`;
