import { getCheckboxKeysFromSectionContainer } from '../../../../../questionsForm/utils/answers/workflow-schema-answers.util';
import type { Answers } from '../../../../../types/answer.type';
import type { SectionContainer } from '../../../../../types/section.type';

export const prepareKnockoutAnswersForSubmit = (answers: Answers, containers: SectionContainer[]): Answers => {
  const knockoutQuestionKeys = containers.map((container) => getCheckboxKeysFromSectionContainer(container)).flat(1);

  return knockoutQuestionKeys.reduce((acc, key) => {
    return answers[key] ? { ...acc, [key]: answers[key] } : acc;
  }, {});
};
