import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import { text14CSS, text24CSS, text32CSS, textCSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  margin-right: auto;
  margin-left: auto;
`;

export const headingWrapperCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;

  ${mq[0]} {
    justify-content: center;
    margin-bottom: 40px;
  }
`;

export const headerCSS = css`
  font-weight: 700;
  ${text24CSS};
  line-height: 32px;

  ${mq[0]} {
    ${text32CSS};
    margin-bottom: 12px;
  }
`;

export const subheaderCSS = css`
  ${text14CSS};
  line-height: 20px;

  ${mq[0]} {
    ${textCSS};
  }
`;

export const iconCSS = css`
  width: 64px;
  height: 64px;
  margin-right: 16px;

  ${mq[0]} {
    width: 100px;
    height: 100px;
    margin-right: 24px;
  }
`;
