import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text14CSS, text20CSS, text24CSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerLoaderCSS = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;

  div {
    flex: 50%;
    margin-bottom: 25px;
  }
`;

export const containerCSS = css`
  background-color: ${theme.colors.white};
  padding: 24px 16px;
  border: none;
  border-top: 1px solid ${theme.colors.gray30};

  ${mq[0]} {
    padding: 32px;
    border-radius: 4px;
    border: 1px solid ${theme.colors.gray30};
  }
`;

export const titleCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 10px;
  border: unset;
  background-color: transparent;
  padding: 0;

  ${mq[0]} {
    ${text24CSS};
  }
`;

export const descriptionCSS = css`
  ${text14CSS};
  color: ${theme.colors.gray60};
`;

export const headerCSS = css`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;

  ${mq[0]} {
    flex-wrap: nowrap;
    margin-bottom: 40px;
  }
`;

export const contentCSS = css`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const coverageCSS = css`
  width: 100%;
  margin-top: 12px;

  ${mq[1]} {
    width: auto;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(50% - 33px);
    margin-top: 16px;
  }
`;

export const buttonCSS = css`
  padding: 12px 15px;
  margin-top: 20px;

  ${mq[0]} {
    flex: 30%;
    margin-top: 0;
  }
`;

const expandedCSS = css`
  &::before {
    transform: rotate(-45deg);
  }
  &::after {
    transform: rotate(45deg);
  }
`;

export const accordionArrowCSS = (expanded: boolean): SerializedStyles => css`
  height: 10px;
  width: 14px;
  position: absolute;
  right: 0;
  top: 0;

  &::after,
  &::before {
    background-color: ${theme.colors.azure62};
    content: '';
    display: block;
    height: 4px;
    position: absolute;
    top: 50%;
    border-radius: 2px;
    transition: transform 0.25s ease;
    width: 14px;
  }

  &::before {
    left: 4px;
    transform: rotate(45deg);
  }

  &::after {
    right: 4px;
    transform: rotate(-45deg);
  }

  ${expanded && expandedCSS};
`;

export const coverageDescriptionCSS = css`
  color: ${theme.colors.positiveGreen27};
  font-weight: 700;
`;
