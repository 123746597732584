import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text12CSS, text14CSS, textCSS } from '../../../../styles/typography.style';

export const formCSS = css`
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
  ${mq[0]} {
    margin-right: -${theme.sizes.inputHorizontalSpacing}px;
  }
`;

export const formInnerCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
`;

export const headingCSS = css`
  margin-left: 0;
  padding-top: 25px;

  ${mq[0]} {
    padding-top: 0;
  }
`;

export const formActionsCSS = css`
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
  text-align: center;
  z-index: 1;

  ${mq[0]} {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`;

export const hintCSS = css`
  ${text14CSS};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.azure95};
  padding: 16px;
  margin: 16px auto 0;
  border-radius: 4px;

  ${mq[0]} {
    ${textCSS};
    border-radius: 8px;
  }
`;

export const infoIconCSS = css`
  width: 24px;
  height: 24px;
  padding-right: 16px;
  flex-shrink: 0;
`;

export const discoverMoreButtonCSS = css`
  border-width: 2px;
  padding: 12px 20px 12px 48px;

  ${mq[0]} {
    ${textCSS};
    border-width: 2px;
    padding: 12px 20px 12px 48px;
  }

  &:hover > span > div {
    &::after,
    &::before {
      background-color: ${theme.colors.white};
    }
  }
`;

export const discoverMoreWrapperCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;

  ${mq[0]} {
    margin-top: 12px;
    margin-bottom: 24px;
  }
`;

export const buttonArrowCSS = css`
  width: 18px;
  position: absolute;
  left: 18px;
  height: 20px;

  &::after,
  &::before {
    background-color: ${theme.colors.azure62};
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    top: 50%;
    transition: transform 0.25s ease;
    width: 14px;
    border-radius: 2px;
  }
  &::before {
    left: 6px;
    transform: rotate(-45deg);
  }
  &::after {
    right: 6px;
    transform: rotate(45deg);
  }
`;

export const disclosuresContainerCSS = css`
  text-align: center;
  color: ${theme.colors.gray60};
  ${text12CSS};
  line-height: 16px;
`;

export const disclosureCSS = css`
  margin-bottom: 15px;
`;
