import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCoInsured } from '../../../../../api/coinsured';
import { usePage } from '../../../../../api/page';
import { useMutatePolicyBind } from '../../../../../api/policy';
import { usePublicConfig } from '../../../../../api/publicConfig';
import { useCreateQuoteRequest } from '../../../../../api/quote';
import { waitForQuoteRequestCompleted } from '../../../../../api/quote/use-quote-requests-subscription';
import { useMutateSession, useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import { SEGMENT } from '../../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import { useSegment } from '../../../../../hooks/use-segment';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../../../../utils/current-form-keys.util';
import { getPathByPageType } from '../../../../../utils/route-path.util';
import { getCurrentPageSection, getDisclosuresSchema } from '../../../../../utils/schema.util';
import CheckoutBindStep from '../CheckoutBindStep/CheckoutBindStep';
import CheckoutCoInsuredStep from '../CheckoutCoInsuredStep/CheckoutCoInsuredStep';
import CheckoutHomeFormLoading from '../CheckoutHomeFormLoading/CheckoutHomeFormLoading';
import CheckoutHomePageLoader from '../CheckoutHomePageLoader/CheckoutHomePageLoader';
import CheckoutPaymentStep from '../CheckoutPaymentStep/CheckoutPaymentStep';
import { containerCSS, dividerCSS } from './CheckoutHomeForm.style';
import type { CheckoutHomeFormProps } from './CheckoutHomeForm.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { SessionResponse } from '../../../../../types/session.type';
import type { FC } from 'react';

const CheckoutHomeForm: FC<CheckoutHomeFormProps> = ({ customCSS }) => {
  const queryClient = useQueryClient();
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: configData } = usePublicConfig();
  const { data: sessionData } = useSession(gid);
  const { data: coInsuredData } = useCoInsured(gid);
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);

  const currentPageKey = sessionData?.session.current_page_key;
  const currentSectionKey = sessionData?.session.current_section_key;

  const { data: pageData } = usePage(gid, currentPageKey);
  const { mutateAsync: mutateSession } = useMutateSession(gid);
  const { mutateAsync: mutateBindPolicy } = useMutatePolicyBind(gid);
  const { mutateAsync: createQuoteRequest } = useCreateQuoteRequest(gid);

  const [bindInProgress, setBindInProgress] = useState(false);

  const navigate = useNavigate();
  const { track } = useSegment();

  const [currentStep, setCurrentStep] = useState<number>(1);

  const showCoinsured = sessionFlowConfig?.flow_config?.carrier_key !== 'branch';
  const currentSection = getCurrentPageSection(currentSectionKey, pageData);
  const disclosures = getDisclosuresSchema(currentSection);
  const needAcceptDisclosures = disclosures?.length > 0;

  const shouldSetStepOnInitRef = useRef(true);
  const isBindDisabled = sessionData?.session.test_mode;

  useEffect(() => {
    if ((coInsuredData?.co_insureds[0] ?? !showCoinsured) && shouldSetStepOnInitRef.current) {
      setCurrentStep(2);
      shouldSetStepOnInitRef.current = false;
    }
  }, [coInsuredData, showCoinsured]);

  const onFinishFirstStep = (): void => {
    setCurrentStep(2);
    shouldSetStepOnInitRef.current = false;
  };

  const onFinishSecondStep = async (): Promise<void> => {
    if (needAcceptDisclosures) {
      setCurrentStep(3);
    } else {
      await onBindPolicy();
    }
  };

  const onBeforePaymentSubmit = (): void => {
    if (!needAcceptDisclosures || currentStep === 3) {
      setBindInProgress(true);
    }
  };

  const onBindPolicy = async (): Promise<void> => {
    setBindInProgress(true);
    track(SEGMENT.events.bindPolicyClicked);

    await createQuoteRequest({ bindAttempt: true });

    if (!configData?.config.websocket_url) {
      throw new Error('Websocket URL is not defined');
    }
    await waitForQuoteRequestCompleted(gid, configData.config.websocket_url);

    await mutateBindPolicy({});
    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);
    const nextCurrentPage = getCurrentNavigationPage(newSessionData);
    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });
    navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
  };

  if (!coInsuredData || !sessionFlowConfig?.flow_config?.payment_methods) {
    return <CheckoutHomeFormLoading />;
  }

  if (bindInProgress) {
    return <CheckoutHomePageLoader />;
  }

  return (
    <div css={[containerCSS, customCSS]}>
      {showCoinsured && (
        <>
          <CheckoutCoInsuredStep
            stepNumber={1}
            isCurrent={currentStep === 1}
            onSetCurrent={() => setCurrentStep(1)}
            onGoToNextStep={onFinishFirstStep}
          />
          <div css={dividerCSS} />
        </>
      )}

      <CheckoutPaymentStep
        paymentMethods={sessionFlowConfig?.flow_config?.payment_methods}
        stepNumber={2}
        hideStepNumber={!showCoinsured}
        currentStepNumber={currentStep}
        actionLabel={!needAcceptDisclosures ? currentSection?.action_label : undefined}
        onSetCurrent={() => setCurrentStep(2)}
        onBeforeStepSubmit={onBeforePaymentSubmit}
        onGoToNextStep={onFinishSecondStep}
      />

      {needAcceptDisclosures && (
        <>
          <div css={dividerCSS} />

          <CheckoutBindStep
            stepNumber={3}
            currentStepNumber={currentStep}
            disclosures={disclosures}
            actionLabel={currentSection?.action_label}
            isBindDisabled={isBindDisabled}
            onBeforeStepSubmit={onBeforePaymentSubmit}
            onFinishStep={onBindPolicy}
          />
        </>
      )}
    </div>
  );
};

export default CheckoutHomeForm;
