import { REQUIRED_VALIDATION } from '../../../../questionsForm/utils/question-validations/question-validations.util';
import { QuestionType } from '../../../../types/question.type';
import type { QuestionProps } from '../../../../questionsForm/components/Question/Question.type';

export const SESSION_LOCKED_INPUTS: QuestionProps[] = [
  {
    type: QuestionType.Text,
    questionKey: 'last_name',
    placeholder: 'last name',
    label: 'Last Name',
    validations: [REQUIRED_VALIDATION],
  },
  {
    type: QuestionType.Date,
    questionKey: 'date_of_birth',
    placeholder: 'MM/DD/YYYY',
    mask: '99-99-9999',
    label: 'Date of Birth',
    validations: [REQUIRED_VALIDATION],
  },
  {
    type: QuestionType.Text,
    questionKey: 'zip',
    placeholder: 'XXXXX',
    label: 'Zip Code',
    validations: [REQUIRED_VALIDATION],
  },
];

export const SESSION_LOCKED_FORM_ID = 'session-locked';
