import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicConfig } from '../../api/publicConfig';
import { useSession } from '../../api/session';
import { useSessionTimeoutSubscription } from '../../api/session/use-session-timeout-subscription';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader';
import type { SessionLockGuardProps } from './SessionLockGuard.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const SessionLockGuard: FC<SessionLockGuardProps> = ({ children }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: configData } = usePublicConfig(false);
  const { data: sessionData } = useSession(gid);
  const { isSessionLocked } = useSessionTimeoutSubscription(gid, configData?.config.websocket_url);

  useEffect(() => {
    if (isSessionLocked) {
      window.location.reload();
    }
  }, [isSessionLocked]);

  if (!sessionData || !configData) {
    return <FullPageLoader />;
  }
  return <>{children}</>;
};

export default SessionLockGuard;
