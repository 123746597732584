import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (fullscreen: boolean, dense: boolean): SerializedStyles => css`
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: ${dense ? theme.sizes.paddingBodyHorizontalMobileDense : theme.sizes.paddingBodyHorizontalMobile}px;
  padding-right: ${dense ? theme.sizes.paddingBodyHorizontalMobileDense : theme.sizes.paddingBodyHorizontalMobile}px;
  max-width: ${fullscreen
    ? '100%'
    : `${theme.sizes.containerMaxWidth + theme.sizes.paddingBodyHorizontalTablet * 2}px`};

  ${mq[0]} {
    padding-left: ${theme.sizes.paddingBodyHorizontalTablet}px;
    padding-right: ${theme.sizes.paddingBodyHorizontalTablet}px;
  }
`;
