import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePublicSession } from '../../../../api/public/session';
import { SEGMENT } from '../../../../constants/analytics';
import ROUTES from '../../../../constants/routes';
import { useSegment } from '../../../../hooks/use-segment';
import { Card } from '../../../../shared/components/Card/Card';
import FullPageLoader from '../../../../shared/components/FullPageLoader/FullPageLoader';
import { SessionStatus } from '../../../../types/session.type';
import ForeverLockedCardContent from '../ForeverLockedCardContent/ForeverLockedCardContent';
import UnlockCardContent from '../UnlockCardContent/UnlockCardContent';
import { cardCSS, sessionLockedWrapperCSS } from './SessionLocked.style';
import { MAX_UNLOCK_ATTEMPTS } from './SessionLocked.util';
import type { DefaultLocation } from '../../../../types/route-params.type';

const SessionLocked: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const navigate = useNavigate();
  const { track } = useSegment();

  const { data: publicSessionData } = usePublicSession(gid);

  const [foreverLocked, setForeverLocked] = useState<boolean>(false);

  const onGoToScheduleACall = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: SEGMENT.pages.sessionLocked.name,
    });
    navigate(ROUTES.sessionScheduleCall.replace(':flow', flow).replace(':gid', gid));
  };

  useEffect(() => {
    if (!publicSessionData?.session) {
      return;
    }

    if (publicSessionData?.session.unlock_attempts_count === MAX_UNLOCK_ATTEMPTS) {
      setForeverLocked(true);
      return;
    }

    if (publicSessionData?.session.status !== SessionStatus.locked) {
      navigate(ROUTES.flowAndGid.replace(':flow', flow).replace(':gid', gid));
    }
  }, [navigate, flow, gid, publicSessionData?.session]);

  if (!publicSessionData?.session) {
    return <FullPageLoader />;
  }

  return (
    <div css={sessionLockedWrapperCSS}>
      <Card customCSS={cardCSS}>
        {foreverLocked ? (
          <ForeverLockedCardContent onActionClick={onGoToScheduleACall} />
        ) : (
          <UnlockCardContent onCallMaticAgent={onGoToScheduleACall} />
        )}
      </Card>
    </div>
  );
};

export default SessionLocked;
