import { ComponentTypes } from '../../../../types/form-component.type';
import type { BillingInformationSchema, PaymentPlanCondition } from '../../../../types/form-component.type';
import type { PageResponse } from '../../../../types/page.type';
import type { CustomSectionSchema } from '../../../../types/section.type';

export const getPaymentDetailsFromSchema = (
  data: PageResponse
): {
  finalQuotePremium: number;
  paymentPlans?: Record<string, PaymentPlanCondition>;
} => {
  if (!data) return { finalQuotePremium: 0 };

  const firstSection = data.page.sections[0] as CustomSectionSchema;
  const component = firstSection?.components?.find(
    (c) => c.type === ComponentTypes.BillingInformation
  ) as BillingInformationSchema;

  if (!component) return { finalQuotePremium: 0 };

  return {
    finalQuotePremium: component.final_quote_premium_amount,
    paymentPlans: component.payment_plans,
  };
};
