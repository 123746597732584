import { css } from '@emotion/react';
import theme from '../../styles/theme';
import { text14CSS, textCSS } from '../../styles/typography.style';

export const toggletipContentCSS = css`
  padding: 20px;
`;

export const reminderTitleWrapperCSS = css`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 12px;
`;

export const reminderSubHeaderTextCSS = css`
  ${text14CSS};
  font-weight: 500;
  line-height: 20px;
`;

export const reminderHeaderTextCSS = css`
  ${textCSS};
  line-height: 20px;
  font-weight: 700;
`;

export const reminderTextWrapperCSS = css`
  color: ${theme.colors.black};
  font-family: ${theme.typography.fontFamily};
`;

export const iconCSS = css`
  width: 24px;
  height: 24px;
`;
