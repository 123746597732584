import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import { text20CSS, text24CSS, text32CSS } from '../../../../styles/typography.style';

export const containerCSS = css`
  ${mq[0]} {
    text-align: center;
  }
`;

export const dividerCSS = css`
  height: 24px;
  margin-bottom: 55px;
  ${mq[0]} {
    margin-bottom: 48px;
  }
`;

export const titleCSS = css`
  ${text24CSS};
  font-weight: 700;
  margin-bottom: 16px;
  ${mq[0]} {
    ${text32CSS};
    margin-bottom: 12px;
  }
`;

export const subtitleCSS = css`
  ${mq[0]} {
    ${text20CSS};
  }
`;
