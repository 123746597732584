import theme from '../../../styles/theme';
import type { SxProps, Theme } from '@mui/material';

const getThumbStyles = (dense = false): Record<string, unknown> => ({
  width: dense ? 24 : 30,
  height: dense ? 24 : 30,
  borderRadius: '40px',
  border: `2px solid ${theme.colors.gray60}`,
  marginLeft: 0,
});

const getTrackStyles = (dense: boolean): Record<string, unknown> => ({
  backgroundColor: theme.colors.gray60,
  opacity: 1,
  width: dense ? 42 : 50,
  height: 16,
});

const activeStyles: SxProps = {
  color: theme.colors.white,
  transform: `translateX(21px)`,
  '&+.MuiSwitch-track': {
    backgroundColor: theme.colors.azure50,
    opacity: 1,
  },
  '.MuiSwitch-thumb': {
    backgroundColor: theme.colors.white,
    borderColor: theme.colors.azure50,
  },
};

const rootStyles: SxProps = { width: 'auto', height: 'auto' };

export const getMuiSwitchStylesCSS = (dense = false): SxProps<Theme> => ({
  ...rootStyles,
  paddingLeft: dense ? '0' : '3px',
  paddingRight: dense ? '15px' : '3px',
  '.MuiSwitch-track': getTrackStyles(dense),
  '.MuiSwitch-thumb': getThumbStyles(dense),
  '.MuiSwitch-switchBase': {
    padding: 0,
    paddingTop: dense ? '6px' : '3px',
    '&.Mui-checked': activeStyles,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});
