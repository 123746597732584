import type { SerializedStyles } from '@emotion/react';
import type { ReactNode } from 'react';

export enum RadioButtonVariant {
  Default = 'default',
  Photo = 'photo',
}

export interface RadioButtonProps {
  customCSS?: SerializedStyles;
  label: string;
  value: string;
  description?: string;
  imageUrl?: string;
  boldLabel?: boolean;
  index: number;
  name: string;
  checked: boolean;
  disabled?: boolean;
  variant?: RadioButtonVariant;
  children?: ReactNode;
  hasError?: boolean;
  onChange?: (...event: any[]) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}
