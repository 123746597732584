import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

interface CustomerChoiceMutationParams {
  engagementGid: string;
  quoteGid: string;
}

const useCreateCustomerChoice = (): UseBaseMutationResult<
  Record<never, never>,
  HTTPResponse,
  CustomerChoiceMutationParams
> => {
  return useMutation({
    mutationFn: async ({ engagementGid, quoteGid }: CustomerChoiceMutationParams) =>
      await api
        .post<HTTPResponse<Record<never, never>>>(getApiUrl(API_PATH.customerChoices, engagementGid), {
          body: {
            data: {
              quote_gid: quoteGid,
            },
          },
        })
        .then((res) => res.data),
    throwOnError: false,
  });
};

export default useCreateCustomerChoice;
