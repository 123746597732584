import { ClickAwayListener } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import QuestionLabel from '../../../../questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel';
import { labelCSS } from '../../../../questionsForm/components/QuestionWrapper/QuestionWrapper.style';
import noop from '../../../utils/noop';
import Select from '../../Select/Select';
import RadioButton from '../RadioButton/RadioButton';
import { RadioButtonVariant } from '../RadioButton/RadioButton.type';
import { radiButtonCSS } from './ShowMoreRadioButton.style';
import type { ShowMoreRadioButtonProps } from './ShowMoreRadioButton.type';
import type { FC } from 'react';

const ShowMoreRadioButton: FC<ShowMoreRadioButtonProps> = ({
  customCSS,
  index,
  name,
  selectedValue,
  options = [],
  disabled,
  buttonVariant = RadioButtonVariant.Default,
  boldLabel,
  hasError,
  onBlur = noop,
  onChange = noop,
}) => {
  const isFocused = useRef(false);
  const [value, selectValue] = useState<string>('');
  const [showSelect, setShowSelect] = useState(false);
  const showMoreOptionSelected = options.some((option) => option.value === selectedValue);

  const selectKey = name + '-select';
  const radioButtonDescription = buttonVariant === RadioButtonVariant.Photo ? 'Select from more options.' : '';
  const showMoreSelectLabel = buttonVariant === RadioButtonVariant.Photo ? '' : 'Select from more options.';

  useEffect(() => {
    if (selectedValue) {
      setShowSelect(false);
    }
  }, [selectedValue]);

  const onRadioFocus = (): void => {
    isFocused.current = true;
  };

  const onOptionSelect = (): void => {
    setShowSelect(true);
    if (!showMoreOptionSelected) {
      selectValue('');
    }
  };

  const onSelectChange = (value: unknown): void => {
    const inputValue = value as string;
    selectValue(inputValue);
    onChange(inputValue);
    onBlur();
  };

  const onClickOutside = (): void => {
    if (isFocused.current) {
      setShowSelect(false);
      onBlur();
      isFocused.current = false;
    }
  };

  if (!options.length) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={onClickOutside}>
      <div css={customCSS}>
        <RadioButton
          customCSS={radiButtonCSS}
          index={index}
          name={name}
          label="Show more options"
          description={radioButtonDescription}
          boldLabel={boldLabel}
          value={value}
          imageUrl={SIRV_IMAGES.otherOption}
          disabled={disabled}
          checked={!!value && selectedValue === value}
          variant={buttonVariant}
          hasError={hasError}
          onChange={onOptionSelect}
          onFocus={onRadioFocus}
        >
          {(showMoreOptionSelected || showSelect) && (
            <>
              {showMoreSelectLabel && (
                <QuestionLabel customCSS={labelCSS} keyName={selectKey}>
                  {showMoreSelectLabel}
                </QuestionLabel>
              )}
              <Select
                inputId={selectKey}
                value={value}
                placeholder="- choose option -"
                options={options}
                blurInputOnSelect
                onValidEntry={onSelectChange}
              />
            </>
          )}
        </RadioButton>
      </div>
    </ClickAwayListener>
  );
};

export default ShowMoreRadioButton;
