import { useQuery } from '@tanstack/react-query';
import QUERY_CACHE_KEYS from '../../../constants/query-cache-keys';
import publicSessionService from '../session/public-session.service';
import type { PublicFlowConfigResponse } from '../../../types/public-flow-config.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const usePublicFlowConfig = (gid: string | null, throwOnError = true): QueryObserverResult<PublicFlowConfigResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.publicFlowConfig, gid],
    queryFn: gid ? async () => await publicSessionService.getPublicFlowConfig(gid) : undefined,
    enabled: Boolean(gid),
    throwOnError,
  });

export default usePublicFlowConfig;
