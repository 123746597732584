import { useEffect, useRef, useState } from 'react';

import { getElementByType, getFormElementContext } from '../../utils/form-element.util';
import { formCSS, formInnerCSS, scrollPointCSS } from './ContainerForm.style';
import type { ContainerFormProps } from './ContainerForm.type';
import type { FC } from 'react';

const ContainerForm: FC<ContainerFormProps> = ({
  customCSS,
  containerKey,
  visibleElements = [],
  isFirstContainerOnPage = false,
  isLineHidden = false,
  isLastContainerOnPage,
  autoScrollEnabled = false,
  answers,
}) => {
  const containerScrollPointRef = useRef<HTMLDivElement>(null);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (autoScrollEnabled && containerScrollPointRef.current) {
      const timerId = setTimeout(() => {
        if (containerScrollPointRef?.current) {
          containerScrollPointRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          setAnimate(true);
        }
      }, 500);

      return () => clearTimeout(timerId);
    }
  }, [containerKey, autoScrollEnabled]);

  return (
    <fieldset css={[formCSS(isLineHidden, isLastContainerOnPage, true), customCSS]}>
      <div data-testid="form-scroll-point" css={scrollPointCSS} ref={containerScrollPointRef} id={containerKey} />
      <div data-testid="form-inner" css={[formInnerCSS(autoScrollEnabled, animate)]}>
        {visibleElements.map((e, index) =>
          getElementByType({
            element: e,
            isFirstContainerOnPage,
            enableScrollAnimation: autoScrollEnabled,
            isSubElement: false,
            context: getFormElementContext(visibleElements[index - 1], visibleElements[index + 1]),
            answers,
          })
        )}
      </div>
    </fieldset>
  );
};

export default ContainerForm;
