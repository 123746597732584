import { css } from '@emotion/react';
import { maxCalendlyLikeWidthContainerCSS } from '../../../../../styles/common.style';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text24CSS, text32CSS } from '../../../../../styles/typography.style';

export const cardCSS = css`
  ${maxCalendlyLikeWidthContainerCSS};
  margin: 0 0 20px 0;
  box-sizing: border-box;

  ${mq[0]} {
    margin: 0 0 20px 0;
  }
`;

export const noQuoteImageCSS = css`
  ${mq[0]} {
    margin: 16px 0;
    width: 140px;
  }

  ${mq[1]} {
    width: unset;
  }
`;

export const headerContentCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${mq[0]} {
    width: 360px;
  }

  ${mq[1]} {
    width: 660px;
  }
`;

export const wrapperCSS = css`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  width: 100%;
  background-color: ${theme.colors.white};
  z-index: 1;
  overflow: hidden;
  text-align: center;

  ${mq[0]} {
    margin-bottom: 50px;
    background-color: ${theme.colors.azure95};
  }
`;

export const pageWrapperCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${mq[0]} {
    padding: 0;
    width: 100vw;
  }
`;

export const elipseCSS = css`
  clip-path: ellipse(40% 45%);
  position: absolute;
  background-color: ${theme.colors.white};
  display: none;
  z-index: -1;

  ${mq[0]} {
    display: flex;
    width: 630px;
    height: 631px;
  }

  ${mq[1]} {
    width: 930px;
    height: 731px;
  }
`;

const iconCSS = css`
  display: none;

  ${mq[0]} {
    display: flex;
    width: 145px;
  }

  ${mq[1]} {
    width: 245px;
  }
`;

export const separateHouseIconCSS = css`
  left: 1%;
  ${iconCSS};
`;

export const doubleHouseIconCSS = css`
  right: 1%;
  ${iconCSS};
`;

export const headerCSS = css`
  ${text24CSS};
  font-weight: 700;
  margin-bottom: 6px;

  ${mq[0]} {
    margin-bottom: 16px;
  }
  ${mq[1]} {
    ${text32CSS};
  }
`;

export const subheaderCSS = css`
  margin-bottom: 16px;
`;
