import * as Sentry from '@sentry/react';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useParams } from 'react-router-dom';
import { recaptchaService } from '../api/recaptcha';
import type { DefaultLocation } from '../types/route-params.type';

const useRecaptchaVerify = (): {
  verifyRecaptcha: (currentPageKey: string) => Promise<boolean>;
} => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { gid } = useParams() as DefaultLocation;

  const handleRecaptchaVerify = useCallback(
    async (step: string) => {
      if (!executeRecaptcha) {
        return;
      }

      try {
        const token = await executeRecaptcha(step);
        return token;
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [executeRecaptcha]
  );

  const verifyRecaptcha = useCallback(
    async (currentPageKey: string) => {
      // To test CX-3496 if (import.meta.env.PROD) {
      const recaptchaToken = await handleRecaptchaVerify(currentPageKey);

      if (recaptchaToken) {
        const result = await recaptchaService.verifyRecaptchaV3Token(gid, {
          recaptcha_token: recaptchaToken,
          action: currentPageKey,
        });

        if (result?.data?.show_recaptcha_puzzle) {
          return true;
        }
      }
      return false;
    },
    [gid, handleRecaptchaVerify]
  );

  return { verifyRecaptcha };
};

export default useRecaptchaVerify;
