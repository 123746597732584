import useResponsive from '../../../../hooks/use-responsive';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import CheckboxInput from './CheckboxInput/CheckboxInput';
import { getCheckboxVariant } from './CheckboxQuestion.util';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const CheckboxQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  description,
  workflowHeading,
  icon,
  isDisabled,
  validations,
  gridWidth,
  tags = [],
  subElements,
}) => {
  const { isMobile } = useResponsive();

  return (
    <QuestionWrapper
      questionKey={questionKey}
      label={label}
      workflowHeading={workflowHeading}
      hideLabel
      isDisabled={isDisabled}
      gridWidth={gridWidth}
      inputProps={{
        name: questionKey,
        label,
        labelDescription: description,
        icon,
        isDisabled,
        validations,
        subElements,
        variant: getCheckboxVariant(isMobile, gridWidth, tags),
      }}
      inputComponent={CheckboxInput}
    />
  );
};

export default CheckboxQuestion;
