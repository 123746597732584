import { isTrackForbidden } from '../../../utils/form-element-tags.util';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import CardExpirationInput from './CardExpirationInput/CardExpirationInput';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const CardExpirationQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  workflowHeading,
  label,
  description,
  hint,
  isDisabled,
  placeholder,
  validations,
  tags = [],
}) => (
  <QuestionWrapper
    questionKey={questionKey}
    label={label}
    hint={hint}
    description={description}
    workflowHeading={workflowHeading}
    inputProps={{
      name: questionKey,
      placeholder,
      isDisabled,
      inputId: questionKey,
      validations,
      trackingForbidden: isTrackForbidden(tags),
    }}
    inputComponent={CardExpirationInput}
  />
);

export default CardExpirationQuestion;
