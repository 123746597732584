import { cardCSS } from './Card.style';
import type { SerializedStyles } from '@emotion/react';
import type { FC, ReactNode } from 'react';

interface CardProps {
  customCSS?: SerializedStyles | Array<SerializedStyles | undefined>;
  children: ReactNode;
}

export const Card: FC<CardProps> = ({ customCSS, children }) => <div css={[cardCSS, customCSS]}>{children}</div>;
