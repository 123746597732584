import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SEGMENT } from '../../../constants/analytics';
import { useSegment } from '../../../hooks/use-segment';
import { FormElementTag } from '../../../types/form-element.type';
import { QuestionType } from '../../../types/question.type';
import AddressQuestion from './AddressQuestion/AddressQuestion';
import CardExpirationQuestion from './CardExpirationQuestion/CardExpirationQuestion';
import CheckboxQuestion from './CheckboxQuestion/CheckboxQuestion';
import CounterQuestion from './CounterQuestion/CounterQuestion';
import DateInputQuestion from './DateInputQuestion/DateInputQuestion';
import EmailQuestion from './EmailQuestion/EmailQuestion';
import MultiSelectQuestion from './MultiSelectQuestion/MultiSelectQuestion';
import NumberInputQuestion from './NumberInputQuestion/NumberInputQuestion';
import PhoneQuestion from './PhoneQuestion/PhoneQuestion';
import RadioQuestion from './RadioQuestion/RadioQuestion';
import SelectQuestion from './SelectQuestion/SelectQuestion';
import SwitchQuestion from './SwitchQuestion/SwitchQuestion';
import TextInputQuestion from './TextInputQuestion/TextInputQuestion';
import VehicleQuestion from './VehicleQuestion/VehicleQuestion';
import type { SpecificQuestionProps, QuestionProps } from './Question.type';
import type { FC } from 'react';

const getQuestionByType = (type: QuestionType = QuestionType.Text): FC<SpecificQuestionProps> => {
  switch (type) {
    case QuestionType.Radio:
      return RadioQuestion;
    case QuestionType.Date:
      return DateInputQuestion;
    case QuestionType.SingleSelect:
      return SelectQuestion;
    case QuestionType.MultiSelect:
      return MultiSelectQuestion;
    case QuestionType.Address:
      return AddressQuestion;
    case QuestionType.Number:
      return NumberInputQuestion;
    case QuestionType.Checkbox:
      return CheckboxQuestion;
    case QuestionType.Email:
      return EmailQuestion;
    case QuestionType.Phone:
      return PhoneQuestion;
    case QuestionType.Counter:
      return CounterQuestion;
    case QuestionType.CardExpirationDate:
      return CardExpirationQuestion;
    case QuestionType.Switch:
      return SwitchQuestion;
    case QuestionType.VehicleSelect:
      return VehicleQuestion;
    case QuestionType.Text:
    default:
      return TextInputQuestion;
  }
};

const Question: FC<QuestionProps> = ({ type, ...props }) => {
  const QuestionComponent = getQuestionByType(type);
  const { clearErrors } = useFormContext();
  const { track } = useSegment();
  const shouldReportViewed = props.tags?.includes(FormElementTag.ReportViewed);
  const questionKey = props.questionKey;

  useEffect(() => {
    if (shouldReportViewed) {
      track(SEGMENT.events.questionViewed, { key: questionKey });
    }
  }, [questionKey, shouldReportViewed, track]);

  /* Since we use `shouldUnregister: false`, there is a possibility
     to trigger validation and then unmount that field.
     Without cleanup formState will be invalid. */
  useEffect(() => {
    return () => {
      clearErrors(props.questionKey);
    };
  }, [clearErrors, props.questionKey]);

  return <QuestionComponent {...props} />;
};

export default Question;
