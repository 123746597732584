import type { SessionError } from '../../types/public-session.type';
import type { ReactNode } from 'react';

export enum ErrorReasonsIcon {
  Warning = 'warning',
  Check = 'check',
}

export interface ErrorPageMessageProps {
  title: string;
  description: string | ReactNode;
  errorReasons?: SessionError[];
  errorReasonsIcon?: ErrorReasonsIcon;
  children?: ReactNode;
  hideSpamFreeAndSecure?: boolean;
}
