import { ClickAwayListener } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import noop from '../../utils/noop';
import { activeOutlineCSS, containerCSS, counterWrapperCSS } from './CounterInput.style';
import { CounterInputVariant } from './CounterInput.type';
import CounterInputInner from './CounterInputInner/CounterInputInner';
import CounterInputWithCheckbox from './CounterInputWithCheckbox/CounterInputWithCheckbox';
import type { CounterInputProps } from './CounterInput.type';
import type { ChangeEvent, FC } from 'react';

const CounterInput: FC<CounterInputProps> = ({
  inputId,
  disabled = false,
  hasError = true,
  isPrefilled = false,
  value,
  variant,
  label,
  iconUrl,
  componentRef = noop,
  onBlur = noop,
  onChange = noop,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>();
  const inputRef = useRef<HTMLInputElement>(null);
  const onBlurPendingRef = useRef(false);

  useEffect(() => {
    if (componentRef && inputRef.current) {
      componentRef(inputRef.current);
    }
  }, [componentRef, inputRef]);

  const onButtonClick = (delta: number, minValue = 0): void => {
    const numberValue = Number.parseInt(inputRef.current?.value ?? '', 10);

    if (Number.isNaN(numberValue)) {
      onChange(minValue);
      return;
    }

    if (numberValue === minValue && delta === -1) {
      return;
    }

    onChange(numberValue + delta);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>, minValue = 0): void => {
    const numberValue = Number.parseInt(e.target.value, 10);
    const v = Number.isNaN(numberValue) ? '' : Math.max(numberValue, minValue);
    (inputRef.current ?? { value: '' }).value = String(v);
    onChange(v);
  };

  const onInputBlur = (triggerAway?: boolean): void => {
    onBlurPendingRef.current = true;
    if (triggerAway) {
      onClickAwayHandler();
    }
  };

  const onClickAwayHandler = (): void => {
    if (onBlurPendingRef.current) {
      onBlur();
      onBlurPendingRef.current = false;
      setIsFocused(false);
    }
  };

  const onToggleCheckbox = (value: boolean): void => {
    !value && (onBlurPendingRef.current = true);
    onChange(+value);
  };

  return (
    <ClickAwayListener onClickAway={onClickAwayHandler}>
      <div css={counterWrapperCSS}>
        {variant === CounterInputVariant.WithCheckbox ? (
          <CounterInputWithCheckbox
            disabled={disabled}
            inputId={inputId}
            label={label}
            iconUrl={iconUrl}
            hasError={hasError}
            isFocused={isFocused}
            onFocus={setIsFocused}
            onChange={onChange}
            onToggleCheckbox={onToggleCheckbox}
            inputElement={
              <CounterInputInner
                inputId={inputId}
                value={value}
                hasError={hasError}
                disabled={disabled}
                ref={inputRef}
                onFocus={setIsFocused}
                onButtonClick={(delta) => onButtonClick(delta, 1)}
                onInputBlur={() => onInputBlur(true)}
                onInputChange={(e) => onInputChange(e, 1)}
              />
            }
          />
        ) : (
          <div css={containerCSS(CounterInputVariant.Default, hasError, isFocused, false, isPrefilled)}>
            <CounterInputInner
              inputId={inputId}
              value={value}
              hasError={hasError}
              disabled={disabled}
              ref={inputRef}
              onFocus={setIsFocused}
              onButtonClick={onButtonClick}
              onInputBlur={onInputBlur}
              onInputChange={onInputChange}
            />
          </div>
        )}
        <div css={activeOutlineCSS(isFocused, hasError)} />
      </div>
    </ClickAwayListener>
  );
};

export default CounterInput;
