import { useFormContext } from 'react-hook-form';
import { CHECKBOX_VALUE } from '../../../../../questionsForm/components/Question/CheckboxQuestion/CheckboxInput/CheckboxInput.util';

import SectionForm from '../../../../../questionsForm/components/SectionForm/SectionForm';
import Button from '../../../../../shared/components/Button/Button';
import { QuestionType } from '../../../../../types/question.type';
import { submitCSS, backCSS, buttonsWrapperCSS } from './KnockoutQuestions.style';
import type { KnockoutQuestionsProps } from './KnockoutQuestions.types';
import type { QuestionElementContent } from '../../../../../types/form-element.type';
import type { SyntheticEvent, FC } from 'react';

const KnockoutQuestions: FC<KnockoutQuestionsProps> = ({
  sectionKey,
  container,
  step,
  onButtonClick,
  isLoading,
  onSubmit,
  isDisabled,
}) => {
  const { watch, handleSubmit } = useFormContext();
  const answers = watch();

  const isLastStep = step === 1;
  const questions = container.elements;

  const isAnsweredAtLeastOne = questions.some((element) => {
    const elementContent = element.content as QuestionElementContent;
    return elementContent.type === QuestionType.Checkbox && answers[elementContent.key] === CHECKBOX_VALUE.checked;
  });

  const submitText = isAnsweredAtLeastOne ? 'Submit' : 'None of the Above Apply';

  const onActionButtonClick = (e: SyntheticEvent): void => {
    if (!isLastStep) {
      e.preventDefault();
      onButtonClick(1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SectionForm
        sectionKey={sectionKey}
        containers={[container]}
        renderAllContainersAtOnce
        isFirst
        isLastWorkflowSection
      />
      <div css={buttonsWrapperCSS}>
        {isLastStep && (
          <Button
            customCSS={backCSS}
            variant="secondary"
            type="button"
            disabled={isDisabled}
            onClick={() => onButtonClick(-1)}
          >
            Go Back
          </Button>
        )}
        <Button
          customCSS={submitCSS}
          type={isLastStep ? 'submit' : 'button'}
          isLoading={isLoading}
          onClick={onActionButtonClick}
        >
          {submitText}
        </Button>
      </div>
    </form>
  );
};

export default KnockoutQuestions;
