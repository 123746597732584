import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  margin-bottom: -8px;
  color: ${theme.colors.gray80};
  ${mq[0]} {
    flex-direction: row;
    margin-bottom: -12px;
  }
`;

export const itemsColCSS = css`
  flex-basis: 50%;
`;

export const itemCSS = css`
  margin-bottom: 8px;
  span {
    font-weight: 700;
  }
  ${mq[0]} {
    margin-bottom: 12px;
  }
`;
