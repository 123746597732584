import { ValidationType } from '../../../../types/validation.type';
import { getDateFromTodayByDays } from '../../../../utils/date.util';
import { getValidationRules } from '../../../utils/question-validations/question-validations.util';
import type { Validation } from '../../../../types/validation.type';

export const shouldDisableDate = (date: Date, validations?: Validation[]): boolean => {
  const validRules = getValidationRules(validations);
  if (validRules.validate) {
    const result = Object.values(validRules.validate).map((validateFunc) => validateFunc(date.toISOString()));
    return result.some((el) => typeof el === 'string');
  }
  return false;
};

export const getMinAndMaxDates = (rules?: Validation[]): { maxDate?: Date; minDate?: Date } => {
  const minDateValue = rules?.find((r) => r.key === ValidationType.MinDaysFromNow)?.value ?? undefined;
  const maxDateValue = rules?.find((r) => r.key === ValidationType.MaxDaysFromNow)?.value ?? undefined;

  const maxDate = typeof maxDateValue === 'string' ? getDateFromTodayByDays(maxDateValue) : undefined;
  const minDate = typeof minDateValue === 'string' ? getDateFromTodayByDays(minDateValue) : undefined;

  return {
    maxDate,
    minDate,
  };
};
