import { css } from '@emotion/react';
import { indicatorLineCSS } from '../../../styles/form.style';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text14CSS } from '../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (hasHiddenLine?: boolean): SerializedStyles => css`
  position: relative;
  width: 100%;
  &:before {
    ${!hasHiddenLine && indicatorLineCSS};
  }

  ${mq[0]} {
    margin-right: ${theme.sizes.inputHorizontalSpacing}px;
  }
`;

export const labelCSS = css`
  ${text14CSS};
  line-height: 20px;
  color: ${theme.colors.gray60};
`;

export const inputCSS = css`
  border: 0;
  box-shadow: none;
  padding: 0 0 0 20px;

  &:before {
    display: none;
  }

  .checkbox-input-mark {
    top: 1px;
    left: 0;
  }
`;

export const errorCSS = css`
  margin-top: 4px;
  margin-left: 36px;
`;
