import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import useEffectivePolicy from '../../../../../api/policy/use-effective-policy';
import { usePublicConfig } from '../../../../../api/publicConfig';
import { useCreateQuoteRequest, useMutateQuote, useQuoteRequestsSubscription } from '../../../../../api/quote';
import { useSession } from '../../../../../api/session';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import useResponsive from '../../../../../hooks/use-responsive';
import Button from '../../../../../shared/components/Button/Button';
import DashedRow from '../../../../../shared/components/DashedRow/DashedRow';
import Logo from '../../../../../shared/components/Logo/Logo';
import { getCents, removeCents } from '../../../../../shared/utils/money.util';
import formatUtil from '../../../../../utils/format.util';
import { getQuoteSavings } from '../../../../../utils/quote.util';
import QuoteDiscounts from '../../QuoteDiscounts/QuoteDiscounts';
import ConfirmationQuoteModal from '../ConfirmationQuoteModal/ConfirmationQuoteModal';
import QuoteDetailsEditButton from '../QuoteDetailsEditButton/QuoteDetailsEditButton';
import WaitingLoadingModal from '../WaitingLoadingModal/WaitingLoadingModal';
import {
  actionButtonCSS,
  annualCSS,
  baseDetailItemCSS,
  basicDetailsHeaderCSS,
  bottomLineCSS,
  containerCSS,
  containerInnerCSS,
  dotCSS,
  editableDetailCSS,
  editableDetailsContainerCSS,
  logoCSS,
  logoWrapperCSS,
  priceCSS,
  priceWrapperCSS,
  savingsAndDiscountsBlockCSS,
  savingsBadgeCSS,
  topLineCSS,
} from './HomePolicyEditableDetails.style';
import { getQuestions } from './HomePolicyEditableDetails.util';
import type { HomePolicyEditableDetailsProps } from './HomePolicyEditableDetails.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { FC } from 'react';

const HomePolicyEditableDetails: FC<HomePolicyEditableDetailsProps> = ({ customCSS, quote, onSubmit }) => {
  const { isDesktop } = useResponsive();
  const { gid } = useParams() as DefaultLocation;
  const { data: configData } = usePublicConfig();
  const { data: sessionData } = useSession(gid);
  const currentPage = sessionData?.session.current_page_key ?? '';
  const { data: pageData } = usePage<CustomSectionSchema>(gid, currentPage);
  const { data: effectivePolicy } = useEffectivePolicy(gid);

  const { mutateAsync: updateQuote } = useMutateQuote(gid);
  const { mutateAsync: createRequest } = useCreateQuoteRequest(gid);

  const webSocketUrl = configData?.config.websocket_url;
  const { isFinished, startSubscription } = useQuoteRequestsSubscription({ gid, webSocketUrl });

  const queryClient = useQueryClient();

  const [showQuotingModal, setShowQuotingModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const savings = getQuoteSavings(quote, !!quote.multi_policy_discount_premium, effectivePolicy?.effective_policy);
  const hasSavings = savings > 0;
  const premiumDollars = formatUtil.money(String(removeCents(quote.premium)), 0, '$');
  const savingFormatted = formatUtil.money(savings || 0, 0, '$');
  const policyStartDate = formatUtil.date(quote.policy_effective_date);
  const deductibleFormatted = formatUtil.money(quote.deductible, 0, '$');

  const questions = useMemo(() => getQuestions(quote, pageData?.page.sections), [quote, pageData]);

  useEffect(() => {
    const call = async (): Promise<void> => {
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.quoteRequest, gid] });

      // Refetching the session to update session lock timer
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.session, gid] });
      setShowQuotingModal(false);
    };

    if (showQuotingModal && isFinished) {
      call();
    }
  }, [showQuotingModal, isFinished, queryClient, gid]);

  const onUpdateQuoteDetails = async (values: Record<string, string>): Promise<void> => {
    await updateQuote(values);

    startSubscription();
    await createRequest({});
    setShowQuotingModal(true);
  };

  const triggerConfirmationModal = (value: boolean): void => setShowConfirmationModal(value);

  if (isDesktop) {
    return (
      <div css={[containerCSS, customCSS]}>
        <WaitingLoadingModal
          title="Calculating new price..."
          description="This might take up to 30 seconds"
          open={showQuotingModal}
        />
        <div css={topLineCSS}>
          <div css={logoWrapperCSS}>
            <Logo
              carrierLogo={quote.carrier_logo_url}
              carrierName={quote.carrier_name}
              customCSS={logoCSS}
              height={35}
            />
            <div css={savingsAndDiscountsBlockCSS}>
              {hasSavings && <div css={savingsBadgeCSS}>{savingFormatted} Savings/Year</div>}
              {hasSavings && quote.discounts.length > 0 && <span css={dotCSS} />}
              {quote.discounts.length > 0 && <QuoteDiscounts discounts={quote.discounts} />}
            </div>
          </div>
          <div>
            <Button customCSS={actionButtonCSS} onClick={() => triggerConfirmationModal(true)}>
              Finalize Checkout
            </Button>
          </div>
        </div>
        <div css={bottomLineCSS}>
          <div>
            <div css={priceCSS} data-testid="premium-value">
              {premiumDollars}
              <span>.{getCents(quote.premium)}/yr</span>
            </div>
            <p css={annualCSS}>Annual Premium</p>
          </div>
          <div css={editableDetailsContainerCSS}>
            <div css={editableDetailCSS}>
              Policy Start Date <strong>{policyStartDate}</strong>
            </div>
            <div css={editableDetailCSS}>
              Deductible <strong>{deductibleFormatted}</strong>
            </div>
            <QuoteDetailsEditButton
              policyExpirationDate={effectivePolicy?.effective_policy?.expiration_date}
              questions={questions}
              onSubmit={onUpdateQuoteDetails}
            />
          </div>
        </div>
        <ConfirmationQuoteModal
          onSubmit={onSubmit}
          toggleModal={triggerConfirmationModal}
          isOpen={showConfirmationModal}
        />
      </div>
    );
  }

  return (
    <div css={[containerCSS, customCSS]}>
      <WaitingLoadingModal
        title="Calculating new price..."
        description="This might take up to 30 seconds"
        open={showQuotingModal}
      />
      <div css={containerInnerCSS(1)}>
        <Logo carrierLogo={quote.carrier_logo_url} carrierName={quote.carrier_name} customCSS={logoCSS} height={35} />

        {(hasSavings ?? quote.discounts.length > 0) && (
          <div css={savingsAndDiscountsBlockCSS}>
            {hasSavings && <div css={savingsBadgeCSS}>{savingFormatted} Savings/Year</div>}
            {hasSavings && quote.discounts.length > 0 && <span css={dotCSS} />}
            {quote.discounts.length > 0 && <QuoteDiscounts discounts={quote.discounts} />}
          </div>
        )}

        <div css={priceWrapperCSS}>
          <div css={priceCSS} data-testid="premium-value">
            {premiumDollars}
            <span>.{getCents(quote.premium)}/yr</span>
          </div>
          <p css={annualCSS}>Annual Premium</p>
        </div>
      </div>
      <div css={containerInnerCSS(2)}>
        <div css={basicDetailsHeaderCSS}>
          <strong>Basic Details</strong>
          <QuoteDetailsEditButton
            policyExpirationDate={effectivePolicy?.effective_policy?.expiration_date}
            questions={questions}
            onSubmit={onUpdateQuoteDetails}
          />
        </div>
        <DashedRow customCSS={baseDetailItemCSS} label="Policy Start Date" content={policyStartDate} />
        <DashedRow customCSS={baseDetailItemCSS} label="Deductible" content={deductibleFormatted} />
      </div>
      <div css={containerInnerCSS(3)}>
        <Button customCSS={actionButtonCSS} fullWidth onClick={() => triggerConfirmationModal(true)}>
          Finalize Checkout
        </Button>
      </div>
      <ConfirmationQuoteModal
        onSubmit={onSubmit}
        toggleModal={triggerConfirmationModal}
        isOpen={showConfirmationModal}
      />
    </div>
  );
};

export default HomePolicyEditableDetails;
