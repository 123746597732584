import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';

export const loaderCSS = css`
  padding-top: 48px;
  width: 100%;
  ${mq[0]} {
    padding-top: 72px;
  }
`;
