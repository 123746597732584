import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { EngagementResponse } from '../../types/engagement.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useEngagement = (gid: string, engagementGid?: string, enabled = true): QueryObserverResult<EngagementResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.engagement, gid],
    queryFn: async () =>
      await api
        .get<
          HTTPResponse<EngagementResponse>
        >(getApiUrl(API_PATH.engagement, engagementGid as string) + `?session_gid=${gid}`)
        .then((res) => res.data),
    enabled: !!engagementGid && !!gid && enabled,
  });

export default useEngagement;
