import { memo, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from '../../../shared/components/Button/Button';
import { ButtonSize } from '../../../shared/components/Button/Button.type';
import { noopAsync } from '../../../shared/utils/noop';
import { isObjectEmpty } from '../../../shared/utils/object-helpers.util';
import { sectionFormActionsCSS } from '../../../styles/form.style';
import { FormElementType } from '../../../types/form-element.type';
import { questionHasAnswer } from '../../utils/answers/answers.util';
import { getLastQuestionKeyFromContainer } from './QuestionFormActionButton.util';
import type { QuestionFormActionButtonProps } from './QuestionFormActionButton.type';
import type { FormElementSchema, QuestionElementContent } from '../../../types/form-element.type';
import type { RenderSectionContainer } from '../SectionForm/SectionForm.type';
import type { FC } from 'react';

const QuestionFormActionButton: FC<QuestionFormActionButtonProps> = ({
  customCSS,
  containers,
  currentContainerKey,
  lastContainerButtonLabel = 'Go to next page',
  onContainerByClickSubmit = noopAsync,
  onContainerByAnswerSubmit = noopAsync,
  onLastContainerSubmit = noopAsync,
}) => {
  const {
    formState: { defaultValues, errors },
    watch,
  } = useFormContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showButtonBecauseOfError, setShowButtonBecauseOfError] = useState(false);
  const currentContainer = containers.reduce((acc: RenderSectionContainer | null, container) => {
    if (container.key === currentContainerKey) {
      return container;
    }
    return acc;
  }, null);
  const lastQuestionKey = getLastQuestionKeyFromContainer(currentContainer);
  const visibleQuestionKeys = currentContainer?.elements
    .filter((e) => e.kind === FormElementType.Question)
    .map((q) => (q as FormElementSchema<QuestionElementContent>).content.key);
  const currentContainerQuestionAnswers = watch(visibleQuestionKeys || []);
  const containerHasAllAnswers = currentContainerQuestionAnswers.every((answer) => questionHasAnswer(answer));
  const hasLastQuestionDefaultValue =
    lastQuestionKey && defaultValues ? questionHasAnswer(defaultValues[lastQuestionKey]) : null;
  const lastQuestionHasAnswer = questionHasAnswer(
    currentContainerQuestionAnswers[currentContainerQuestionAnswers.length - 1]
  );
  const currentContainerIsLast = currentContainer?.key === containers[containers.length - 1]?.key;
  const canSubmitWithoutButton =
    currentContainer?.submitWithoutButton &&
    !hasLastQuestionDefaultValue &&
    !currentContainerIsLast &&
    !showButtonBecauseOfError;
  const containerFormHasErrors = !isObjectEmpty(errors);
  const submitByAnswerTriggered = useRef(false);

  useEffect(() => {
    if (lastQuestionHasAnswer && (!containerHasAllAnswers || containerFormHasErrors)) {
      setShowButtonBecauseOfError(true);
    } else if (canSubmitWithoutButton && lastQuestionKey && lastQuestionHasAnswer && !submitByAnswerTriggered.current) {
      setShowButtonBecauseOfError(false);
      onContainerByAnswerSubmit(lastQuestionKey);
      submitByAnswerTriggered.current = true;
    }
  }, [
    lastQuestionKey,
    lastQuestionHasAnswer,
    canSubmitWithoutButton,
    containerHasAllAnswers,
    containerFormHasErrors,
    onContainerByAnswerSubmit,
  ]);

  useEffect(() => {
    submitByAnswerTriggered.current = false;
  }, [currentContainerKey]);

  const onSubmit = async (): Promise<void> => {
    if (currentContainerIsLast) {
      setIsSubmitting(true);
      await onLastContainerSubmit();
      setIsSubmitting(false);
    } else {
      await onContainerByClickSubmit();
    }
    setShowButtonBecauseOfError(false);
  };

  if (!currentContainer || canSubmitWithoutButton) {
    return null;
  }

  return (
    <div css={[sectionFormActionsCSS, customCSS]}>
      <Button
        type="button"
        fullWidth
        disabled={containerFormHasErrors}
        size={ButtonSize.Large}
        isLoading={isSubmitting}
        onClick={onSubmit}
      >
        {currentContainerIsLast ? lastContainerButtonLabel : 'Move to Next Question'}
      </Button>
    </div>
  );
};

export default memo(QuestionFormActionButton);
