import { useParams } from 'react-router-dom';
import { usePublicFlowConfig } from '../api/public/flow-config';
import { usePublicPrimaryInsured } from '../api/public/primary-insured';
import { CONTACT_NUMBER, CONTACT_NUMBER_POLICYHOLDERS } from '../constants/contacts';
import { StrategyType } from '../types/public-flow-config.type';
import type { DefaultLocation } from '../types/route-params.type';

const usePhoneNumber = (): { phoneNumber: string } => {
  const { gid } = useParams() as DefaultLocation;
  const { data: publicFlowConfigData } = usePublicFlowConfig(gid, false);
  const { data: publicPrimaryInsuredData } = usePublicPrimaryInsured(gid, false);
  const isPolicyholder = publicPrimaryInsuredData?.primary_insured.is_policyholder;
  const isDigitalProfileStrategy = publicFlowConfigData?.flow_config?.strategy === StrategyType.DigitalProfile;

  return {
    phoneNumber: isPolicyholder && isDigitalProfileStrategy ? CONTACT_NUMBER_POLICYHOLDERS : CONTACT_NUMBER,
  };
};

export default usePhoneNumber;
