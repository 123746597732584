import { useEffect } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useCoInsured, useCreateCoInsured, useMutateCoInsured } from '../../../api/coinsured';
import SCHEMA_KEYS, { RELATIONSHIP_KIND_SPOUSE } from '../../../constants/schema-hardcoded-keys';
import Question from '../Question/Question';
import { containerCSS } from './CoInsuredForm.style';
import { getCoInsuredQuestions } from './CoInsuredForm.util';
import type { CoInsuredFormProps } from './CoInsuredForm.type';
import type { CoInsured } from '../../../types/co-insured.type';
import type { DefaultLocation } from '../../../types/route-params.type';
import type { FC } from 'react';

const CoInsuredForm: FC<CoInsuredFormProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;

  const { setValue } = useFormContext();
  const { isSubmitting, isValid } = useFormState();

  const { mutateAsync: updateCoInsured } = useMutateCoInsured(gid);
  const { mutateAsync: createCoInsured } = useCreateCoInsured(gid);

  const toWatchQuestionKeys = getCoInsuredQuestions().map((q) => q.questionKey);
  const watchedQuestions = useWatch({ name: toWatchQuestionKeys });

  const maritalStatus = useWatch({ name: SCHEMA_KEYS.personMaritalStatus });
  const isMarried = maritalStatus === 'married';

  const { data } = useCoInsured(gid);
  const coInsuredData = data?.co_insureds[0];

  useEffect(() => {
    if (coInsuredData) {
      getCoInsuredQuestions().forEach((q) => {
        const value = coInsuredData[q.questionKey as keyof CoInsured];
        setValue(q.questionKey, value);
      });
    }
  }, [coInsuredData, setValue]);

  useEffect(() => {
    const call = async (): Promise<void> => {
      const data: Omit<CoInsured, 'gid'> = {
        first_name: '',
        last_name: '',
        date_of_birth: '',
        kind: '',
        gender: '',
      };
      getCoInsuredQuestions().forEach(
        (q, index) => (data[q.questionKey as keyof Omit<CoInsured, 'gid'>] = watchedQuestions[index])
      );
      const maritalStatus = data.kind === RELATIONSHIP_KIND_SPOUSE ? 'married' : 'single';
      coInsuredData
        ? await updateCoInsured({ ...data, gid: coInsuredData.gid, marital_status: maritalStatus })
        : await createCoInsured({ ...data, marital_status: maritalStatus });
    };
    if (isSubmitting && isValid) {
      call();
    }
  }, [isSubmitting, isValid, watchedQuestions, coInsuredData, createCoInsured, updateCoInsured]);

  return (
    <div css={[containerCSS, customCSS]}>
      {getCoInsuredQuestions(isMarried).map((q) => (
        <Question {...q} key={q.questionKey} gridWidth={q.gridWidth} />
      ))}
    </div>
  );
};

export default CoInsuredForm;
