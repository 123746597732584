import { css, keyframes } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text14CSS, textCSS } from '../../../../../styles/typography.style';

const bounce = keyframes`
  0%, 30% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -5px);
  }
  70%, 100% {
    transform: translate(0, 0);
  }
`;

export const containerCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  text-align: center;

  ${mq[0]} {
    min-height: 245px;
    width: 458px;
  }
`;

export const contentCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const loadingDotsCustomCSS = css`
  width: 60px;
  height: 60px;
  padding: 27px 0;
  border-radius: 50%;
  background-color: ${theme.colors.azure95};
  box-sizing: border-box;

  > * {
    background-color: ${theme.colors.azure62};
    width: 6px;
    height: 6px;
    animation: ${bounce} 0.65s linear infinite;
    animation-delay: var(--loadingDotsDelay);
  }
`;

export const titleCSS = css`
  ${textCSS};
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 16px;
`;

export const descriptionCSS = css`
  ${text14CSS};
  font-weight: 400;
`;
