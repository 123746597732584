export const VEHICLE_CONTAINER = 'vehicle-container';
export const DRIVER_CONTAINER = 'driver-container';

export const scrollToError = (id?: string): void => {
  const elementToScroll = id ? document.getElementById(id) : document.querySelectorAll('form')[0];

  if (elementToScroll) {
    elementToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};
