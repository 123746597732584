import type { SerializedStyles } from '@emotion/react';

export interface CoInsuredQuestionProps {
  customCSS?: SerializedStyles;
  onSelectOption?: (option: CoInsuredQuestionOptions) => void;
}

export enum CoInsuredQuestionOptions {
  Yes = 'yes',
  No = 'no',
}
