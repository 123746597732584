import { css } from '@emotion/react';

export const containerCSS = css`
  display: inline-block;
`;

export const imageCSS = css`
  display: block;
  max-width: 100%;
`;
