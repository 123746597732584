import useResponsive from '../../../../../hooks/use-responsive';
import SkeletonLoader from '../../../../../shared/components/SkeletonLoader/SkeletonLoader';
import { loaderItemCSS } from './NoQuotesLoader.style';
import type { FC } from 'react';

const NoQuotesLoader: FC = () => {
  const { isMobile } = useResponsive();

  return (
    <>
      <SkeletonLoader customCSS={loaderItemCSS} absoluteWidth={isMobile ? 220 : 400} absoluteHeight={50} />
      <SkeletonLoader customCSS={loaderItemCSS} absoluteWidth={isMobile ? 250 : 500} absoluteHeight={62} />
      <SkeletonLoader customCSS={loaderItemCSS} absoluteWidth={isMobile ? 290 : 500} absoluteHeight={450} />
    </>
  );
};

export default NoQuotesLoader;
