import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { SessionResponse, UpdateSessionData } from '../../types/session.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useMutateSession = (gid: string): UseBaseMutationResult<SessionResponse, HTTPResponse, UpdateSessionData> =>
  useMutation({
    mutationFn: async (data) =>
      await api
        .put<HTTPResponse<SessionResponse>>(getApiUrl(API_PATH.session, gid), { body: { data: { session: data } } })
        .then((res) => res.data),
  });

export default useMutateSession;
