import { css } from '@emotion/react';
import theme from '../../../../styles/theme';

export const labelCSS = css`
  display: block;
  color: ${theme.colors.black};
  vertical-align: middle;
`;

export const tooltipButtonCSS = css`
  bottom: 3px;
`;
