import type { PreventDefaultWrapperProps } from './PreventDefaultWrapper.type';
import type { FC } from 'react';

const PreventDefaultWrapper: FC<PreventDefaultWrapperProps> = ({ customCSS, children }) => (
  <div role="presentation" css={customCSS} onClick={(e) => e.preventDefault()}>
    {children}
  </div>
);

export default PreventDefaultWrapper;
