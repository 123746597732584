import isVisibleByAnswers from './visibility-conditions.util';
import type { Answers } from '../types/answer.type';
import type { SessionNavigationItem, SessionResponse } from '../types/session.type';

export const getCurrentNavigationPage = (sessionResponse?: SessionResponse): SessionNavigationItem | null => {
  if (sessionResponse?.session == null) {
    return null;
  }

  const currentPageKey = getCurrentPageKey(sessionResponse);

  return sessionResponse.session.navigation.find((p) => p.key === currentPageKey) ?? null;
};

const getCurrentPageKey = (sessionResponse?: SessionResponse): string => {
  return sessionResponse?.session.current_page_key ?? '';
};

export const getCurrentSectionKey = (sessionResponse?: SessionResponse): string | null => {
  if (sessionResponse?.session == null) {
    return null;
  }

  if (sessionResponse.session.current_section_key != null) {
    return sessionResponse.session.current_section_key;
  }

  const currentPageKey = getCurrentPageKey(sessionResponse);
  const currentPageKeyIndex = sessionResponse.session.navigation.findIndex((p) => p.key === currentPageKey);

  const pageKeyNotFound = currentPageKeyIndex === -1;
  if (pageKeyNotFound) {
    return null;
  }

  return sessionResponse.session.navigation[currentPageKeyIndex].sections[0]?.key;
};

export const getNextNavigationKeys = (
  sessionResponse: SessionResponse,
  answers: Answers = {}
): { pageKey: string; sectionKey: string | null } => {
  const currentPageKey = getCurrentPageKey(sessionResponse);
  const currentSectionKey = getCurrentSectionKey(sessionResponse);
  const visiblePages = sessionResponse.session.navigation.filter((p) =>
    isVisibleByAnswers(answers, p.visibility_conditions)
  );

  const currentPageIndex = visiblePages.findIndex((p) => p.key === currentPageKey);
  if (currentPageIndex === -1) {
    throw new Error('Current page key is not correct');
  }

  if (currentSectionKey == null) {
    return {
      pageKey: visiblePages[currentPageIndex + 1].key,
      sectionKey: visiblePages[currentPageIndex + 1].sections[0]?.key ?? null,
    };
  }

  const currentSectionIndex = visiblePages[currentPageIndex].sections.findIndex((s) => s.key === currentSectionKey);
  if (currentSectionIndex === -1) {
    throw new Error('Current section key is not correct');
  }

  return visiblePages[currentPageIndex].sections[currentSectionIndex + 1]
    ? {
        pageKey: visiblePages[currentPageIndex].key,
        sectionKey: visiblePages[currentPageIndex].sections[currentSectionIndex + 1].key,
      }
    : {
        pageKey: visiblePages[currentPageIndex + 1].key,
        sectionKey: visiblePages[currentPageIndex + 1].sections[0]?.key || null,
      };
};
