import type { HTTPResponse } from '../types/api.type';
import type { RetryValue } from '@tanstack/query-core/src/retryer';
import type { QueryClientConfig } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';

const retry: RetryValue<unknown> = (failureCount, error): boolean => {
  const response = (error as AxiosError).response as AxiosResponse<HTTPResponse>;

  return failureCount < 2 && (!response || response.status >= 500);
};

const QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      throwOnError: true,
      retry,
    },
    mutations: {
      throwOnError: true,
      retry: false,
    },
  },
};

export default QUERY_CLIENT_CONFIG;
