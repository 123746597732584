import { forwardRef } from 'react';
import TooltipQuestionButton from '../TooltipQuestionButton/TooltipQuestionButton';
import {
  checkboxCSS,
  checkIconCSS,
  containerCSS,
  imageCSS,
  labelContainerCSS,
  subElementsWrapperCSS,
  subtitleCSS,
  titleCSS,
  verticalSubtitleCSS,
  verticalTitleContainerCSS,
  verticalTitleCSS,
} from './Checkbox.style';
import CheckboxIcon from './CheckboxIcon/CheckboxIcon';
import type { CheckboxProps } from './Checkbox.type';
import type { ReactElement } from 'react';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      customCSS,
      label,
      labelDescription,
      icon,
      alt = '',
      variant = 'default',
      className,
      hasError = false,
      hint,
      subElements,
      ...props
    },
    ref
  ) => {
    const hasIconOrHint = Boolean(icon ?? hint);
    const verticalDirection = variant === 'vertical' || variant === 'narrow';

    const renderLabel = (): ReactElement => {
      if (verticalDirection) {
        return (
          <div>
            <div css={verticalTitleContainerCSS}>
              {icon && <img css={imageCSS(!!props.checked)} src={icon} width={48} height={48} alt={altProp} />}
              <span css={[titleCSS, verticalTitleCSS]}>{label}</span>
            </div>
            {labelDescription && <span css={[subtitleCSS, verticalSubtitleCSS]}>{labelDescription}</span>}
          </div>
        );
      }

      if (labelDescription) {
        return (
          <div css={labelContainerCSS(hasSubElements, variant)}>
            <span css={titleCSS}>{label}</span>
            <span css={subtitleCSS}>{labelDescription}</span>
          </div>
        );
      }
      return <span css={labelContainerCSS(hasSubElements, variant)}>{label}</span>;
    };

    const renderIconOrHint = (): ReactElement | null => {
      if (icon) {
        return <img css={imageCSS(!!props.checked, true)} src={icon} width={40} height={40} alt={altProp} />;
      }

      if (hint) {
        return <TooltipQuestionButton content={hint} size={20} />;
      }

      return null;
    };

    const altProp = typeof label === 'string' ? label : alt;
    const hasSubElements = subElements && subElements.length > 0;

    return (
      <label css={customCSS?.label}>
        <input type="checkbox" css={checkboxCSS} {...props} ref={ref} data-testid={props.name + '-checkbox-input'} />
        <div
          css={[containerCSS(props.disabled, variant, hasSubElements, props.checked, hasError), customCSS?.container]}
          className={className}
        >
          <CheckboxIcon
            customCSS={checkIconCSS(hasError, variant, hasSubElements, Boolean(icon))}
            checked={!!props.checked}
            disabled={props.disabled}
            testId={props.name + '-checkbox-icon'}
          />
          {renderLabel()}
          {hasIconOrHint && !verticalDirection && renderIconOrHint()}
          {hasSubElements && <div css={subElementsWrapperCSS}>{subElements}</div>}
        </div>
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';
export default Checkbox;
