import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  margin-bottom: 24px;

  ${mq[0]} {
    margin-bottom: 32px;
  }
`;

export const headingCSS = css`
  margin-bottom: 0;

  ${mq[0]} {
    text-align: center;
    margin-bottom: 0;
  }
`;

export const subheadingCSS = css`
  color: ${theme.colors.gray80};
  margin-top: 12px;
  text-align: center;

  ${mq[0]} {
    text-align: center;
    margin-top: 16px;
  }
`;
