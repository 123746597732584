import { useState } from 'react';
import Button from '../../../../../shared/components/Button/Button';
import { noopAsync } from '../../../../../shared/utils/noop';
import { containerCSS, headerCSS, subheaderCSS, headerSuffixCSS, buttonCSS } from './NavigationCheckoutButton.style';
import type { NavigationCheckoutButtonProps } from './NavigationCheckoutButton.type';
import type { FC } from 'react';

const NavigationCheckoutButton: FC<NavigationCheckoutButtonProps> = ({
  customCSS,
  premium,
  policyTermInMonths,
  onSubmit = noopAsync,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const period = policyTermInMonths === 12 ? '1 yr' : `${policyTermInMonths} mo.`;

  const onClick = async (): Promise<void> => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={subheaderCSS}>Premium</div>
      <div css={headerCSS}>
        {premium}
        <span css={headerSuffixCSS}>/{period}</span>
      </div>
      <Button customCSS={buttonCSS} isLoading={isLoading} onClick={onClick}>
        Checkout
      </Button>
    </div>
  );
};

export default NavigationCheckoutButton;
