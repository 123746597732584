import { CardNumberElement, CardVerificationCodeElement, TextElement } from '@basis-theory/basis-theory-react';
import { useRef, useState } from 'react';
import ValidationError from '../../../shared/components/ValidationError/ValidationError';
import noop from '../../../shared/utils/noop';
import { QuestionType } from '../../../types/question.type';
import QuestionLabel from '../QuestionWrapper/QuestionLabel/QuestionLabel';
import { QuestionLabelWrapper } from '../QuestionWrapper/QuestionLabel/QuestionLabel.type';
import {
  activeOutlineCSS,
  containerCSS,
  elementStyleCSS,
  inputWrapperCSS,
  labelCSS,
  errorCSS,
} from './BTQuestion.style';
import { BT_QUESTION_ERROR_MESSAGES } from './BTQuestion.util';
import type { BTQuestionProps } from './BTQuestion.type';
import type { ElementEventListener, TextElementEvents } from '@basis-theory/basis-theory-js/types/elements';
import type { FC, ReactElement } from 'react';

const BTQuestion: FC<BTQuestionProps> = ({
  customCSS,
  questionKey,
  type,
  label,
  placeholder,
  showError,
  onFieldValidationChange = noop,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const isFieldValid = useRef<boolean>();
  const hasError = Boolean(showError && errorMessage);

  const sendValidationEvent = (valid: boolean): void => {
    if (isFieldValid.current !== valid) {
      isFieldValid.current = valid;
      onFieldValidationChange(valid);
    }
  };

  const onReady = (): void => {
    setIsLoading(false);
  };

  const onFocus = (): void => {
    setIsFocused(true);
  };

  const onBlur = (): void => {
    setIsFocused(false);
  };

  const onChange: ElementEventListener<TextElementEvents, 'change'> = (e): void => {
    switch (true) {
      case e.empty:
        setErrorMessage(BT_QUESTION_ERROR_MESSAGES.Required);
        sendValidationEvent(false);
        break;
      case e.errors?.length && !!e.errors.find((error) => error.type === 'incomplete'):
        setErrorMessage(BT_QUESTION_ERROR_MESSAGES.Incomplete);
        sendValidationEvent(false);
        break;
      case e.errors?.length && !!e.errors.find((error) => error.type === 'invalid'):
        setErrorMessage(BT_QUESTION_ERROR_MESSAGES.Invalid);
        sendValidationEvent(false);
        break;
      default:
        setErrorMessage(null);
        sendValidationEvent(true);
    }
  };

  const renderElement = (): ReactElement => {
    const baseProps = {
      id: questionKey,
      style: elementStyleCSS,
      placeholder,
      onReady,
      onBlur,
      onFocus,
      onChange,
    };
    switch (type) {
      case QuestionType.CardNumber:
        return <CardNumberElement {...baseProps} iconPosition="right" />;
      case QuestionType.CardSecurityCode:
        return <CardVerificationCodeElement {...baseProps} />;
      default:
        return <TextElement {...baseProps} />;
    }
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <QuestionLabel customCSS={labelCSS} keyName={questionKey} wrapperElement={QuestionLabelWrapper.Div}>
        {label}
      </QuestionLabel>
      <div css={inputWrapperCSS(isLoading, hasError, isFocused)}>
        {renderElement()}
        {isFocused && <div css={activeOutlineCSS(isFocused, hasError)} />}
      </div>
      <ValidationError customCSS={errorCSS} heading={label} visible={hasError}>
        {errorMessage}
      </ValidationError>
    </div>
  );
};

export default BTQuestion;
