import CalendarIcon from '../../../../../assets/calendar-icon.svg?react';
import GlobeIcon from '../../../../../assets/globe.svg?react';
import useResponsive from '../../../../../hooks/use-responsive';
import Button from '../../../../../shared/components/Button/Button';
import noop from '../../../../../shared/utils/noop';
import { getScheduledCallTime, getScheduledCallTimezone } from '../../../../../utils/scheduled-time-util';
import { buttonCSS, containerCSS, detailsColCSS, timeItemCSS } from './ScheduledTimeBlock.style';
import type { ScheduledTimeBlockProps } from './ScheduledTimeBlock.type';
import type { FC } from 'react';

const ScheduledTimeBlock: FC<ScheduledTimeBlockProps> = ({
  customCSS,
  timeZoneName,
  scheduledTime,
  onActionButtonClick = noop,
}) => {
  const { isMobile } = useResponsive();
  const scheduledTimeFormatted = getScheduledCallTime(scheduledTime, timeZoneName);
  const timeZoneFormatted = getScheduledCallTimezone(scheduledTime, timeZoneName);

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={detailsColCSS}>
        <div css={timeItemCSS}>
          <CalendarIcon width={24} height={24} />
          <span>{scheduledTimeFormatted}</span>
        </div>
        <div css={timeItemCSS}>
          <GlobeIcon width={24} height={24} />
          <span>{timeZoneFormatted}</span>
        </div>
      </div>
      <div>
        <Button customCSS={buttonCSS} variant="secondary" fullWidth={isMobile} onClick={onActionButtonClick}>
          Stick to this time
        </Button>
      </div>
    </div>
  );
};

export default ScheduledTimeBlock;
