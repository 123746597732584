import useResponsive from '../../../hooks/use-responsive';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader';
import {
  headingCSS,
  inputCSS,
  labelCSS,
  radioWrapperCSS,
  workflowCSS,
  workflowHeadingCSS,
} from './QuestionsFormLoading.style';
import type { QuestionsFormLoadingProps } from './QuestionsFormLoading.type';
import type { FC } from 'react';

const QuestionFormLoading: FC<QuestionsFormLoadingProps> = ({ customCSS }) => {
  const { isMobile } = useResponsive();

  return (
    <div css={customCSS} role="status" aria-label="Loading">
      <SkeletonLoader customCSS={headingCSS} absoluteHeight={isMobile ? 32 : 40} minWidth={60} maxWidth={90} />
      <div css={workflowCSS}>
        <SkeletonLoader
          customCSS={workflowHeadingCSS}
          absoluteHeight={isMobile ? 24 : 32}
          minWidth={60}
          maxWidth={90}
        />

        <SkeletonLoader customCSS={labelCSS} absoluteHeight={16} minWidth={40} maxWidth={80} />
        <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />

        <SkeletonLoader customCSS={labelCSS} absoluteHeight={16} minWidth={40} maxWidth={80} />
        <div css={radioWrapperCSS}>
          <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />
          <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />
        </div>

        <SkeletonLoader customCSS={labelCSS} absoluteHeight={16} minWidth={40} maxWidth={80} />
        <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />
      </div>
    </div>
  );
};

export default QuestionFormLoading;
