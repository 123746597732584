import * as Sentry from '@sentry/react';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useContactFlows } from '../../../../../api/contact-flows';
import { usePage } from '../../../../../api/page';
import { useCreateCustomerChoice } from '../../../../../api/people';
import useEffectivePolicy from '../../../../../api/policy/use-effective-policy';
import { usePrimaryInsured } from '../../../../../api/primary-insured';
import { useInitialQuote } from '../../../../../api/quote';
import { useSession } from '../../../../../api/session';
import { SEGMENT } from '../../../../../constants/analytics';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../../constants/full-story';
import {
  CALENDLY_ID,
  CALENDLY_PREVIEW_DETAILS_ID,
} from '../../../../../hooks/use-init-calendly/use-init-calendly.util';
import usePhoneNumber from '../../../../../hooks/use-phone-number';
import { useSegment } from '../../../../../hooks/use-segment';
import noop from '../../../../../shared/utils/noop';
import format from '../../../../../utils/format.util';
import { scrollToNode } from '../../../../../utils/scroll.util';
import CalendlyBundleScheduledMessage from '../CalendlyBundleScheduledMessage/CalendlyBundleScheduledMessage';
import DigitalProfileBundleQuoteCoverages from '../DigitalProfileBundleQuoteCoverages/DigitalProfileBundleQuoteCoverages';
import DigitalProfileBundleQuoteDetails from '../DigitalProfileBundleQuoteDetails/DigitalProfileBundleQuoteDetails';
import DigitalProfileQuotePageHeading from '../DigitalProfileQuotePageHeading/DigitalProfileQuotePageHeading';
import MaticHelpBlock from '../MaticHelpBlock/MaticHelpBlock';
import QuoteCardWrapper from '../QuoteCardWrapper/QuoteCardWrapper';
import QuotePageScheduleCall from '../QuotePageScheduleCall/QuotePageScheduleCall';
import { cardCSS, quoteCardCSS } from './DigitalProfileBundleQuoteContent.style';
import type { DigitalProfileBundleQuoteContentProps } from './DigitalProfileBundleQuoteContent.type';
import type { Address } from '../../../../../types/address.type';
import type { DigitalProfileQuote } from '../../../../../types/quote.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const DigitalProfileBundleQuoteContent: FC<DigitalProfileBundleQuoteContentProps> = ({
  quotes,
  quotesGroup,
  onPhoneClicked = noop,
  onScheduleCallClick = noop,
}) => {
  const { gid } = useParams() as DefaultLocation;
  const { track } = useSegment();
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData } = usePage(gid, currentPageKey);
  const { data: primaryInsuredData } = usePrimaryInsured(gid);
  const { mutateAsync: createCustomerChoice } = useCreateCustomerChoice();
  const { data: initialQuote } = useInitialQuote<DigitalProfileQuote>(gid);
  const { data: effectivePolicy } = useEffectivePolicy(gid);
  const address = (pageData?.answers?.property_address as Address) || primaryInsuredData?.person.mailing_address || '';
  const finalQuoteViewedRef = useRef(false);

  const { data: contactFlowData } = useContactFlows(gid);
  const { phoneNumber } = usePhoneNumber();

  const lastContactFlow = contactFlowData?.contact_flows[0];

  useEffect(() => {
    if (!finalQuoteViewedRef.current && quotes.length) {
      finalQuoteViewedRef.current = true;
      track(SEGMENT.events.finalQuoteViewed, {
        quotes: quotes.map((q) => q.gid),
      });
    }
  }, [track, quotes]);

  const onScheduleCall = (): void => {
    onScheduleCallClick();
    scrollToNode(lastContactFlow ? CALENDLY_PREVIEW_DETAILS_ID : CALENDLY_ID);
    onCustomerChoiceCreate();
  };

  const onCustomerChoiceCreate = (): void => {
    if (!sessionData?.session.engagement_gid) return;

    quotes.forEach((quote) => {
      const customerChoiceParams = {
        quoteGid: quote.gid,
        engagementGid: sessionData?.session.engagement_gid,
      };
      createCustomerChoice(customerChoiceParams).catch((err) => Sentry.captureException(err));
    });
  };

  return (
    <>
      <DigitalProfileQuotePageHeading
        staticHeading={{
          heading: 'Your quote is ready!',
          subheading: <span className={FS_SENSITIVE_DATA_CLASS.mask}>{format.address(address)}</span>,
        }}
      />
      <QuoteCardWrapper
        customCSS={quoteCardCSS}
        toggleContent={<DigitalProfileBundleQuoteCoverages quotes={quotes} />}
        clearPadding
      >
        <DigitalProfileBundleQuoteDetails
          initialQuote={initialQuote?.quote}
          effectivePolicy={effectivePolicy?.effective_policy}
          quotes={quotes}
          quotesGroup={quotesGroup}
          isCallScheduled={!!lastContactFlow}
          onScheduleCallClick={onScheduleCall}
        />
      </QuoteCardWrapper>
      <QuotePageScheduleCall
        heading="Like what you see? Finalize it with an agent."
        callScheduledInfoBlock={<CalendlyBundleScheduledMessage />}
        onCallScheduled={onCustomerChoiceCreate}
      />
      <MaticHelpBlock customCSS={cardCSS} phoneNumber={phoneNumber} onPhoneClick={onPhoneClicked} />
    </>
  );
};

export default DigitalProfileBundleQuoteContent;
