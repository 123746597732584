import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';
import theme from '../../styles/theme';
import { text14CSS, textCSS } from '../../styles/typography.style';

export const containerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${theme.sizes.containerQuestionsMaxWidth}px;
  padding: 15px ${theme.sizes.paddingBodyHorizontalMobileDense}px;
  margin: 0 auto;

  ${mq[0]} {
    padding-left: ${theme.sizes.questionNavDotPadding[1] + theme.sizes.paddingBodyHorizontalTablet}px;
    padding-right: ${theme.sizes.paddingBodyHorizontalTablet}px;
  }

  ${mq[1]} {
    padding-left: ${theme.sizes.questionNavDotPadding[1]}px;
    padding-right: 0;
  }
`;

export const leftColCSS = css`
  display: flex;
  align-items: center;
  ${text14CSS};
  font-weight: 700;

  ${mq[0]} {
    ${textCSS};
  }
`;

export const iconCSS = css`
  width: 24px;
  height: 24px;
  color: ${theme.colors.gray60};
  margin-right: 12px;
`;

export const buttonCSS = css`
  font-weight: 700;
`;
