import { FormElementTag, FormElementType } from '../../../../types/form-element.type';
import { QuestionType } from '../../../../types/question.type';
import {
  PERSON_AGE_VALIDATION,
  REQUIRED_VALIDATION,
} from '../../../utils/question-validations/question-validations.util';
import type { FormElementSchema, QuestionElementContent } from '../../../../types/form-element.type';

export const DOB_CONTAINER_KEY = 'simple_personal_info';

const DATE_OF_BIRTH_QUESTION: FormElementSchema<QuestionElementContent> = {
  key: 'date_of_birth_element',
  kind: FormElementType.Question,
  width: 6,
  position: 3,
  tags: [FormElementTag.TrackForbidden],
  sub_elements: [],
  visibility_conditions: [],
  feature_condition: null,
  content: {
    key: 'person_date_of_birth',
    label: 'Confirm your Date of Birth',
    type: QuestionType.Date,
    variants: [],
    validations: [REQUIRED_VALIDATION, ...PERSON_AGE_VALIDATION],
  },
};

const MARITAL_STATUS_QUESTION: FormElementSchema<QuestionElementContent> = {
  key: 'marital_status_element',
  kind: FormElementType.Question,
  width: 6,
  position: 4,
  tags: [],
  visibility_conditions: [],
  feature_condition: null,
  sub_elements: [],
  content: {
    key: 'person_marital_status',
    label: 'What is your marital status?',
    type: QuestionType.SingleSelect,
    variants: [
      {
        label: 'Single',
        value: 'single',
      },
      {
        label: 'Married',
        value: 'married',
      },
      {
        label: 'Divorced',
        value: 'divorced',
      },
    ],
    validations: [REQUIRED_VALIDATION],
  },
};

const AUTH_QUESTIONS: Array<FormElementSchema<QuestionElementContent>> = [
  DATE_OF_BIRTH_QUESTION,
  MARITAL_STATUS_QUESTION,
];

export const getElementsSchema = (params: { firstName: string; hasError: boolean }): FormElementSchema[] => {
  const navigationElement: FormElementSchema = {
    key: 'navigation_point',
    kind: FormElementType.TextBox,
    width: 12,
    position: 1,
    tags: [FormElementTag.NavigationPoint],
    visibility_conditions: [],
    feature_condition: null,
    sub_elements: [],
    content: {
      heading: `${params.firstName}, tell us a few things about yourself. `,
    },
  };

  return params.hasError
    ? [
        navigationElement,
        {
          key: 'error_message',
          kind: FormElementType.InfoBlock,
          width: 12,
          position: 2,
          tags: [FormElementTag.InfoBlockWarning],
          sub_elements: [],
          visibility_conditions: [],
          feature_condition: null,
          content: {
            key: 'error_message',
            icon_url: 'https://maticinsurance.sirv.com/olb/element_icons/error-sign.svg',
            info_block_text:
              `The date of birth entered for ${params.firstName} does not match the one provided by your mortgage ` +
              'servicer. For security purposes the birthdates need to match.',
          },
        },
        ...AUTH_QUESTIONS,
      ]
    : [navigationElement, ...AUTH_QUESTIONS];
};

export const getAuthFormQuestionKeys = (): string[] => {
  return [DATE_OF_BIRTH_QUESTION.content.key, MARITAL_STATUS_QUESTION.content.key];
};
