import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { usePage } from '../../api/page';
import { usePublicPrimaryInsured } from '../../api/public/primary-insured';
import { useSession } from '../../api/session';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader';
import analytics from '../../utils/analytics.util';
import { getPathByPageType } from '../../utils/route-path.util';

import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const SessionPath: FC = () => {
  const { gid, flow } = useParams<DefaultLocation>();
  const { data: sessionData, isPending: isSessionPending } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData, isPending: isPagePending } = usePage(gid, currentPageKey);
  const { data: publicSessionPrimaryInsuredData } = usePublicPrimaryInsured(gid);
  const [path, setPath] = useState<string>();

  useEffect(() => {
    if (pageData && gid) {
      const res = getPathByPageType(pageData?.page.page_type, gid, flow);
      setPath(res);
    }
  }, [pageData, gid, flow]);

  useEffect(() => {
    if (publicSessionPrimaryInsuredData?.primary_insured) {
      analytics.identify(publicSessionPrimaryInsuredData.primary_insured.gid, {
        email: analytics.generateTrackEmail(publicSessionPrimaryInsuredData.primary_insured.gid),
      });
      Sentry.setUser({ email: analytics.generateTrackEmail(publicSessionPrimaryInsuredData.primary_insured.gid) });
    }
  }, [publicSessionPrimaryInsuredData]);

  if (isSessionPending || isPagePending || !path) {
    return <FullPageLoader />;
  }

  return <Navigate to={path} replace />;
};

export default SessionPath;
