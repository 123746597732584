import { css } from '@emotion/react';
import theme from '../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const checkboxIconHoveredCSS = (hasError?: boolean): SerializedStyles => css`
  background-color: ${hasError ? theme.colors.white : theme.colors.azure68};
`;

export const errorContainerCSS = (checked: boolean): SerializedStyles => css`
  background-color: ${checked ? theme.colors.errorRed : theme.colors.white};
  border: 1px solid ${theme.colors.errorRed};

  &:hover {
    background-color: ${theme.colors.errorRed};
  }
`;

export const containerCSS = (checked: boolean, disabled: boolean, hasError: boolean): SerializedStyles => css`
  position: relative;
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: ${checked ? theme.colors.azure50 : theme.colors.white};
  border: 1px solid ${checked ? theme.colors.azure50 : theme.colors.black};
  border-radius: ${theme.sizes.borderRadius}px;

  &:hover {
    ${!disabled && checkboxIconHoveredCSS(hasError)};
  }

  ${hasError && errorContainerCSS(checked)};
`;

export const iconCSS = (checked: boolean): SerializedStyles => css`
  display: ${checked ? 'block' : 'none'};
  padding: 4px;
  color: ${theme.colors.white};
  width: 10px;
  height: 10px;
`;
