import { useState, type ReactNode, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import AddIcon from '../../../../assets/add.svg?react';
import Question from '../../../../questionsForm/components/Question/Question';
import { shouldHideQuestionLabel } from '../../../../questionsForm/utils/form-element-tags.util';
import { interpolateHint, interpolateTooltip } from '../../../../questionsForm/utils/interpolation/interpolation.util';
import { scrollToError } from '../../../../questionsForm/utils/questions-form.util';
import Button from '../../../../shared/components/Button/Button';
import { Card } from '../../../../shared/components/Card/Card';
import noop, { noopAsync } from '../../../../shared/utils/noop';
import FormAddListLoader from '../FormListAddLoader/FormListAddLoader';
import {
  errorMessageCSS,
  formTitleCSS,
  iconCSS,
  listItemWrapperCSS,
  titleCSS,
} from '../FormListItem/FormListItem.style';
import { actionButtonsCSS, containerCSS, formCardCSS, formWrapperCSS } from './FormListAddItem.style';
import type { FormListAddItemProps } from './FormListAddItem.type';

const FormListAddItem = <T extends Record<string, unknown>>({
  customCSS,
  label,
  addItemSchema,
  isEdit,
  title,
  type,
  asyncUrl,
  formKey,
  hasError = false,
  isDisabled = false,
  isLoading = false,
  defaultValues,
  setIsEdit = noop,
  onValidSubmit = noopAsync,
}: FormListAddItemProps<T>): ReactNode => {
  const [isAdding, setIsAdding] = useState(false);

  const methods = useForm<T>({
    shouldUnregister: true,
    shouldFocusError: false,
    mode: 'onTouched',
    defaultValues,
  });

  const onAdd = async (data: T): Promise<void> => {
    if (isAdding) return;

    setIsAdding(true);
    await onValidSubmit(data);
    setIsEdit(false);
    setIsAdding(false);
  };

  const onRemove = (): void => {
    setIsEdit(false);
  };

  useEffect(() => {
    hasError ? methods.trigger() : methods.clearErrors();
  }, [hasError, methods]);

  if (isEdit)
    return (
      <>
        <Card customCSS={formCardCSS(hasError)}>
          {isLoading && <FormAddListLoader />}
          <FormProvider {...methods}>
            {title && <div css={formTitleCSS}>{title}</div>}
            <form
              id={`new-item-form-${formKey}`}
              css={formWrapperCSS}
              onSubmit={methods.handleSubmit(onAdd, scrollToError)}
            >
              {addItemSchema.map((element) => (
                <Question
                  key={element.key}
                  questionKey={element.content.key}
                  label={interpolateTooltip(element.content.label)}
                  hideLabel={shouldHideQuestionLabel(element)}
                  description={element.content.description}
                  hint={interpolateHint(element.content.hint, {})}
                  type={element.content.type}
                  placeholder={element.content.placeholder}
                  icon={element.content.icon}
                  variants={element.content.variants}
                  validations={element.content.validations}
                  gridWidth={element.width}
                  tags={element.tags}
                  dense={true}
                  url={asyncUrl}
                />
              ))}
            </form>
          </FormProvider>

          <div css={actionButtonsCSS}>
            <Button variant="secondary" onClick={onRemove} disabled={isLoading}>
              Cancel
            </Button>

            <Button form={`new-item-form-${formKey}`} type="submit" disabled={isLoading}>
              Add
            </Button>
          </div>
        </Card>
        {hasError && <div css={errorMessageCSS}>Please submit the {type} information to proceed.</div>}
      </>
    );

  return (
    <button
      css={[listItemWrapperCSS, containerCSS(hasError, isDisabled), customCSS]}
      onClick={() => !isDisabled && setIsEdit(true)}
    >
      <AddIcon css={iconCSS(isDisabled, hasError)} width={24} height={24} />
      <div css={titleCSS}>{label}</div>
    </button>
  );
};

export default FormListAddItem;
