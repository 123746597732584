import type { FeatureFlag, ExperimentVariation } from '../types/feature-flag.type';

export const getFeatureFlagConfig = (
  featureFlags: FeatureFlag[] = [],
  name: string
): {
  name: string | undefined;
  enabled: boolean;
  variant: ExperimentVariation | null | undefined;
} => {
  const featureFlag = featureFlags.find((featureFlag) => featureFlag.name === name);

  return { enabled: !!featureFlag?.enabled, variant: featureFlag?.variant, name: featureFlag?.name };
};
