import { SEGMENT } from '../../../constants/analytics';
import SCHEMA_KEYS from '../../../constants/schema-hardcoded-keys';
import { FeatureVisibilityOption } from '../../../types/feature_condition.type';
import { FormElementType } from '../../../types/form-element.type';
import { QuestionType } from '../../../types/question.type';
import isVisibleByAnswers from '../../../utils/visibility-conditions.util';
import { hasAnswers } from '../../utils/questions-form.util';
import type { RenderSectionContainer } from './SectionForm.type';
import type { FeatureFlag } from '../../../../src/types/feature-flag.type';
import type { Answers } from '../../../types/answer.type';
import type { FeatureCondition } from '../../../types/feature_condition.type';
import type { FormElementSchema, QuestionElementContent } from '../../../types/form-element.type';
import type { SectionContainer } from '../../../types/section.type';
import type { VisibilityCondition } from '../../../types/visibility-condition.type';

export const CUSTOM_CONTAINERS_KEYS = ['mortgage_info', 'partner_information'];

const OPEN_QUESTION_TYPES = [QuestionType.Radio, QuestionType.SingleSelect];

export const getLastContainerIndex = (containerKeys: string[], lastContainerKey?: string): number => {
  if (!lastContainerKey) {
    return 0;
  }

  const possibleIndex = containerKeys.indexOf(lastContainerKey);

  return possibleIndex !== -1 ? possibleIndex : 0;
};

export const trackFeatureFlagVisibility = (
  track: (eventName: string, config?: Record<string, unknown> | undefined) => void,
  containers: SectionContainer[],
  featureFlags?: FeatureFlag[]
): void => {
  const onTrack = (featureFlag: string, featureVariant: string): void => {
    track(SEGMENT.events.experimentViewed, {
      experiment_id: featureFlag,
      variation_id: featureVariant,
    });
  };

  const trackFeature = (featureFlagName: string | undefined): void => {
    if (!featureFlagName) return;

    const featureToggle = featureFlags?.find((ft) => ft.name === featureFlagName);
    if (featureToggle?.enabled) {
      onTrack(featureToggle.name, featureToggle.variant as string);
    }
  };

  containers.forEach((container) => {
    trackFeature(container.feature_condition?.feature_flag);
    container.elements.forEach((e) => {
      trackFeature(e.feature_condition?.feature_flag);
    });
  });
};

export const isContainerVisibleByVisibilityCondition = (
  visibilityConditions: VisibilityCondition[][],
  answers: Answers,
  hasVisibleQuestions: boolean,
  apiAnswers: Answers,
  containerKey: string
): boolean => {
  if (visibilityConditions.length) {
    // @TODO very bad solution, need to be refactored according to the https://maticinsurance.atlassian.net/browse/CX-3477
    if (containerKey === SCHEMA_KEYS.bundleContainerKey) {
      return isVisibleByAnswers(apiAnswers, visibilityConditions);
    }
    return isVisibleByAnswers(answers, visibilityConditions);
  }

  return hasVisibleQuestions;
};

export const isVisibleByFeatureCondition = (
  featureCondition: FeatureCondition | null,
  featureFlags?: FeatureFlag[]
): boolean => {
  if (!featureCondition) return true;

  const featureToggle = featureFlags?.find((ft) => ft.name === featureCondition?.feature_flag);

  if (!featureToggle) {
    return featureCondition.visibility !== FeatureVisibilityOption.Visible;
  }
  if (!featureToggle.enabled) {
    return featureCondition.visibility === FeatureVisibilityOption.Hidden;
  }

  return featureCondition.variation === featureToggle.variant
    ? featureCondition.visibility === FeatureVisibilityOption.Visible
    : featureCondition.visibility === FeatureVisibilityOption.Hidden;
};

export const getVisibleSubElements = (element: FormElementSchema, pageAnswers: Answers): FormElementSchema => ({
  ...element,
  sub_elements: element.sub_elements.filter((subElement: FormElementSchema) =>
    isVisibleByAnswers(pageAnswers, subElement.visibility_conditions)
  ),
});

const canSubmitWithoutButton = (container: SectionContainer): boolean => {
  const containerHasCustomElements = CUSTOM_CONTAINERS_KEYS.includes(container.key);
  const containerHasDisclosureElement = container.elements.some((e) => e.kind === FormElementType.Disclosure);
  const containerQuestionElements = container.elements.filter((e) => e.kind === FormElementType.Question);
  const lastQuestionElement = containerQuestionElements[containerQuestionElements.length - 1] as
    | FormElementSchema<QuestionElementContent>
    | undefined;

  return (
    !containerHasCustomElements &&
    !containerHasDisclosureElement &&
    !!lastQuestionElement &&
    OPEN_QUESTION_TYPES.includes(lastQuestionElement.content.type) &&
    !lastQuestionElement.sub_elements?.length &&
    !lastQuestionElement.visibility_conditions.length
  );
};

export const getAllContainers = (
  containers: SectionContainer[],
  pageAnswers: Answers,
  featureFlags: FeatureFlag[],
  apiAnswers: Answers
): RenderSectionContainer[] =>
  containers.map((container): RenderSectionContainer => {
    const visibleElements = container.elements
      .filter((e) => isVisibleByFeatureCondition(e.feature_condition, featureFlags))
      .filter((e) => isVisibleByAnswers(pageAnswers, e.visibility_conditions))
      .map((e) => getVisibleSubElements(e, pageAnswers));

    const visibleQuestions = visibleElements.filter((e) => e.kind === FormElementType.Question);
    const visibleQuestionKeys = visibleQuestions.map((q) => (q.content as QuestionElementContent).key);

    const isAllowRender =
      isContainerVisibleByVisibilityCondition(
        container.visibility_conditions,
        pageAnswers,
        visibleQuestions.length > 0,
        apiAnswers,
        container.key
      ) && isVisibleByFeatureCondition(container.feature_condition, featureFlags);

    return {
      key: container.key,
      elements: visibleElements,
      hasAnswers: hasAnswers(visibleQuestionKeys, pageAnswers),
      submitWithoutButton: canSubmitWithoutButton(container),
      isAllowRender,
    };
  });

export const shouldGoToNextContainer = (pageAnswers: Answers, triggerQuestionKey?: string | null): boolean => {
  return !!triggerQuestionKey && hasAnswers([triggerQuestionKey], pageAnswers);
};

export const getNextVisibleContainer = (
  containers: RenderSectionContainer[],
  currentContainerKey: string
): RenderSectionContainer | null => {
  const currentContainerIndex = containers.findIndex((container) => container.key === currentContainerKey);
  return containers.filter((container, index) => index > currentContainerIndex && container.isAllowRender)[0] || null;
};
