import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';

export const containerCSS = css`
  min-width: 375px;
  text-align: center;

  ${mq[0]} {
    min-width: 500px;
  }
`;

export const startOverMessageCSS = css`
  margin: 8px 0 32px;
  font-weight: 500;
  text-align: center;

  p {
    margin: 0;
  }
`;

export const foreverLockedIconCSS = css`
  margin-bottom: 16px;
`;
