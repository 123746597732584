import { css } from '@emotion/react';
import theme from '../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const addressMenuCSS = (isOpen: boolean): SerializedStyles => css`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray30};
  border-radius: ${theme.sizes.borderRadius}px;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: ${theme.sizes.controlHeight + 4}px;
  width: 100%;
  z-index: 2;
  padding: 4px 0;

  ${isOpen &&
  css`
    display: block;
  `};
`;

export const menuListCSS = css`
  max-height: 400px;
  overflow: auto;
`;

export const addressCSS = css`
  position: relative;
`;

export const loadingCSS = css`
  color: ${theme.colors.gray60};
  padding: 8px 20px;
  text-align: center;
`;

export const errorCSS = (isVisible: boolean): SerializedStyles => css`
  height: 0;
  margin-top: 0;
  transition: height .3s ease-in-out, margin-top .3s ease-in-out;
  will-change: height, margin-top;

  ${
    isVisible &&
    css`
      height: 20px;
      margin-top: 8px;
    `
  };
    
  }
`;
