import Button from '../../../../../shared/components/Button/Button';
import { Card } from '../../../../../shared/components/Card/Card';
import { buttonCSS, containerCSS, headingCSS, iconCSS, subHeadingCSS } from './CTACard.style';
import type { CTACardProps } from './CTACard.type';
import type { FC } from 'react';

const CTACard: FC<CTACardProps> = ({ customCSS, onButtonClick, icon: Icon, heading, subHeading, buttonText }) => {
  return (
    <Card customCSS={[containerCSS, customCSS]}>
      <Icon css={iconCSS} />
      <div>
        <div css={headingCSS}>{heading}</div>
        <div css={subHeadingCSS}>{subHeading}</div>
        <Button css={buttonCSS} variant="secondary" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </div>
    </Card>
  );
};

export default CTACard;
