import useResponsive from '../../../../../hooks/use-responsive';
import SkeletonLoader from '../../../../../shared/components/SkeletonLoader/SkeletonLoader';
import { headingCSS, inputCSS, labelCSS } from './CheckoutFormLoading.style';
import type { CheckoutFormLoadingProps } from './CheckoutFormLoading.type';
import type { FC } from 'react';

const CheckoutFormLoading: FC<CheckoutFormLoadingProps> = ({ customCSS }) => {
  const { isMobile } = useResponsive();
  return (
    <div css={customCSS} role="status" aria-label="Loading">
      <SkeletonLoader customCSS={headingCSS} absoluteHeight={24} minWidth={60} maxWidth={90} />

      <SkeletonLoader customCSS={labelCSS} absoluteHeight={16} minWidth={40} maxWidth={80} />
      <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />

      <SkeletonLoader absoluteHeight={isMobile ? 44 : 60} width={100} />
    </div>
  );
};

export default CheckoutFormLoading;
