import { isIos } from '../shared/utils/device.util';
import type { TouchEventHandler } from 'react';

export const scrollToNode = (id: string): void => {
  const node = document.getElementById(id);
  node?.scrollIntoView({ behavior: 'smooth' });
};

export const onIosTouchMoveFocusedInputScrollFix: TouchEventHandler = (event) => {
  if (isIos()) {
    const inputs = event.currentTarget.getElementsByTagName('input');

    for (let i = 0; i < (inputs || []).length; i++) {
      inputs[i].blur();
    }
  }
};

export const resetScroll = (): void => {
  window.scrollTo(0, 0);
};
