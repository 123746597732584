import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import { text20CSS, textCSS } from '../../../../styles/typography.style';

export const containerCSS = css`
  ${mq[0]} {
    max-width: 400px;
    width: 400px;
  }
`;

export const contentCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const actionButtonCSS = css`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

export const buttonIconCSS = css`
  margin-right: 12px;
  position: relative;
  bottom: 5px;
  width: 18px;
`;

export const scheduleCallCSS = css`
  margin-top: 16px;
  span {
    ${actionButtonCSS};
  }
  width: 100%;
`;

export const mainImageCSS = css`
  width: 80px;
`;

export const titleCSS = css`
  ${text20CSS};
  line-height: 28px;
  font-weight: 700;
`;

export const subtitleCSS = css`
  ${textCSS};
  line-height: 20px;
  margin-bottom: 32px;
  text-align: center;
`;
