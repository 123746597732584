import { Controller } from 'react-hook-form';
import { isTrackForbidden } from '../../../utils/form-element-tags.util';
import {
  hardcodedValidations,
  getValidationRules,
} from '../../../utils/question-validations/question-validations.util';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import Address from './Address';
import type { AddressAnswer } from '../../../../types/answer.type';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const AddressQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  description,
  placeholder,
  hint,
  icon,
  workflowHeading,
  isDisabled,
  validations,
  gridWidth,
  dense,
  tags = [],
}) => {
  return (
    <Controller
      name={questionKey}
      rules={getValidationRules(validations, { isValidAddress: hardcodedValidations.isValidAddress })}
      render={({ field: { onChange, value, onBlur, ref }, fieldState: { isTouched } }) => {
        const onSelectChange = (v: AddressAnswer | string): void => {
          onChange(v);
        };

        return (
          <>
            <QuestionWrapper
              questionKey={questionKey}
              label={label}
              hideLabel={hideLabel}
              hint={hint}
              description={description}
              icon={icon}
              workflowHeading={workflowHeading}
              isDisabled={isDisabled}
              gridWidth={gridWidth}
              inputProps={{
                inputId: questionKey,
                errorHeading: label || workflowHeading,
                value,
                placeholder,
                onBlur,
                isDisabled,
                isPrefilled: !!value && !isTouched,
                dense,
                trackingForbidden: isTrackForbidden(tags),
                onValidEntry: onSelectChange,
                componentRef: ref,
              }}
              inputComponent={Address}
            />
          </>
        );
      }}
    />
  );
};

export default AddressQuestion;
