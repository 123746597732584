import { MARITAL_STATUS_VARIANTS, RELATIONSHIP_VARIANTS } from '../../../../../constants/schema-hardcoded-keys';
import { REQUIRED_VALIDATION } from '../../../../../questionsForm/utils/question-validations/question-validations.util';
import { FormElementTag } from '../../../../../types/form-element.type';
import { QuestionType } from '../../../../../types/question.type';
import type { QuestionProps } from '../../../../../questionsForm/components/Question/Question.type';

export const CO_INSURED_QUESTIONS: QuestionProps[] = [
  {
    questionKey: 'first_name',
    type: QuestionType.Text,
    label: 'First Name of Co-insured?',
    placeholder: 'first name',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 6,
  },
  {
    questionKey: 'last_name',
    type: QuestionType.Text,
    label: 'Last Name of Co-insured?',
    placeholder: 'last name',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 6,
    tags: [FormElementTag.TrackForbidden],
  },
  {
    questionKey: 'date_of_birth',
    type: QuestionType.Date,
    label: 'Date of birth',
    placeholder: 'MM/DD/YYYY',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 12,
    tags: [FormElementTag.TrackForbidden],
  },
  {
    questionKey: 'marital_status',
    type: QuestionType.SingleSelect,
    label: 'What is their Marital Status?',
    placeholder: '- choose option -',
    variants: MARITAL_STATUS_VARIANTS,
    validations: [REQUIRED_VALIDATION],
    gridWidth: 6,
  },
  {
    questionKey: 'kind',
    type: QuestionType.SingleSelect,
    label: 'Relationship to Co-insured?',
    placeholder: '- choose option -',
    variants: RELATIONSHIP_VARIANTS,
    validations: [REQUIRED_VALIDATION],
    gridWidth: 6,
  },
];
