import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { ContactFlowsResponse } from '../../types/contact-flows';
import type { QueryObserverResult } from '@tanstack/react-query';

const useContactFlows = (gid: string, enabled: boolean = true): QueryObserverResult<ContactFlowsResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.contactFlows, gid],
    queryFn: async () =>
      await api.get<HTTPResponse<ContactFlowsResponse>>(getApiUrl(API_PATH.contactFlows, gid)).then((res) => res.data),
    enabled,
  });

export default useContactFlows;
