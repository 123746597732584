import { Controller } from 'react-hook-form';
import Checkbox from '../../../../../shared/components/Checkbox/Checkbox';
import noop from '../../../../../shared/utils/noop';
import { isValueSpecificType } from '../../../../utils/answers/answers.util';
import { getValidationRules } from '../../../../utils/question-validations/question-validations.util';
import { CHECKBOX_VALUE } from './CheckboxInput.util';
import type { CheckboxInputProps } from './CheckboxInput.type';
import type { FC } from 'react';

const CheckboxInput: FC<CheckboxInputProps> = ({
  name,
  label = '',
  labelDescription,
  icon,
  isDisabled,
  hasError,
  validations,
  variant,
  subElements,
  onValidatePreviousInputs = noop,
}) => {
  return (
    <Controller
      name={name}
      rules={getValidationRules(validations)}
      render={({ field }) => {
        const inputValue = isValueSpecificType<string | string[] | number>(field.value, ['string', 'array', 'number'])
          ? field.value
          : CHECKBOX_VALUE.unchecked;
        return (
          <Checkbox
            {...field}
            label={label}
            labelDescription={labelDescription}
            icon={icon}
            disabled={isDisabled}
            hasError={hasError}
            checked={field.value === CHECKBOX_VALUE.checked}
            value={inputValue}
            variant={variant}
            onChange={(event) => {
              field.onChange(event.target.checked ? CHECKBOX_VALUE.checked : CHECKBOX_VALUE.unchecked);
              onValidatePreviousInputs();
            }}
            subElements={subElements}
            onBlur={field.onBlur}
          />
        );
      }}
    />
  );
};

export default CheckboxInput;
