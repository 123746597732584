import { useCallback } from 'react';
import { useFormContext, get } from 'react-hook-form';
import { useQuestionsFormState } from '../../../pages/questions/contexts/questions-context';
import ValidationError from '../../../shared/components/ValidationError/ValidationError';
import QuestionLabel from './QuestionLabel/QuestionLabel';
import {
  containerCSS,
  labelCSS,
  errorCSS,
  innerWrapperCSS,
  labelWithIconCSS,
  descriptionWithIconCSS,
  iconLabelWrapperCSS,
} from './QuestionWrapper.style';
import type { QuestionWrapperProps } from './QuestionWrapper.type';
import type { FC, ReactElement } from 'react';

export const QuestionWrapper: FC<QuestionWrapperProps> = ({
  customCSS,
  hint,
  description,
  labelTag,
  hideLabel = false,
  label,
  icon,
  workflowHeading,
  questionKey,
  inputComponent: InputComponent,
  inputProps,
  isDisabled,
  gridWidth,
}) => {
  const {
    formState: { errors },
    trigger,
  } = useFormContext();
  const { state } = useQuestionsFormState();

  const keyName = questionKey;
  const error = get(errors, keyName) || '';

  const triggerPreviousInputsValidation = useCallback(() => {
    const index = state.questionsKeys.findIndex((el) => el === keyName);
    const previousQuestionsKeys = [...state.questionsKeys].slice(0, index);
    if (previousQuestionsKeys.length > 0) {
      trigger(previousQuestionsKeys);
    }
  }, [state, trigger, keyName]);

  const renderInnerWithIcon = (): ReactElement => (
    <div css={innerWrapperCSS}>
      <div css={iconLabelWrapperCSS}>
        <img src={icon} height={48} alt="" />
        {!hideLabel && label && (
          <QuestionLabel customCSS={labelWithIconCSS} wrapperElement={labelTag} keyName={keyName}>
            {label}
          </QuestionLabel>
        )}
      </div>

      {description && <div css={descriptionWithIconCSS}>{description}</div>}

      <InputComponent
        {...inputProps}
        dense
        hasError={!!error?.message}
        onValidatePreviousInputs={triggerPreviousInputsValidation}
      />
      <ValidationError customCSS={errorCSS} heading={label ?? workflowHeading} visible={!!error?.message}>
        {error?.message}
      </ValidationError>
    </div>
  );

  const renderDefaultInner = (): ReactElement => (
    <>
      {!hideLabel && label && (
        <QuestionLabel customCSS={labelCSS} wrapperElement={labelTag} keyName={keyName} tooltipText={hint}>
          {label}
        </QuestionLabel>
      )}
      <InputComponent
        {...inputProps}
        hasError={!!error?.message}
        onValidatePreviousInputs={triggerPreviousInputsValidation}
      />
      <ValidationError customCSS={errorCSS} heading={label ?? workflowHeading} visible={!!error?.message}>
        {error?.message}
      </ValidationError>
    </>
  );

  return (
    <div css={[containerCSS(isDisabled, gridWidth), customCSS]} data-testid={`question-input-${keyName}`}>
      {icon ? renderInnerWithIcon() : renderDefaultInner()}
    </div>
  );
};

export default QuestionWrapper;
