export interface AddressAnswer {
  city: string;
  line1: string;
  state: string;
  zip: string;
  time_zone_name?: string;
}

export type AnswerValue = string | string[] | number | AddressAnswer | null | boolean;

export type Answers = Record<string, AnswerValue>;

export interface AnswersResponse {
  answers: Answers;
}

export const ANSWER_BOOLEAN_VALUE = {
  yes: 'yes',
  no: 'no',
};
