import { useCallback } from 'react';
import { Controller, useFormState, get, useFormContext } from 'react-hook-form';
import { useQuestionsFormState } from '../../../pages/questions/contexts/questions-context';
import Checkbox from '../../../shared/components/Checkbox/Checkbox';
import ValidationError from '../../../shared/components/ValidationError/ValidationError';
import { getValidationRules } from '../../utils/question-validations/question-validations.util';
import { containerCSS, errorCSS, inputCSS, labelCSS } from './DisclosureComponent.style';
import type { DisclosureComponentProps } from './DisclosureComponent.type';
import type { FC } from 'react';
import type { FieldError } from 'react-hook-form/dist/types/errors';

const DisclosureComponent: FC<DisclosureComponentProps> = ({
  customCSS,
  content,
  componentKey,
  disclosureGid,
  validations,
  isLineHidden,
}) => {
  const { trigger } = useFormContext();
  const { errors } = useFormState({ name: componentKey, exact: true });
  const error: FieldError = get(errors, componentKey);

  const { state } = useQuestionsFormState();

  const getValue = (checked: boolean): string | boolean => {
    return checked ? disclosureGid : false;
  };

  const triggerPreviousInputsValidation = useCallback(() => {
    const index = state.questionsKeys.findIndex((el) => el === componentKey);
    const previousQuestionsKeys = [...state.questionsKeys].slice(0, index);
    if (previousQuestionsKeys.length > 0) {
      trigger(previousQuestionsKeys);
    }
  }, [state, trigger, componentKey]);

  return (
    <div css={[containerCSS(isLineHidden), customCSS]}>
      <div>
        <Controller
          name={componentKey}
          rules={getValidationRules(validations)}
          render={({ field }) => (
            <Checkbox
              {...field}
              id={componentKey}
              customCSS={{ container: inputCSS }}
              label={<span css={labelCSS} dangerouslySetInnerHTML={{ __html: content }} />}
              hasError={!!error}
              checked={Boolean(field.value)}
              value={field.name}
              onChange={(event) => {
                field.onChange(getValue(event.target.checked));
                triggerPreviousInputsValidation();
              }}
            />
          )}
        />
        <ValidationError customCSS={errorCSS} heading="Disclosures" visible={!!error}>
          {error?.message}
        </ValidationError>
      </div>
    </div>
  );
};

export default DisclosureComponent;
