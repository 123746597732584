import { useState } from 'react';
import ToggleArrow from '../../../../assets/toggle-arrow.svg?react';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import useResponsive from '../../../../hooks/use-responsive';
import { isOtherIcon } from '../../../../utils/icons.util';
import { getImgUrlWithParams } from '../../SirvImage/SirvImage.util';
import { radioButtonDotCSS, radioContainerInnerCSS, radioIconCSS } from '../RadioButton/RadioButton.style';
import { RADIO_BUTTON_CLASSNAMES } from '../RadioButton/RadioButton.util';

import {
  contentCSS,
  descriptionCSS,
  dotCSS,
  labelContainerCSS,
  labelCSS,
  nestedQuestionsCSS,
  otherCSS,
  photoCSS,
  toggleCSS,
} from './RadioPhotoView.style';
import type { RadioPhotoViewProps } from './RadioPhotoView.type';
import type { FC } from 'react';

const RadioPhotoView: FC<RadioPhotoViewProps> = ({ customCSS, label, description, photoUrl, hasError, children }) => {
  const [isClosed, setIsClosed] = useState(true);
  const { isMobile } = useResponsive();
  const applyClosed = isMobile && isClosed;
  const sirvUrl = getImgUrlWithParams({ url: photoUrl ?? SIRV_IMAGES.photoPlaceholder, width: 500 });
  const withIcon = isOtherIcon(sirvUrl);

  return (
    <div className={RADIO_BUTTON_CLASSNAMES.inner} css={[radioContainerInnerCSS(hasError), customCSS]}>
      <div css={contentCSS(applyClosed)}>
        <div className={RADIO_BUTTON_CLASSNAMES.dot} css={[radioButtonDotCSS(), dotCSS(applyClosed)]} />
        {isMobile && !withIcon && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setIsClosed((v) => !v);
            }}
            css={toggleCSS(isClosed)}
          >
            <ToggleArrow />
          </button>
        )}
        {withIcon ? (
          <div className={RADIO_BUTTON_CLASSNAMES.image} css={otherCSS(applyClosed)}>
            <img className={RADIO_BUTTON_CLASSNAMES.image} css={[radioIconCSS]} src={sirvUrl} aria-hidden alt="" />
          </div>
        ) : (
          <div className={RADIO_BUTTON_CLASSNAMES.image} css={photoCSS(sirvUrl, applyClosed)} />
        )}
        <div css={labelContainerCSS(applyClosed)}>
          <div css={labelCSS}>{label}</div>
          {description && <div css={descriptionCSS(applyClosed)}>{description}</div>}
        </div>
      </div>

      {children && <div css={nestedQuestionsCSS}>{children}</div>}
    </div>
  );
};

export default RadioPhotoView;
