import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import * as Sentry from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutateAnswers } from '../../../../../api/page';
import { useMutatePolicyBind } from '../../../../../api/policy';
import { usePublicConfig } from '../../../../../api/publicConfig';
import { useMutateSession, useSession } from '../../../../../api/session';
import { SEGMENT } from '../../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import useResponsive from '../../../../../hooks/use-responsive';
import { useSegment } from '../../../../../hooks/use-segment';
import BillingInformationFormComponent from '../../../../../questionsForm/components/BillingInformationFormComponent/BillingInformationFormComponent';
import DisclosureComponent from '../../../../../questionsForm/components/DisclosureComponent/DisclosureComponent';
import PaymentMethodFormComponent from '../../../../../questionsForm/components/PaymentMethodFormComponent/PaymentMethodFormComponent';
import { keyToId } from '../../../../../questionsForm/components/PaymentMethodFormComponent/PaymentMethodFormComponent.util';
import { getVisibleComponents, scrollToError } from '../../../../../questionsForm/utils/questions-form.util';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import { ComponentTypes } from '../../../../../types/form-component.type';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../../../../utils/current-form-keys.util';
import { getPathByPageType } from '../../../../../utils/route-path.util';
import { buttonCSS, componentCSS, disclosuresComponentCSS } from './CheckoutAutoForm.style';
import { getVisibleAnswersOnly, getVisibleQuestionKeysToTokenize } from './CheckoutAutoForm.util';
import type { CheckoutFormProps } from './CheckoutAutoForm.type';
import type { Answers } from '../../../../../types/answer.type';
import type {
  FormComponentSchema,
  BillingInformationSchema,
  DisclosureSchema,
  PaymentMethodInformationSchema,
} from '../../../../../types/form-component.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { SessionResponse } from '../../../../../types/session.type';
import type { FC, ReactElement } from 'react';

const CheckoutForm: FC<CheckoutFormProps> = ({ customCSS, schema }) => {
  const configQuery = usePublicConfig();
  const { bt, error: BTError } = useBasisTheory(configQuery.data?.config.integrations.bt.public_api_key, {
    elements: true,
  });
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const { mutateAsync: mutateSession } = useMutateSession(gid);
  const { mutateAsync: mutateBindPolicy } = useMutatePolicyBind(gid);
  const { mutateAsync: mutateAnswers } = useMutateAnswers(gid);
  const { handleSubmit, watch } = useFormContext();
  const answers = watch();
  const visibleComponents = getVisibleComponents(schema.components || [], answers);
  const queryClient = useQueryClient();
  const btValidationState = useRef<Record<string, boolean>>({});
  const currentPageKey = sessionData?.session.current_page_key;

  const isBindDisabled = sessionData?.session.test_mode;

  const { track } = useSegment();

  if (BTError) {
    Sentry.captureException(BTError);
  }

  const onBTQuestionValidationChange = (key: string, valid: boolean): void => {
    btValidationState.current = { ...btValidationState.current, [key]: valid };
  };

  const isQuestionsToTokenizeIsValid = (keys: string[]): boolean => {
    return keys.every((key) => btValidationState.current[key]);
  };

  const getBTElementsToTokenize = (keys: string[]): Record<string, any> => {
    return keys.reduce((acc, key) => {
      const element = bt?.getElement(keyToId(key));
      return element ? { ...acc, [key]: element } : acc;
    }, {});
  };

  const onSubmit = async (data: Answers): Promise<void> => {
    if (isSubmitLoading) return;
    track(SEGMENT.events.bindPolicyClicked);

    const keysToTokenize = getVisibleQuestionKeysToTokenize(visibleComponents);
    if (!isQuestionsToTokenizeIsValid(keysToTokenize)) {
      return;
    }

    setIsSubmitLoading(true);

    const visibleAnswers = getVisibleAnswersOnly(data, visibleComponents);

    const tokenizedValues = await bt?.tokenize(getBTElementsToTokenize(keysToTokenize));

    await mutateAnswers({ ...visibleAnswers, ...(tokenizedValues as Answers) });

    await mutateBindPolicy({});

    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });
    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);
    const nextCurrentPage = getCurrentNavigationPage(newSessionData);
    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });
    navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
  };

  const getComponentByType = ({ key, type, ...c }: FormComponentSchema, index: number): ReactElement | null => {
    switch (type) {
      case ComponentTypes.BillingInformation: {
        const billingInformationSchema = c as Omit<BillingInformationSchema, 'key' | 'type'>;
        return (
          <BillingInformationFormComponent
            customCSS={componentCSS}
            key={key}
            componentKey={key}
            index={index}
            heading={billingInformationSchema.heading}
            questions={billingInformationSchema.questions}
            answers={answers}
          />
        );
      }
      case ComponentTypes.CCPaymentMethodInformation:
      case ComponentTypes.EFTPaymentMethodInformation: {
        const paymentMethodSchema = c as Omit<PaymentMethodInformationSchema, 'key' | 'type'>;
        return (
          <PaymentMethodFormComponent
            customCSS={componentCSS}
            key={key}
            componentKey={key}
            index={index}
            heading={paymentMethodSchema.heading}
            questions={paymentMethodSchema.questions}
            answers={answers}
            onBTQuestionValidationChange={onBTQuestionValidationChange}
          />
        );
      }
      case ComponentTypes.Disclosure: {
        const disclosureSchema = c as Omit<DisclosureSchema, 'key' | 'type'>;
        return (
          <DisclosureComponent
            customCSS={disclosuresComponentCSS}
            key={key}
            componentKey={key}
            content={disclosureSchema.content}
            disclosureGid={disclosureSchema.disclosure_gid}
            validations={disclosureSchema.validations}
            isLineHidden
          />
        );
      }
      default:
        return null;
    }
  };

  return (
    <BasisTheoryProvider bt={bt}>
      <form css={customCSS} onSubmit={handleSubmit(onSubmit, scrollToError)}>
        <div>{visibleComponents.map(getComponentByType)}</div>
        <Button
          css={buttonCSS}
          type="submit"
          size={isMobile ? ButtonSize.Normal : ButtonSize.Large}
          fullWidth
          isLoading={isSubmitLoading}
          disabled={isBindDisabled}
        >
          {schema.action_label}
        </Button>
      </form>
    </BasisTheoryProvider>
  );
};

export default CheckoutForm;
