import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';
import theme from '../../styles/theme';
import { text14CSS, text20CSS, text32CSS, textCSS } from '../../styles/typography.style';

export const mainImageCSS = css`
  margin-bottom: 24px;
`;

export const titleCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 16px;

  ${mq[0]} {
    ${text32CSS};
    line-height: 40px;
    max-width: 510px;
  }
`;

export const subtitleCSS = css`
  ${text14CSS};
  line-height: 20px;
  margin-bottom: 32px;

  ${mq[0]} {
    ${textCSS};
    max-width: 580px;
  }
`;

export const buttonsWrapperCSS = css`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 212px;

  ${mq[0]} {
    flex-wrap: nowrap;
    gap: 24px;
    max-width: 100%;
  }
`;

export const cardCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  text-align: left;
  width: 306px;
  margin-bottom: 32px;

  ${mq[0]} {
    width: 350px;
  }
`;

export const messageCSS = css`
  display: flex;
  align-items: center;
  ${text14CSS};
  position: relative;
  gap: 8px;

  ${mq[0]} {
    ${textCSS};
  }
`;

export const warningIconCSS = css`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export const checkIconCSS = css`
  background-color: ${theme.colors.positiveGreen27};
  width: 12px;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
`;
