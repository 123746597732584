import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { text12CSS } from '../../../styles/typography.style';

export const tooltipCSS = css`
  border: none;
  cursor: pointer;
`;

export const tooltipContentCSS = css`
  color: ${theme.colors.gray60};
  ${text12CSS};
`;

export const closeIconCSS = css`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;
