import { FormElementType } from '../../../types/form-element.type';
import { isQuestionElement } from '../../../utils/schema.util';
import type { FormElementSchema } from '../../../types/form-element.type';
import type { RenderSectionContainer } from '../SectionForm/SectionForm.type';

export const getLastQuestionKeyFromContainer = (container: RenderSectionContainer | null): string | null => {
  if (!container) {
    return null;
  }

  const lastQuestion = container.elements.reduce((acc: FormElementSchema | null, element) => {
    if (element.kind === FormElementType.Question) {
      return element;
    }
    return acc;
  }, null);

  return lastQuestion && isQuestionElement(lastQuestion) ? lastQuestion.content.key : null;
};
