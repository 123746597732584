import { useEffect, useState } from 'react';
import { useCreateFullStorySession } from '../api/full-story-sessions';
import analytics from '../utils/analytics.util';
import { captureException } from '../utils/error.util';

const useTrackFullStorySession = (sessionGid?: string, enabled?: boolean): void => {
  const { mutate: createFullStorySession } = useCreateFullStorySession(sessionGid);
  const [fsUrl, setFsUrl] = useState<string | null>();

  useEffect(() => {
    if (import.meta.env.PROD && enabled) {
      analytics.getFSUrl().then((url) => {
        setFsUrl(url);
      });
    }
  }, [enabled]);

  useEffect(() => {
    if (sessionGid && fsUrl && enabled) {
      try {
        createFullStorySession({ url: fsUrl });
      } catch (error) {
        captureException(error, 'track_fs_hook');
      }
    }
  }, [sessionGid, fsUrl, enabled, createFullStorySession]);
};

export default useTrackFullStorySession;
