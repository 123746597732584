import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { CoInsuredResponse } from '../../types/co-insured.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useCoInsured = (gid?: string): QueryObserverResult<CoInsuredResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.coInsureds, gid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<CoInsuredResponse>>(getApiUrl(API_PATH.coInsureds, gid as string))
        .then((res) => res.data),
    enabled: !!gid,
  });

export default useCoInsured;
