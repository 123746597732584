import { useEffect, useState, useRef, useMemo } from 'react';

const MOBILE = 'MOBILE';
const TABLET = 'TABLET';
const DESKTOP = 'DESKTOP';

const MOBILE_EDGE = 600;
const TABLET_EDGE = 1200;
const DESKTOP_EDGE = 1440;

export const defaultThresholds = {
  [MOBILE]: MOBILE_EDGE,
  [TABLET]: TABLET_EDGE,
  [DESKTOP]: DESKTOP_EDGE,
};

export const getBreakpoint = (breakpointThresholds: Record<string, number>): string =>
  Object.keys(breakpointThresholds).find(
    (breakpointName) => window.innerWidth < breakpointThresholds[breakpointName]
  ) ?? DESKTOP;

export interface Breakpoints {
  breakpoint: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

/**
 * You can pass custom Breakpoint Threshold to override default one.
 *
 * **Note:** value should be in growing order.
 *
 * **Example:** `{ mobile: 500, tablet: 1000, desktop: 2000 }`
 */
export const useResponsive = (breakpointThresholds = defaultThresholds): Breakpoints => {
  const [breakpoint, setBreakpoint] = useState(getBreakpoint(breakpointThresholds));
  const savedBreakpoint = useRef(getBreakpoint(breakpointThresholds));

  useEffect(() => {
    const onResize = (): void => {
      const newBreakpoint = getBreakpoint(breakpointThresholds);

      if (savedBreakpoint.current !== newBreakpoint) {
        setBreakpoint(newBreakpoint);
        savedBreakpoint.current = newBreakpoint;
      }
    };

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, [breakpointThresholds]);

  return useMemo(
    () => ({
      breakpoint,
      isMobile: MOBILE === breakpoint,
      isTablet: TABLET === breakpoint,
      isDesktop: DESKTOP === breakpoint,
    }),
    [breakpoint]
  );
};

export default useResponsive;
