import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text24CSS, text32CSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const checkIconCSS = css`
  background-color: ${theme.colors.positiveGreen27};
  width: 12px;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
`;

const iconCSS = css`
  width: 300px;
  display: none;

  ${mq[0]} {
    display: flex;
  }
`;

export const separateHouseIconCSS = css`
  left: 1%;
  ${iconCSS};
`;

export const doubleHouseIconCSS = css`
  right: 1%;
  ${iconCSS};
`;

export const headerCSS = css`
  ${text24CSS};
  font-weight: 700;
  margin-bottom: 6px;

  ${mq[0]} {
    margin: 16px 0;
    ${text32CSS};
  }
`;

export const subheaderCSS = css`
  margin-bottom: 16px;
  text-align: center;

  ${mq[0]} {
    width: 600px;
  }
`;

export const infoBlockCSS = (isShadowAgentVariation: boolean): SerializedStyles => css`
  max-width: 328px;
  margin-top: 24px;
  text-align: left;

  ${mq[0]} {
    margin-top: ${isShadowAgentVariation ? '-30' : 32}px;
    ${isShadowAgentVariation &&
    css`
      margin-bottom: 60px;
    `};
    width: 560px;
    max-width: 560px;
  }
`;

export const headerBackgroundCSS = css`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: end;
  width: 100%;
  background-color: ${theme.colors.white};
  z-index: 1;
  overflow: hidden;
  text-align: center;

  ${mq[0]} {
    background-color: ${theme.colors.azure95};
    width: 100vw;
  }
`;

export const elipseCSS = css`
  clip-path: ellipse(40% 45%);
  position: absolute;
  width: 930px;
  height: 731px;
  background-color: ${theme.colors.white};
  display: none;
  z-index: -1;
  ${mq[0]} {
    display: flex;
  }
`;

export const headerContentCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mq[0]} {
    padding: 0 16px;
  }
`;

export const carriersSliderCSS = (isShadowAgentVariation?: boolean): SerializedStyles => css`
  width: 100%;
  padding-top: ${isShadowAgentVariation ? 30 : 15}px;

  ${mq[0]} {
    min-width: 430px;
    ${isShadowAgentVariation &&
    css`
      width: 440px;
    `}
  }
`;

export const readyQuoteImgCSS = css`
  margin-top: 16px;
`;

export const buttonCSS = css`
  margin: 0;
  width: 220px;
`;
