import { css } from '@emotion/react';
import theme from '../../../../styles/theme';
import { text14CSS } from '../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (hasIcon: boolean): SerializedStyles => css`
  position: relative;
  box-sizing: border-box;
  padding-left: ${hasIcon ? 36 : 0}px;
`;

export const headerCSS = css`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
`;

export const descriptionCSS = css`
  color: ${theme.colors.gray60};
  margin-top: 8px;
  ${text14CSS};
  line-height: 20px;
`;

export const nameCSS = css`
  color: ${theme.colors.black};
`;

export const valueCSS = css`
  color: ${theme.colors.positiveGreen27};
  position: static;
  flex-shrink: 0;
  padding-right: 5px;
`;

export const iconCSS = css`
  position: absolute;
  left: 0;
`;
