import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { EffectivePolicyResponse } from '../../types/policy.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useEffectivePolicy = (gid: string): QueryObserverResult<EffectivePolicyResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.effectivePolicy, gid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<EffectivePolicyResponse>>(getApiUrl(API_PATH.effectivePolicy, gid))
        .then((res) => res.data),
  });

export default useEffectivePolicy;
