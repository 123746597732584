import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text20CSS, text24CSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  padding: 32px 0;

  ${mq[0]} {
    padding: 32px 40px 40px;
  }
`;

export const agentIconCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 72px;
  margin: 0 auto 16px;
  border-radius: 50%;
  background-color: ${theme.colors.azure95};

  svg {
    color: ${theme.colors.azure50};
    width: 48px;
    height: 48px;
  }
`;

export const titleCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin: 0 24px 24px;

  ${mq[0]} {
    ${text24CSS};
  }
`;

export const infoBlockCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 4px;
  background: ${theme.colors.azure95};
  padding: 24px;
  font-weight: 400;
  text-align: left;

  ${mq[0]} {
    padding: 24px;
  }
`;

export const infoItemCSS = css`
  position: relative;
  padding-left: 24px;
`;

export const checkIconCSS = css`
  position: absolute;
  top: 2px;
  left: 0;
  background-color: ${theme.colors.positiveGreen27};
  width: 8px;
  padding: 4px;
  border-radius: 50%;
`;
