import type { SerializedStyles } from '@emotion/react';

export enum IconSize {
  Default = 'default',
  Small = 'small',
}

export interface TextFormBlockProps {
  customCSS?: SerializedStyles;
  heading: string;
  subheading?: string;
  description?: string;
  headingIconUrl?: string;
  blockIconUrl?: string;
  isLineHidden?: boolean;
  iconSize?: IconSize;
  isPaddingBottomHidden?: boolean;
  trackForbidden?: boolean;
}
