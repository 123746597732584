import { css } from '@emotion/react';
import theme from '../../../../../styles/theme';

export const textCSS = css`
  color: ${theme.colors.gray80};
  margin-top: 16px;
`;

export const formCSS = css`
  margin-top: 16px;
`;

export const disclosureCSS = css`
  margin-bottom: 16px;
`;

export const buttonCSS = css`
  margin-top: 8px;
`;
