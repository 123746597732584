import { css } from '@emotion/react';

export const containerCSS = css`
  position: relative;
`;

export const scrollPointCSS = css`
  position: absolute;
  scroll-margin-top: calc(var(--header-height) + 20px);
`;
