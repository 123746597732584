import { useFormContext } from 'react-hook-form';
import { visuallyHiddenCSS } from '../../../shared/shared.style';
import noop from '../../../shared/utils/noop';
import { checkoutComponentsHeadingCSS } from '../../../styles/form.style';
import isVisibleByAnswers from '../../../utils/visibility-conditions.util';
import BTQuestion from '../BTQuestion/BTQuestion';
import Question from '../Question/Question';
import { containerCSS } from './PaymentMethodFormComponent.style';
import { keyToId, shouldBeTokenized } from './PaymentMethodFormComponent.util';
import type { PaymentMethodFormComponentProps } from './PaymentMethodFormComponent.type';
import type { FC } from 'react';

const PaymentMethodFormComponent: FC<PaymentMethodFormComponentProps> = ({
  customCSS,
  index,
  heading,
  questions,
  answers,
  onBTQuestionValidationChange = noop,
}) => {
  const { formState } = useFormContext();
  const visibleQuestions = questions.filter((q) => isVisibleByAnswers(answers, q.visibility_conditions));

  return (
    <fieldset css={[containerCSS, customCSS]}>
      <legend css={visuallyHiddenCSS}>{heading}</legend>
      <div css={checkoutComponentsHeadingCSS}>
        {index + 1}. {heading}
      </div>

      <div>
        {visibleQuestions.map((q) =>
          shouldBeTokenized(q.key) ? (
            <BTQuestion
              key={q.key}
              questionKey={keyToId(q.key)}
              type={q.type}
              label={q.label}
              placeholder={q.placeholder}
              showError={formState.isSubmitted}
              onFieldValidationChange={(valid) => onBTQuestionValidationChange(q.key, valid)}
            />
          ) : (
            <Question {...q} key={q.key} workflowHeading={heading} questionKey={q.key} />
          )
        )}
      </div>
    </fieldset>
  );
};

export default PaymentMethodFormComponent;
