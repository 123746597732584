import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLastQuoteRequest, useQuotesRequestsList } from '../../../../../api/quote';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import { SEGMENT } from '../../../../../constants/analytics';
import { useSegment } from '../../../../../hooks/use-segment';
import { Card } from '../../../../../shared/components/Card/Card';
import DashedRow from '../../../../../shared/components/DashedRow/DashedRow';
import Divider from '../../../../../shared/components/Divider/Divider';
import SirvImage from '../../../../../shared/components/SirvImage/SirvImage';
import SkeletonLoader from '../../../../../shared/components/SkeletonLoader/SkeletonLoader';
import { getCents, removeCents } from '../../../../../shared/utils/money.util';
import { ConfigType } from '../../../../../types/public-flow-config.type';
import format from '../../../../../utils/format.util';
import { getPrimaryQuote } from '../../../../../utils/quote.util';
import {
  additionalDiscountCSS,
  annualPremiumBoxCSS,
  annualPremiumLabelCSS,
  annualPremiumValueCSS,
  arrowCSS,
  containerCSS,
  dividerCSS,
  effectiveDateCSS,
  expandButtonCSS,
  expandLabelCSS,
  loadingCSS,
  logoCSS,
  rowCSS,
  rowsContainerCSS,
  summaryHeroCSS,
} from './CheckoutHomeSummary.style';
import { CHECKOUT_SUMMARY_EXPANDER_ID, getButtonLabel, getAdditionalDiscount } from './CheckoutHomeSummary.util';
import type { CheckoutHomeSummaryProps } from './CheckoutHomeSummary.type';
import type { HomeQuote } from '../../../../../types/quote.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const CheckoutHomeSummary: FC<CheckoutHomeSummaryProps> = ({ customCSS, isExpandable }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const { track } = useSegment();

  const isDynamicConfigType = sessionFlowConfig?.flow_config?.config_type === ConfigType.Dynamic;

  const { data: quotesRequestsListData } = useQuotesRequestsList<HomeQuote>(gid, isDynamicConfigType);

  const { data: quoteRequestData } = useLastQuoteRequest<HomeQuote>(gid);

  const quotes = quoteRequestData?.quotes_request?.groups[0]?.quotes;

  const additionalDiscount = getAdditionalDiscount(quotesRequestsListData?.quotes_requests);

  const quote = getPrimaryQuote<HomeQuote>(quotes, sessionFlowConfig?.flow_config?.carrier_key);

  const [isExpanded, setExpanded] = useState(!isExpandable);

  useEffect(() => {
    if (additionalDiscount) {
      track(SEGMENT.events.finalPriceDiscountViewed, {
        discount: additionalDiscount,
      });
    }
  }, [additionalDiscount, track]);

  return (
    <Card customCSS={customCSS}>
      <div css={containerCSS}>
        {additionalDiscount && (
          <div css={additionalDiscountCSS}>
            <div>Additional discount applied!</div>
            <strong>${additionalDiscount} more in savings</strong>
          </div>
        )}
        <div css={summaryHeroCSS}>
          {quote && (
            <SirvImage
              customCSS={logoCSS}
              url={quote?.carrier_logo_url}
              width={110}
              alt={`${quote?.carrier_name} logo`}
            />
          )}
          <Divider customCSS={dividerCSS} height={50} />
          <div css={annualPremiumBoxCSS}>
            {quote?.premium ? (
              <div css={annualPremiumValueCSS} data-testid="premium-value">
                ${format.money(removeCents(quote.premium))}
                <span>.{getCents(quote.premium)}/yr</span>
              </div>
            ) : (
              <SkeletonLoader absoluteHeight={40} absoluteWidth={130} />
            )}
            <div css={annualPremiumLabelCSS}>Annual Premium</div>
          </div>
        </div>
        {isExpanded && (
          <div id={CHECKOUT_SUMMARY_EXPANDER_ID} css={rowsContainerCSS}>
            {quote?.deductible ? (
              <DashedRow customCSS={rowCSS} label="Deductible" content={`$${format.money(quote.deductible)}`} />
            ) : (
              <SkeletonLoader customCSS={loadingCSS} absoluteHeight={20} width={100} />
            )}

            {quote?.coverages ? (
              quote.coverages.map(({ key, title, value }) => (
                <DashedRow key={key} customCSS={rowCSS} label={title} content={value} />
              ))
            ) : (
              <>
                <SkeletonLoader customCSS={loadingCSS} absoluteHeight={20} width={100} />
                <SkeletonLoader customCSS={loadingCSS} absoluteHeight={20} width={100} />
                <SkeletonLoader customCSS={loadingCSS} absoluteHeight={20} width={100} />
                <SkeletonLoader customCSS={loadingCSS} absoluteHeight={20} width={100} />
                <SkeletonLoader customCSS={loadingCSS} absoluteHeight={20} width={100} />
                <SkeletonLoader customCSS={loadingCSS} absoluteHeight={20} width={100} />
              </>
            )}

            <DashedRow
              customCSS={effectiveDateCSS}
              label="Policy Effective Date"
              content={
                quote?.policy_effective_date ? (
                  format.date(quote.policy_effective_date)
                ) : (
                  <SkeletonLoader absoluteHeight={20} absoluteWidth={60} />
                )
              }
            />
          </div>
        )}
      </div>

      {isExpandable && (
        <button
          css={expandButtonCSS}
          aria-controls={CHECKOUT_SUMMARY_EXPANDER_ID}
          aria-expanded={isExpanded}
          onClick={() => setExpanded((s) => !s)}
        >
          <span css={expandLabelCSS}>{getButtonLabel(isExpanded)}</span>
          <div css={arrowCSS} />
        </button>
      )}
    </Card>
  );
};

export default CheckoutHomeSummary;
