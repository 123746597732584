import type { Dispatch } from 'react';

export enum StoreActions {
  SET_QUESTIONS_KEYS = 'SET_QUESTIONS_KEYS',
}

interface SetQuestionsKeys {
  type: StoreActions.SET_QUESTIONS_KEYS;
  payload: string[];
}

export type QuestionsFormActionsType = SetQuestionsKeys;

export const setQuestionsKeys = (questionsKeys: string[]) => {
  return (dispatch: Dispatch<SetQuestionsKeys>) => {
    dispatch({ type: StoreActions.SET_QUESTIONS_KEYS, payload: questionsKeys });
  };
};
