import type { SerializedStyles } from '@emotion/react';
import type { ReactNode } from 'react';

export const enum TooltipTheme {
  Dark = 'dark',
  Light = 'light',
}
export interface TooltipProps {
  content: ReactNode;
  children: ReactNode;
  customCSS?: {
    tooltipCSS?: Record<string, string>;
    buttonCSS?: SerializedStyles;
  };
  id?: string;
  theme?: TooltipTheme;
  position?: 'top' | 'bottom' | 'left' | 'right';
}
