import { Fragment, useEffect } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useCreateLoan } from '../../../api/loan';
import { useServicers } from '../../../api/servicer';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader';
import Question from '../Question/Question';
import { containerCSS, inputCSS } from './LoanForm.style';
import { LOAN_QUESTION_SCHEMA, SERVICER_QUESTION_SCHEMA } from './LoanForm.util';
import type { LoanFormProps } from './LoanForm.type';
import type { QuestionVariant } from '../../../types/question.type';
import type { DefaultLocation } from '../../../types/route-params.type';
import type { FC } from 'react';

const LoanForm: FC<LoanFormProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;

  const { mutateAsync: createLoan } = useCreateLoan(gid);
  const { data, isPending } = useServicers();
  const servicers = data?.servicers;

  const { isSubmitting, isValid } = useFormState();
  const pageAnswers = useWatch({ name: [LOAN_QUESTION_SCHEMA.questionKey, SERVICER_QUESTION_SCHEMA.questionKey] });

  useEffect(() => {
    if (isValid && isSubmitting) {
      const loanPayload = {
        loan_number: pageAnswers[0],
        servicer_gid: pageAnswers[1],
      };
      createLoan(loanPayload);
    }
  }, [isSubmitting, isValid, createLoan, pageAnswers]);

  const getServicersOptions = (): QuestionVariant[] => {
    if (!servicers) {
      return [];
    }

    return servicers.map((s) => ({
      value: s.gid,
      label: s.name,
    }));
  };

  return (
    <div css={[containerCSS, customCSS]}>
      {isPending ? (
        <Fragment>
          <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />
          <SkeletonLoader customCSS={inputCSS} absoluteHeight={60} width={100} />
        </Fragment>
      ) : (
        <Fragment>
          <Question {...SERVICER_QUESTION_SCHEMA} variants={getServicersOptions()} />
          <Question {...LOAN_QUESTION_SCHEMA} />
        </Fragment>
      )}
    </div>
  );
};

export default LoanForm;
