import {
  getCheckboxKeysFromSectionContainer,
  getNestedQuestionsKeysFromSectionContainer,
} from '../../../../questionsForm/utils/answers/workflow-schema-answers.util';
import { FormElementType } from '../../../../types/form-element.type';
import type { Answers } from '../../../../types/answer.type';
import type { AcceptedDisclosures } from '../../../../types/disclosure.type';
import type { DisclosureContent, FormElementSchema } from '../../../../types/form-element.type';
import type { SectionContainer } from '../../../../types/section.type';

export const LIMIT_DISCOUNTS_ON_SCREEN = 9;
export const ALWAYS_TO_SHOW_DISCOUNTS_NUMBER = 6;

export const prepareDiscountAnswersForSubmit = (answers: Answers, containers: SectionContainer[]): Answers => {
  const discountQuestionKeys = containers
    .map((container) => {
      return [
        ...getCheckboxKeysFromSectionContainer(container),
        ...getNestedQuestionsKeysFromSectionContainer(container),
      ];
    })
    .flat(1);

  return discountQuestionKeys.reduce((acc, key) => {
    return answers[key] ? { ...acc, [key]: answers[key] } : acc;
  }, {});
};

export const getDisclosuresElementsFromSection = (
  containers?: SectionContainer[]
): Array<FormElementSchema<DisclosureContent>> => {
  if (!containers) {
    return [];
  }

  return containers.reduce<Array<FormElementSchema<DisclosureContent>>>(
    (acc, container) =>
      [...acc, ...container.elements.filter((element) => element.kind === FormElementType.Disclosure)] as Array<
        FormElementSchema<DisclosureContent>
      >,
    []
  );
};

export const getDisclosuresToRender = (
  containers?: SectionContainer[],
  acceptedDisclosures?: AcceptedDisclosures[]
): Array<FormElementSchema<DisclosureContent>> => {
  if (!containers || !acceptedDisclosures) {
    return [];
  }

  const allDisclosures = getDisclosuresElementsFromSection(containers);

  return allDisclosures.reduce<Array<FormElementSchema<DisclosureContent>>>((acc, disclosure) => {
    const acceptedDisclosure = acceptedDisclosures.find((accepted) => accepted.kind === disclosure.key);

    return acceptedDisclosure ? acc : [...acc, disclosure];
  }, []);
};

export const prepareDisclosuresForSubmit = (
  disclosures: Array<FormElementSchema<DisclosureContent>>
): Record<string, string> =>
  disclosures.reduce(
    (acc, disclosure) => ({
      ...acc,
      [disclosure.key]: disclosure.content.disclosure_gid,
    }),
    {}
  );

export const interpolateDisclosureCTA = (disclosureText: string, replaceKeys: Array<Record<string, string>>): string =>
  replaceKeys.reduce((acc, replaceObject) => {
    const { value, key } = replaceObject;

    const regex = new RegExp(key, 'g');
    return acc.replace(regex, `\`${value}\``);
  }, disclosureText);
