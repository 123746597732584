import PreventDefaultWrapper from '../../shared/components/PreventDefaultWrapper/PreventDefaultWrapper';
import { FormElementTag, FormElementType } from '../../types/form-element.type';
import { QuestionType } from '../../types/question.type';
import CoInsuredForm from '../components/CoInsuredForm/CoInsuredForm';
import { disclosureCSS } from '../components/ContainerForm/ContainerForm.style';
import DisclosureComponent from '../components/DisclosureComponent/DisclosureComponent';
import InfoFormBlock from '../components/InfoFormBlock/InfoFormBlock';
import LoanForm from '../components/LoanForm/LoanForm';
import Question from '../components/Question/Question';
import QuestionsGroupLabel from '../components/QuestionsGroupLabel/QuestionsGroupLabel';
import TextBoxNavigationPoint from '../components/TextBoxNavigationPoint/TextBoxNavigationPoint';
import TextFormBlock from '../components/TextFormBlock/TextFormBlock';
import {
  getInfoBlockType,
  getTextFormBlockIconSize,
  isNavigationPointTextBoxElement,
  isQuestionsGroupLabel,
  isTrackForbidden,
  shouldHideQuestionLabel,
} from './form-element-tags.util';
import { interpolateHint, interpolateTooltip } from './interpolation/interpolation.util';
import { REQUIRED_VALIDATION } from './question-validations/question-validations.util';
import type { Answers } from '../../types/answer.type';
import type {
  DisclosureContent,
  FormElementSchema,
  GetElementByTypeArguments,
  InfoBlockContent,
  QuestionElementContent,
  TextElementContent,
} from '../../types/form-element.type';
import type { QuestionProps } from '../components/Question/Question.type';
import type { ReactElement } from 'react';

export const getFormElementContext = (
  prevElement?: FormElementSchema,
  nextElement?: FormElementSchema
): Array<string | null> => {
  return [prevElement?.kind ?? null, nextElement?.kind ?? null];
};

export const getElementByType = ({
  element,
  isFirstContainerOnPage = false,
  enableScrollAnimation = false,
  isSubElement = false,
  context = [],
  answers,
}: GetElementByTypeArguments): ReactElement | null => {
  switch (true) {
    case isNavigationPointTextBoxElement(element): {
      const textContent = element.content as TextElementContent;
      return (
        <TextBoxNavigationPoint
          key={element.key}
          elementKey={element.key}
          heading={textContent.heading}
          description={textContent.description}
          enableAnimation={enableScrollAnimation}
        />
      );
    }
    case isQuestionsGroupLabel(element): {
      const textContent = element.content as TextElementContent;

      return (
        <QuestionsGroupLabel
          key={element.key}
          title={textContent.heading}
          subtitle={textContent.description}
          isSubElement={isSubElement}
        />
      );
    }
    case element.kind === FormElementType.TextBox: {
      const textContent = element.content as TextElementContent;
      return (
        <TextFormBlock
          key={element.key}
          isLineHidden={isFirstContainerOnPage}
          heading={textContent.heading}
          description={textContent.description}
          subheading={textContent.subheading}
          blockIconUrl={textContent.block_icon_url ?? ''}
          iconSize={getTextFormBlockIconSize(element)}
          headingIconUrl={textContent.heading_icon_url ?? ''}
          isPaddingBottomHidden={element.tags?.includes(FormElementTag.Heading) ?? false}
          trackForbidden={isTrackForbidden(element.tags)}
        />
      );
    }
    case element.kind === FormElementType.InfoBlock: {
      const infoBlockContent = element.content as InfoBlockContent;
      return (
        <InfoFormBlock
          key={element.key}
          iconUrl={infoBlockContent.icon_url}
          content={infoBlockContent.info_block_text}
          type={getInfoBlockType(element)}
          gridMode
        />
      );
    }
    case element.kind === FormElementType.Disclosure: {
      const disclosureContent = element.content as DisclosureContent;
      return (
        <>
          <DisclosureComponent
            customCSS={disclosureCSS(
              context[0] !== FormElementType.Disclosure,
              context[1] !== FormElementType.Disclosure
            )}
            disclosureGid={disclosureContent.disclosure_gid}
            componentKey={element.key}
            key={element.key}
            content={disclosureContent.text}
            validations={[REQUIRED_VALIDATION]}
            isLineHidden
          />
        </>
      );
    }
    case element.kind === FormElementType.LoanForm: {
      return <LoanForm key={element.key} />;
    }
    case element.kind === FormElementType.CoInsuredForm: {
      return <CoInsuredForm key={element.key} />;
    }
    case element.kind === FormElementType.Question: {
      return generateQuestionType(element, isSubElement, answers);
    }
    default:
      return null;
  }
};

const generateQuestionType = (
  element: FormElementSchema,
  isSubElement?: boolean,
  answers?: Answers
): ReactElement<QuestionProps> => {
  const questionContent = element.content as QuestionElementContent;

  return (
    <Question
      key={element.key}
      questionKey={questionContent.key}
      label={interpolateTooltip(questionContent.label)}
      hideLabel={shouldHideQuestionLabel(element)}
      description={questionContent.description}
      hint={interpolateHint(questionContent.hint, answers)}
      type={questionContent.type}
      placeholder={questionContent.placeholder}
      icon={questionContent.icon}
      variants={questionContent.variants}
      validations={questionContent.validations}
      gridWidth={isSubElement ? null : element.width}
      tags={element.tags}
      dense={isSubElement}
      subElements={isSubElement ? [] : getSubElements(element.sub_elements)}
    />
  );
};

const getSubElements = (subElements: FormElementSchema[]): ReactElement[] =>
  subElements.reduce<ReactElement[]>((acc, curr) => {
    let element = getElementByType({
      element: curr,
      isSubElement: true,
    });
    if (
      element &&
      curr.kind === FormElementType.Question &&
      (curr.content as QuestionElementContent).type !== QuestionType.Checkbox
    ) {
      element = <PreventDefaultWrapper key={element.key}>{element}</PreventDefaultWrapper>;
    }

    return element ? [...acc, element] : acc;
  }, []);
