import { ValidationType } from '../../../../../types/validation.type';

export const CARD_EXPIRATION_DATE_VALIDATION = {
  key: ValidationType.CardExpirationDate,
  value: true,
  message: 'Please, enter a valid expiration date',
};

export const normalizeCardExpirationValue = (v: string): string => {
  return v.replace(/[_ /]/g, '');
};
