import { useEffect } from 'react';
import { useSession } from '../api/session';
import { getCurrentNavigationPage } from '../utils/current-form-keys.util';

const PAGE_TITLE = '{page_name} | Online Bind | Matic';

export const useBrowserTabTitle = (gid: string, enabled: boolean): void => {
  const { data } = useSession(gid);

  useEffect(() => {
    const page = getCurrentNavigationPage(data);

    if (data && page?.navigation_heading && enabled) {
      document.title = PAGE_TITLE.replace('{page_name}', page.navigation_heading);
    }
  }, [data, enabled]);
};
