import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicFlowConfig } from '../../../../api/public/flow-config';
import { usePublicPrimaryInsured } from '../../../../api/public/primary-insured';
import { usePublicSession } from '../../../../api/public/session';
import { usePublicConfig } from '../../../../api/publicConfig';
import useInitCalendly from '../../../../hooks/use-init-calendly/use-init-calendly';
import { CALENDLY_STEP } from '../../../../hooks/use-init-calendly/use-init-calendly.type';
import { CALENDLY_ID } from '../../../../hooks/use-init-calendly/use-init-calendly.util';
import LoadingDots from '../../../../shared/components/LoadingDots/LoadingDots';
import { calendlyWidgetCSS } from '../../../../styles/common.style';
import { StrategyType } from '../../../../types/public-flow-config.type';
import { containerCSS, loaderCSS, subtitleCSS, titleCSS } from './ScheduleCallPage.style';
import type { ScheduleCallPageProps } from './ScheduleCallPage.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const ScheduleCallPage: FC<ScheduleCallPageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: configData } = usePublicConfig();
  const { data: publicFlowConfigData, isLoading, isPending } = usePublicFlowConfig(gid);
  const { data: publicSessionPrimaryInsuredData } = usePublicPrimaryInsured(gid);
  const { data: publicSessionData } = usePublicSession(gid);
  const prefillData = {
    firstName: publicSessionPrimaryInsuredData?.primary_insured.first_name,
    lastName: publicSessionPrimaryInsuredData?.primary_insured.last_name,
    email: publicSessionPrimaryInsuredData?.primary_insured.email,
  };
  const [calendlyUrl, setCalendlyUrl] = useState<string | null>(null);
  const { step, calendlyInit, isLoaded } = useInitCalendly({
    leadGid: publicSessionData?.session.engagement_gid,
    url: calendlyUrl,
    prefillData,
    strategy: publicFlowConfigData?.flow_config.strategy,
    carrierKey: publicFlowConfigData?.flow_config.carrier_key,
  });

  const isAllowInit = (!isLoading && isPending) || (!isLoading && !isPending);
  const eventIsScheduled = step === CALENDLY_STEP.eventScheduled;

  useEffect(() => {
    const primaryInsuredDone = publicSessionPrimaryInsuredData?.primary_insured || !gid;
    const flowConfigDone = publicFlowConfigData?.flow_config || !gid;
    if (configData?.config && primaryInsuredDone && flowConfigDone) {
      const url =
        publicSessionPrimaryInsuredData?.primary_insured.is_policyholder &&
        publicFlowConfigData?.flow_config.strategy === StrategyType.DigitalProfile
          ? configData.config.integrations.calendly.policyholders_url
          : configData.config.integrations.calendly.primary_url;
      setCalendlyUrl(url);
    }
  }, [gid, configData, publicSessionPrimaryInsuredData, publicFlowConfigData]);

  useEffect(() => {
    if (calendlyUrl && isAllowInit) {
      calendlyInit();
    }
  }, [calendlyUrl, calendlyInit, isAllowInit]);

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={titleCSS}>
        {eventIsScheduled ? (
          'You’re all set!'
        ) : (
          <>
            Schedule a call<span> with Matic</span>
          </>
        )}
      </div>
      <div css={subtitleCSS}>
        {eventIsScheduled
          ? 'A confirmation email with your appointment details has been sent.'
          : 'Schedule a time that works for you.'}
      </div>
      {!isLoaded && <LoadingDots customCSS={loaderCSS} />}
      <div id={CALENDLY_ID} data-testid="calendly-widget" css={[calendlyWidgetCSS(step)]} />
    </div>
  );
};

export default ScheduleCallPage;
