import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';

export const stepHeadingCSS = css`
  margin-bottom: 16px;
`;

export const singleStepHeadingCSS = css`
  margin-bottom: 32px;
`;

export const infoBlockCSS = css`
  margin-bottom: 24px;

  ${mq[0]} {
    div {
      height: 56px;
    }
  }
`;

export const labelCSS = css`
  padding: 2px 0;
  margin-bottom: 4px;
`;

export const radioWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const inputCSS = css`
  width: calc(50% - 9px);
`;
