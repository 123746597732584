import type { ImgUrlParams } from './SirvImage.type';

export const getImgUrlWithParams = ({ height = 0, width, url, multiplier = 1 }: ImgUrlParams): string => {
  const imgUrl = new URL(url);
  width = (Number(imgUrl.searchParams.get('w')) || width) * multiplier;
  height = (Number(imgUrl.searchParams.get('h')) || height) * multiplier;

  imgUrl.searchParams.set('w', width.toString());
  imgUrl.searchParams.set('h', height.toString());

  return imgUrl.toString();
};
