import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { QuoteResponse } from '../../types/quote.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useInitialQuote = <T>(gid?: string): QueryObserverResult<QuoteResponse<T>> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.initialQuote, gid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<QuoteResponse>>(getApiUrl(API_PATH.initialQuote, gid as string))
        .then((res) => res.data),
    enabled: !!gid,
  });

export default useInitialQuote;
