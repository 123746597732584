import { Controller } from 'react-hook-form';
import RadioGroup from '../../../../../shared/components/RadioGroup/RadioGroup';
import { RadioGroupVariant } from '../../../../../shared/components/RadioGroup/RadioGroup.type';
import noop from '../../../../../shared/utils/noop';
import { isValueSpecificType } from '../../../../utils/answers/answers.util';
import { getValidationRules } from '../../../../utils/question-validations/question-validations.util';
import type { RadioInputProps } from './RadioInput.type';
import type { FC } from 'react';

const RadioInput: FC<RadioInputProps> = ({
  name,
  options,
  isDisabled,
  validations,
  variant = RadioGroupVariant.Default,
  hasError,
  onValidatePreviousInputs = noop,
}) => {
  return (
    <Controller
      name={name}
      rules={getValidationRules(validations)}
      render={({ field: { ref, value, onBlur, onChange } }) => (
        <RadioGroup
          name={name}
          value={isValueSpecificType<string>(value, ['string']) ? value : ''}
          options={options}
          disabled={isDisabled}
          componentRef={ref}
          variant={variant}
          hasError={hasError}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e);
            onValidatePreviousInputs();
          }}
        />
      )}
    />
  );
};

export default RadioInput;
