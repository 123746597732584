import { Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../constants/full-story';
import { focusHelperCSS, inputCSS, inputWrapperCSS } from '../../../../shared/components/Input/Input.style';
import { normalizeDateValue, parseDateValue } from '../../../../utils/date.util';
import { isValueSpecificType } from '../../../utils/answers/answers.util';
import {
  hardcodedValidations,
  getValidationRules,
} from '../../../utils/question-validations/question-validations.util';
import type { DateInputProps } from './DateInput.type';
import type { FC } from 'react';

const DateInput: FC<DateInputProps> = ({
  name,
  inputId,
  hasError = false,
  isDisabled,
  placeholder,
  mask,
  validations,
  trackingForbidden = false,
  dense = false,
}) => {
  return (
    <Controller
      name={name}
      rules={getValidationRules(validations, {
        isValidDate: hardcodedValidations.isValidDate,
      })}
      render={({ field: { onChange, value, onBlur, name, ref }, fieldState: { isTouched } }) => (
        <div css={inputWrapperCSS}>
          <MaskedInput
            className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
            css={inputCSS(hasError, isDisabled, false, Boolean(value && !isTouched), dense)}
            id={inputId}
            inputRef={(el: HTMLInputElement) => {
              ref(el);
            }}
            mask={mask}
            name={name}
            type="text"
            onChange={(e) => {
              const value = normalizeDateValue(e.target.value);
              onChange(value);
              if (e.target.value !== value) {
                onBlur();
              }
            }}
            value={parseDateValue(isValueSpecificType<string>(value, ['string']) ? value : '')}
            disabled={isDisabled}
            placeholder={placeholder}
            onBlur={onBlur}
            inputMode="decimal"
          />
          <div css={focusHelperCSS} className="input-focus-helper" />
        </div>
      )}
    />
  );
};

export default DateInput;
