import type { ReactElement } from 'react';

export interface QuotePageScheduleCallProps {
  hideHeading?: boolean;
  heading?: string;
  callScheduledInfoBlock?: ReactElement | null;
  onCallScheduled?: () => void;
}

export enum ViewState {
  Pending = 'pending',
  Preview = 'preview',
  Schedule = 'schedule',
}
