import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';

export const containerCSS = css`
  padding-left: 18px;
  margin-left: auto;
  margin-right: auto;

  ${mq[0]} {
    padding-left: 38px;
  }
`;
