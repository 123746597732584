import type { PageKey, PageTypes } from './page.type';
import type { VisibilityCondition } from './visibility-condition.type';

export interface SessionNavigationItem {
  key: string;
  navigation_heading: string;
  page_type: PageTypes;
  display_in_navigation: boolean;
  sections: SessionNavigationItemSection[];
  visibility_conditions?: VisibilityCondition[][];
}

export interface SessionNavigationItemSection {
  icon_url: string | null;
  title: string | null;
  key: string;
  visible_in_navigation?: boolean;
}

// TODO: brake down SessionResponse to smaller interfaces use flow config endpoint where it is possible)
export interface SessionResponse {
  session: {
    gid: string;
    navigation: SessionNavigationItem[];
    current_page_key: PageKey;
    current_section_key: string | null;
    last_user_interaction_created_at: string; // '2022-01-01T00:00:00.000Z'
    test_mode: boolean;
    primary_insured_gid: string;
    engagement_gid: string;
  };
}

export enum SessionStatus {
  locked = 'locked',
  pending = 'pending',
  bound = 'bound',
  failed = 'failed',
}

export enum SessionQualificationStatus {
  Unknown = 'unknown',
  Qualified = 'qualified',
  Disqualified = 'disqualified',
}

export interface UpdateSessionData {
  current_page_key: string;
  current_section_key?: string | null;
  completed_page_key?: string | null;
  completed_section_key?: string | null;
}

export interface UnlockSessionData {
  last_name: string;
  date_of_birth: string;
  zip: string;
}

export interface UnlockSessionResponse {
  data: {
    status: string;
    data: number;
  };
}
