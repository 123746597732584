const HEADING_WITH_SAVINGS = 'Like what you see? Finalize it with an agent.';
const HEADING_WITHOUT_SAVINGS =
  'Let’s find the right insurance for your needs. Talk to one of Matic’s licensed experts.';
const HEADING_FOR_BUNDLE = 'Want to discuss bundle?';

export const getCalendlyHeading = (hasSavings: boolean, isBundle: boolean): string => {
  if (isBundle) {
    return HEADING_FOR_BUNDLE;
  }
  return hasSavings ? HEADING_WITH_SAVINGS : HEADING_WITHOUT_SAVINGS;
};
