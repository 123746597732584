import { css } from '@emotion/react';
import { arrowCSS as accordionArrowCSS } from '../../../../../shared/components/Accordion/Accordion.style';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text14CSS, text20CSS, text24CSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  padding: 16px;
  ${mq[0]} {
    padding: 24px;
  }
`;

export const headingCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 4px;
`;

export const expandableHeadingCSS = css`
  display: flex;
  justify-content: space-between;
`;

export const expandableButtonCSS = css`
  display: flex;
  align-items: center;
  ${text20CSS};
  font-weight: 700;
  border: unset;
  background: transparent;
  padding: 0;
  cursor: pointer;
`;

export const arrowCSS = css`
  ${accordionArrowCSS};
  margin-left: 8px;
  &:before,
  &:after {
    background-color: ${theme.colors.black};
  }
`;

export const expandableTotalCSS = css`
  ${text24CSS};
  font-weight: 700;
  color: ${theme.colors.positiveGreen27};
`;

export const expandableContentCSS = css`
  margin-top: 16px;
`;

export const subheadingCSS = css`
  ${text14CSS};
  color: ${theme.colors.gray60};
`;

export const paymentScheduleCSS = css`
  margin-top: 24px;
`;

export const dashedRowsContainerCSS = css`
  margin-top: 18px;
  ${mq[0]} {
    margin-top: 26px;
  }
`;

export const dashedRowCSS = css`
  font-weight: 500;
  margin-top: 10px;
  border-color: ${theme.colors.gray30};
  span {
    color: ${theme.colors.black};
  }
  & span:last-of-type {
    font-weight: 700;
  }
`;

export const totalCSS = css`
  ${text24CSS};
  font-weight: 700;
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
`;

export const totalValueCSS = css`
  color: ${theme.colors.positiveGreen27};
`;
