import { isTrackForbidden } from '../../../utils/form-element-tags.util';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import QuestionInput from '../QuestionInput/QuestionInput';
import { EMAIL_PATTERN_VALIDATION } from './EmailQuestions.util';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const EmailQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  description,
  hint,
  icon,
  workflowHeading,
  isDisabled,
  placeholder,
  validations = [],
  gridWidth,
  dense,
  tags = [],
}) => (
  <QuestionWrapper
    questionKey={questionKey}
    label={label}
    hideLabel={hideLabel}
    hint={hint}
    description={description}
    icon={icon}
    workflowHeading={workflowHeading}
    isDisabled={isDisabled}
    gridWidth={gridWidth}
    inputProps={{
      type: 'email',
      name: questionKey,
      isDisabled,
      inputId: questionKey,
      placeholder,
      validations: [EMAIL_PATTERN_VALIDATION, ...validations],
      trackingForbidden: isTrackForbidden(tags),
      dense,
    }}
    inputComponent={QuestionInput}
  />
);

export default EmailQuestion;
