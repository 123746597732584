import { Tooltip as MuiTooltip } from '@mui/material';
import { useId } from 'react';
import { muiTooltipCSS, tooltipArrowCSS, tooltipContentCSS } from '../../../styles/common.style';
import { tooltipCSS } from './Tooltip.style';
import { TooltipTheme, type TooltipProps } from './Tooltip.type';
import type { FC } from 'react';

const Tooltip: FC<TooltipProps> = ({ id, children, content, customCSS, theme = TooltipTheme.Light, position }) => {
  const generatedId = useId();
  const key = id ?? generatedId;

  const tooltipProps = {
    id: `tooltip-${key}`,
    arrow: true,
    componentsProps: {
      tooltip: { sx: { ...muiTooltipCSS(theme), ...customCSS?.tooltipCSS } },
      arrow: { sx: { ...tooltipArrowCSS(theme) } },
    },
    title: <div css={tooltipContentCSS(theme)}>{content}</div>,
    enterTouchDelay: 0,
    leaveTouchDelay: 100000,
    placement: position,
    PopperProps: {
      style: { zIndex: 2 },
    },
  };

  return (
    <MuiTooltip {...tooltipProps}>
      <button type="button" css={[tooltipCSS, customCSS?.buttonCSS]}>
        {children}
      </button>
    </MuiTooltip>
  );
};

export default Tooltip;
