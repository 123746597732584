import { css } from '@emotion/react';
import { TooltipTheme } from '../shared/components/Tooltip/Tooltip.type';
import { mq, mqCalendly } from './media-queries';
import theme from './theme';
import { text14CSS } from './typography.style';
import type { SerializedStyles } from '@emotion/react';

/**
 * MUI Tooltip styles
 * */

const lightTooltip = {
  backgroundColor: theme.colors.white,
  maxWidth: '360px',
  padding: '0',
  borderRadius: '4px',
  border: `1px solid ${theme.colors.gray30}`,
  boxShadow: '2px 4px 0px 0px rgba(0, 0, 0, 0.12)',
  margin: '12px !important',
};

const darkTooltip = {
  backgroundColor: `${theme.colors.gray60}`,
  borderRadius: '0',
  padding: '0',
  margin: '12px !important',
};

const lightTooltipArrow = {
  color: `${theme.colors.white}`,

  '&:before': {
    border: `1px solid ${theme.colors.gray30}`,
  },
};

const darkTooltipArrow = { color: `${theme.colors.gray10}` };

export const muiTooltipCSS = (tooltipTheme?: TooltipTheme): Record<string, string> => {
  return tooltipTheme === TooltipTheme.Light ? lightTooltip : darkTooltip;
};

export const tooltipArrowCSS = (tooltipTheme?: TooltipTheme): Record<string, string> =>
  tooltipTheme === TooltipTheme.Light ? lightTooltipArrow : darkTooltipArrow;

export const tooltipContentCSS = (tooltipTheme: TooltipTheme = TooltipTheme.Light): SerializedStyles => css`
  color: ${tooltipTheme === TooltipTheme.Light ? theme.colors.gray80 : theme.colors.white};
  padding: ${tooltipTheme === TooltipTheme.Light ? '20px' : '8px'};
  ${text14CSS};
  line-height: 20px;
  a {
    color: ${theme.colors.azure50};
  }
  p:not(:last-of-type) {
    margin-bottom: 8px;
  }
`;

/**
 * Error pages styles
 * */
export const errorPageAnchorButtonCSS = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: unset;

  ${mq[0]} {
    width: auto;
  }
`;

export const errorPageButtonIconCSS = css`
  margin-right: 9px;
  width: 19px;
  height: 19px;

  ${mq[0]} {
    margin-right: 12px;
  }
`;

export const errorPageActionButtonCSS = css`
  width: 100%;

  ${mq[0]} {
    width: auto;
  }
`;

export const errorPageSectionCSS = css`
  padding: 24px 0 54px;

  ${mq[0]} {
    padding: 87px 0 120px;
  }
`;

export const errorPageCenterContainerCSS = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 0 20px;

  ${mq[0]} {
    padding: 0;
  }
`;

/**
 * Calendly styles
 * */
export const maxCalendlyLikeWidthContainerCSS = css`
  max-width: 100%;

  ${mqCalendly[0]} {
    max-width: 680px;
  }

  ${mqCalendly[1]} {
    max-width: 800px;
  }
`;

const CALENDLY_WIDGET_HEIGHT = {
  sm: [1015, 1015, 1025, 560],
  md: [1074, 1074, 1120, 570],
  lg: [700],
};

export const calendlyWidgetCSS = (step: number): SerializedStyles => css`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.gray30};
  border-radius: ${theme.sizes.borderRadius}px;
  height: ${CALENDLY_WIDGET_HEIGHT.sm[step]}px;
  box-shadow: ${theme.boxShadow.container};
  margin-bottom: 20px;

  iframe {
    border-radius: ${theme.sizes.borderRadius}px;
  }

  ${mqCalendly[0]} {
    border: unset;
    box-shadow: unset;
    height: ${CALENDLY_WIDGET_HEIGHT.md[step]}px;
    margin-bottom: 0;

    &:after,
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      background-color: ${theme.colors.white};
    }

    &:after {
      top: 0;
      height: 60px;
    }

    &:before {
      bottom: 0;
      height: 26px;
    }
  }

  ${mqCalendly[1]} {
    height: ${CALENDLY_WIDGET_HEIGHT.lg[0]}px;
    min-width: 320px;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }
`;
