export enum Flows {
  Auto = 'auto',
  Home = 'home',
  Condo = 'condo',
}

export interface DefaultLocation extends Record<string, string> {
  gid: string;
  flow: Flows;
}
