import { css } from '@emotion/react';
import { pageHeadingCSS } from '../../../../styles/layout.style';
import { mq } from '../../../../styles/media-queries';

export const headingCSS = css`
  ${pageHeadingCSS};
  text-align: left;
`;

export const formContainerCSS = css`
  ${mq[0]} {
    max-width: 580px;
  }
  ${mq[1]} {
    flex-grow: 1;
  }
`;

export const contentCSS = css`
  ${mq[1]} {
    display: flex;
    justify-content: space-between;
  }
`;

export const checkoutSummaryCSS = css`
  margin-bottom: 32px;
  position: sticky;
  top: calc(var(--header-height) + 8px);
  z-index: 2;

  &:before {
    position: absolute;
    content: '';
    top: -12px;
    bottom: -12px;
    left: -4px;
    right: -4px;
    background: #fff;
    z-index: -1;
  }
`;
