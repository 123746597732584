import { FS_SENSITIVE_DATA_CLASS } from '../../../constants/full-story';
import {
  containerCSS,
  containerInnerCSS,
  blockIconCSS,
  descriptionCSS,
  headingCSS,
  headingIconCSS,
  subHeadingCSS,
} from './TextFormBlock.style';
import { IconSize } from './TextFormBlock.type';
import type { TextFormBlockProps } from './TextFormBlock.type';
import type { FC } from 'react';

const TextFormBlock: FC<TextFormBlockProps> = ({
  customCSS,
  heading,
  subheading,
  description,
  headingIconUrl,
  blockIconUrl,
  iconSize,
  isLineHidden,
  isPaddingBottomHidden,
  trackForbidden,
}) => {
  return (
    <div css={[containerCSS(isLineHidden, isPaddingBottomHidden), customCSS]}>
      <div css={containerInnerCSS}>
        {blockIconUrl && (
          <img
            css={blockIconCSS(iconSize)}
            src={blockIconUrl}
            alt=""
            height={iconSize === IconSize.Small ? '48' : '88'}
          />
        )}
        <div>
          {subheading && <div css={subHeadingCSS}>{subheading}</div>}
          <div>
            {headingIconUrl && <img css={headingIconCSS} src={headingIconUrl} alt="" height="24" />}
            <span css={headingCSS} className={trackForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}>
              {heading}
            </span>
          </div>
          {description && <div css={descriptionCSS} dangerouslySetInnerHTML={{ __html: description }} />}
        </div>
      </div>
    </div>
  );
};

export default TextFormBlock;
