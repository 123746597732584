import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { PersonUpdatePayload, Person } from '../../types/person.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useMutatePrimaryInsured = (
  gid: string
): UseBaseMutationResult<{ person: Person }, HTTPResponse, PersonUpdatePayload> => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      return await api
        .patch<HTTPResponse<{ person: Person }>>(getApiUrl(API_PATH.primaryInsured, gid), {
          body: { data },
        })
        .then((res) => res.data);
    },
    onSuccess: async (data) => client.setQueryData([QUERY_CACHE_KEYS.primaryInsured, gid], data),
  });
};

export default useMutatePrimaryInsured;
