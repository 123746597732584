import { css } from '@emotion/react';
import './styles/normalize.css';
import { mq } from './styles/media-queries';
import theme from './styles/theme';

export const globalCSS = css`
  body {
    font-family: ${theme.typography.fontFamily}, sans-serif;
    font-size: ${theme.typography.fontSize};
    line-height: ${theme.typography.lineHeight};
    font-variant-numeric: lining-nums;
    font-weight: 500;
    .select__menu-portal {
      z-index: 1400; // Modal has 1300. This is to ensure the dropdown is always on top of the modal
    }
  }

  :root {
    --header-height: ${theme.header.headerHeightMobile}px;

    ${mq[0]} {
      --header-height: ${theme.header.headerHeightDesktop}px;
    }
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td {
    margin: 0;
    padding: 0;
    border: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  p {
    margin: 0;
  }

  input[type='search'],
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-decoration {
    appearance: none;
  }

  ::placeholder {
    color: ${theme.colors.gray60};
    font-style: italic;
  }
`;
