import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type {
  RecaptchaVerifyResponse,
  RecaptchaSolvePuzzleResponse,
  RecaptchaVerifyProps,
  RecaptchaSolvePuzzleProps,
} from '../../types/recaptcha.type';

const recaptchaService = {
  verifyRecaptchaV3Token(gid: string, data: RecaptchaVerifyProps): Promise<RecaptchaVerifyResponse> {
    return api.put(getApiUrl(API_PATH.recaptchaVerify, gid), { body: { data: { ...data } } });
  },
  updateRecaptchaSolvePuzzle(gid: string, data: RecaptchaSolvePuzzleProps): Promise<RecaptchaSolvePuzzleResponse> {
    return api.put(getApiUrl(API_PATH.recaptchaSolvePuzzle, gid), { body: { data: { ...data } } });
  },
};

export default recaptchaService;
