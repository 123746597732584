import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { PageResponse } from '../../types/page.type';
import type { SectionSchema } from '../../types/section.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const usePage = <T = SectionSchema>(gid?: string, pageKey?: string): QueryObserverResult<PageResponse<T>> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.page, gid, pageKey],
    queryFn: async () =>
      await api
        .get<HTTPResponse<PageResponse<T>>>(getApiUrl(API_PATH.page, gid as string, pageKey as string))
        .then((res) => res.data),
    enabled: !!gid && !!pageKey,
  });

export default usePage;
