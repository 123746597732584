import { css } from '@emotion/react';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  margin-bottom: 20px;
`;

export const disclosureCSS = css`
  margin-top: 20px;
`;

export const headingCSS = css`
  font-weight: 700;
`;

export const disclosureHeadingCSS = css`
  margin-bottom: 15px;
`;

export const starCSS = css`
  color: ${theme.colors.errorRed};
`;
