import { css } from '@emotion/react';
import { activeElementResetCSS } from '../../../../../shared/shared.style';
import { formWithGridCSS } from '../../../../../styles/form.style';

export const formCSS = css`
  ${activeElementResetCSS};
  ${formWithGridCSS};
`;

export const buttonCSS = css`
  margin-top: 12px;
`;
