import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import { text20CSS, text40CSS } from '../../../../styles/typography.style';

export const containerCSS = css`
  padding-top: 12px;
  padding-bottom: 32px;
  ${mq[0]} {
    padding-top: 42px;
    padding-bottom: 90px;
  }
`;

export const titleCSS = css`
  ${text20CSS};
  font-weight: 700;
  text-align: center;
  margin-bottom: 24px;
  span {
    display: none;
  }
  ${mq[0]} {
    ${text40CSS};
    margin-bottom: 16px;
    span {
      display: inline;
    }
  }
`;

export const subtitleCSS = css`
  display: none;
  text-align: center;
  ${mq[0]} {
    display: block;
    margin-bottom: 0;
  }
`;

export const loaderCSS = css`
  margin-top: 24px;
`;
