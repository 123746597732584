import { format } from 'date-fns';
import { useState } from 'react';
import useResponsive from '../../../../../hooks/use-responsive';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import Divider from '../../../../../shared/components/Divider/Divider';
import Logo from '../../../../../shared/components/Logo/Logo';
import { getCents, removeCents } from '../../../../../shared/utils/money.util';
import { noopAsync } from '../../../../../shared/utils/noop';
import formatUtil from '../../../../../utils/format.util';
import QuoteDiscounts from '../../QuoteDiscounts/QuoteDiscounts';
import DeductibleControl from '../DeductibleControl/DeductibleControl';
import {
  annualCSS,
  buttonCSS,
  deductibleCSS,
  deductibleInputCSS,
  deductibleLabelCSS,
  detailsCSS,
  dividerCSS,
  dotCSS,
  effectiveDateCSS,
  logoCSS,
  mobileListCSS,
  priceCSS,
  priceWrapperCSS,
  savingsAndDiscountsBlockCSS,
  savingsBadgeCSS,
} from './HomePolicyDetails.style';
import type { HomePolicyDetailsProps } from './HomePolicyDetails.types';
import type { FC } from 'react';

const HomePolicyDetails: FC<HomePolicyDetailsProps> = ({
  premium,
  carrierLogo,
  carrierName,
  effectiveDate,
  savings,
  discounts,
  onSubmit = noopAsync,
}) => {
  const { isDesktop, isTablet } = useResponsive();
  const [isLoading, setIsLoading] = useState(false);

  const premiumDollars = formatUtil.money(String(removeCents(premium)), 0, '$');

  const onClick = async (): Promise<void> => {
    setIsLoading(true);
    await onSubmit();
    setIsLoading(false);
  };

  if (isDesktop) {
    return (
      <div css={detailsCSS}>
        <div>
          <Logo carrierLogo={carrierLogo} carrierName={carrierName} customCSS={logoCSS} height={45} />
          <div>
            <span css={effectiveDateCSS}>
              <strong>Policy start date:</strong> {format(new Date(effectiveDate), 'M/d/yyyy')}
            </span>
            <Button customCSS={buttonCSS} size={ButtonSize.Large} isLoading={isLoading} onClick={onClick}>
              Continue with Purchase
            </Button>
          </div>
        </div>
        <div>
          {(!!savings || discounts.length > 0) && (
            <div css={savingsAndDiscountsBlockCSS}>
              {!!savings && <div css={savingsBadgeCSS}>${formatUtil.money(savings)} Savings Per Year</div>}
              {!!savings && discounts.length > 0 && <span css={dotCSS} />}
              {discounts.length > 0 && <QuoteDiscounts discounts={discounts} />}
            </div>
          )}
          <div css={priceWrapperCSS}>
            <div>
              <div css={priceCSS} data-testid="premium-value">
                {premiumDollars}
                <span>.{getCents(premium)}/yr</span>
              </div>
              <p css={annualCSS}>Annual Premium</p>
            </div>
            <Divider customCSS={dividerCSS} aria-label="Divider" height={52} />
            <DeductibleControl />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div css={detailsCSS}>
      <Logo carrierLogo={carrierLogo} carrierName={carrierName} customCSS={logoCSS} height={35} />

      {(!!savings || discounts.length > 0) && (
        <div css={savingsAndDiscountsBlockCSS}>
          {!!savings && <div css={savingsBadgeCSS}>${formatUtil.money(savings)} Savings/Year</div>}
          {!!savings && discounts.length > 0 && <span css={dotCSS} />}
          {discounts.length > 0 && <QuoteDiscounts discounts={discounts} />}
        </div>
      )}

      <div css={priceWrapperCSS}>
        <div css={priceCSS} data-testid="premium-value">
          {premiumDollars}
          <span>.{getCents(premium)}/yr</span>
        </div>
        <p css={annualCSS}>Annual Premium</p>
      </div>
      <DeductibleControl
        customCSS={{
          container: deductibleCSS,
          label: deductibleLabelCSS,
          input: deductibleInputCSS,
        }}
      />

      <div css={mobileListCSS}>
        <div>Policy start date:</div>
        <strong>{format(new Date(effectiveDate), 'M/d/yyyy')}</strong>
      </div>
      <Button
        customCSS={buttonCSS}
        size={isTablet ? ButtonSize.Large : ButtonSize.Normal}
        isLoading={isLoading}
        onClick={onClick}
      >
        Continue with Purchase
      </Button>
    </div>
  );
};

export default HomePolicyDetails;
