import { useParams } from 'react-router-dom';
import { useSession } from '../../../../api/session';
import { SEGMENT } from '../../../../constants/analytics';
import useResponsive from '../../../../hooks/use-responsive';
import { useSegment } from '../../../../hooks/use-segment';
import Anchor from '../../../../shared/components/Anchor/Anchor';
import SirvImage from '../../../../shared/components/SirvImage/SirvImage';
import {
  contactHeadingCSS,
  contactItemCSS,
  contactsCSS,
  containerCSS,
  contentCSS,
  descriptionCSS,
  externalUrlCSS,
  externalUrlPrefixCSS,
  headingCSS,
  imageCSS,
  stepNumberCSS,
} from './CongratulationsInstructionStep.style';
import type { CongratulationsInstructionStepProps } from './CongratulationsInstructionStep.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const CongratulationsInstructionStep: FC<CongratulationsInstructionStepProps> = ({
  customCSS,
  showLine = false,
  stepNumber,
  heading,
  description,
  iconUrl,
  email,
  phone,
  url,
}) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const { isMobile } = useResponsive();
  const { track } = useSegment();

  const currentPageKey = sessionData?.session.current_page_key;

  const onCallClick = (): void =>
    track(SEGMENT.events.phoneConversationInitiated, {
      page: currentPageKey,
      location: SEGMENT.locations.page,
    });

  return (
    <div css={[containerCSS(isMobile && showLine), customCSS]}>
      <SirvImage customCSS={imageCSS} url={iconUrl} width={140} height={140} alt="" />
      <div css={contentCSS}>
        <div css={stepNumberCSS}>{stepNumber}</div>
        <div css={headingCSS}>{heading}</div>
        <div css={descriptionCSS} dangerouslySetInnerHTML={{ __html: description }} />
        {url && (
          <div css={descriptionCSS}>
            {url.prefix_text && <div css={externalUrlPrefixCSS}>{url.prefix_text}</div>}
            <Anchor customCSS={externalUrlCSS} href={url.value}>
              {url.label}
            </Anchor>
          </div>
        )}
        {email && phone && (
          <div css={contactsCSS}>
            <div css={contactItemCSS}>
              <div css={contactHeadingCSS}>Email</div>
              <Anchor anchorType="underline-active" href={`mailto:${email}`}>
                {email}
              </Anchor>
            </div>
            <div css={contactItemCSS}>
              <div css={contactHeadingCSS}>Phone</div>
              <Anchor anchorType="underline-active" href={`tel:${phone}`} onClick={onCallClick}>
                {phone}
              </Anchor>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CongratulationsInstructionStep;
