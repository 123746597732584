import { css, type SerializedStyles } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = (clearPadding?: boolean): SerializedStyles => css`
  position: relative;
  border-color: ${theme.colors.gray30};
  padding: ${clearPadding ? '0 0 50px' : '16px 16px 50px 16px'};
  margin-top: 46px;
  border-radius: 0;
  border-left: none;
  border-right: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.12);
  width: 99%;
  max-width: 804px;

  ${mq[0]} {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding: ${clearPadding ? '0 0 50px' : '24px 24px 50px 24px'};
    max-width: 804px;
    border-left: 1px solid ${theme.colors.gray30};
    border-right: 1px solid ${theme.colors.gray30};
    border-radius: 4px;
    box-shadow: 2px 4px 0 rgba(0, 0, 0, 0.12);
  }
`;

export const toggleCSS = css`
  cursor: pointer;
  position: relative;
  border: none;
  color: ${theme.colors.azure50};
  font-weight: 700;
  background-color: transparent;
  padding-right: 20px;
`;

export const toggleWrapperCSS = css`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid ${theme.colors.gray10};
`;

export const contentCSS = css`
  border: none;
  width: 100%;
`;

export const accordionArrowCSS = (expanded: boolean): SerializedStyles => css`
  height: 10px;
  width: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &::after,
  &::before {
    background-color: ${theme.colors.azure50};
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    top: 50%;
    transition: transform 0.25s ease;
    width: 10px;
  }

  &::before {
    left: 4px;
    transform: rotate(-45deg);
  }

  &::after {
    right: 4px;
    transform: rotate(45deg);
  }

  ${expanded && expandedCSS};
`;

const expandedCSS = css`
  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
`;
