import PhoneIcon from '../../../../assets/phone.svg?react';
import ErrorPageMessage from '../../../../components/ErrorPageMessage/ErrorPageMessage';
import { SEGMENT } from '../../../../constants/analytics';
import { CONTACT_NUMBER } from '../../../../constants/contacts';
import { useSegment } from '../../../../hooks/use-segment';
import Anchor from '../../../../shared/components/Anchor/Anchor';
import Button from '../../../../shared/components/Button/Button';
import { buttonCSS } from '../../../../shared/components/Button/Button.style';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import Container from '../../../../shared/components/Container/Container';
import Footer from '../../../../shared/components/Footer/Footer';
import Header from '../../../../shared/components/Header/Header';
import noop from '../../../../shared/utils/noop';
import {
  errorPageActionButtonCSS,
  errorPageAnchorButtonCSS,
  errorPageButtonIconCSS,
  errorPageCenterContainerCSS,
  errorPageSectionCSS,
} from '../../../../styles/common.style';
import { fullHeightPageCSS } from '../../../../styles/layout.style';
import type { ErrorFallbackContentProps } from './ErrorFallbackContent.type';
import type { FC } from 'react';

const ErrorFallbackContent: FC<ErrorFallbackContentProps> = ({ resetError = noop }) => {
  const { track } = useSegment();

  const onPhoneClicked = (location: string): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: SEGMENT.pages.error.name,
      location,
    });
  };

  return (
    <div css={fullHeightPageCSS}>
      <Header
        phoneNumber={CONTACT_NUMBER}
        showContacts
        denseContainer
        onPhoneClick={() => onPhoneClicked(SEGMENT.locations.header)}
      />
      <main>
        <section css={errorPageSectionCSS}>
          <Container dense>
            <div css={errorPageCenterContainerCSS}>
              <ErrorPageMessage
                title="An unexpected error occurred"
                description={
                  <>
                    <strong>You can still purchase your policy.</strong> Please try to refresh a page to continue or
                    call a Matic agent to finalize the policy.
                  </>
                }
                hideSpamFreeAndSecure
              >
                <Button css={errorPageActionButtonCSS} size={ButtonSize.Large} onClick={resetError}>
                  Try to Continue
                </Button>
                <Anchor
                  css={[buttonCSS(true, 'secondary'), errorPageAnchorButtonCSS]}
                  href={`tel:${CONTACT_NUMBER}`}
                  onClick={() => onPhoneClicked(SEGMENT.locations.page)}
                >
                  <PhoneIcon css={errorPageButtonIconCSS} />
                  Call an Agent Now
                </Anchor>
              </ErrorPageMessage>
            </div>
          </Container>
        </section>
      </main>
      <Footer
        phone={CONTACT_NUMBER}
        denseContainer
        onSocialLinkClick={() => onPhoneClicked(SEGMENT.locations.footer)}
      />
    </div>
  );
};

export default ErrorFallbackContent;
