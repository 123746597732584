import { ReactSVG } from 'react-svg';
import { visuallyHiddenCSS } from '../../shared.style';
import Wrapper from '../Wrapper/Wrapper';
import { containerCSS, containerSingleColorCSS, imageCSS, imageSingleColorCSS } from './IconButton.style';
import type { IconButtonProps } from './IconButton.type';
import type { FC } from 'react';

const IconButton: FC<IconButtonProps> = ({
  iconType = 'default',
  src,
  wrapperElement = 'button',
  iconText,
  target,
  customCSS,
  ...props
}) => {
  const singleColor = iconType === 'single-color';

  const elProps = {
    ...props,
    rel: wrapperElement === 'a' && target === '_blank' ? 'noopener noreferrer' : undefined,
    type: wrapperElement === 'button' ? 'button' : undefined,
    target,
  };

  return (
    <Wrapper tag={wrapperElement} css={[customCSS, containerCSS, singleColor && containerSingleColorCSS]} {...elProps}>
      <ReactSVG src={src} css={[imageCSS, singleColor && imageSingleColorCSS]} />
      {iconText && <span css={visuallyHiddenCSS}>{iconText}</span>}
    </Wrapper>
  );
};

export default IconButton;
