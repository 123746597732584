import type { HomeQuoteCoverage } from '../../../../../types/quote.type';
import type { QuoteCoveragesGroup } from '../../QuoteCoverageGroup/QuoteCoverageGroup.type';

const POLICY_HOME_COVERAGES_GROUP = {
  assetGid: 'without_asset',
  title: "What's covered",
};

export const generateCoveragesGroup = (coverages: HomeQuoteCoverage[]): QuoteCoveragesGroup[] => [
  {
    ...POLICY_HOME_COVERAGES_GROUP,
    coverages: coverages.map((el) => ({
      ...el,
      iconUrl: el.icon_url,
    })),
  },
];
