export const HEADERS = [
  'Gathering policy details...',
  'Connecting with carrier...',
  'Payment processing...',
  'Finalizing your purchase...',
];

export const getSubheader = (index: number, carrierName: string = 'your carrier'): string => {
  const subheader = [
    'We’re preparing to send your policy details to the carrier. This process can take up to 30 seconds.',
    `Hold tight! Establishing a secure connection with ${carrierName}`,
    'We’re securely processing your payment information.',
    'Almost there! Just a moment while we tidy up.',
  ];

  return subheader[index];
};
