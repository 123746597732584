import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePage } from '../../../../api/page';
import { useMutateSession, useSession } from '../../../../api/session';
import { SEGMENT } from '../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../constants/query-cache-keys';
import SCHEMA_KEYS from '../../../../constants/schema-hardcoded-keys';
import { useSegment } from '../../../../hooks/use-segment';
import QuestionPageHeading from '../../../../questionsForm/components/QuestionPageHeading/QuestionPageHeading';
import QuestionFormLoading from '../../../../questionsForm/components/QuestionsFormLoading/QuestionFormLoading';
import Button from '../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import { extraLayoutPaddingCSS, innerLayoutContainerCSS } from '../../../../styles/layout.style';
import { getCurrentNavigationPage, getNextNavigationKeys } from '../../../../utils/current-form-keys.util';
import { getPathByPageType } from '../../../../utils/route-path.util';
import { useAutoInformationActions, useAutoInformationData } from '../../contexts/auto-information-context';
import DriversListContainer from '../DriversListContainer/DriversListContainer';
import VehiclesListContainer from '../VehiclesListContainer/VehiclesListContainer';
import { containerCSS } from './AutoInformationPage.style';
import { DRIVER_CONTAINER, VEHICLE_CONTAINER, scrollToError } from './AutoInformationPage.util';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { WorkflowSectionSchema } from '../../../../types/section.type';
import type { SessionResponse } from '../../../../types/session.type';
import type { FC, ReactNode } from 'react';

const AutoInformationPage: FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData } = usePage<WorkflowSectionSchema>(gid, currentPageKey);

  const { vehicles, editingState } = useAutoInformationData();
  const { triggerValidation } = useAutoInformationActions();
  const { mutateAsync: mutateSession, isPending: isSubmitting } = useMutateSession(gid);
  const queryClient = useQueryClient();

  const pageViewedTracked = useRef(false);
  const { page, track } = useSegment();
  const navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState<number>(1);

  const containerScrollPointRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (currentPageKey && !pageViewedTracked.current) {
      page(currentPageKey);
      pageViewedTracked.current = true;
    }
  }, [currentPageKey, page]);

  useEffect(() => {
    if (currentStep > 1 && containerScrollPointRef.current) {
      const timerId = setTimeout(() => {
        if (containerScrollPointRef?.current) {
          containerScrollPointRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 500);

      return () => clearTimeout(timerId);
    }
  }, [currentStep, containerScrollPointRef]);

  const checkNextPage = (updatedSessionData: SessionResponse): void => {
    const nextCurrentPage = getCurrentNavigationPage(updatedSessionData);
    if (nextCurrentPage) {
      navigate(getPathByPageType(nextCurrentPage?.page_type, gid, flow), { replace: true });
    }
  };

  const onContainerSubmit = (): void => {
    if (!validateForm()) return;
    setCurrentStep((prev) => prev + 1);
    track(SEGMENT.events.answersSubmitted, {
      section_key: SCHEMA_KEYS.vehiclesSectionKey,
    });
  };

  const validateForm = (): boolean => {
    const noVehiclesAdded = vehicles.length === 0;

    if (noVehiclesAdded || editingState.length > 0) {
      const elementId = noVehiclesAdded ? VEHICLE_CONTAINER : undefined;
      triggerValidation();
      scrollToError(elementId);
      return false;
    }
    return true;
  };

  const onSubmit = async (): Promise<void> => {
    if (!validateForm()) return;

    const { pageKey, sectionKey } = getNextNavigationKeys(sessionData as SessionResponse, pageData?.answers);
    const newSessionData = await mutateSession({
      current_page_key: pageKey,
      current_section_key: sectionKey,
      completed_page_key: currentPageKey,
    });

    track(SEGMENT.events.answersSubmitted, {
      section_key: SCHEMA_KEYS.driversSectionKey,
    });

    track(SEGMENT.events.pageCompleted, {
      page: currentPageKey,
    });

    queryClient.setQueryData([QUERY_CACHE_KEYS.session, gid], newSessionData);

    checkNextPage(newSessionData);
  };

  if (!sessionData || !pageData) {
    return (
      <div css={[innerLayoutContainerCSS(false), extraLayoutPaddingCSS, containerCSS]}>
        <QuestionFormLoading />
      </div>
    );
  }
  const componentsToRender = [
    <VehiclesListContainer key={VEHICLE_CONTAINER} isCurrent={currentStep === 1} onSubmit={onContainerSubmit} />,
    <DriversListContainer key={DRIVER_CONTAINER} isCurrent={currentStep === 2} />,
  ];

  const renderComponents = (): ReactNode => componentsToRender.filter((_, index) => index < currentStep);

  return (
    <div css={[innerLayoutContainerCSS(false), extraLayoutPaddingCSS, containerCSS]}>
      {pageData?.page.content_heading && (
        <QuestionPageHeading
          heading={pageData.page.content_heading}
          description={pageData.page.content_description ?? ''}
          icon={pageData.page.content_icon_url ?? ''}
          showSkipButton
        />
      )}
      {renderComponents()}
      <div ref={containerScrollPointRef} />
      {componentsToRender.length === currentStep && (
        <Button type="button" fullWidth size={ButtonSize.Large} onClick={onSubmit} isLoading={isSubmitting}>
          Complete Auto
        </Button>
      )}
    </div>
  );
};

export default AutoInformationPage;
