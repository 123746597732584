export enum ContactFlowRequester {
  Customer = 'customer',
  AgencyRepresentative = 'agency_representative',
}

export interface ContactFlowsResponse {
  contact_flows: Array<{
    person_gid: string;
    flow: string;
    run_at: string; // ISO 8601 UTC
    created_at: string;
    engagement_gid: string;
    engagement_timezone: string;
    finished: boolean;
    phone: string;
    requester: ContactFlowRequester;
    status: string;
  }>;
}
