import { useParams } from 'react-router-dom';
import { usePage } from '../../api/page';
import { useSession } from '../../api/session';
import SkeletonLoader from '../../shared/components/SkeletonLoader/SkeletonLoader';
import { type DefaultLocation } from '../../types/route-params.type';
import isVisibleByAnswers from '../../utils/visibility-conditions.util';
import { containerCSS, dotCSS, itemCSS, labelCSS } from './FlowNavigationHorizontal.style';
import type { NavigationPage, FlowNavigationHorizontalProps } from './FlowNavigationHorizontal.type';
import type { Answers } from '../../types/answer.type';
import type { SessionNavigationItem } from '../../types/session.type';
import type { FC } from 'react';

const FlowNavigationHorizontal: FC<FlowNavigationHorizontalProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData, isPending: isSessionPending } = useSession(gid);
  const currentPage = sessionData?.session.current_page_key ?? '';
  const pagesKeys = sessionData?.session.navigation.map((p) => p.key) ?? [];
  const currentPageIndex = pagesKeys.indexOf(currentPage);
  const navigation = sessionData?.session.navigation ?? [];
  const { data: pageData, isPending: isPagePending } = usePage(gid, currentPage);

  const pageLoaded = !isSessionPending && !isPagePending;

  const isPageVisible = (page: SessionNavigationItem, answers?: Answers): boolean => {
    if (page.visibility_conditions?.length === 0 || !answers) {
      return page.display_in_navigation;
    }
    return isVisibleByAnswers(answers, page.visibility_conditions);
  };

  const pages: NavigationPage[] = navigation.reduce<NavigationPage[]>((acc, p, index) => {
    const completed = index < currentPageIndex;
    const unavailable = index > currentPageIndex;

    return isPageVisible(p, pageData?.answers)
      ? [
          ...acc,
          {
            name: p.navigation_heading || '',
            key_name: p.key,
            current: p.key === currentPage,
            completed,
            unavailable,
          },
        ]
      : acc;
  }, []);

  return (
    <div css={[containerCSS(pageLoaded), customCSS]}>
      {!pageLoaded && <SkeletonLoader absoluteWidth={350} absoluteHeight={30} />}
      {pageLoaded &&
        pages.map((p, index) => (
          <div css={itemCSS(index === 0, pages.length - 1 === index, p.unavailable, p.current)} key={p.key_name}>
            <div css={dotCSS(p.completed, p.unavailable)} />
            <div css={labelCSS(p.current)}>{p.name}</div>
          </div>
        ))}
    </div>
  );
};

export default FlowNavigationHorizontal;
