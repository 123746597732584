import type { SchemaTooltip, SchemaTooltipKey } from './interpolation.util.type';

export const DATA_POINT_INTERPOLATION_REGEX = /%data_point{{([^}]+)}}/g;
export const TOOLTIP_INTERPOLATION_REGEX = /%tooltip{{([^}]+)}}/g;

export const tooltips: Record<SchemaTooltipKey, SchemaTooltip> = {
  circuit_breakers: {
    title: 'circuit breakers',
    description: `Your home has circuit breakers if you can reset a breaker by flipping a switch.
      Fuses need to be replaced every time they blow.`,
    image: 'https://maticinsurance.sirv.com/olb/ui/circuit-breakers.png',
  },
};
