import { Global } from '@emotion/react';
import { useParams } from 'react-router-dom';
import { usePublicConfig } from '../../../../api/publicConfig';
import { useInitQuoteRequestSubscription } from '../../../../api/quote';
import { useSessionFlowConfig } from '../../../../api/session-flow-config';
import QuotePageLoader from '../../../../components/QuotePageLoader/QuotePageLoader';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import { hideFooterCSS } from '../../../../shared/shared.style';
import { getPrimaryQuote } from '../../../../utils/quote.util';
import { loaderCSS, loaderImageCSS } from './HomeQuotePage.style';
import HomeQuotePageContent from './HomeQuotePageContent/HomeQuotePageContent';
import type { HomeQuotePageProps } from './HomeQuotePage.type';
import type { HomeQuote } from '../../../../types/quote.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const HomeQuotePage: FC<HomeQuotePageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionFlowConfig, isPending: isSessionFlowConfigLoading } = useSessionFlowConfig(gid);
  const { data: configData } = usePublicConfig();
  const webSocketUrl = configData?.config.websocket_url;

  const { quoteRequest } = useInitQuoteRequestSubscription<HomeQuote>({
    gid,
    webSocketUrl,
  });

  const quotes = quoteRequest?.groups[0]?.quotes ?? [];

  const quote = getPrimaryQuote<HomeQuote>(quotes, sessionFlowConfig?.flow_config?.carrier_key);

  if (!quote || isSessionFlowConfigLoading) {
    return (
      <>
        <Global styles={hideFooterCSS} />
        <QuotePageLoader customCSS={loaderCSS} customImageCSS={loaderImageCSS} imgUrl={SIRV_IMAGES.homeLoader} />
      </>
    );
  }

  return (
    <div css={customCSS}>
      <HomeQuotePageContent quote={quote} />
    </div>
  );
};

export default HomeQuotePage;
