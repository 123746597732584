import { useState, type FC, type ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMutateAnswers } from '../../../../../api/page';
import { useSession } from '../../../../../api/session';
import QuestionLabel from '../../../../../questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel';
import Select from '../../../../../shared/components/Select/Select';
import noop, { noopAsync } from '../../../../../shared/utils/noop';
import { PaymentMethod } from '../../../../../types/public-flow-config.type';
import CheckoutCreditCardStep from '../CheckoutCreditCardStep/CheckoutCreditCardStep';
import CheckoutMortgageStep from '../CheckoutMortgageStep/CheckoutMortgageStep';
import CheckoutStepTitle from '../CheckoutStepTitle/CheckoutStepTitle';
import { StepTitleButtonType } from '../CheckoutStepTitle/CheckoutStepTitle.type';
import { labelCSS, paymentMethodCSS } from './CheckoutPaymentStep.style';
import {
  DIRECT_BILL_STEP_TITLE,
  ESCROW_STEP_TITLE,
  PAYMENT_METHOD_QUESTION,
  PAYMENT_METHOD_QUESTION_KEY,
  PAYMENT_METHOD_STEP_TITLE,
} from './CheckoutPaymentStep.util';
import type { CheckoutSecondStepProps } from './CheckoutPaymentStep.type';
import type { CheckoutSecondStepData } from './CheckoutPaymentStep.util';
import type { DefaultLocation } from '../../../../../types/route-params.type';

const CheckoutPaymentStep: FC<CheckoutSecondStepProps> = ({
  customCSS,
  currentStepNumber,
  paymentMethods,
  stepNumber,
  actionLabel,
  hideStepNumber = false,
  onSetCurrent = noop,
  onBeforeStepSubmit = noop,
  onGoToNextStep = noopAsync,
}) => {
  const { gid } = useParams() as DefaultLocation;
  const { mutateAsync: mutateAnswers } = useMutateAnswers(gid);

  const { data: sessionData } = useSession(gid);

  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<PaymentMethod>();
  const [isEdit, setIsEdit] = useState(false);

  const isFinished = currentStepNumber > stepNumber;
  const isDisabled = currentStepNumber < stepNumber;
  const isCurrent = currentStepNumber === stepNumber;

  useEffect(() => {
    if (paymentMethods?.length === 1) {
      setCurrentPaymentMethod(paymentMethods[0]);
    }
  }, [paymentMethods]);

  const onEdit = (): void => {
    if (!isCurrent) {
      onSetCurrent();
    }

    setIsEdit(true);
  };

  const onSubmit = async (answersToUpdate?: CheckoutSecondStepData): Promise<void> => {
    await mutateAnswers({ ...answersToUpdate, [PAYMENT_METHOD_QUESTION_KEY]: currentPaymentMethod as string });
    await onGoToNextStep();
    setIsEdit(false);
  };

  const renderContent = (paymentMethod: PaymentMethod | undefined): ReactNode => {
    if (!paymentMethod) {
      return null;
    }

    const CheckoutPaymentStepComponent =
      paymentMethod === PaymentMethod.escrow ? CheckoutMortgageStep : CheckoutCreditCardStep;

    return (
      <CheckoutPaymentStepComponent
        isEdit={isEdit}
        isCurrent={isCurrent}
        actionLabel={actionLabel}
        onSetEdit={(val) => setIsEdit(val)}
        onBeforeStepSubmit={onBeforeStepSubmit}
        onStepSubmit={onSubmit}
        isBindDisabled={sessionData?.session.test_mode}
      />
    );
  };

  const getStepTitle = (paymentMethods: PaymentMethod[], currentMethod: PaymentMethod | undefined): string => {
    const hasTwoOptions = paymentMethods.length > 1;
    const title = currentMethod === PaymentMethod.direct_bill ? DIRECT_BILL_STEP_TITLE : ESCROW_STEP_TITLE;
    return hasTwoOptions ? PAYMENT_METHOD_STEP_TITLE : title;
  };

  return (
    <div css={customCSS}>
      <CheckoutStepTitle
        stepNumber={stepNumber}
        title={getStepTitle(paymentMethods, currentPaymentMethod)}
        isDisabled={isDisabled}
        hideStepNumber={hideStepNumber}
        isFinished={isFinished}
        onButtonClick={onEdit}
        buttonType={!isEdit && currentPaymentMethod ? StepTitleButtonType.Edit : null}
      />
      {!isDisabled && (
        <>
          {paymentMethods?.length > 1 && (
            <div css={paymentMethodCSS}>
              <QuestionLabel customCSS={labelCSS} keyName={PAYMENT_METHOD_QUESTION.key}>
                How do you pay for your home insurance today?
              </QuestionLabel>
              <Select
                inputId={PAYMENT_METHOD_QUESTION.key}
                options={PAYMENT_METHOD_QUESTION.options}
                placeholder={PAYMENT_METHOD_QUESTION.placeholder}
                value={currentPaymentMethod}
                onValidEntry={(val) => setCurrentPaymentMethod(val as PaymentMethod)}
              />
            </div>
          )}
          {renderContent(currentPaymentMethod)}
        </>
      )}
    </div>
  );
};

export default CheckoutPaymentStep;
