import { useParams } from 'react-router-dom';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import LockIcon from '../../../../../assets/lock.svg?react';
import useResponsive from '../../../../../hooks/use-responsive';
import { layoutRightColumnCSS, stickyContainerCSS } from '../../../../../styles/layout.style';
import { ConfigType } from '../../../../../types/public-flow-config.type';
import CheckoutDynamicHomeForm from '../CheckoutDynamicHomeForm/CheckoutDynamicHomeForm';
import CheckoutHomeForm from '../CheckoutHomeForm/CheckoutHomeForm';
import CheckoutHomeSummary from '../CheckoutHomeSummary/CheckoutHomeSummary';
import {
  checkoutSummaryCSS,
  containerCSS,
  formContainerCSS,
  pageTitleCSS,
  policySummaryTitleCSS,
} from './CheckoutHome.style';
import type { CheckoutHomeProps } from './CheckoutHome.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC, ReactNode } from 'react';

const CheckoutHome: FC<CheckoutHomeProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { isDesktop } = useResponsive();

  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);

  const isDynamicConfigType = sessionFlowConfig?.flow_config?.config_type === ConfigType.Dynamic;

  const renderSummary = (isExpandable?: boolean): ReactNode => {
    return <CheckoutHomeSummary isExpandable={isExpandable} />;
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={formContainerCSS}>
        {!isDesktop && <div css={checkoutSummaryCSS}>{renderSummary(true)}</div>}
        <div css={pageTitleCSS}>
          <span>Secure checkout</span>
          <LockIcon />
        </div>
        {isDynamicConfigType ? <CheckoutDynamicHomeForm /> : <CheckoutHomeForm />}
      </div>
      {isDesktop && (
        <div css={layoutRightColumnCSS}>
          <div css={stickyContainerCSS}>
            <div css={policySummaryTitleCSS}>Policy Summary</div>
            {renderSummary()}
          </div>
        </div>
      )}
    </div>
  );
};

export default CheckoutHome;
