import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { VehiclesResponse } from '../../types/vehicle.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useDeleteVehicle = (gid: string): UseBaseMutationResult<VehiclesResponse, HTTPResponse, string> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (vehicleGid) =>
      await api
        .delete<HTTPResponse<VehiclesResponse>>(getApiUrl(API_PATH.vehicle, gid, vehicleGid))
        .then((res) => res.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.vehicles, gid] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.vehiclesAndDriversData] });
    },
  });
};

export default useDeleteVehicle;
