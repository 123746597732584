export enum VisibilityOperation {
  Equal = 'equal',
  NotEqual = 'not_equal',
  Filled = 'filled',
  NotFilled = 'not_filled',
  OneOf = 'one_of',
  NotOneOf = 'not_one_of',
  LessThan = 'less_than',
  GreaterThan = 'greater_than',
  Before = 'before',
  After = 'after',
  OlderThanYears = 'older_than_years',
  NewerThanYears = 'newer_than_years',
}

export type VisibilityValue = string | string[] | number | boolean | null;

export interface VisibilityCondition {
  operation: VisibilityOperation;
  source: string;
  value: VisibilityValue;
}
