import { css, keyframes } from '@emotion/react';
import { mq } from '../../styles/media-queries';
import theme from '../../styles/theme';
import { text14CSS } from '../../styles/typography.style';

export const containerCSS = css`
  display: flex;
  align-items: center;
`;

export const skipButtonCSS = css`
  ${text14CSS};
  padding: 4px 12px;
`;

export const modalTextCSS = css`
  color: ${theme.colors.gray60};
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const loaderCSS = css`
  border: 3px solid #3498db;
  border-left: 3px solid transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
  margin-right: 10px;
  flex-shrink: 0;

  ${mq[0]} {
    width: 24px;
    height: 24px;
  }
`;
