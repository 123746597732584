import useResponsive from '../../../../hooks/use-responsive';
import SkeletonLoader from '../../../../shared/components/SkeletonLoader/SkeletonLoader';
import { headingCSS, inputCSS, radioWrapperCSS, workflowCSS } from './DiscountsFormLoading.style';
import type { QuestionsFormLoadingProps } from './DiscountsFormLoading.type';
import type { FC } from 'react';

const DiscountsFormLoading: FC<QuestionsFormLoadingProps> = ({ customCSS }) => {
  const { isMobile } = useResponsive();

  const skeletonInputProps = {
    customCSS: inputCSS,
    absoluteHeight: isMobile ? 75 : 240,
    width: isMobile ? 100 : 92,
  };

  return (
    <div css={customCSS} role="status" aria-label="Loading">
      <SkeletonLoader customCSS={headingCSS} absoluteHeight={isMobile ? 32 : 40} minWidth={60} maxWidth={90} />
      <SkeletonLoader absoluteHeight={isMobile ? 24 : 32} minWidth={60} maxWidth={90} />
      <div css={workflowCSS}>
        <div css={radioWrapperCSS}>
          <SkeletonLoader {...skeletonInputProps} />
          <SkeletonLoader {...skeletonInputProps} />
          <SkeletonLoader {...skeletonInputProps} />
        </div>
        <div css={radioWrapperCSS}>
          <SkeletonLoader {...skeletonInputProps} />
          <SkeletonLoader {...skeletonInputProps} />
          <SkeletonLoader {...skeletonInputProps} />
        </div>
      </div>
    </div>
  );
};

export default DiscountsFormLoading;
