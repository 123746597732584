import { addWeeks, addYears, format, isAfter, isBefore, isFuture, isPast, addDays, parseISO } from 'date-fns';
import {
  DefaultConditionOperation,
  DynamicDefaultFunction,
  DynamicDefaultFunctionParamTypes,
  DynamicDefaultTypes,
} from '../../types/dynamic-defaults.type';
import { FormElementType, type QuestionElementContent } from '../../types/form-element.type';
import { SectionPresentationType } from '../../types/section.type';
import { captureException } from '../../utils/error.util';
import type { AnswerValue, Answers } from '../../types/answer.type';
import type {
  DefaultCondition,
  DynamicDefault,
  DynamicDefaultConditionValue,
  DynamicDefaultsFunctionParam,
} from '../../types/dynamic-defaults.type';
import type { SectionContainer, SectionSchema, WorkflowSectionSchema } from '../../types/section.type';

const getAllKeysFromSectionContainer = (container: SectionContainer): string[] => {
  return container.elements
    .filter((element) => element.kind === FormElementType.Question)
    .map((element) => (element.content as QuestionElementContent).key);
};

export const getAllQuestionsKeys = (sections: SectionSchema[]): string[] => {
  return sections.reduce((acc: string[], section: SectionSchema) => {
    if (section.presentation_type === SectionPresentationType.Workflow) {
      const keys = (section as WorkflowSectionSchema).containers.map(getAllKeysFromSectionContainer).flat();
      return [...acc, ...keys];
    }

    return acc;
  }, []);
};

const operations: Record<
  DefaultConditionOperation,
  (answer: AnswerValue, value: DynamicDefaultConditionValue) => boolean
> = {
  [DefaultConditionOperation.Filled]: (answer) => !!answer,
  [DefaultConditionOperation.NotFilled]: (answer) => !answer,
  [DefaultConditionOperation.NewerThanYears]: (answer, value) => {
    const yearDate = new Date(answer as number, 0, 1);
    const currentDate = new Date();
    return isPast(yearDate) && currentDate.getFullYear() - Number(answer) <= Number(value);
  },
  [DefaultConditionOperation.OlderThanYears]: (answer, value) => {
    const yearDate = new Date(answer as number, 0, 1);
    const currentDate = new Date();
    return isPast(yearDate) && currentDate.getFullYear() - Number(answer) >= Number(value);
  },
  [DefaultConditionOperation.MoreThanDaysFromToday]: (answer, value) => {
    const futureDate = new Date(answer as string);
    const currentDate = new Date();
    return isFuture(futureDate) && isBefore(addDays(currentDate, Number(value)), futureDate);
  },
  [DefaultConditionOperation.LessThanDaysFromToday]: (answer, value) => {
    const futureDate = new Date(answer as string);
    const currentDate = new Date();
    return isFuture(futureDate) && isAfter(addDays(currentDate, Number(value)), futureDate);
  },
};

const functions: Record<
  DynamicDefaultFunction,
  ({ params, answers }: { params?: DynamicDefaultsFunctionParam[]; answers: Answers }) => AnswerValue
> = {
  [DynamicDefaultFunction.OneDayAfterDate]: ({ params, answers }) => {
    const value =
      params?.[0].type === DynamicDefaultFunctionParamTypes.Datapoint && typeof params?.[0].value === 'string'
        ? answers[params[0].value]
        : params?.[0].value;
    const date = parseISO(value as string);
    const theNextDay = addDays(date, 1);
    return format(theNextDay, 'yyyy-MM-dd');
  },
  [DynamicDefaultFunction.DateInWeeksFromToday]: ({ params }) => {
    const weeks = params?.[0].value as number;
    const today = new Date();
    const weeksAfterToday = addWeeks(today, weeks);
    return format(weeksAfterToday, 'yyyy-MM-dd');
  },
  [DynamicDefaultFunction.YearInYearsFromToday]: ({ params }) => {
    const years = params?.[0].value as number;
    const today = new Date();
    const yearsBeforeToday = addYears(today, years);
    return yearsBeforeToday.getFullYear();
  },
};

const getDynamicValue = ({
  value,
  type,
  answers,
  params,
}: {
  value: AnswerValue;
  type: DynamicDefaultTypes;
  answers: Answers;
  params: DynamicDefaultsFunctionParam[] | undefined;
}): AnswerValue => {
  if (type === DynamicDefaultTypes.Datapoint && typeof value === 'string') {
    return answers[value];
  } else if (type === DynamicDefaultTypes.Function) {
    return functions[value as DynamicDefaultFunction]({ params, answers });
  }
  return value;
};

export const isConditionMet = (answers: Answers, orConditions?: DefaultCondition[][]): boolean => {
  if (!orConditions?.length) return true;

  return orConditions.some((andConditions: DefaultCondition[]) =>
    andConditions.every((c) => {
      try {
        return operations[c.operation](answers[c.source], c.value);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(`Can't parse dynamic default operation: ${c.source};\n`, e);
        captureException(e, 'dynamic_defaults');
        return false;
      }
    })
  );
};

export const getDynamicDefaultValue = (answers: Answers, dynamicDefault: DynamicDefault[]): AnswerValue => {
  let defaultValue: AnswerValue = '';

  dynamicDefault.forEach((condition) => {
    if (condition.conditions === null || isConditionMet(answers, condition.conditions)) {
      defaultValue = getDynamicValue({
        value: condition.value,
        type: condition.type,
        answers,
        params: condition.params,
      });
    }
  });

  return defaultValue;
};
