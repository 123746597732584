import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PhoneIcon from '../../../../assets/phone.svg?react';
import ErrorPageMessage from '../../../../components/ErrorPageMessage/ErrorPageMessage';
import { ErrorReasonsIcon } from '../../../../components/ErrorPageMessage/ErrorPageMessage.type';
import { SEGMENT } from '../../../../constants/analytics';
import ROUTES from '../../../../constants/routes';
import usePhoneNumber from '../../../../hooks/use-phone-number';
import { useSegment } from '../../../../hooks/use-segment';
import Button from '../../../../shared/components/Button/Button';
import { buttonCSS } from '../../../../shared/components/Button/Button.style';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import ButtonSelect from '../../../../shared/components/ButtonSelect/ButtonSelect';
import {
  errorPageActionButtonCSS,
  errorPageAnchorButtonCSS,
  errorPageButtonIconCSS,
} from '../../../../styles/common.style';
import { Flows } from '../../../../types/route-params.type';
import { SessionQualificationStatus } from '../../../../types/session.type';
import { ApiErrorFailureReasons } from '../../../../utils/error.util';
import { buttonSelectCSS } from './ErrorAgentPageContent.style';
import { getButtonOptions, getFailureMessage } from './ErrorAgentPageContent.util';
import type { SessionErrorPageProps } from './ErrorAgentPageContent.types';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const ErrorAgentPageContent: FC<SessionErrorPageProps> = ({
  failureReason = ApiErrorFailureReasons.INTERNAL_TECHNICAL_ERROR,
  errors,
  allFunnelUrl,
  carrierName,
  qualificationStatus,
}) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { track } = useSegment();
  const navigate = useNavigate();
  const showAllFunnelQuoteButton = allFunnelUrl && [ApiErrorFailureReasons.RC2_NOT_SUCCESSFUL].includes(failureReason);
  const leadDisqualified = qualificationStatus === SessionQualificationStatus.Disqualified;
  const hideActionButtons =
    leadDisqualified && [ApiErrorFailureReasons.PRIMARY_INSURED_DISQUALIFIED].includes(failureReason);
  const errorObject = getFailureMessage(
    failureReason,
    flow !== Flows.Auto,
    !!showAllFunnelQuoteButton,
    carrierName,
    leadDisqualified
  );
  const { phoneNumber } = usePhoneNumber();
  const buttonOptions = getButtonOptions(phoneNumber);

  useEffect(() => {
    if (showAllFunnelQuoteButton) {
      track(SEGMENT.events.allFunnelQuotesOffered, {
        page: SEGMENT.pages.error.name,
      });
    }
  }, [track, showAllFunnelQuoteButton]);

  const onPhoneClick = (): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: SEGMENT.pages.error.name,
      location: SEGMENT.locations.page,
    });
  };

  const onScheduleCall = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: SEGMENT.pages.error.name,
    });
    navigate(ROUTES.sessionScheduleCall.replace(':gid', gid).replace(':flow', flow));
  };

  const onViewMoreQuotesClick: () => void = () =>
    track(SEGMENT.events.allFunnelQuotesSelected, {
      page: SEGMENT.pages.error.name,
    });

  const onButtonSelect = (option: string): void => {
    if (option === buttonOptions[0].key) {
      onPhoneClick();
    }

    if (option === buttonOptions[1].key) {
      onScheduleCall();
    }
  };

  const allFunnelQuoteButton = (
    <>
      <a css={[buttonCSS(true), errorPageAnchorButtonCSS]} href={allFunnelUrl} onClick={onViewMoreQuotesClick}>
        View More Quotes
      </a>

      <ButtonSelect
        customCSS={[errorPageActionButtonCSS, buttonSelectCSS]}
        options={buttonOptions}
        icon={<PhoneIcon />}
        size={ButtonSize.Large}
        variant={'secondary'}
        onSelectOption={onButtonSelect}
      >
        Talk to an Agent
      </ButtonSelect>
    </>
  );

  const callActionsButtons = (
    <>
      <a css={[buttonCSS(true), errorPageAnchorButtonCSS]} href={`tel:${phoneNumber}`} onClick={onPhoneClick}>
        <PhoneIcon css={errorPageButtonIconCSS} />
        Call an Agent Now
      </a>
      <Button css={errorPageActionButtonCSS} variant={'secondary'} size={ButtonSize.Large} onClick={onScheduleCall}>
        Schedule Another Time
      </Button>
    </>
  );

  const actionButtons = showAllFunnelQuoteButton ? allFunnelQuoteButton : callActionsButtons;

  return (
    <ErrorPageMessage
      title={errorObject.title}
      description={errorObject.description}
      errorReasons={errors}
      errorReasonsIcon={leadDisqualified ? ErrorReasonsIcon.Warning : ErrorReasonsIcon.Check}
    >
      {!hideActionButtons && actionButtons}
    </ErrorPageMessage>
  );
};

export default ErrorAgentPageContent;
