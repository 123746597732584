import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';

export const headingCSS = css`
  padding: 2px 0;
  margin-bottom: 48px;
  ${mq[0]} {
    padding: 6px 0;
    margin-bottom: 56px;
  }
`;

export const workflowCSS = css`
  position: relative;
`;

export const workflowHeadingCSS = css`
  padding: 5px 0;
  margin-bottom: 12px;
  ${mq[0]} {
    padding: 6px 0;
    margin-bottom: 20px;
  }
`;

export const labelCSS = css`
  padding: 2px 0;
  margin-bottom: 4px;
`;

export const inputCSS = css`
  margin-bottom: 16px;
`;

export const radioWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  & > div {
    width: 48%;
  }
`;
