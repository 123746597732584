import AddIcon from '../../../../../assets/add-mobile.svg?react';
import PencilIcon from '../../../../../assets/pencil.svg?react';
import BinIcon from '../../../../../assets/trash-bin.svg?react';
import useResponsive from '../../../../../hooks/use-responsive';
import {
  buttonCSS,
  buttonIconCSS,
  completedMarkCSS,
  containerCSS,
  singleIconButtonCSS,
  stepNumberCSS,
  titleCSS,
} from './CheckoutStepTitle.style';
import { StepTitleButtonType } from './CheckoutStepTitle.type';
import type { CheckoutStepTitleProps } from './CheckoutStepTitle.type';
import type { FC, ReactNode } from 'react';

const CheckoutStepTitle: FC<CheckoutStepTitleProps> = ({
  customCSS,
  stepNumber,
  title,
  isSkipped,
  isDisabled,
  isFinished,
  buttonType,
  hideStepNumber,
  onButtonClick,
}) => {
  const { isMobile } = useResponsive();

  const getButtonLabel = (type: StepTitleButtonType): ReactNode => {
    switch (type) {
      case StepTitleButtonType.AddCoInsured:
        return isMobile ? (
          <AddIcon css={singleIconButtonCSS} aria-label="Add" />
        ) : (
          <>
            <AddIcon css={buttonIconCSS} />
            Add Co-Insured
          </>
        );
      case StepTitleButtonType.RemoveCoInsured:
        return isMobile ? <BinIcon css={singleIconButtonCSS} aria-label="Remove" /> : 'Remove Co-Insured';
      case StepTitleButtonType.Edit:
        return isMobile ? <PencilIcon css={singleIconButtonCSS} aria-label="Edit" /> : 'Edit';
    }
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={titleCSS(isDisabled, isSkipped, hideStepNumber)}>
        {!hideStepNumber && <div css={stepNumberCSS}>{stepNumber}</div>}
        <span>{title}</span>
        {isFinished && <span css={completedMarkCSS} role="img" aria-label="Green check mark" />}
      </div>
      {buttonType && (
        <button css={buttonCSS} onClick={onButtonClick}>
          {getButtonLabel(buttonType)}
        </button>
      )}
    </div>
  );
};

export default CheckoutStepTitle;
