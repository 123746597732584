import { useParams } from 'react-router-dom';
import { usePage } from '../../../../api/page';
import { useSession } from '../../../../api/session';
import FlowNavigation from '../../../../components/FlowNavigation/FlowNavigation';
import useResponsive from '../../../../hooks/use-responsive';
import SkeletonLoader from '../../../../shared/components/SkeletonLoader/SkeletonLoader';
import {
  innerLayoutContainerCSS,
  layoutNavigationCSS,
  layoutRightColumnCSS,
  stickyContainerCSS,
} from '../../../../styles/layout.style';
import PageFormWrapper from '../../../questions/components/PageFormWrapper/PageFormWrapper';
import CheckoutAutoForm from './CheckoutAutoForm/CheckoutAutoForm';
import { checkoutSummaryCSS, contentCSS, formContainerCSS, headingCSS } from './CheckoutAutoPage.style';
import { getPaymentDetailsFromSchema } from './CheckoutAutoPage.util';
import CheckoutAutoSummary from './CheckoutAutoSummary/CheckoutAutoSummary';
import CheckoutFormLoading from './CheckoutFormLoading/CheckoutFormLoading';
import type { CheckoutPageProps } from './CheckoutAutoPage.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../types/section.type';
import type { FC, ReactElement } from 'react';

const CheckoutAutoPage: FC<CheckoutPageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { isDesktop } = useResponsive();
  const sessionQuery = useSession(gid);
  const currentPageKey = sessionQuery.data?.session.current_page_key;
  const pageQuery = usePage(gid, currentPageKey);

  const renderSummary = (isExpandable?: boolean): ReactElement => {
    if (!pageQuery.data) {
      return <SkeletonLoader minWidth={100} maxWidth={100} absoluteHeight={isDesktop ? 250 : 62} />;
    }
    return <CheckoutAutoSummary {...getPaymentDetailsFromSchema(pageQuery.data)} isExpandable={isExpandable} />;
  };

  return (
    <div css={customCSS}>
      <div css={layoutNavigationCSS}>
        <FlowNavigation />
      </div>

      <PageFormWrapper defaultValues={pageQuery.data?.answers}>
        <div css={contentCSS}>
          <div css={[innerLayoutContainerCSS(true), formContainerCSS]}>
            {!isDesktop && <div css={checkoutSummaryCSS}>{renderSummary(true)}</div>}
            <div css={headingCSS}>Checkout</div>
            {!pageQuery.data ? (
              <CheckoutFormLoading />
            ) : (
              <CheckoutAutoForm schema={pageQuery.data.page.sections[0] as CustomSectionSchema} />
            )}
          </div>
          {isDesktop && (
            <div css={layoutRightColumnCSS}>
              <div css={stickyContainerCSS}>{renderSummary()}</div>
            </div>
          )}
        </div>
      </PageFormWrapper>
    </div>
  );
};

export default CheckoutAutoPage;
