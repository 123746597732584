import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { LoanResponse } from '../../types/loan.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useRecentLoan = (gid?: string): QueryObserverResult<LoanResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.loan, gid],
    queryFn: async () =>
      await api.get<HTTPResponse<LoanResponse>>(getApiUrl(API_PATH.loansRecent, gid as string)).then((res) => res.data),
    enabled: !!gid,
  });

export default useRecentLoan;
