import type { PolicyType } from './quote.type';

export interface Policy {
  effective_date: string;
  expiration_date: string;
  policy_number: string;
  premium: number;
  policy_term: number;
}

export interface EffectivePolicyResponse {
  effective_policy?: EffectivePolicy;
}

export interface EffectivePolicy {
  gid: string;
  premium: string;
  asset_gids: string[];
  carrier_gid: string;
  effective_date?: string | null;
  expiration_date?: string | null;
  person_gid: string;
  policy_number: string;
  policy_type: PolicyType;
  role: string;
  status: string;
}

export const UNKNOWN_POLICY_NUMBER = 'unknown';
