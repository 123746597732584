import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';

export const containerCSS = css`
  margin-top: 40px;
  background-color: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.gray30};
  padding: 24px 16px;
  border-radius: 4px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  ${mq[0]} {
    padding: 32px;
    border: 1px solid ${theme.colors.gray30};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-bottom: 48px;
  }
`;
