import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateDriver, useDeleteDriver, useDrivers, useUpdateDriver } from '../../../../api/driver';
import { useVehiclesAndDriversData } from '../../../../api/primary-insured';
import { useSession } from '../../../../api/session';
import PersonIcon from '../../../../assets/person.svg?react';
import InfoFormBlock from '../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import TextBoxNavigationPoint from '../../../../questionsForm/components/TextBoxNavigationPoint/TextBoxNavigationPoint';
import { useAutoInformationActions, useAutoInformationData } from '../../contexts/auto-information-context';
import FormListAddItem from '../FormListAddItem/FormListAddItem';
import FormListItem from '../FormListItem/FormListItem';
import ListLoader from '../ListLoader/ListLoader';
import {
  formCSS,
  formInnerScrollCSS,
  iconCSS,
  listWrapperCSS,
  messageCSS,
  navigationPointCSS,
  scrollPointCSS,
} from './DriversListContainer.style';
import {
  NEW_DRIVER_FORM_SCHEMA,
  PREFILLED_DRIVER_FORM_SCHEMA,
  DRIVER_FORM_TYPE,
  sortDrivers,
  NEW_DRIVER_FORM_KEY,
} from './DriversListContainer.util';
import type { DriverData, DriversListContainerProps } from './DriversListContainer.type';
import type { Driver } from '../../../../types/driver.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC, ReactNode } from 'react';

const DriversListContainer: FC<DriversListContainerProps> = ({ customCSS, isCurrent }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);

  const { drivers, editingState } = useAutoInformationData();
  const { setEditingState, setDrivers } = useAutoInformationActions();

  const { data: vehiclesAndDriversData, isPending: isVehiclesAndDriversLoading } = useVehiclesAndDriversData(gid);
  const { data: driversData, isPending: isDriversLoading } = useDrivers(gid);
  const { mutateAsync: createDriver, isPending: isCreating } = useCreateDriver(gid);
  const { mutateAsync: updateDriver, isPending: isUpdating } = useUpdateDriver(gid);
  const { mutateAsync: deleteDriver, isPending: isDeleting } = useDeleteDriver(gid);

  const isLoading = isVehiclesAndDriversLoading || isDriversLoading;
  const isActionLoading = isCreating || isDeleting || isUpdating;

  const isLimitedDrivers = drivers.length === 6;
  const warningMessage = isLimitedDrivers
    ? "You've added 6 drivers. If you need add more drivers call us to speak to an agent."
    : 'You can add up to 6 drivers.';

  useEffect(() => {
    if (!driversData || !sessionData?.session) return;
    setDrivers(sortDrivers(driversData?.drivers, sessionData.session.primary_insured_gid));
  }, [driversData, sessionData, setDrivers]);

  const onAddDriver = async (data: DriverData, driver?: Driver): Promise<void> => {
    const driverData = {
      ...driver,
      ...data,
    };

    driverData.gid ? await updateDriver(driverData) : await createDriver(driverData);
  };

  const onDeleteDriver = async (driverGid: string): Promise<void> => {
    await deleteDriver(driverGid);
  };

  const onSetEdit = (value: boolean, key: string): void => {
    const result = value ? [...editingState, { key, hasError: false }] : editingState.filter((el) => el.key !== key);
    setEditingState(result);
  };

  const formTitle = (driver: Driver): ReactNode => (
    <>
      <PersonIcon css={iconCSS} /> {driver.first_name} {driver.last_name}
    </>
  );

  const renderPrefilledDrivers = (): ReactNode =>
    vehiclesAndDriversData?.drivers.map((d) => {
      const label = `Add - ${d.first_name} ${d.last_name}`;
      const key = `${d.first_name}-${d.last_name}`;

      return (
        <FormListAddItem<DriverData>
          key={key}
          formKey={key}
          label={label}
          type={DRIVER_FORM_TYPE}
          addItemSchema={PREFILLED_DRIVER_FORM_SCHEMA}
          isEdit={editingState.some((el) => el.key === key)}
          hasError={editingState.some((el) => el.key === key && el.hasError)}
          onValidSubmit={(data) => onAddDriver(data, d)}
          setIsEdit={(value) => (d.gid ? onAddDriver({ ...d }) : onSetEdit(value, key))}
          title={formTitle(d)}
        />
      );
    });

  const renderDrivers = (): ReactNode =>
    drivers.map((driver) => {
      const isPrimaryInsured = driver.gid === sessionData?.session.primary_insured_gid;
      const label = isPrimaryInsured
        ? `${driver.first_name} ${driver.last_name} (You)`
        : `${driver.first_name} ${driver.last_name}`;
      return (
        <FormListItem
          key={driver.gid}
          type={DRIVER_FORM_TYPE}
          showRemoveButton={!isPrimaryInsured && !isActionLoading}
          trackForbidden
          onRemoveClick={async () => await onDeleteDriver(driver.gid)}
          label={label}
        />
      );
    });

  const renderDriversList = (): ReactNode => (
    <>
      <InfoFormBlock customCSS={messageCSS(isLimitedDrivers)} content={warningMessage} />
      {renderDrivers()}
      {isDeleting || isUpdating ? (
        <ListLoader />
      ) : (
        <>
          {!isLimitedDrivers && (
            <>
              {renderPrefilledDrivers()}
              <FormListAddItem<DriverData>
                label="Add a driver"
                type={DRIVER_FORM_TYPE}
                addItemSchema={NEW_DRIVER_FORM_SCHEMA}
                isEdit={editingState.some((el) => el.key === NEW_DRIVER_FORM_KEY)}
                hasError={editingState.some((el) => el.key === NEW_DRIVER_FORM_KEY && el.hasError)}
                isDisabled={isLimitedDrivers}
                setIsEdit={(value) => onSetEdit(value, NEW_DRIVER_FORM_KEY)}
                onValidSubmit={onAddDriver}
                formKey={NEW_DRIVER_FORM_KEY}
              />
            </>
          )}
        </>
      )}
    </>
  );

  return (
    <div css={[formCSS(isCurrent, isCurrent), customCSS]}>
      <div data-testid="form-scroll-point" css={scrollPointCSS} />
      <div data-testid="form-inner" css={formInnerScrollCSS}>
        <TextBoxNavigationPoint
          customCSS={navigationPointCSS}
          elementKey={'drivers-list'}
          heading="What drivers need to be on the policy?"
          description="Every person of driving age living in your household must be on your auto policy."
        />
        <div css={listWrapperCSS}>{isLoading ? <ListLoader /> : renderDriversList()}</div>
      </div>
    </div>
  );
};

export default DriversListContainer;
