import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { VehiclesResponse } from '../../types/vehicle.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useVehicles = (gid: string): QueryObserverResult<VehiclesResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.vehicles, gid],
    queryFn: async () =>
      await api.get<HTTPResponse<VehiclesResponse>>(getApiUrl(API_PATH.vehicles, gid)).then((res) => res.data),
  });

export default useVehicles;
