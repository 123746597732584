import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text14CSS, text32CSS, text48CSS } from '../../../../styles/typography.style';

export const containerCSS = css`
  padding-top: 20px;
  padding-bottom: 24px;
  ${mq[0]} {
    padding-top: 25px;
    padding-bottom: 80px;
  }
`;

export const headingContainerCSS = css`
  margin-bottom: 35px;
  text-align: center;

  ${mq[0]} {
    margin-bottom: 40px;
  }
`;

export const headingIconCSS = css`
  margin-bottom: 18px;
  width: 80px;
  height: 80px;
  ${mq[0]} {
    margin-bottom: 8px;
    width: 100px;
    height: 100px;
  }
`;

export const headingCSS = css`
  ${text32CSS};
  line-height: 40px;
  font-weight: 700;
  ${mq[0]} {
    ${text48CSS};
    font-weight: 900;
  }
`;

export const pageHeadingCSS = css`
  margin-top: 80px;
`;

export const insuranceTypesContainerCSS = css`
  display: flex;
  max-width: 320px;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: space-around;
  ${mq[0]} {
    max-width: 736px;
  }
`;

export const insuranceTypeItemCSS = css`
  text-align: center;
  color: inherit;
  text-decoration: none;
  transition: color 300ms ease-in-out;
  width: 135px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 32px;
  svg {
    transition: transform 300ms ease-in-out;
  }
  &:hover {
    color: ${theme.colors.azure68};
    svg {
      transform: scale(1.1);
    }
  }
  ${mq[0]} {
    margin-top: 40px;
  }
`;

export const insuranceTypeNameCSS = css`
  white-space: pre-line;
  ${text14CSS};
  ${mq[0]} {
    white-space: normal;
  }
`;

export const blogPostsCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${mq[0]} {
    flex-direction: row;
    margin-top: 40px;
  }
`;

export const blogPostCSS = css`
  margin-top: 32px;
  ${mq[0]} {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0;
  }
`;
