import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import { LoaderSizeEnum } from './Loader.type';
import type { SerializedStyles } from '@emotion/react';

const smallDotSize = 8;
const mediumDotSize = 12;
const animationSpeed = 0.6;

const fullPageLoaderCSS = css`
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const loaderWrapperCSS = (fullPage: boolean): SerializedStyles => (fullPage ? fullPageLoaderCSS : css``);

export const loaderCSS = css`
  display: flex;
  justify-content: center;
`;

export const dotCSS = (isInverted: boolean, withColor: boolean, size: LoaderSizeEnum): SerializedStyles => css`
  animation: fade ${animationSpeed}s infinite alternate;
  background-color: ${withColor ? theme.colors.azure62 : isInverted ? theme.colors.white : theme.colors.black};
  border-radius: 50%;
  height: ${size === LoaderSizeEnum.Small ? smallDotSize : mediumDotSize}px;
  margin-right: ${size === LoaderSizeEnum.Small ? smallDotSize : mediumDotSize}px;
  width: ${size === LoaderSizeEnum.Small ? smallDotSize : mediumDotSize}px;

  &:nth-of-type(2) {
    animation-delay: ${animationSpeed / 3}s;
  }

  &:nth-of-type(3) {
    animation-delay: ${animationSpeed / 1.5}s;
    margin-right: 0;
  }

  @keyframes fade {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
