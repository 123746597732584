import { css } from '@emotion/react';
import checkboxMark from '../../../../assets/check-mark.svg';
import theme from '../../../../styles/theme';
import { text20CSS } from '../../../../styles/typography.style';
import { activeElementResetCSS } from '../../../shared.style';
import type { SerializedStyles } from '@emotion/react';

export const navigationCSS = (isCompleted: boolean): SerializedStyles => css`
  padding-bottom: 24px;
  position: relative;
  &::before {
    background-color: ${isCompleted ? theme.colors.gray80 : theme.colors.gray30};
    bottom: ${theme.sizes.indicatorDesktop / 2 - 16}px;
    content: '';
    left: -18.5px;
    position: absolute;
    top: 10px;
    width: 2px;
  }
  &:last-of-type::before {
    content: none;
  }
`;

export const navigationNameCSS = (isAvailable: boolean): SerializedStyles => css`
  ${activeElementResetCSS};
  ${text20CSS};
  font-weight: 700;
  color: ${isAvailable ? theme.colors.black : theme.colors.gray30};
  ${!isAvailable && 'pointer-events: none;'};
`;

export const chevronCSS = (isExpanded: boolean): SerializedStyles => css`
  height: 12px;
  margin-left: 10px;
  transform: ${isExpanded ? 'none' : 'rotate(-180deg)'};
  transition: transform 300ms ease;
  width: 12px;
`;

export const navigationIndicatorCSS = (isCompleted: boolean, isCurrent: boolean): SerializedStyles => css`
  background-color: ${theme.colors.white};
  border: 2px solid ${theme.colors.gray30};
  border-radius: 50%;
  content: '';
  height: ${theme.sizes.indicatorDesktop}px;
  left: ${-theme.sizes.indicatorDesktop - 11}px;
  position: absolute;
  top: ${13 - theme.sizes.indicatorDesktop / 2}px;
  width: ${theme.sizes.indicatorDesktop}px;
  z-index: 1;

  ${isCompleted &&
  css`
    background: ${theme.colors.positiveGreen94} url(${checkboxMark}) no-repeat center center;
    background-size: 8px;
    border-color: ${theme.colors.positiveGreen94};
  `};

  ${isCurrent &&
  css`
    background-color: ${theme.colors.gray80};
    border-color: ${theme.colors.gray80};
  `};
`;

export const loadingLineCSS = css`
  padding: 6px 0 4px;
`;
