import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useVehiclesAndDriversData } from '../../../../api/primary-insured';
import { useSession } from '../../../../api/session';
import { useCreateVehicle, useUpdateVehicle, useDeleteVehicle, useVehicles } from '../../../../api/vehicle';
import VehicleIcon from '../../../../assets/vehicle.svg?react';
import InfoFormBlock from '../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import TextBoxNavigationPoint from '../../../../questionsForm/components/TextBoxNavigationPoint/TextBoxNavigationPoint';
import Button from '../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../shared/components/Button/Button.type';
import { useAutoInformationActions, useAutoInformationData } from '../../contexts/auto-information-context';
import { VEHICLE_CONTAINER } from '../AutoInformationPage/AutoInformationPage.util';
import FormListAddItem from '../FormListAddItem/FormListAddItem';
import FormListItem from '../FormListItem/FormListItem';
import ListLoader from '../ListLoader/ListLoader';
import {
  errorMessageCSS,
  formCSS,
  iconCSS,
  listWrapperCSS,
  navigationPointCSS,
  messageCSS,
} from './VehiclesListContainer.style';
import {
  NEW_VEHICLE_FORM_KEY,
  NEW_VEHICLE_FORM_SCHEMA,
  PREFILLED_VEHICLE_FORM_SCHEMA,
  VEHICLES_DEFAULT_ANSWERS,
  VEHICLE_FORM_TYPE,
} from './VehiclesListContainer.util';
import type { VehicleData, VehiclesListContainerProps } from './VehiclesListContainer.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { Vehicle } from '../../../../types/vehicle.type';
import type { FC, ReactElement, ReactNode } from 'react';

const VehiclesListContainer: FC<VehiclesListContainerProps> = ({ customCSS, isCurrent, onSubmit }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);

  const { vehicles, editingState } = useAutoInformationData();
  const { setEditingState, setVehicles } = useAutoInformationActions();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const { data: vehiclesAndDriversData, isPending: isVehiclesAndDriversLoading } = useVehiclesAndDriversData(gid);
  const { data: vehiclesData, isPending: isVehiclesLoading } = useVehicles(gid);
  const { mutateAsync: createVehicle, isPending: isCreating } = useCreateVehicle(gid);
  const { mutateAsync: updateVehicle, isPending: isUpdating } = useUpdateVehicle(gid);
  const { mutateAsync: deleteVehicle, isPending: isDeleting } = useDeleteVehicle(gid);

  const isLoading = isVehiclesAndDriversLoading || isVehiclesLoading;
  const isActionLoading = isDeleting || isCreating || isUpdating;
  const asyncUrl = `/api/sessions/${sessionData?.session.gid}/vehicles/search`;

  const noVehiclesAddedError = isSubmitted && vehicles.length === 0;
  const isLimitedVehicles = vehicles.length === 6;
  const warningMessage = isLimitedVehicles
    ? "You've added 6 cars. If you need add more cars call us to speak to an agent."
    : 'You can add up to 6 vehicles.';

  const handleSubmit = (): void => {
    setIsSubmitted(true);
    onSubmit();
  };

  useEffect(() => {
    if (!vehiclesData) return;
    setVehicles(vehiclesData.vehicles);
  }, [vehiclesData, setVehicles]);

  const onAddNewVehicle = async (data: VehicleData): Promise<void> => {
    const dataToSubmit = {
      ...data,
      ...data.vehicle,
    };

    dataToSubmit.gid ? await updateVehicle(dataToSubmit) : await createVehicle(dataToSubmit);
  };

  const addNewVehicleHandler = async (data: VehicleData, vehicle: Vehicle): Promise<void> => {
    const { gid, make, model, year, vin } = vehicle;
    await onAddNewVehicle({
      ...data,
      vehicle: { gid, make, model, year, vin },
    });
  };

  const onDeleteVehicle = async (gid: string): Promise<void> => {
    await deleteVehicle(gid);
  };

  const onSetEdit = (value: boolean, key: string): void => {
    const result = value ? [...editingState, { key, hasError: false }] : editingState.filter((el) => key !== el.key);
    setEditingState(result);
  };

  const formTitle = (vehicle: Vehicle): ReactElement => (
    <>
      <VehicleIcon css={iconCSS} /> {vehicle.year} {vehicle.make} {vehicle.model}
    </>
  );

  const renderPrefilledVehicles = (): ReactNode =>
    vehiclesAndDriversData?.vehicles.map((v) => {
      const label = `Add - ${v.year} ${v.make} ${v.model}`;
      const isEditing = editingState.some((el) => el.key === v.vin);

      return (
        <FormListAddItem<VehicleData>
          key={v.vin}
          formKey={v.vin}
          label={label}
          type={VEHICLE_FORM_TYPE}
          addItemSchema={PREFILLED_VEHICLE_FORM_SCHEMA}
          isEdit={isEditing}
          onValidSubmit={(data) => addNewVehicleHandler(data, v)}
          hasError={editingState.some((el) => el.key === v.vin && el.hasError) || noVehiclesAddedError}
          setIsEdit={(value) => onSetEdit(value, v.vin)}
          defaultValues={VEHICLES_DEFAULT_ANSWERS}
          isLoading={isEditing && isActionLoading}
          title={formTitle(v)}
        />
      );
    });

  const renderVehicles = (): ReactNode =>
    vehicles.map((vehicle) => {
      const label = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
      return (
        <FormListItem
          key={vehicle.gid}
          type={VEHICLE_FORM_TYPE}
          showRemoveButton={!isActionLoading}
          onRemoveClick={async () => await onDeleteVehicle(vehicle.gid)}
          label={label}
        />
      );
    });

  const renderVehiclesList = (): ReactElement => (
    <>
      <InfoFormBlock customCSS={messageCSS(isLimitedVehicles)} content={warningMessage} />
      {renderVehicles()}
      {!isLimitedVehicles && (
        <>
          {renderPrefilledVehicles()}
          <FormListAddItem<VehicleData>
            label="Add a Vehicle – Year, Make, Model"
            type={VEHICLE_FORM_TYPE}
            addItemSchema={NEW_VEHICLE_FORM_SCHEMA}
            isEdit={editingState.some((el) => el.key === NEW_VEHICLE_FORM_KEY)}
            hasError={editingState.some((el) => el.key === NEW_VEHICLE_FORM_KEY && el.hasError) || noVehiclesAddedError}
            isDisabled={isLimitedVehicles}
            setIsEdit={(value) => onSetEdit(value, NEW_VEHICLE_FORM_KEY)}
            onValidSubmit={onAddNewVehicle}
            asyncUrl={asyncUrl}
            defaultValues={VEHICLES_DEFAULT_ANSWERS}
            formKey={NEW_VEHICLE_FORM_KEY}
            isLoading={isActionLoading}
          />
        </>
      )}
      {noVehiclesAddedError && <div css={errorMessageCSS}>You need to add at least one {VEHICLE_FORM_TYPE}</div>}
    </>
  );

  return (
    <>
      <div id={VEHICLE_CONTAINER} css={[formCSS(isCurrent, isCurrent), customCSS]}>
        <TextBoxNavigationPoint
          customCSS={navigationPointCSS}
          elementKey={'vehicles-list'}
          heading="Which vehicles would you like to insure?"
          enableAnimation={false}
        />
        <div css={listWrapperCSS}>{isLoading ? <ListLoader /> : renderVehiclesList()}</div>
      </div>
      {isCurrent && (
        <Button
          type="button"
          fullWidth
          size={ButtonSize.Large}
          disabled={isActionLoading}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Move to Next Question
        </Button>
      )}
    </>
  );
};

export default VehiclesListContainer;
