import { useState } from 'react';
import Wrapper from '../../../../shared/components/Wrapper/Wrapper';
import QuoteCoverage from '../QuoteCoverage/QuoteCoverage';
import { accordionArrowCSS, contentCSS, coverageCSS, headerCSS, headerImageCSS } from './QuoteCoverageGroup.style';
import type { QuoteCoverageGroupProps } from './QuoteCoverageGroup.type';
import type { FC, ReactNode } from 'react';

const QuoteCoverageGroup: FC<QuoteCoverageGroupProps> = ({ customCSS, group, isExpandable = true }) => {
  const { assetGid, title, coverages, iconUrl } = group;
  const [isExpanded, setIsExpanded] = useState(true);

  const onToggle = (): void => {
    setIsExpanded((s) => !s);
  };

  const accordionProps = {
    'aria-controls': assetGid,
    'aria-expanded': isExpanded,
    onClick: onToggle,
  };

  const renderHeader = (): ReactNode => {
    if (!title) {
      return null;
    }

    return isExpandable ? (
      <Wrapper css={headerCSS} tag={'button'} {...accordionProps}>
        {iconUrl && <img css={headerImageCSS} src={iconUrl} alt="" />}

        <span>{title}</span>
        <div css={accordionArrowCSS(isExpanded)} />
      </Wrapper>
    ) : (
      <div css={headerCSS}>{title}</div>
    );
  };

  return (
    <div css={customCSS}>
      {renderHeader()}
      {isExpanded && (
        <div id={assetGid} css={contentCSS}>
          {coverages.map((c) => (
            <QuoteCoverage
              key={c.title}
              customCSS={coverageCSS}
              title={c.title}
              value={c.value}
              description={c.description}
              iconUrl={c.iconUrl}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default QuoteCoverageGroup;
