import { shouldBeTokenized } from '../../../../../questionsForm/components/PaymentMethodFormComponent/PaymentMethodFormComponent.util';
import { ComponentTypes } from '../../../../../types/form-component.type';
import { isFormComponentSchemaWithQuestions } from '../../../../../utils/schema.util';
import isVisibleByAnswers from '../../../../../utils/visibility-conditions.util';
import type { Answers } from '../../../../../types/answer.type';
import type { DisclosureSchema, FormComponentSchema } from '../../../../../types/form-component.type';
import type { QuestionSchema } from '../../../../../types/question.type';

export const getVisibleAnswersOnly = (answers: Answers, visibleComponents: FormComponentSchema[]): Answers => {
  const visibleQuestionsKeys = getVisibleQuestionsKeys(visibleComponents, answers);
  return Object.keys(answers).reduce<Answers>((acc, answerKey) => {
    return visibleQuestionsKeys.includes(answerKey) ? { ...acc, [answerKey]: answers[answerKey] } : acc;
  }, {});
};

const getVisibleQuestionsKeys = (visibleComponents: FormComponentSchema[], answers: Answers): string[] => {
  return visibleComponents.reduce<string[]>((acc, component) => {
    if (component.type === ComponentTypes.Disclosure) {
      const disclosureSchema = component as DisclosureSchema;
      return isVisibleByAnswers(answers, disclosureSchema.visibility_conditions) ? [...acc, disclosureSchema.key] : acc;
    }

    if ('questions' in component && isVisibleByAnswers(answers, component.visibility_conditions)) {
      const componentVisibleQuestionsKeys = component.questions
        .filter((q) => isVisibleByAnswers(answers, q.visibility_conditions))
        .map((q) => q.key);

      return [...acc, ...componentVisibleQuestionsKeys];
    }

    return acc;
  }, []);
};

export const getVisibleQuestionKeysToTokenize = (components: FormComponentSchema[]): string[] => {
  const questionsToTokenize = components.reduce<QuestionSchema[]>((acc, component) => {
    if (isFormComponentSchemaWithQuestions(component)) {
      const questions = component.questions.filter((q) => shouldBeTokenized(q.key));
      return [...acc, ...questions];
    }
    return acc;
  }, []);

  return questionsToTokenize.map((q) => q.key);
};
