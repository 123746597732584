import { useEffect, useRef, useState } from 'react';
import { containerCSS, containerInnerCSS } from './HeaderMobileNavigationWrapper.style';
import { ScrollDirection } from './HeaderMobileNavigationWrapper.type';
import { hideNavigationOnScroll, showNavigationOnScroll } from './HeaderMobileNavigationWrapper.util';
import type { HeaderMobileNavigationWrapperProps } from './HeaderMobileNavigationWrapper.type';
import type { FC } from 'react';

const HeaderMobileNavigationWrapper: FC<HeaderMobileNavigationWrapperProps> = ({
  customCSS,
  getHeaderElement = () => null,
  children,
}) => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection | null>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let lastScrollY = window?.scrollY;

    const updateScrollDirection = (): void => {
      const scrollY = window?.scrollY;
      const direction = scrollY > lastScrollY ? ScrollDirection.Down : ScrollDirection.Up;

      if (direction !== scrollDirection && (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)) {
        const header = getHeaderElement();
        const navigation = innerRef.current;
        const lastKnownScrollPosition = window?.scrollY;

        if (header && navigation) {
          if (lastKnownScrollPosition < 140) {
            showNavigationOnScroll(header, navigation);
          } else if (direction === ScrollDirection.Down) {
            hideNavigationOnScroll(header, navigation);
            setScrollDirection(direction);
          } else if (direction === ScrollDirection.Up) {
            showNavigationOnScroll(header, navigation);
            setScrollDirection(direction);
          }
        }
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window?.addEventListener('scroll', updateScrollDirection);

    return () => {
      window?.removeEventListener('scroll', updateScrollDirection);
    };
  }, [scrollDirection, getHeaderElement]);

  return (
    <div css={[containerCSS, customCSS]}>
      <div ref={innerRef} css={containerInnerCSS}>
        {children}
      </div>
    </div>
  );
};

export default HeaderMobileNavigationWrapper;
