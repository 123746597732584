import { type FC, type ReactElement, Fragment } from 'react';
import useResponsive from '../../../../../hooks/use-responsive';
import SelectQuestion from '../../../../../questionsForm/components/Question/SelectQuestion/SelectQuestion';
import SwitchQuestion from '../../../../../questionsForm/components/Question/SwitchQuestion/SwitchQuestion';
import { CustomComponentPresentationType } from '../../../../../types/custom-components.type';
import isVisibleByAnswers from '../../../../../utils/visibility-conditions.util';
import { interpolateCoverageDescription } from '../QuoteOptionalCoverage/QuoteOptionalCoverage.util';
import {
  descriptionCSS,
  formCSS,
  imageCSS,
  inputWrapperCSS,
  questionBlockCSS,
  questionInfoCSS,
  titleCSS,
} from './OptionalCoverageForm.style';
import type { OptionalCoverageFormProps } from './OptionalCoverageForm.type';
import type { OptionalCoverageSchema } from '../../../../../types/form-component.type';

const OptionalCoverageForm: FC<OptionalCoverageFormProps> = ({ components, answers, coverages }) => {
  const { isMobile } = useResponsive();

  const getVisibleComponents = (): OptionalCoverageSchema[] =>
    components.filter((c) => isVisibleByAnswers(answers, c.visibility_conditions));

  const renderComponentQuestion = (component: OptionalCoverageSchema): ReactElement | null => {
    switch (component.presentation_type) {
      case CustomComponentPresentationType.Switch:
        return (
          <SwitchQuestion
            {...(isMobile && { dense: true })}
            questionKey={component.key}
            label={component.label}
            variants={component.variants}
            hideLabel
            dense
          />
        );
      case CustomComponentPresentationType.SingleSelect:
        return (
          <SelectQuestion
            {...(isMobile && { dense: true })}
            dense
            questionKey={component.key}
            label={component.label}
            variants={component.variants}
            hideLabel
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      {getVisibleComponents().map((component) => (
        <div css={questionBlockCSS} key={component.key}>
          {isMobile ? (
            <Fragment>
              <div css={questionInfoCSS}>
                <img css={imageCSS} src={component.icon_url} alt={component.key} />
                <div css={titleCSS}>{component.label}</div>
              </div>
              <div css={descriptionCSS}>{interpolateCoverageDescription(component.description, coverages)}</div>
            </Fragment>
          ) : (
            <div css={questionInfoCSS}>
              <img css={imageCSS} src={component.icon_url} alt={component.key} />
              <div>
                <div css={titleCSS}>{component.label}</div>
                <div css={descriptionCSS}>{interpolateCoverageDescription(component.description, coverages)}</div>
              </div>
            </div>
          )}
          <div css={inputWrapperCSS}>
            <fieldset css={formCSS(component.presentation_type === CustomComponentPresentationType.Switch)}>
              {renderComponentQuestion(component)}
            </fieldset>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OptionalCoverageForm;
