import { css } from '@emotion/react';
import theme from '../../../../styles/theme';

export const layerCSS = css`
  background-color: ${theme.colors.white};
  opacity: 70%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const loaderCSS = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
`;
