import { css } from '@emotion/react';
import { formInputFocusOutlineCSS } from '../../../styles/form.style';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const errorCSS = css`
  .select {
    &__control {
      border-color: ${theme.colors.errorRed};
      box-shadow: inset 0 0 0 1px ${theme.colors.errorRed};

      .select__indicators {
        border-color: ${theme.colors.errorRed};
      }

      &:hover {
        border-color: ${theme.colors.errorRed};
        box-shadow: ${theme.colors.errorRed};

        .select__indicators {
          border-color: ${theme.colors.errorRed};
        }
      }

      &--is-focused,
      &--is-focused:hover {
        border-color: ${theme.colors.black};
        box-shadow: unset;

        .select__indicators {
          border-color: ${theme.colors.black};
        }

        &::before {
          border-color: ${theme.colors.errorRed};
        }
      }
    }
  }
`;

export const selectCSS = (
  hasError = false,
  isDisabled = false,
  dense = false,
  isPrefilled = false
): SerializedStyles => css`
  .select {
    &__control {
      background-color: ${isDisabled ? theme.colors.gray10 : theme.colors.white};
      border: 1px solid ${isPrefilled ? theme.colors.azure50 : theme.colors.black};
      box-shadow: inset 0 0 0 1px ${isPrefilled ? theme.colors.azure50 : 'transparent'};
      border-radius: ${theme.sizes.borderRadius}px;
      cursor: pointer;
      font-size: ${theme.typography.fontSize};
      min-height: ${dense ? theme.sizes.controlHeightSmall : theme.sizes.controlHeight}px;
      position: relative;
      transition: border-color 0.2s ease;

      .select__indicators {
        border-color: ${isPrefilled ? theme.colors.azure50 : theme.colors.black};
        border-width: ${isPrefilled ? 2 : 1}px;
      }

      &:hover {
        border-color: ${theme.colors.azure62};
        box-shadow: transparent;

        .select__indicators {
          border-color: ${theme.colors.azure62};
        }
      }

      &--is-focused,
      &--is-focused:hover {
        border-width: 1px;
        border-color: ${theme.colors.black};
        box-shadow: none;

        &::before {
          ${formInputFocusOutlineCSS};
          border-color: ${theme.colors.azure50};
        }

        .select__indicators {
          border-width: 1px;
          border-color: ${theme.colors.black};
        }
      }

      &--menu-is-open {
        .select__dropdown-indicator {
          transform: rotate(180deg);
        }
      }
    }

    &__dropdown-indicator {
      svg {
        color: ${theme.colors.black};
        height: 12px;
        width: ${dense ? 16 : 24}px;
      }
    }

    &__loading-indicator {
      position: absolute;
      right: 70px;
      z-index: 1;
    }

    &__menu {
      border: 1px solid ${theme.colors.gray30};
      box-shadow: 0 4px 0 0 rgba(0, 0, 0, 0.09);
      z-index: 3;
    }

    &__placeholder {
      color: ${theme.colors.gray60};
      font-size: ${theme.typography.fontSize};
      font-style: italic;
    }

    &__indicators {
      border-left: 1px solid ${theme.colors.black};
      justify-content: center;
      width: ${dense ? 40 : 56}px;
    }

    &__indicator-separator {
      display: none;
    }

    &__value-container {
      padding-left: ${dense ? 8 : 18}px;
      padding-right: ${dense ? 10 : 14}px;

      &--is-multi {
        padding-top: ${dense ? 6 : 15}px;
        padding-bottom: ${dense ? 6 : 15}px;
      }
    }

    &__single-value {
      color: ${theme.colors.black};
    }

    &__multi-value {
      &__remove {
        &:hover {
          color: ${theme.colors.white};
          background-color: ${theme.colors.azure68};
        }
      }
    }

    &__option {
      cursor: pointer;

      &--is-focused,
      &--is-focused:active {
        background-color: ${theme.colors.gray10};
      }

      &--is-selected,
      &--is-selected:active {
        background-color: ${theme.colors.azure50};
        color: ${theme.colors.white};
      }
    }
  }

  ${hasError && errorCSS};
`;
