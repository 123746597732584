import { css } from '@emotion/react';
import {
  arrowCSS as arrow,
  buttonCSS as button,
  itemCSS as item,
} from '../../../../../shared/components/Accordion/Accordion.style';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const itemCSS = css`
  ${item};
  padding: 0;
`;

export const buttonCSS = css`
  ${button};
`;

export const titleCSS = css`
  font-weight: 500;
`;

export const arrowCSS = css`
  ${arrow};
  &:before,
  &:after {
    background-color: ${theme.colors.black};
  }
`;

export const panelCSS = css`
  margin-top: 12px;
  ${mq[0]} {
    margin-top: 10px;
  }
`;

export const paymentRowCSS = (isFirst?: boolean): SerializedStyles => css`
  display: flex;
  justify-content: space-between;
  margin-top: ${isFirst ? 0 : 12}px;
  position: relative;
  padding-left: 18px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: calc(50% - 3px);
    background-color: ${theme.colors.gray30};
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: ${isFirst ? 9 : -22}px;
    bottom: 9px;
    left: 2.5px;
    width: 1px;
    background-color: ${theme.colors.gray30};
  }
`;

export const paymentTimeCSS = css`
  margin-right: 12px;
`;

export const paymentValueCSS = css`
  font-weight: 700;
`;

export const paymentFeeCSS = css`
  color: ${theme.colors.gray60};
  margin-right: 12px;
`;
