import { Tooltip } from '@mui/material';
import { useId } from 'react';
import QuestionIcon from '../../../assets/question-mark-circle.svg?react';
import { muiTooltipCSS, tooltipArrowCSS, tooltipContentCSS } from '../../../styles/common.style';
import { TooltipTheme } from '../Tooltip/Tooltip.type';
import { questionMarkCSS, tooltipButtonCSS } from './TooltipQuestionButton.style';
import type { TooltipQuestionButtonProps } from './TooltipQuestionButton.type';
import type { FC } from 'react';

const TooltipQuestionButton: FC<TooltipQuestionButtonProps> = ({
  customCSS,
  id,
  content,
  size = 18,
  theme = TooltipTheme.Light,
}) => {
  const generatedId = useId();
  const key = id ?? generatedId;

  return (
    <Tooltip
      id={`tooltip-${key}`}
      arrow
      placement="top"
      componentsProps={{
        tooltip: { sx: { ...muiTooltipCSS(theme) } },
        arrow: { sx: { ...tooltipArrowCSS(theme) } },
      }}
      title={<div css={tooltipContentCSS(theme)} dangerouslySetInnerHTML={{ __html: content }} />}
      enterTouchDelay={0}
      leaveTouchDelay={100000}
    >
      <button
        data-testid="tooltip"
        type="button"
        css={[tooltipButtonCSS(size), customCSS]}
        data-tip="tooltip"
        aria-label={content}
        data-for={`tooltip-${key}`}
      >
        <QuestionIcon css={questionMarkCSS(size)} />
      </button>
    </Tooltip>
  );
};

export default TooltipQuestionButton;
