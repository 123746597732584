import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text12CSS, text24CSS, text40CSS, textCSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  overflow: hidden;
  width: 100%;
`;

export const containerInnerCSS = (homeOnly?: boolean): SerializedStyles => css`
  display: flex;
  justify-content: center;
  text-align: center;
  background: ${theme.colors.azure95};
  margin-bottom: 54px;

  ${mq[0]} {
    margin-bottom: 70px;
    background: linear-gradient(
      90deg,
      ${theme.colors.azure95} 50%,
      ${homeOnly ? theme.colors.gray10 : theme.colors.azure95} 50%
    );
  }
`;

export const contentCSS = (bundle?: boolean): SerializedStyles => css`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -${bundle ? 25 : 25}px;
    bottom: -${bundle ? 25 : 25}px;
    left: -${bundle ? 70 : 45}px;
    right: -${bundle ? 70 : 45}px;
    background-color: ${theme.colors.white};
    border-radius: 50%;
    z-index: 1;
  }

  ${mq[0]} {
    &:before {
      top: -${bundle ? 60 : 70}px;
      bottom: -${bundle ? 60 : 70}px;
      left: -${bundle ? 80 : 50}px;
      right: -${bundle ? 80 : 50}px;
    }
  }
`;

export const policyTypeIconCSS = css`
  display: none;
  position: absolute;
  top: calc(50% - 62px);
  z-index: 0;

  ${mq[0]} {
    display: block;
  }
`;

export const houseCSS = (bundle?: boolean): SerializedStyles => css`
  ${policyTypeIconCSS};
  left: -${bundle ? 170 : 148}px;
  width: 100px;
`;

export const carIconCSS = (bundle?: boolean, homeOnly?: boolean): SerializedStyles => css`
  ${policyTypeIconCSS};
  right: -${bundle ? 170 : 144}px;
  filter: ${homeOnly ? 'grayscale(100%)' : 'none'};
`;

export const topLayerCSS = css`
  padding-top: 40px;
  position: relative;
  z-index: 2;
`;

export const discountPremiumCSS = css`
  color: ${theme.colors.gray60};
  text-decoration: line-through;
`;

export const premiumCSS = (bundle?: boolean): SerializedStyles => css`
  position: relative;
  ${text40CSS};
  font-weight: 700;
  color: ${bundle ? theme.colors.violet : theme.colors.black};

  span {
    ${text24CSS};
  }
`;

export const subheadingCSS = (bundle?: boolean): SerializedStyles => css`
  position: relative;
  ${textCSS};
  font-weight: 500;
  color: ${bundle ? theme.colors.violet : theme.colors.gray60};
  margin-top: 4px;
`;

export const labelContainerCSS = css`
  margin-top: 16px;
`;

export const discountLabelCSS = css`
  display: inline-block;
  ${text12CSS};
  font-weight: 700;
  line-height: 16px;
  background-color: ${theme.colors.violet};
  color: ${theme.colors.white};
  padding: 4px 12px;
  border-radius: 40px;
`;

export const actionButtonCSS = css`
  position: relative;
  top: 22px;
  min-width: 140px;
  margin-top: 10px;

  ${mq[0]} {
    min-width: 220px;
  }
`;
