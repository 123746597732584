import { css } from '@emotion/react';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const inputWrapperCSS = css`
  position: relative;
`;

export const inputCSS = (
  isInvalid?: boolean,
  isDisabled?: boolean,
  hasPrefix = false,
  isPrefilled = false,
  dense = false
): SerializedStyles => css`
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.black};
  border-radius: ${theme.sizes.borderRadius}px;
  box-sizing: border-box;
  color: ${theme.colors.black};
  font-family: ${theme.typography.fontFamily};
  font-size: ${theme.typography.fontSize};
  height: ${dense ? theme.sizes.controlHeightSmall : theme.sizes.controlHeight}px;
  outline: none;
  padding-left: ${dense ? theme.sizes.inputPaddingHorizontalSmall : theme.sizes.inputPaddingHorizontal}px;
  padding-right: ${dense ? theme.sizes.inputPaddingHorizontalSmall : theme.sizes.inputPaddingHorizontal}px;
  width: 100%;
  transition:
    border-color 0.2s ease,
    color 0.2s ease;
  position: relative;
  z-index: 1;
  font-variant: lining-nums;
  appearance: none;

  &:hover {
    border-color: ${theme.colors.azure62};
  }

  ${isPrefilled &&
  css`
    border-color: ${theme.colors.azure50};
    box-shadow: inset 0 0 0 1px ${theme.colors.azure50};
  `}

  ${isInvalid &&
  css`
    &,
    &:hover,
    &:focus {
      padding-left: ${dense ? theme.sizes.inputPaddingHorizontalSmall - 1 : theme.sizes.inputPaddingHorizontal - 1}px;
      padding-right: ${dense ? theme.sizes.inputPaddingHorizontalSmall - 1 : theme.sizes.inputPaddingHorizontal - 1}px;
      color: ${theme.colors.errorRed};
      border-color: ${theme.colors.errorRed};
      box-shadow: inset 0 0 0 1px ${theme.colors.errorRed};
    }
  `};

  ${isDisabled &&
  css`
    background-color: ${theme.colors.gray10};
    cursor: not-allowed;
  `}
  &:focus {
    border-color: ${theme.colors.black};
    box-shadow: none;
  }

  &:focus + .input-focus-helper {
    border-color: ${isInvalid ? theme.colors.errorRed : theme.colors.azure50};
  }

  &:focus ~ .input-icon {
    color: ${isInvalid ? theme.colors.errorRed : theme.colors.azure50};
  }

  ${hasPrefix &&
  css`
    &,
    &:hover,
    &:focus {
      padding-left: 30px;
      padding-right: 20px;
    }
  `};
`;

export const focusHelperCSS = css`
  position: absolute;
  top: -3px;
  right: -3px;
  bottom: -3px;
  left: -3px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 7px;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
  z-index: 0;
`;
