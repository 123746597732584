import { css } from '@emotion/react';

export const text12CSS = css`
  font-size: 12px;
  line-height: 20px;
`;

export const text14CSS = css`
  font-size: 14px;
  line-height: 18px;
`;

export const textCSS = css`
  font-size: 16px;
  line-height: 20px;
`;

export const text20CSS = css`
  font-size: 20px;
  line-height: 28px;
`;

export const text24CSS = css`
  font-size: 24px;
  line-height: 34px;
`;

export const text32CSS = css`
  font-size: 32px;
  line-height: 44px;
`;

export const text40CSS = css`
  font-size: 40px;
  line-height: 52px;
`;

export const text48CSS = css`
  font-size: 48px;
  line-height: 68px;
`;

export const text64CSS = css`
  font-size: 64px;
  line-height: 80px;
`;
