import { isValid } from 'date-fns';
import Tooltip from '../../../shared/components/Tooltip/Tooltip';
import { formatDateValue } from '../../../utils/date.util';
import { DATA_POINT_INTERPOLATION_REGEX, TOOLTIP_INTERPOLATION_REGEX, tooltips } from './interpolation.util.const';
import { tooltipButtonCSS, tooltipContentCSS, tooltipLabelCSS } from './interpolation.util.style';
import type { SchemaTooltipKey } from './interpolation.util.type';
import type { Answers } from '../../../types/answer.type';
import type { ReactElement } from 'react';

export const interpolateHint = (hint?: string, answers?: Answers): string | undefined => {
  if (!hint) {
    return undefined;
  }
  const replaceKey = hint.match(DATA_POINT_INTERPOLATION_REGEX);
  if (!replaceKey || !answers) {
    return hint;
  }
  const valueToReplace = answers[replaceKey[0].slice(13, -2)] as string;
  const isDateValue = isValid(new Date(valueToReplace));
  const formattedValue = isDateValue ? formatDateValue(valueToReplace) : valueToReplace;

  return hint.replace(replaceKey[0], formattedValue);
};

const getStringWithInterpolatedTooltip = (
  tooltipKey: SchemaTooltipKey,
  str: string,
  valueToReplace: string
): ReactElement => {
  const tooltip = (
    <Tooltip
      customCSS={{
        buttonCSS: tooltipButtonCSS,
        tooltipCSS: {
          maxWidth: '300px',
        },
      }}
      content={
        <div css={tooltipContentCSS}>
          <div>{tooltips[tooltipKey].description}</div>
          <div>
            <img src={tooltips[tooltipKey].image} alt={tooltips[tooltipKey].title} />
          </div>
        </div>
      }
    >
      <span css={tooltipLabelCSS}>{tooltips[tooltipKey].title}</span>
    </Tooltip>
  );
  const parts = str.split(valueToReplace);
  const result = parts.map((part, index) => {
    if (index < parts.length - 1) {
      return (
        <span key={tooltipKey}>
          {part}
          {tooltip}
        </span>
      );
    }
    return part;
  });
  return <span>{result}</span>;
};

export const interpolateTooltip = (str?: string): ReactElement => {
  let result: ReactElement | string | undefined = str;
  const matches = str?.match(TOOLTIP_INTERPOLATION_REGEX);
  const tooltipKeys = Object.keys(tooltips);

  if (matches && str) {
    matches.forEach((match) => {
      const tooltipKey = match.replace(/%tooltip{{/, '').replace(/}}/, '');
      if (tooltipKeys.includes(tooltipKey)) {
        result = getStringWithInterpolatedTooltip(tooltipKey as SchemaTooltipKey, str, match);
      }
    });
  }

  return <>{result}</>;
};
