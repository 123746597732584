import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';

export const formCSS = css`
  width: 100%;

  & > div:first-of-type {
    margin-top: 24px;
  }

  & > div {
    margin-top: 16px;
  }
`;

export const submitErrorCSS = css`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${theme.colors.errorRed};
  padding: 12px;
  box-sizing: border-box;
  margin-top: 32px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;

  span {
    color: ${theme.colors.errorRed};
    font-weight: 700;
  }

  svg {
    margin-right: 8px;
  }

  p {
    margin: 0;
    text-align: left;
  }

  ${mq[0]} {
    padding: 20px 24px;

    svg {
      margin-right: 14px;
    }
  }
`;
