import { Controller } from 'react-hook-form';
import Switch from '../../../../../shared/components/Switch/Switch';
import { SWITCH_VALUE } from './SwitchInput.util';
import type { SwitchInputProps } from './SwitchInput.type';
import type { FC } from 'react';

const SwitchInput: FC<SwitchInputProps> = ({ inputId, name, isDisabled, dense }) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, onBlur, value } }) => (
        <Switch
          disabled={isDisabled}
          value={value === SWITCH_VALUE.on}
          inputId={inputId}
          dense={dense}
          onBlur={onBlur}
          onChange={(event) => onChange(event.target.checked ? SWITCH_VALUE.on : SWITCH_VALUE.off)}
        />
      )}
    />
  );
};

export default SwitchInput;
