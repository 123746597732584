import SIRV_IMAGES from '../../../../../constants/sirv-images';
import InfoFormBlock from '../../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import QuestionLabel from '../../../../../questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel';
import { QuestionLabelWrapper } from '../../../../../questionsForm/components/QuestionWrapper/QuestionLabel/QuestionLabel.type';
import RadioGroup from '../../../../../shared/components/RadioGroup/RadioGroup';
import noop from '../../../../../shared/utils/noop';
import { infoBlockCSS, labelCSS } from './CoInsuredQuestion.style';
import {
  CO_INSURED_QUESTION_HINT,
  CO_INSURED_QUESTION_KEY,
  CO_INSURED_QUESTION_OPTIONS,
} from './CoInsuredQuestion.util';
import type { CoInsuredQuestionOptions, CoInsuredQuestionProps } from './CoInsuredQuestion.type';
import type { ChangeEvent, FC } from 'react';

const CoInsuredQuestion: FC<CoInsuredQuestionProps> = ({ customCSS, onSelectOption = noop }) => {
  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    onSelectOption(event.target.value as CoInsuredQuestionOptions);
  };

  return (
    <div css={customCSS}>
      <InfoFormBlock customCSS={infoBlockCSS} iconUrl={SIRV_IMAGES.infoIcon} content={CO_INSURED_QUESTION_HINT} />
      <QuestionLabel customCSS={labelCSS} keyName={CO_INSURED_QUESTION_KEY} wrapperElement={QuestionLabelWrapper.Div}>
        Would you like to add a co-insured?
      </QuestionLabel>
      <RadioGroup name={CO_INSURED_QUESTION_KEY} options={CO_INSURED_QUESTION_OPTIONS} onChange={onChange} />
    </div>
  );
};

export default CoInsuredQuestion;
