import { useParams } from 'react-router-dom';
import { useSession } from '../../api/session';
import { PAGES } from '../../constants/schema-hardcoded-keys';
import NavigationItem from '../../shared/components/Navigation/NavigationItem/NavigationItem';
import FlowNavigationDetails from '../FlowNavigationDetails/FlowNavigationDetails';
import { containerCSS } from './FlowNavigation.style';
import type { FlowNavigationProps } from './FlowNavigation.type';
import type { NavigationItemProps } from '../../shared/components/Navigation/NavigationItem/NavigationItem.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const FlowNavigation: FC<FlowNavigationProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData, isPending } = useSession(gid);
  const currentPage = sessionData?.session.current_page_key ?? '';
  const pagesKeys = sessionData?.session.navigation.map((p) => p.key) ?? [];
  const currentPageIndex = pagesKeys.indexOf(currentPage);

  const pages: NavigationItemProps[] =
    sessionData?.session.navigation.map((p, index) => {
      const sections = p.sections.filter((s) => s.visible_in_navigation);
      const isCompleted = index < currentPageIndex;
      const isUnavailable = index > currentPageIndex;
      const isExpandable = index <= currentPageIndex && sections.length;

      return {
        name: p.navigation_heading || '',
        key_name: p.key,
        isCurrent: p.key === currentPage,
        isCompleted,
        isUnavailable,
        details: isExpandable ? (
          <FlowNavigationDetails sections={sections} trackForbidden={p.key === PAGES.driverDetails} />
        ) : null,
      };
    }) ?? [];

  if (isPending) {
    return (
      <div css={containerCSS}>
        <NavigationItem name="loader" key_name="loader" isLoading />
        <NavigationItem name="loader" key_name="loader" isLoading />
        <NavigationItem name="loader" key_name="loader" isLoading />
      </div>
    );
  }

  return (
    <nav css={[containerCSS, customCSS]}>
      {pages
        .filter((s) => !!s.name)
        .map((props) => (
          <NavigationItem {...props} key={props.key_name} />
        ))}
    </nav>
  );
};

export default FlowNavigation;
