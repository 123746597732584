import { css } from '@emotion/react';
import { maxCalendlyLikeWidthContainerCSS } from '../../../../../styles/common.style';
import { mq, mqCalendly } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text20CSS, text24CSS, text40CSS, textCSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const widgetCSS = css`
  width: 100%;
  position: relative;
  margin-top: 24px;

  ${mqCalendly[0]} {
    margin-top: 0;
  }
`;

export const widgetLoaderCSS = css`
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 63px);
`;

export const headingContainerCSS = (isShadowAgentRunning?: boolean): SerializedStyles => css`
  ${maxCalendlyLikeWidthContainerCSS};
  width: 100%;
  text-align: center;
  margin-top: 56px;
  ${mq[0]} {
    margin-top: ${isShadowAgentRunning ? '40' : '80'}px;
  }
`;

export const titleCSS = (isShadowAgentRunning?: boolean): SerializedStyles => css`
  ${text24CSS};
  line-height: 28px;
  font-weight: 700;

  ${mq[0]} {
    ${isShadowAgentRunning ? text24CSS : text40CSS}
  }
`;

export const subtitleCSS = (isShadowAgentRunning?: boolean): SerializedStyles => css`
  color: ${theme.colors.gray80};
  margin-top: 4px;
  ${textCSS};

  ${mq[0]} {
    ${isShadowAgentRunning ? textCSS : text20CSS}
  }
`;

export const scheduledCallInfoCSS = css`
  ${maxCalendlyLikeWidthContainerCSS};
  margin-top: 24px;

  ${mqCalendly[0]} {
    margin-top: 32px;
  }
`;

export const scheduledCallDetailsCSS = css`
  ${maxCalendlyLikeWidthContainerCSS};
  width: 100%;
  margin-top: 24px;
`;
