import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text12CSS } from '../../../../../styles/typography.style';

export const wrapperCSS = css`
  margin: 0 auto;

  ${mq[0]} {
    max-width: 890px;
  }
`;

export const cardFooterCSS = css`
  background-color: ${theme.colors.gray5};
  border-radius: 4px;

  ${mq[0]} {
    padding: 40px 60px;
  }
`;

export const coveragesContainerCSS = css`
  border-top: 0;
  margin-top: 0;
`;

export const disclaimerCSS = css`
  position: relative;
  ${text12CSS};
  color: ${theme.colors.gray60};
  padding-top: 65px;

  &:before {
    position: absolute;
    content: '';
    top: 32px;
    left: -16px;
    right: -16px;
    height: 1px;
    display: block;
    background-color: ${theme.colors.gray10};
  }

  ${mq[0]} {
    margin-top: 24px;
    padding-top: 0;

    &:before {
      display: none;
    }
  }
`;
