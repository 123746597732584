import { StrategyType } from '../../types/public-flow-config.type';

export const CALENDLY_ID = 'calendly-widget';
export const CALENDLY_PREVIEW_DETAILS_ID = 'calendly-preview-details';
export const CALL_SCHEDULED_STEP = 3;
export const UTM_MEDIUM = 'serweb';
const UTM_SOURCE_DIGITAL_PROFILE = 'real_digitalprofile';

export const filterCalendlyEvents = (eventHandler: (event: string) => void) => (event: MessageEvent) => {
  !(event.data.event || '').indexOf('calendly') && eventHandler(event.data.event);
};

export const getUtmSource = (strategy?: StrategyType, carrierKey = ''): string =>
  strategy === StrategyType.DigitalProfile ? UTM_SOURCE_DIGITAL_PROFILE : `olb_${carrierKey}`;
