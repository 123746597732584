import { FS_SENSITIVE_DATA_CLASS } from '../../../../../constants/full-story';
import { MARITAL_STATUS_VARIANTS, RELATIONSHIP_VARIANTS } from '../../../../../constants/schema-hardcoded-keys';
import SkeletonLoader from '../../../../../shared/components/SkeletonLoader/SkeletonLoader';
import format from '../../../../../utils/format.util';
import { containerCSS, itemCSS, itemsColCSS } from './CoInsuredPreview.style';
import type { CoInsuredPreviewProps } from './CoInsuredPreview.type';
import type { FC } from 'react';

const CoInsuredPreview: FC<CoInsuredPreviewProps> = ({ customCSS, coInsured }) => {
  const {
    first_name: firstName,
    last_name: lastName,
    date_of_birth: dateOfBirth,
    marital_status: maritalStatus,
    kind: relationship,
  } = coInsured ?? {};

  const maritalStatusLabel = MARITAL_STATUS_VARIANTS.find((s) => s.value === maritalStatus)?.label;
  const relationshipLabel = RELATIONSHIP_VARIANTS.find((s) => s.value === relationship)?.label;

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={itemsColCSS}>
        <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
          {firstName && lastName ? (
            <>
              <span>Name:</span> {firstName} {lastName}
            </>
          ) : (
            <SkeletonLoader minWidth={60} maxWidth={80} />
          )}
        </div>
        <div css={itemCSS} className={FS_SENSITIVE_DATA_CLASS.mask}>
          {dateOfBirth ? (
            <>
              <span>Birthdate:</span> {format.date(dateOfBirth)}
            </>
          ) : (
            <SkeletonLoader minWidth={60} maxWidth={80} />
          )}
        </div>
      </div>
      <div css={itemsColCSS}>
        <div css={itemCSS}>
          {maritalStatusLabel ? (
            <>
              <span>Marital Status:</span> {maritalStatusLabel}
            </>
          ) : (
            <SkeletonLoader minWidth={60} maxWidth={80} />
          )}
        </div>
        <div css={itemCSS}>
          {relationshipLabel ? (
            <>
              <span>Relationship:</span> {relationshipLabel}
            </>
          ) : (
            <SkeletonLoader minWidth={60} maxWidth={80} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CoInsuredPreview;
