import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { QuotesRequestsListResponse } from '../../types/quote.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useQuotesRequestsList = <T>(gid?: string, enabled = true): QueryObserverResult<QuotesRequestsListResponse<T>> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.quotesRequestsList, gid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<QuotesRequestsListResponse>>(getApiUrl(API_PATH.quotesRequestsList, gid as string))
        .then((res) => res.data),
    enabled: !!gid && enabled,
  });

export default useQuotesRequestsList;
