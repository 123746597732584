import { ClickAwayListener, Popper } from '@mui/material';
import { DateCalendar, LocalizationProvider, type DateCalendarSlotProps } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, isValid, parse } from 'date-fns';
import { useState, type FC, type MouseEvent, useId } from 'react';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import CalendarIcon from '../../../../assets/calendar-icon.svg?react';
import ChevronIcon from '../../../../assets/chevron.svg?react';
import ArrowIcon from '../../../../assets/toggle-arrow.svg?react';

import { FS_SENSITIVE_DATA_CLASS } from '../../../../constants/full-story';
import { focusHelperCSS, inputCSS, inputWrapperCSS } from '../../../../shared/components/Input/Input.style';
import noop from '../../../../shared/utils/noop';
import { parseDateValue } from '../../../../utils/date.util';
import { isValueSpecificType } from '../../../utils/answers/answers.util';
import {
  hardcodedValidations,
  getValidationRules,
} from '../../../utils/question-validations/question-validations.util';
import {
  calendarIconCSS,
  dayCSS,
  layoutCSS,
  nextIconCSS,
  previousIconCSS,
  switchIconCSS,
} from './DatePickerInput.style';
import { getMinAndMaxDates, shouldDisableDate } from './DatePickerInput.util';
import type { DatePickerInputProps } from './DatePickerInput.type';
import type { PickerSelectionState } from '@mui/x-date-pickers/internals';

const datePickerSlotProps = {
  day: {
    sx: dayCSS,
  },
  previousIconButton: {
    sx: nextIconCSS,
  },
  nextIconButton: {
    sx: previousIconCSS,
  },
  switchViewButton: {
    sx: switchIconCSS,
  },
};

const DatePickerInput: FC<DatePickerInputProps> = ({
  name,
  hasError = false,
  isDisabled,
  validations,
  trackingForbidden = false,
  inputId,
  onValidatePreviousInputs = noop,
  mask,
  placeholder,
  renderDropdownInBody = false,
  dense = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const triggerPopper: (event: MouseEvent<HTMLElement>) => void = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const popperId = useId();
  const open = Boolean(anchorEl);

  return (
    <Controller
      name={name}
      rules={getValidationRules(validations, {
        isValidDate: hardcodedValidations.isValidDate,
      })}
      render={({ field: { onChange, value, onBlur, name, ref }, fieldState: { isTouched } }) => {
        const stringValue = isValueSpecificType<string>(value, ['string']) ? value : '';
        const dateValue = parse(parseDateValue(stringValue), 'MM-dd-yyyy', new Date());

        const onCalendarChange: (value: Date | null, selectionState?: PickerSelectionState | undefined) => void = (
          value: Date | null
        ) => {
          const dateValue = (value && format(value, 'yyyy-MM-dd')) ?? null;
          onChange(dateValue);
          onValidatePreviousInputs();
          setAnchorEl(null);
        };

        return (
          <div css={inputWrapperCSS}>
            <MaskedInput
              className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
              css={inputCSS(hasError, isDisabled, false, Boolean(value && !isTouched), dense)}
              id={inputId}
              inputRef={(el: HTMLInputElement) => {
                ref(el);
              }}
              mask={mask}
              name={name}
              type="text"
              value={parseDateValue(stringValue)}
              disabled={isDisabled}
              placeholder={placeholder}
              onBlur={onBlur}
              onClick={triggerPopper}
              readOnly
            />
            <div css={focusHelperCSS} className="input-focus-helper" />
            <CalendarIcon css={calendarIconCSS(hasError)} className="input-icon" />
            <Popper
              data-testid="date-picker-popper"
              id={popperId}
              open={open}
              anchorEl={anchorEl}
              disablePortal={!renderDropdownInBody}
              modifiers={[
                {
                  name: 'flip',
                  enabled: true,
                  options: {
                    altBoundary: false,
                    rootBoundary: 'document',
                    padding: 8,
                  },
                },
                {
                  name: 'preventOverflow',
                  enabled: false,
                  options: {
                    altAxis: true,
                    altBoundary: false,
                    tether: true,
                    rootBoundary: 'document',
                    padding: 8,
                  },
                },
              ]}
              css={layoutCSS}
              placement="bottom-start"
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateCalendar
                      className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
                      value={isValid(dateValue) ? dateValue : null}
                      disabled={isDisabled}
                      showDaysOutsideCurrentMonth
                      fixedWeekNumber={6}
                      shouldDisableDate={(date: Date) => shouldDisableDate(date, validations)}
                      shouldDisableYear={(date: Date) => shouldDisableDate(date, validations)}
                      onChange={onCalendarChange}
                      slotProps={datePickerSlotProps as unknown as DateCalendarSlotProps<Date>}
                      slots={{
                        leftArrowIcon: ArrowIcon,
                        rightArrowIcon: ArrowIcon,
                        switchViewIcon: ChevronIcon,
                      }}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      {...getMinAndMaxDates(validations)}
                    />
                  </LocalizationProvider>
                </div>
              </ClickAwayListener>
            </Popper>
          </div>
        );
      }}
    />
  );
};

export default DatePickerInput;
