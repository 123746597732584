import { ComponentTypes } from '../../types/form-component.type';
import { captureException } from '../../utils/error.util';
import isVisibleByAnswers from '../../utils/visibility-conditions.util';
import type { AddressAnswer, Answers } from '../../types/answer.type';
import type { FormComponentSchema, WorkflowSchema } from '../../types/form-component.type';

const getFirstProperty = (obj: Record<string, any>): unknown => Object.keys(obj)[0];

export const scrollToError = (errors: Record<string, any>): void => {
  const firstErrorKey = getFirstProperty(errors) as string;
  let inputKey;
  if (Array.isArray(errors[firstErrorKey])) {
    const index: number = errors[firstErrorKey].findIndex((el: Record<string, string>) => !!el);
    inputKey = `${firstErrorKey}[${index}].${getFirstProperty(errors[firstErrorKey][index]) as string}`;
  } else {
    inputKey = firstErrorKey;
  }
  let inputToScroll = document.getElementById(inputKey);
  if (!inputToScroll) {
    /** If it is radio button, each input has id as "key" + "index" */
    inputToScroll = document.getElementById(`${inputKey}[0]`);
  }

  if (inputToScroll) {
    inputToScroll.focus({ preventScroll: true });
    inputToScroll.scrollIntoView({ behavior: 'smooth', block: 'center' });
  } else {
    captureException(new Error(`Failed to scroll to error input ${inputKey}`));
  }
};

const hasAllQuestionsHidden = (component: FormComponentSchema, answers: Answers): boolean => {
  if (component.type === ComponentTypes.Workflow) {
    const workflow = component as WorkflowSchema;
    return workflow.questions.every((q) => !isVisibleByAnswers(answers, q.visibility_conditions));
  }
  return false;
};

export const getVisibleComponents = (
  components: FormComponentSchema[] = [],
  answers: Answers = {}
): FormComponentSchema[] =>
  components.filter((c) => isVisibleByAnswers(answers, c.visibility_conditions) && !hasAllQuestionsHidden(c, answers));

/** Consider all questions if required */
export const hasAnswers = (questionsKeys: string[], answers: Answers): boolean =>
  !questionsKeys.some((key) => {
    if (typeof answers[key] === 'object' && answers[key] !== null) {
      return Object.keys(answers[key] as AddressAnswer).length === 0;
    }
    if (typeof answers[key] === 'number' && answers[key] !== null) {
      return false;
    }

    return !answers[key];
  });
