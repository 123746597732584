import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { CoInsured } from '../../types/co-insured.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useMutateCoInsured = (gid: string): UseBaseMutationResult<{ data: CoInsured }, HTTPResponse, CoInsured> => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (data) => {
      const { gid: coInsuredGid, ...rest } = data;
      return await api
        .patch<HTTPResponse<{ data: CoInsured }>>(getApiUrl(API_PATH.coInsured, gid, coInsuredGid), {
          body: { data: rest },
        })
        .then((res) => res.data);
    },
    onSuccess: async () => {
      await client.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.coInsureds] });
    },
  });
};

export default useMutateCoInsured;
