import { Switch as MuiSwitch } from '@mui/material';
import useResponsive from '../../../hooks/use-responsive';
import { getMuiSwitchStylesCSS } from './Switch.style';
import type { SwitchProps } from './Switch.type';
import type { FC } from 'react';

export const Switch: FC<SwitchProps> = ({ value, dense, inputId, disabled, onChange, onBlur }) => {
  const { isMobile } = useResponsive();

  return (
    <MuiSwitch
      sx={getMuiSwitchStylesCSS(isMobile || dense)}
      id={inputId}
      checked={value}
      onChange={onChange}
      inputProps={{ onBlur }}
      disabled={disabled}
    />
  );
};

export default Switch;
