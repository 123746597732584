import type { HomeQuote, QuoteRequest } from '../../../../../types/quote.type';

export const CHECKOUT_SUMMARY_EXPANDER_ID = 'checkout-expander';

export const getButtonLabel = (isExpanded: boolean): string => {
  return isExpanded ? 'Hide Details' : 'Show Details';
};

export const getAdditionalDiscount = (quoteRequests?: Array<QuoteRequest<HomeQuote>>): null | number => {
  if (!quoteRequests) {
    return null;
  }

  if (quoteRequests?.length < 2) {
    return null;
  }

  const savings =
    Number(quoteRequests[1].groups[0].quotes[0].premium) - Number(quoteRequests[0].groups[0].quotes[0].premium);

  return savings > 0 ? Math.round(savings) : null;
};
