import Tooltip from '../../../../shared/components/Tooltip/Tooltip';
import { discountsTextCSS, tooltipButtonCSS, discountsContentCSS } from './QuoteDiscounts.style';
import type { QuoteDiscountsProps } from './QuoteDiscounts.type';
import type { FC } from 'react';

const DiscountsTooltipContent: FC<QuoteDiscountsProps> = ({ discounts }) => {
  return (
    <div css={discountsContentCSS}>
      {discounts.map((d, index) => (
        <span key={d.title}>
          {d.title}
          {discounts[index + 1] ? ', ' : ''}
        </span>
      ))}
    </div>
  );
};

const QuoteDiscounts: FC<QuoteDiscountsProps> = ({ discounts }) => {
  return (
    <div>
      <Tooltip
        customCSS={{
          buttonCSS: tooltipButtonCSS,
          tooltipCSS: {
            maxWidth: '530px',
          },
        }}
        content={<DiscountsTooltipContent discounts={discounts} />}
        position="top"
      >
        <span css={discountsTextCSS}>
          {discounts.length} Discount{discounts.length > 1 ? 's' : ''} applied
        </span>
      </Tooltip>
    </div>
  );
};

export default QuoteDiscounts;
