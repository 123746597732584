import { isTrackForbidden } from '../../../utils/form-element-tags.util';
import { QuestionWrapper } from '../../QuestionWrapper/QuestionWrapper';
import CounterFormControl from './CounterFormControl/CounterFormControl';
import { getCounterInputVariant } from './CounterQuestion.util';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const CounterQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  description,
  hint,
  workflowHeading,
  isDisabled,
  placeholder,
  validations,
  gridWidth,
  icon,
  tags = [],
}) => (
  <QuestionWrapper
    questionKey={questionKey}
    label={label}
    hideLabel={hideLabel}
    hint={hint}
    description={description}
    workflowHeading={workflowHeading}
    isDisabled={isDisabled}
    gridWidth={gridWidth}
    inputProps={{
      type: 'number',
      name: questionKey,
      isDisabled,
      inputId: questionKey,
      placeholder,
      validations,
      trackingForbidden: isTrackForbidden(tags),
      variant: getCounterInputVariant(tags),
      iconUrl: icon,
      label,
    }}
    inputComponent={CounterFormControl}
  />
);

export default CounterQuestion;
