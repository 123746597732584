enum SubscriptionEventTypes {
  ConfirmSubscription = 'confirm_subscription',
}

export enum SubscriptionEventMessage {
  QuotesRequestFinished = 'quotes_request_finished',
  SessionWasLocked = 'session_was_locked',
  SlowQuotingFinished = 'slow_quoting_finished',
}

export interface SubscriptionEventData {
  type: SubscriptionEventTypes;
  message?: {
    message: SubscriptionEventMessage;
  };
}
