import type { MultiSelectValue } from './Select.type';
import type { MenuPlacement } from 'react-select/dist/declarations/src/types';

export const DEFAULT_SELECT_OPTIONS = {
  classNamePrefix: 'select',
  'data-testid': 'select',
  isClearable: false,
  menuPlacement: 'auto' as MenuPlacement,
  minMenuHeight: 160,
};

export const filterOptions = (
  candidate: MultiSelectValue,
  rawInput: string,
  otherOptionKey?: string | null
): boolean => {
  if (!otherOptionKey) {
    return candidate.label.toLowerCase().includes(rawInput.toLowerCase());
  }
  return candidate.label.toLowerCase().includes(rawInput.toLowerCase()) || candidate.value === otherOptionKey;
};
