import { REQUIRED_VALIDATION } from '../../../../../questionsForm/utils/question-validations/question-validations.util';
import { QuestionType } from '../../../../../types/question.type';
import type { QuestionProps } from '../../../../../questionsForm/components/Question/Question.type';
import type { Loan } from '../../../../../types/loan.type';
import type { Person } from '../../../../../types/person.type';

export const MORTGAGE_STEP_HINT =
  'We will work with your lender ' +
  'to <b>include your new insurance premium into your escrow</b> as part of your mortgage payments.';

export const SERVICER_QUESTION_SCHEMA: QuestionProps = {
  questionKey: 'servicer_gid',
  type: QuestionType.SingleSelect,
  label: 'Who Is Your Mortgage With?',
  placeholder: 'type to search',
  variants: [],
  validations: [REQUIRED_VALIDATION],
  gridWidth: 12,
};

export const LOAN_QUESTION_SCHEMA: QuestionProps = {
  questionKey: 'loan_number',
  type: QuestionType.Text,
  label: 'What Is Your Loan Number?',
  placeholder: 'loan number',
  validations: [REQUIRED_VALIDATION],
  gridWidth: 12,
};

export const hasFullLoanAndPersonData = (loan?: Loan, primaryInsured?: Person): boolean => {
  return Boolean(
    loan?.loan_number &&
      loan.servicer?.gid &&
      primaryInsured?.email &&
      primaryInsured.phone &&
      primaryInsured.mailing_address?.gid
  );
};
