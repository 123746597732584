import { Card } from '../../../../shared/components/Card/Card';
import {
  containerCSS,
  descriptionCSS,
  hrefCSS,
  imageCSS,
  tagCSS,
  tagsWrapperCSS,
  titleCSS,
} from './BlogPostCard.style';
import type { BlogPostCardProps } from './BlogPostCard.type';
import type { FC } from 'react';

const BlogPostCard: FC<BlogPostCardProps> = ({ customCSS, title, description, image, imageAlt, url, tags }) => {
  return (
    <Card customCSS={[containerCSS, customCSS]}>
      <a css={hrefCSS} href={url} target="_blank" rel="noreferrer">
        <div css={imageCSS(image)} role="img" aria-label={imageAlt} />
        <div css={titleCSS}>{title}</div>
        {description && <div css={descriptionCSS}>{description}</div>}
        {!!tags?.length && (
          <div css={tagsWrapperCSS}>
            {tags.map((t) => (
              <div css={tagCSS} key={t}>
                {t}
              </div>
            ))}
          </div>
        )}
      </a>
    </Card>
  );
};

export default BlogPostCard;
