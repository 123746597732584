import type { CheckoutAutoSummaryState } from './CheckoutAutoSummary.type';
import type { PaymentPlanCondition } from '../../../../../types/form-component.type';

export const getSummaryValues = (
  paymentPlans?: Record<string, PaymentPlanCondition>,
  selectedPaymentPlan?: string
): CheckoutAutoSummaryState | null => {
  if (!selectedPaymentPlan || !paymentPlans) {
    return null;
  }
  const plan = paymentPlans[selectedPaymentPlan];

  if (!plan) {
    return null;
  }

  const feesTotal = plan.installment_fee ? plan.installment_fee * plan.payments_count : 0;

  return {
    total: plan.total_cost,
    premiumCost: plan.total_cost - feesTotal,
    feesTotal,
    paymentsSchedule: plan.payments_schedule?.length
      ? plan.payments_schedule.map((p, index) => ({
          timeStamp: index === 0 ? 'Today’s Payment' : p.date,
          fee: plan.installment_fee && index !== 0 ? `inc. $${plan.installment_fee} fee` : null,
          value: `$${p.amount}`,
        }))
      : [],
  };
};
