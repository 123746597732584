export const HIDE_NAVIGATION_ID = 'hide-navigation-on-scroll';

export const hideNavigationOnScroll = (header: HTMLElement, navigation: HTMLDivElement): void => {
  if (!header.classList.contains(HIDE_NAVIGATION_ID)) {
    header.style.minHeight = `${header.offsetHeight - navigation.offsetHeight}px`;
    header.classList.add(HIDE_NAVIGATION_ID);
  }
};

export const showNavigationOnScroll = (header: HTMLElement, navigation: HTMLDivElement): void => {
  if (header.classList.contains(HIDE_NAVIGATION_ID)) {
    header.style.minHeight = `${header.offsetHeight + navigation.offsetHeight}px`;
    header.classList.remove(HIDE_NAVIGATION_ID);
  }
};
