import SIRV_IMAGES from '../../../../constants/sirv-images';
import Anchor from '../../../../shared/components/Anchor/Anchor';
import { buttonCSS } from '../../../../shared/components/Button/Button.style';
import BlogPostCard from '../../../congratulations/components/BlogPostCard/BlogPostCard';
import {
  moreButtonCSS,
  cardsWrapperCSS,
  containerCSS,
  subtitleCSS,
  titleCSS,
  imageCSS,
} from './CongratulationsErrorPage.style';
import { CONGRATULATIONS_ARTICLES } from './CongratulationsErrorPage.util';
import type { CongratulationsErrorPageProps } from './CongratulationsErrorPage.type';
import type { FC } from 'react';

export const CongratulationsErrorPage: FC<CongratulationsErrorPageProps> = ({ firstName = '' }) => {
  const firstNameToRender = firstName ? ` ${firstName}` : '';

  return (
    <div css={containerCSS}>
      <img css={imageCSS} src={SIRV_IMAGES.confetti} alt="gif" />
      <div css={titleCSS}>Congrats{firstNameToRender}, you’re all set!</div>
      <div css={subtitleCSS}>
        We will be sending you an email shortly with further instructions and policy details. Meanwhile, If you want to
        learn more about managing your insurance, be sure to check out these resources:
      </div>
      <div css={cardsWrapperCSS}>
        {CONGRATULATIONS_ARTICLES.map((c) => (
          <BlogPostCard
            key={c.imageAlt}
            title={c.title}
            imageAlt={c.imageAlt}
            url={c.href}
            image={`${c.imageSrc}?w=400&h=200`}
            tags={c.tags}
          />
        ))}
      </div>
      <Anchor href="https://matic.com/blog/" css={[buttonCSS(true, 'secondary'), moreButtonCSS]}>
        See More Related Articles
      </Anchor>
    </div>
  );
};
