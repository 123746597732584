import { css } from '@emotion/react';
import { mq } from './media-queries';
import theme from './theme';
import { text24CSS, text40CSS, text48CSS } from './typography.style';
import type { SerializedStyles } from '@emotion/react';

export const innerLayoutContainerCSS = (withNavigation?: boolean): SerializedStyles => css`
  padding-top: 24px;
  padding-bottom: 56px;
  ${mq[0]} {
    padding-top: 64px;
    padding-bottom: 80px;
    max-width: ${theme.sizes.containerQuestionsMaxWidth}px;
    ${withNavigation &&
    css`
      padding-left: ${theme.navigationWidthTablet}px;
    `}
  }

  ${mq[1]} {
    ${withNavigation &&
    css`
      padding-left: ${theme.navigationWidth}px;
    `}
  }
`;

export const extraLayoutPaddingCSS = css`
  padding-bottom: 120px;

  ${mq[0]} {
    padding-bottom: 240px;
  }
`;

export const layoutNavigationCSS = css`
  position: fixed;
  box-sizing: border-box;
  display: none;
  padding-right: 20px;
  padding-top: 60px;
  width: ${theme.navigationWidthTablet}px;

  ${mq[0]} {
    display: block;
  }
  ${mq[1]} {
    width: ${theme.navigationWidth}px;
  }
`;

export const fullHeightPageCSS = css`
  ${mq[0]} {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

export const pageHeadingCSS = css`
  ${text24CSS};
  font-weight: 700;
  margin-bottom: 24px;
  text-align: center;
  ${mq[0]} {
    ${text40CSS};
    margin-bottom: 32px;
    text-align: left;
  }
  ${mq[1]} {
    ${text48CSS};
    font-weight: 900;
  }
`;

export const layoutRightColumnCSS = css`
  margin-top: 64px;
  width: 320px;
  margin-left: 24px;
  flex-shrink: 0;
`;

export const stickyContainerCSS = css`
  position: sticky;
  top: calc(var(--header-height) + 64px);
`;
