import { Elements } from '@stripe/react-stripe-js';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutatePrimaryInsured, usePrimaryInsured } from '../../../../../api/primary-insured';
import { usePublicConfig } from '../../../../../api/publicConfig';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import noop, { noopAsync } from '../../../../../shared/utils/noop';
import { MAIN_FONT_URL } from '../../../../../styles/font';
import CheckoutContactInfoQuestions from '../CheckoutContactInfoQuestions/CheckoutContactInfoQuestions';
import {
  PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA,
  PERSONAL_INFO_EMAIL_QUESTION_SCHEMA,
  PERSONAL_INFO_PHONE_QUESTION_SCHEMA,
} from '../CheckoutContactInfoQuestions/CheckoutContactInfoQuestions.util';
import CheckoutCreditCardPreview from '../CheckoutCreditCardPreviw/CheckoutCreditCardPreview';
import StripeCardPaymentForm from '../StripeCardPaymentForm/StripeCardPaymentForm';
import { getStripePromise, STRIPE_CREDIT_CARD_ANSWER_KEY } from '../StripeCardPaymentForm/StripeCardPaymentForm.util';
import { hasFullPersonData, getStripeKey } from './CheckoutCreditCardStep.util';

import type { CheckoutCreditCardStepProps } from './CheckoutCreditCardStep.type';
import type { AddressAnswer } from '../../../../../types/answer.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { StripePaymentStepData } from '../StripeCardPaymentForm/StripeCardPaymentForm.type';
import type { FC } from 'react';

const CheckoutCreditCardStep: FC<CheckoutCreditCardStepProps> = ({
  customCSS,
  isEdit,
  isCurrent,
  actionLabel = 'Move to Next Step',
  isBindDisabled = false,
  onSetEdit,
  onBeforeStepSubmit = noop,
  onStepSubmit = noopAsync,
}) => {
  const { gid } = useParams() as DefaultLocation;

  const primaryInsuredQuery = usePrimaryInsured(gid);
  const primaryInsuredData = primaryInsuredQuery.data;
  const { mutateAsync: mutatePrimaryInsured } = useMutatePrimaryInsured(gid);

  const [cardNumber, setCardNumber] = useState<string>();
  const { data: configData } = usePublicConfig();
  const { data: sessionFlowConfig } = useSessionFlowConfig(gid);
  const stripeConfig = configData?.config.integrations.stripe;
  const carrierKey = sessionFlowConfig?.flow_config?.carrier_key;

  const stripePromise = useMemo(
    () => getStripePromise(getStripeKey(stripeConfig, carrierKey)),
    [stripeConfig, carrierKey]
  );

  useEffect(() => {
    if (!cardNumber && !isEdit && primaryInsuredData && hasFullPersonData(primaryInsuredData?.person)) {
      onSetEdit(true);
    }
  }, [cardNumber, isEdit, primaryInsuredData, onSetEdit]);

  const onFormSubmit = async (data: StripePaymentStepData, lastCardNumber?: string): Promise<void> => {
    onBeforeStepSubmit();
    const primaryInsuredPayload = {
      email: data[PERSONAL_INFO_EMAIL_QUESTION_SCHEMA.questionKey] as string,
      phone: data[PERSONAL_INFO_PHONE_QUESTION_SCHEMA.questionKey] as string,
      mailing_address: data[PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA.questionKey] as AddressAnswer,
    };

    await mutatePrimaryInsured(primaryInsuredPayload);
    await onStepSubmit({
      [STRIPE_CREDIT_CARD_ANSWER_KEY]: data[STRIPE_CREDIT_CARD_ANSWER_KEY],
    });

    setCardNumber(lastCardNumber);
    onSetEdit(false);
  };

  return (
    <div css={customCSS}>
      {(isEdit || !cardNumber) && (
        <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: MAIN_FONT_URL }] }}>
          <StripeCardPaymentForm
            isBindDisabled={isBindDisabled}
            actionLabel={actionLabel}
            onAfterValidSubmit={onFormSubmit}
            defaultAnswers={{
              [PERSONAL_INFO_EMAIL_QUESTION_SCHEMA.questionKey]: primaryInsuredData?.person.email,
              [PERSONAL_INFO_PHONE_QUESTION_SCHEMA.questionKey]: primaryInsuredData?.person.phone,
              [PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA.questionKey]: primaryInsuredData?.person?.mailing_address ?? {},
            }}
          >
            <CheckoutContactInfoQuestions />
          </StripeCardPaymentForm>
        </Elements>
      )}

      {!isEdit && primaryInsuredData?.person && cardNumber && (
        <CheckoutCreditCardPreview
          cardNumber={cardNumber}
          personEmail={primaryInsuredData.person.email}
          personNumber={primaryInsuredData.person.phone}
          personAddress={primaryInsuredData.person.mailing_address}
          actionLabel={actionLabel}
          showButton={isCurrent}
          onButtonClick={onStepSubmit}
        />
      )}
    </div>
  );
};

export default CheckoutCreditCardStep;
