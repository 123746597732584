import { useMemo } from 'react';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../../constants/full-story';
import InfoFormBlock from '../../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import format from '../../../../../utils/format.util';
import { headingCSS, headingSubTextCSS, headingTextCSS, infoBlockCSS } from './DigitalProfileQuotePageHeading.style';
import { getOriginalPremium } from './DigitalProfileQuotePageHeading.util';
import type { DigitalProfileQuotePageHeadingProps } from './DigitalProfileQuotePageHeading.type';
import type { FC, ReactNode } from 'react';

const ORIGINAL_QUOTE_BUNDLE_SUBTEXT =
  "We've calculated your homeowner's quote with a bundle discount. However, our agents need to confirm a few details " +
  'to provide your auto premium.';
const BETTER_FIT_BUNDLE_SUBTEXT_APPENDIX =
  "We've calculated your homeowner's quote with a bundle discount. However, our agents need to confirm" +
  ' a few details to provide your auto premium.';

export const DigitalProfileQuotePageHeading: FC<DigitalProfileQuotePageHeadingProps> = ({
  isFirstOriginalQuote,
  originalQuote,
  isSelectedCarrierUnknown,
  cheapestQuote,
  personAddress,
  bundleMessage,
  staticHeading,
}) => {
  const pageHeadingData = useMemo((): Record<string, ReactNode> | null => {
    const originalPremiumString = getOriginalPremium(originalQuote?.premium);
    const formattedPersonAddress = (personAddress && format.address(personAddress)) ?? '';

    switch (true) {
      case isSelectedCarrierUnknown && !!cheapestQuote:
        return {
          headingText: `Homeowners Insurance from ${cheapestQuote?.carrier_name}`,
          headingSubText: <span className={FS_SENSITIVE_DATA_CLASS.mask}>{formattedPersonAddress}</span>,
        };
      case isFirstOriginalQuote:
        return {
          headingText: originalQuote?.carrier_name ? `Homeowners Insurance from ${originalQuote?.carrier_name}` : '',
          headingSubText: bundleMessage ? (
            ORIGINAL_QUOTE_BUNDLE_SUBTEXT
          ) : (
            <span className={FS_SENSITIVE_DATA_CLASS.mask}>{formattedPersonAddress}</span>
          ),
        };
      case !isFirstOriginalQuote && !cheapestQuote && !originalQuote:
        return {
          headingText: 'We’re going to need to chat.',
          headingSubText:
            'Your home is unique and requires a specialist to find you a policy that works for you.' +
            ' A Matic agent will be reaching out shortly to talk to you. Have a specific time you’d' +
            ' like to chat? Schedule below.',
        };
      case !isFirstOriginalQuote:
        return {
          headingText: `We've found you a better fit!`,
          headingSubText: originalQuote?.carrier_name ? (
            <>
              It looks like there is a <strong>better option</strong> for you than our previous{' '}
              {originalQuote?.carrier_name} quote of {originalPremiumString}.{' '}
              {bundleMessage && BETTER_FIT_BUNDLE_SUBTEXT_APPENDIX}
            </>
          ) : (
            <>
              Based on the details of your home, the carrier you selected is unable to provide coverage. We found
              another option for you with {cheapestQuote?.carrier_name}.{' '}
              {bundleMessage && BETTER_FIT_BUNDLE_SUBTEXT_APPENDIX}
            </>
          ),
          infoText:
            'Note: We don’t favor specific carriers or get paid more based on the policy you choose. Your best' +
            ' interest is our best interest.',
        };
      default:
        return null;
    }
  }, [cheapestQuote, isSelectedCarrierUnknown, isFirstOriginalQuote, originalQuote, personAddress, bundleMessage]);

  return pageHeadingData ? (
    <div css={headingCSS}>
      <div css={headingTextCSS}>{staticHeading ? staticHeading.heading : pageHeadingData.headingText}</div>
      <div css={headingSubTextCSS}>{staticHeading ? staticHeading.subheading : pageHeadingData.headingSubText}</div>
      {pageHeadingData.infoText && (
        <InfoFormBlock customCSS={infoBlockCSS} content={pageHeadingData.infoText as string} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default DigitalProfileQuotePageHeading;
