import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { Vehicle, VehicleResponse } from '../../types/vehicle.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useCreateVehicle = (gid: string): UseBaseMutationResult<VehicleResponse, HTTPResponse, Vehicle> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (vehicle) =>
      await api
        .post<HTTPResponse<VehicleResponse>>(getApiUrl(API_PATH.vehicles, gid), {
          body: { data: vehicle },
        })
        .then((res) => res.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.vehiclesAndDriversData] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.vehicles, gid] });
    },
  });
};

export default useCreateVehicle;
