import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateLoan, useMutateLoan, useRecentLoan } from '../../../../../api/loan';
import { useMutatePrimaryInsured, usePrimaryInsured } from '../../../../../api/primary-insured';
import { useServicers } from '../../../../../api/servicer';
import SIRV_IMAGES from '../../../../../constants/sirv-images';
import InfoFormBlock from '../../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import noop, { noopAsync } from '../../../../../shared/utils/noop';
import CheckoutMortgageForm from '../CheckoutMortgageForm/CheckoutMortgageForm';
import CheckoutMortgagePreview from '../CheckoutMortgagePreview/CheckoutMortgagePreview';
import { formCSS, infoBlockCSS, previewCSS } from './CheckoutMortgageStep.style';
import { hasFullLoanAndPersonData, MORTGAGE_STEP_HINT } from './CheckoutMortgageStep.util';
import type { CheckoutMortgageContactFormState, CheckoutMortgageStepProps } from './CheckoutMortgageStep.type';
import type { QuestionVariant } from '../../../../../types/question.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const CheckoutMortgageStep: FC<CheckoutMortgageStepProps> = ({
  customCSS,
  isCurrent,
  isEdit,
  isBindDisabled = false,
  actionLabel = 'Move to Next Step',
  onSetEdit,
  onBeforeStepSubmit = noop,
  onStepSubmit = noopAsync,
}) => {
  const { gid } = useParams() as DefaultLocation;

  const servicersQuery = useServicers();
  const servicers = servicersQuery.data?.servicers;
  const loanQuery = useRecentLoan(gid);
  const loanData = loanQuery.data;
  const primaryInsuredQuery = usePrimaryInsured(gid);
  const primaryInsuredData = primaryInsuredQuery.data;
  const { mutateAsync: createLoan } = useCreateLoan(gid);
  const { mutateAsync: mutateLoan } = useMutateLoan(gid);
  const { mutateAsync: mutatePrimaryInsured } = useMutatePrimaryInsured(gid);

  useEffect(() => {
    if (
      !isEdit &&
      loanData &&
      primaryInsuredData &&
      !hasFullLoanAndPersonData(loanData?.loan, primaryInsuredData.person)
    ) {
      onSetEdit(true);
    }
  }, [isEdit, loanData, primaryInsuredData, onSetEdit]);

  const getServicersOptions = (): QuestionVariant[] => {
    if (!servicers) {
      return [];
    }

    return servicers.map((s) => ({
      value: s.gid,
      label: s.name,
    }));
  };

  const onFormSubmit = async (data: CheckoutMortgageContactFormState): Promise<void> => {
    onBeforeStepSubmit();
    const loanPayload = {
      loan_number: data.loan_number,
      servicer_gid: data.servicer_gid,
    };
    const primaryInsuredPayload = {
      email: data.email,
      phone: data.phone,
      mailing_address: data.address,
    };

    await Promise.all([
      loanData?.loan ? mutateLoan({ ...loanPayload, gid: loanData.loan.gid }) : createLoan(loanPayload),
      mutatePrimaryInsured(primaryInsuredPayload),
    ]);

    await onStepSubmit();
    onSetEdit(false);
  };

  return (
    <div css={customCSS}>
      <InfoFormBlock customCSS={infoBlockCSS} iconUrl={SIRV_IMAGES.infoIcon} content={MORTGAGE_STEP_HINT} />

      {isEdit && (
        <CheckoutMortgageForm
          customCSS={formCSS}
          loan={loanData?.loan}
          primaryInsured={primaryInsuredData?.person}
          servicersOptions={getServicersOptions()}
          actionLabel={actionLabel}
          onValidSubmit={onFormSubmit}
          isBindDisabled={isBindDisabled}
        />
      )}

      {!isEdit && loanData?.loan?.servicer && loanData.loan.loan_number && primaryInsuredData?.person && (
        <CheckoutMortgagePreview
          customCSS={previewCSS}
          landerName={loanData.loan.servicer.name}
          loanNumber={loanData.loan.loan_number}
          personEmail={primaryInsuredData.person.email}
          personNumber={primaryInsuredData.person.phone}
          personAddress={primaryInsuredData.person.mailing_address}
          actionLabel={actionLabel}
          showButton={isCurrent}
          onButtonClick={onStepSubmit}
        />
      )}
    </div>
  );
};

export default CheckoutMortgageStep;
