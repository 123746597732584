import CarIcon from '../../../../../assets/quote-hero-car.svg?react';
import HouseIcon from '../../../../../assets/quote-hero-house.svg?react';
import { AUTOMATION_TEST_ID } from '../../../../../constants/automation-test-id';
import Button from '../../../../../shared/components/Button/Button';
import noop from '../../../../../shared/utils/noop';
import {
  actionButtonCSS,
  carIconCSS,
  containerCSS,
  containerInnerCSS,
  contentCSS,
  discountLabelCSS,
  discountPremiumCSS,
  houseCSS,
  labelContainerCSS,
  premiumCSS,
  subheadingCSS,
  topLayerCSS,
} from './QuoteDetailsHero.style';
import type { QuoteDetailsHeroProps } from './QuoteDetailsHero.type';
import type { FC } from 'react';

const QuoteDetailsHero: FC<QuoteDetailsHeroProps> = ({
  customCSS,
  premium,
  discountPremium,
  homeOnly,
  actionButtonLabel = 'Schedule a call',
  onActionClick = noop,
}) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <div css={containerInnerCSS(homeOnly)}>
        <div css={contentCSS(!!discountPremium)}>
          <div css={houseCSS(!!discountPremium)}>
            <HouseIcon />
          </div>
          <div css={carIconCSS(!!discountPremium, homeOnly)} data-testid="car-icon">
            <CarIcon />
          </div>
          <div css={topLayerCSS}>
            {!!discountPremium && <div css={discountPremiumCSS}>${Math.round(premium)}/mo</div>}
            <div data-testid={AUTOMATION_TEST_ID.bundleQuotePage.premium} css={premiumCSS(!!discountPremium)}>
              ${discountPremium ? Math.round(discountPremium) : Math.round(premium)}
              <span>/mo</span>
            </div>
            <div css={subheadingCSS(!!discountPremium)}>{homeOnly ? 'Est. Home Premium' : 'Est. Monthly Premium'}</div>
            {!!discountPremium && (
              <div css={labelContainerCSS}>
                <div css={discountLabelCSS}>Bundle Discount</div>
              </div>
            )}
            <Button customCSS={actionButtonCSS} onClick={onActionClick}>
              {actionButtonLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteDetailsHero;
