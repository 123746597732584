import { useEffect, type FC, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateAllFunnelFlow } from '../../../../../api/all-funnel-flow';
import { useSession } from '../../../../../api/session';
import { SEGMENT } from '../../../../../constants/analytics';
import SIRV_IMAGES from '../../../../../constants/sirv-images';
import useResponsive from '../../../../../hooks/use-responsive';
import { useSegment } from '../../../../../hooks/use-segment';
import SirvImage from '../../../../../shared/components/SirvImage/SirvImage';
import { buttonCSS, containerCSS, contentCSS, descriptionCSS, titleCSS } from './ViewAFQuotes.style';
import type { ViewAFQuotesProps } from './ViewAFQuotes.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';

const ViewAFQuotes: FC<ViewAFQuotesProps> = ({ customCSS }) => {
  const { isDesktop } = useResponsive();
  const { track } = useSegment();
  const { gid } = useParams() as DefaultLocation;
  const useCreateAllFunnelFlowQuery = useCreateAllFunnelFlow(gid);
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const allFunnelUrl = useCreateAllFunnelFlowQuery.data?.url;
  const isOffered = useRef(false);

  const onViewMoreQuotesClick = (): void => {
    if (currentPageKey) {
      track(SEGMENT.events.allFunnelQuotesSelected, {
        page: currentPageKey,
      });
    }
  };

  useEffect(() => {
    if (allFunnelUrl && currentPageKey && !isOffered.current) {
      isOffered.current = true;
      track(SEGMENT.events.allFunnelQuotesOffered, {
        page: currentPageKey,
      });
    }
  }, [track, currentPageKey, allFunnelUrl]);

  if (!useCreateAllFunnelFlowQuery.data?.url) {
    return null;
  }

  return (
    <div css={[containerCSS, customCSS]}>
      <SirvImage url={SIRV_IMAGES.carriersList} width={isDesktop ? 316 : 250} alt="Carriers list" />
      <div css={contentCSS}>
        <div css={titleCSS}>Looking for other options? See more quotes we found.</div>
        <div css={descriptionCSS}>
          Matic works with over 40+ A-rated carriers. Instantly view more quotes available to you.
        </div>
        <a css={buttonCSS} href={useCreateAllFunnelFlowQuery.data.url} onClick={onViewMoreQuotesClick}>
          View More Quotes
        </a>
      </div>
    </div>
  );
};

export default ViewAFQuotes;
