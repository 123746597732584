import { Fragment } from 'react';
import CheckIcon from '../../assets//check-mark.svg?react';
import ShieldFilledIcon from '../../assets/shield-check-filled.svg?react';
import ShieldIcon from '../../assets/shield-check.svg?react';
import Tooltip from '../../shared/components/Tooltip/Tooltip';
import {
  checkIconCSS,
  messageCSS,
  shieldCheckIconCSS,
  shieldTooltipCheckIconCSS,
  spamFreeBlockCSS,
  spamFreeTextCSS,
  tooltipButtonCSS,
  tooltipContentCSS,
  tooltipMessagesWrapperCSS,
  tooltipSpamFreeBlockCSS,
  tooltipSpamFreeTextCSS,
} from './SpamAndFreeSecure.style';
import { FREE_AND_SECURE_MESSAGES } from './SpamAndFreeSecure.util';
import type { SpamAndFreeSecureProps } from './SpamAndFreeSecure.type';
import type { FC, ReactElement, ReactNode } from 'react';

const SpamAndFreeSecure: FC<SpamAndFreeSecureProps> = ({ customCSS }) => {
  const renderMessage = (message: string): ReactElement => (
    <div css={messageCSS} key={message}>
      <CheckIcon css={checkIconCSS} />
      <span>{message}</span>
    </div>
  );

  const spamAndFreeDescription = (): ReactNode => (
    <Fragment>
      <div css={tooltipSpamFreeBlockCSS}>
        <ShieldIcon css={shieldTooltipCheckIconCSS} />
        <span css={tooltipSpamFreeTextCSS}>Spam Free & Secure!</span>
      </div>
      <div css={tooltipContentCSS}>
        You will <strong>only be receiving</strong> a call from a Matic advisor. Your data is safe with us and will not
        be shared with third parties.
      </div>
      <div css={tooltipMessagesWrapperCSS}>{FREE_AND_SECURE_MESSAGES.map((el) => renderMessage(el))}</div>
    </Fragment>
  );

  return (
    <div css={customCSS?.container}>
      <Tooltip
        customCSS={{
          buttonCSS: tooltipButtonCSS,
          tooltipCSS: {
            maxWidth: '460px',
          },
        }}
        content={spamAndFreeDescription()}
      >
        <div css={[spamFreeBlockCSS, customCSS?.text]}>
          <ShieldFilledIcon css={shieldCheckIconCSS} />
          <span css={spamFreeTextCSS}>Spam Free & Secure</span>
        </div>
      </Tooltip>
    </div>
  );
};

export default SpamAndFreeSecure;
