import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';

const COMPONENTS_MARGIN = 48;

export const componentCSS = css`
  margin-bottom: ${COMPONENTS_MARGIN}px;
`;

export const disclosuresComponentCSS = css`
  margin-top: -${COMPONENTS_MARGIN - 24}px;
  margin-bottom: 40px;
  ${mq[0]} {
    margin-top: -${COMPONENTS_MARGIN - 28}px;
    margin-bottom: 36px;
  }
`;

export const buttonCSS = css`
  ${mq[0]} {
    margin-top: 4px;
  }
`;
