import { FormElementTag } from '../../../../../types/form-element.type';
import { QuestionType } from '../../../../../types/question.type';
import { getDeductibleComponent, getEffectiveDateComponent } from '../../../../../utils/schema.util';
import type { HomeQuote } from '../../../../../types/quote.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { QuoteDetailsQuestion } from '../QuoteDetailsEditButton/QuoteDetailsEditButton.type';

export const getQuestions = (quote: HomeQuote, sections?: CustomSectionSchema[]): QuoteDetailsQuestion[] => {
  const res: QuoteDetailsQuestion[] = [];
  const effectiveDateComponent = getEffectiveDateComponent(sections);
  const deductibleComponent = getDeductibleComponent(sections);

  if (effectiveDateComponent) {
    res.push({
      questionKey: effectiveDateComponent.key,
      type: QuestionType.Date,
      label: effectiveDateComponent.label,
      validations: effectiveDateComponent.validations,
      dense: true,
      tags: [FormElementTag.DatePicker, FormElementTag.RenderDropDownInBody],
      answer: quote.policy_effective_date,
    });
  }

  if (deductibleComponent) {
    res.push({
      questionKey: deductibleComponent.key,
      type: QuestionType.SingleSelect,
      label: deductibleComponent.label,
      variants: deductibleComponent.variants.map((variant) => ({
        label: variant.label,
        value: String(variant.value),
      })),
      validations: deductibleComponent.validations,
      dense: true,
      tags: [FormElementTag.RenderDropDownInBody],
      answer: String(quote.deductible),
    });
  }

  return res;
};
