import { useState, type FC } from 'react';
import ShieldIcon from '../../../../../assets/shield-check.svg?react';
import useResponsive from '../../../../../hooks/use-responsive';
import Wrapper from '../../../../../shared/components/Wrapper/Wrapper';
import theme from '../../../../../styles/theme';
import QuoteCoverage from '../../QuoteCoverage/QuoteCoverage';
import {
  accordionArrowCSS,
  contentCSS,
  coverageCSS,
  headerCSS,
  iconBackgroundCSS,
  iconCSS,
} from './HomeQuoteCoverageGroup.style';
import type { HomeQuoteCoverageGroupProps } from './HomeQuoteCoverageGroup.type';

const HomeQuoteCoverageGroup: FC<HomeQuoteCoverageGroupProps> = ({ customCSS, group }) => {
  const { assetGid, title, coverages } = group;
  const { isMobile } = useResponsive();
  const [isExpanded, setIsExpanded] = useState(true);

  const onToggle = (): void => {
    setIsExpanded((s) => !s);
  };

  const accordionProps = {
    'aria-controls': assetGid,
    'aria-expanded': isExpanded,
    onClick: onToggle,
  };

  const displayContent = isMobile ? isExpanded : true;

  return (
    <div css={customCSS}>
      <Wrapper css={headerCSS} tag={'button'} {...accordionProps}>
        {title}
        <span css={iconBackgroundCSS}>
          <ShieldIcon color={theme.colors.azure62} css={iconCSS} />
        </span>
        {isMobile && <div css={accordionArrowCSS(isExpanded)} />}
      </Wrapper>
      {displayContent && (
        <div id={assetGid} css={contentCSS}>
          {coverages.map((c) => (
            <QuoteCoverage
              key={c.title}
              customCSS={coverageCSS}
              title={c.title}
              value={c.value}
              description={c.description}
              iconUrl={c.iconUrl}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default HomeQuoteCoverageGroup;
