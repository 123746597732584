import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (hasTopRibbon = false, noQuotesVariation = false): SerializedStyles => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${hasTopRibbon ? 76 : 40}px;
  margin-bottom: 60px;

  ${mq[0]} {
    padding-top: ${hasTopRibbon ? 116 : 45}px;

    ${noQuotesVariation &&
    css`
      padding-top: ${hasTopRibbon ? 45 : 0}px;
    `}
  }
`;

export const hideFooterCSS = css`
  footer {
    display: none;
  }
`;

export const removeMainPaddingCSS = css`
  main {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
`;
