import Question from '../../../../../questionsForm/components/Question/Question';
import { FormElementTag } from '../../../../../types/form-element.type';
import {
  PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA,
  PERSONAL_INFO_EMAIL_QUESTION_SCHEMA,
  PERSONAL_INFO_PHONE_QUESTION_SCHEMA,
} from './CheckoutContactInfoQuestions.util';
import type { FC } from 'react';

const CheckoutContactInfoQuestions: FC = () => {
  return (
    <>
      <Question
        {...PERSONAL_INFO_EMAIL_QUESTION_SCHEMA}
        key={PERSONAL_INFO_EMAIL_QUESTION_SCHEMA.questionKey}
        tags={[FormElementTag.TrackForbidden]}
      />
      <Question
        {...PERSONAL_INFO_PHONE_QUESTION_SCHEMA}
        key={PERSONAL_INFO_PHONE_QUESTION_SCHEMA.questionKey}
        tags={[FormElementTag.TrackForbidden]}
      />
      <Question
        {...PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA}
        key={PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA.questionKey}
        tags={[FormElementTag.TrackForbidden]}
      />
    </>
  );
};

export default CheckoutContactInfoQuestions;
