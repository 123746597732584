import { css } from '@emotion/react';
import { linkCSS } from '../../../../shared/components/Anchor/Anchor.style';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text14CSS } from '../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

const IMG_SIZE_MOBILE = 100;
const CONTAINER_MARGIN = 40;

export const containerCSS = (showLine: boolean): SerializedStyles => css`
  margin-bottom: ${CONTAINER_MARGIN}px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  &:before {
    display: ${showLine ? 'block' : 'none'};
    content: '';
    position: absolute;
    top: 0;
    left: 15.5px;
    border-left: 1px solid ${theme.colors.cherise61};
    height: calc(100% + ${CONTAINER_MARGIN}px);
  }
  ${mq[0]} {
    display: flex;
    max-width: 80%;
  }
  ${mq[1]} {
    max-width: 638px;
  }
`;

export const imageCSS = css`
  margin-bottom: 24px;
  margin-left: 48px;
  img {
    width: auto;
    height: ${IMG_SIZE_MOBILE}px;
  }
  ${mq[0]} {
    flex-shrink: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin-right: 36px;
    img {
      width: 136px;
      height: auto;
    }
  }
`;

export const contentCSS = css`
  padding-left: 48px;
  ${mq[0]} {
    position: relative;
    padding-left: 52px;
  }
`;

export const stepNumberCSS = css`
  position: absolute;
  left: 0;
  top: 0;
  border: 3px solid ${theme.colors.cherise61};
  background-color: ${theme.colors.white};
  border-radius: 50%;
  width: 26px;
  line-height: 26px;
  text-align: center;
  ${mq[0]} {
    border-width: 4px;
    line-height: 32px;
    width: 32px;
    font-size: 20px;
  }
`;

export const headingCSS = css`
  font-weight: 700;
  margin-bottom: 12px;
`;

export const descriptionCSS = css`
  font-weight: 500;
  a {
    ${linkCSS('default')}
  }
`;

export const contactsCSS = css`
  display: flex;
  flex-wrap: wrap;
`;

export const contactItemCSS = css`
  margin-top: 16px;
  margin-right: 35px;
`;

export const contactHeadingCSS = css`
  ${text14CSS};
  line-height: 20px;
  color: ${theme.colors.gray60};
  margin-bottom: 6px;
`;

export const externalUrlPrefixCSS = css`
  margin-top: 8px;
`;

export const externalUrlCSS = css`
  line-height: 24px;
  font-weight: 700;
  margin-top: 8px;
  display: inline-block;

  ${mq[0]} {
    margin-top: 4px;
  }
`;
