import {
  GENDER_VARIANTS,
  RELATIONSHIP_KIND_SPOUSE,
  RELATIONSHIP_VARIANTS,
} from '../../../constants/schema-hardcoded-keys';
import { FormElementTag } from '../../../types/form-element.type';
import { QuestionType } from '../../../types/question.type';
import { PERSON_AGE_VALIDATION, REQUIRED_VALIDATION } from '../../utils/question-validations/question-validations.util';
import type { QuestionProps } from '../Question/Question.type';

export const getCoInsuredQuestions = (isMarried = true): QuestionProps[] => [
  {
    questionKey: 'first_name',
    type: QuestionType.Text,
    label: 'First Name of Co-insured?',
    placeholder: 'first name',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 6,
  },
  {
    questionKey: 'last_name',
    type: QuestionType.Text,
    label: 'Last Name of Co-insured?',
    placeholder: 'last name',
    validations: [REQUIRED_VALIDATION],
    gridWidth: 6,
    tags: [FormElementTag.TrackForbidden],
  },
  {
    questionKey: 'gender',
    type: QuestionType.SingleSelect,
    label: 'Gender',
    placeholder: '- choose option -',
    variants: GENDER_VARIANTS,
    validations: [REQUIRED_VALIDATION],
    gridWidth: 12,
  },
  {
    questionKey: 'date_of_birth',
    type: QuestionType.Date,
    label: 'Date of birth',
    placeholder: 'MM/DD/YYYY',
    validations: [REQUIRED_VALIDATION, ...PERSON_AGE_VALIDATION],
    gridWidth: 6,
    tags: [FormElementTag.TrackForbidden],
  },
  {
    questionKey: 'kind',
    type: QuestionType.SingleSelect,
    label: 'Relationship to Co-insured?',
    placeholder: '- choose option -',
    variants: isMarried
      ? RELATIONSHIP_VARIANTS
      : RELATIONSHIP_VARIANTS.filter((variant) => variant.value !== RELATIONSHIP_KIND_SPOUSE),
    validations: [REQUIRED_VALIDATION],
    gridWidth: 6,
  },
];
