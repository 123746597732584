import { Fragment, useEffect, useState } from 'react';
import { SEGMENT } from '../../../../../constants/analytics';
import useResponsive from '../../../../../hooks/use-responsive';
import { useSegment } from '../../../../../hooks/use-segment';
import InfoFormBlock from '../../../../../questionsForm/components/InfoFormBlock/InfoFormBlock';
import Button from '../../../../../shared/components/Button/Button';
import { Card } from '../../../../../shared/components/Card/Card';
import Logo from '../../../../../shared/components/Logo/Logo';
import Tooltip from '../../../../../shared/components/Tooltip/Tooltip';
import { getCents, removeCents } from '../../../../../shared/utils/money.util';
import formatUtil from '../../../../../utils/format.util';
import DigitalProfileQuoteCoverages from '../DigitalProfileQuoteCoverages/DigitalProfileQuoteCoverages';
import QuoteCardWrapper from '../QuoteCardWrapper/QuoteCardWrapper';
import {
  scheduleCallCSS,
  bundleButtonCSS,
  policyInfoCSS,
  deductibleCSS,
  bundleCardCSS,
  premiumCSS,
  pricesInformationCSS,
  bundleToggleWrapperCSS,
  priceDescriptionCSS,
  bundleOffCSS,
  bundleOnCSS,
  policyInfoWrapperCSS,
  carrierLogoCSS,
  yearCSS,
  badgesCSS,
  savingsBadgeCSS,
  badgeCSS,
  bundleBadgeCSS,
  infoMessageCSS,
  tooltipButtonCSS,
  coveragesCSS,
} from './DigitalProfileQuoteDetails.style';
import type { DigitalProfileQuoteDetailsProps } from './DigitalProfileQuoteDetails.type';
import type { FC, ReactElement, ReactNode } from 'react';

const DigitalProfileQuoteDetails: FC<DigitalProfileQuoteDetailsProps> = ({
  coverages,
  premium,
  savings,
  deductible,
  multiPolicyDiscountPremium,
  carrierLogo,
  carrierName,
  quoteIndex,
  bundleAnswer = null,
  actionButtonLabel = 'Schedule a Call',
  onScheduleCall,
}) => {
  const [isBundleOn, setIsBundleOn] = useState(false);

  const { isMobile } = useResponsive();

  const { track } = useSegment();

  const premiumValue =
    multiPolicyDiscountPremium && isBundleOn ? parseFloat(multiPolicyDiscountPremium) : parseFloat(premium);

  const premiumDollars = formatUtil.money(String(removeCents(premiumValue)), 0, '$');
  const deductibleDollars = formatUtil.money(String(deductible), 0, '$');

  const bundleApplied = !!bundleAnswer && !!multiPolicyDiscountPremium;

  useEffect(() => {
    if (bundleAnswer !== false && multiPolicyDiscountPremium) {
      setIsBundleOn(true);
    }
  }, [multiPolicyDiscountPremium, bundleAnswer]);

  const getSavingsValue = (savings: number, premiumValue: number): string => {
    const value = isBundleOn ? parseInt(removeCents(premium)) - parseInt(removeCents(premiumValue)) + savings : savings;
    return formatUtil.money(value);
  };

  const onToggleBundle = (state: boolean): void => {
    setIsBundleOn(state);
    track(SEGMENT.events.bundleButtonClicked, {
      value: state ? 'on' : 'off',
    });
  };

  const renderBundleToggle = (multiPolicyDiscountPremium: string): ReactElement => {
    const premiumWithBundle = `${formatUtil.money(
      String(removeCents(parseFloat(premium) - parseFloat(multiPolicyDiscountPremium))),
      0,
      '$'
    )}.${getCents(parseFloat(premium) - parseFloat(multiPolicyDiscountPremium))}`;

    return (
      <div css={bundleToggleWrapperCSS}>
        <Card customCSS={bundleCardCSS}>
          <button css={[bundleButtonCSS(!isBundleOn), bundleOffCSS]} onClick={() => onToggleBundle(false)}>
            Bundle off
          </button>
          <button css={[bundleButtonCSS(isBundleOn), bundleOnCSS]} onClick={() => onToggleBundle(true)}>
            {!isMobile ? <>Bundle on - Save {premiumWithBundle}</> : 'Bundle on'}
          </button>
        </Card>
      </div>
    );
  };

  const renderPolicyDetails = (): ReactNode => (
    <Fragment>
      <div css={badgesCSS}>
        {!!savings && (
          <div css={[badgeCSS, savingsBadgeCSS]}>Total savings: ${getSavingsValue(savings, premiumValue)}</div>
        )}
        {bundleApplied && <div css={[badgeCSS, bundleBadgeCSS]}>Bundle discount</div>}
      </div>
      <div css={policyInfoWrapperCSS}>
        {!!multiPolicyDiscountPremium && !bundleApplied && renderBundleToggle(multiPolicyDiscountPremium)}
        <div css={policyInfoCSS}>
          <Logo carrierLogo={carrierLogo} carrierName={carrierName} customCSS={carrierLogoCSS} height={80} />
          <div css={pricesInformationCSS}>
            <div>
              <div css={deductibleCSS} data-testid={`${quoteIndex}-deductible-value`}>
                {deductibleDollars}
              </div>
              <p css={priceDescriptionCSS}>Deductible</p>
            </div>
            <div>
              <div css={premiumCSS} data-testid={`${quoteIndex}-premium-value`}>
                {premiumDollars}/<span css={yearCSS}>yr</span>
              </div>
              <p css={priceDescriptionCSS}>Annual Premium {isBundleOn && '(Bundle)'}</p>
            </div>
          </div>
          <Button css={scheduleCallCSS} onClick={onScheduleCall}>
            {actionButtonLabel}
          </Button>
        </div>
        {!multiPolicyDiscountPremium && !!bundleAnswer && (
          <InfoFormBlock
            customCSS={infoMessageCSS}
            content={
              <>
                Buying home and auto together can save you up to 20%.{' '}
                <Tooltip
                  customCSS={{ buttonCSS: tooltipButtonCSS }}
                  position="top"
                  content={'Our agents will be happy to assist you in showing you home and auto quotes'}
                >
                  Want to see your auto quote?
                </Tooltip>
              </>
            }
          />
        )}
      </div>
    </Fragment>
  );

  return (
    <QuoteCardWrapper toggleContent={<DigitalProfileQuoteCoverages customCSS={coveragesCSS} coverages={coverages} />}>
      {renderPolicyDetails()}
    </QuoteCardWrapper>
  );
};

export default DigitalProfileQuoteDetails;
