import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import { text14CSS, text20CSS, text32CSS, textCSS } from '../../../../styles/typography.style';

export const titleCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 16px;

  ${mq[0]} {
    ${text32CSS};
    line-height: 40px;
    max-width: 510px;
  }
`;

export const subtitleCSS = css`
  ${text14CSS};
  line-height: 20px;
  margin-bottom: 32px;

  ${mq[0]} {
    ${textCSS};
    max-width: 580px;
  }
`;

export const cardCSS = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  text-align: left;
  width: 306px;
  margin-bottom: 32px;

  ${mq[0]} {
    width: 350px;
  }
`;

export const buttonSelectCSS = css`
  justify-content: center;
`;

export const phoneUrlInOptionCSS = css`
  display: block;
  width: 100%;

  &:before {
    /* Increase anchor square to whole option */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
