import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { CoInsuredResponse } from '../../types/co-insured.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useRemoveCoInsured = (gid: string): UseBaseMutationResult<CoInsuredResponse, HTTPResponse, { gid: string }> => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: async (data) =>
      await api
        .delete<HTTPResponse<CoInsuredResponse>>(getApiUrl(API_PATH.coInsured, gid, data.gid))
        .then((res) => res.data),
    onSuccess: async () => {
      await client.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.coInsureds] });
    },
  });
};

export default useRemoveCoInsured;
