import { Controller } from 'react-hook-form';
import CounterInput from '../../../../../shared/components/CounterInput/CounterInput';
import { CounterInputVariant } from '../../../../../shared/components/CounterInput/CounterInput.type';
import noop from '../../../../../shared/utils/noop';
import { isValueSpecificType } from '../../../../utils/answers/answers.util';
import { getValidationRules } from '../../../../utils/question-validations/question-validations.util';
import type { CounterFormControlProps } from './CounterFormControl.type';
import type { FC } from 'react';

// TODO: Rename other Inputs that we use as a FormControls
const CounterFormControl: FC<CounterFormControlProps> = ({
  name,
  validations,
  isDisabled,
  hasError,
  variant = CounterInputVariant.Default,
  iconUrl,
  label,
  onValidatePreviousInputs = noop,
}) => (
  <Controller
    name={name}
    rules={getValidationRules(validations)}
    render={({ field: { value, ref, onBlur, onChange }, fieldState: { isTouched } }) => {
      const formValue = isValueSpecificType<string | number>(value, ['string', 'number']) ? value : '';
      return (
        <CounterInput
          inputId={name}
          value={formValue}
          hasError={hasError}
          isPrefilled={Boolean(value && !isTouched)}
          disabled={isDisabled}
          componentRef={ref}
          onBlur={onBlur}
          onChange={(e) => {
            onChange(e);
            onValidatePreviousInputs();
          }}
          label={label}
          variant={variant}
          iconUrl={iconUrl}
        />
      );
    }}
  />
);

export default CounterFormControl;
