import PageContainer from '../../components/PageContainer/PageContainer';
import CongratulationsPage from './components/CongratulationsPage/CongratulationsPage';
import type { FC } from 'react';

const CongratulationsLayout: FC = () => (
  <PageContainer layoutVariant="default" allowFullStoryTracking allowTabTitleUpdate>
    <CongratulationsPage />
  </PageContainer>
);

export default CongratulationsLayout;
