import LoadingDots from '../LoadingDots/LoadingDots';
import { loaderContainerCSS } from './FullPageLoader.style';
import type { FC } from 'react';

const FullPageLoader: FC = () => (
  <div css={loaderContainerCSS}>
    <LoadingDots />
  </div>
);

export default FullPageLoader;
