const SIRV_IMAGES = {
  maticLogo: 'https://maticinsurance-cdn.sirv.com/matic-logo/matic-header-logo-for-consumers.png',
  twitter: 'https://maticinsurance-cdn.sirv.com/icons/svg/twitter.svg',
  linkedin: 'https://maticinsurance-cdn.sirv.com/icons/svg/linkedin.svg',
  facebook: 'https://maticinsurance-cdn.sirv.com/icons/svg/facebook.svg',
  instagram: 'https://maticinsurance-cdn.sirv.com/icons/svg/instagram.svg',
  phone: 'https://maticinsurance-cdn.sirv.com/icons/svg/phone.svg',
  documentLoader: 'https://maticinsurance.sirv.com/olb/ui/document-loader.gif',
  carLoader: 'https://maticinsurance.sirv.com/olb/ui/car-loader.gif',
  photoPlaceholder: 'https://maticinsurance.sirv.com/olb/element_pictures/placeholder.jpg',
  homeLoader: 'https://maticinsurance.sirv.com/olb/ui/home-loader.gif',
  infoIcon: 'https://maticinsurance.sirv.com/olb/element_icons/info.svg',
  otherOption: 'https://maticinsurance.sirv.com/olb/element_icons/other.svg',
  blurredBackgroundDesktop: 'https://maticinsurance.sirv.com/olb/ui/recovery-page-bg-desktop.jpg',
  blurredBackgroundMobile: 'https://maticinsurance.sirv.com/olb/ui/recovery-page-bg-mobile.jpg',
  confetti: 'https://maticinsurance.sirv.com/olb/ui/confetti.gif',
  blogPost4: 'https://maticinsurance.sirv.com/olb/blog_posts/blog-post-4.png',
  blogPost5: 'https://maticinsurance.sirv.com/olb/blog_posts/blog-post-5.png',
  blogPost6: 'https://maticinsurance.sirv.com/olb/blog_posts/blog-post-6.png',
  carriersList: 'https://maticinsurance.sirv.com/olb/ui/carriers-list.jpg',
  ladyAgent: 'https://maticinsurance.sirv.com/olb/ui/lady-agent.png',
  maleAgent: 'https://maticinsurance.sirv.com/olb/ui/male-agent.png',
  checkoutLoader: 'https://maticinsurance.sirv.com/olb/ui/payment-loading.gif',
  maticTeam: 'https://maticinsurance.sirv.com/olb/ui/matic-team.png',
  documentWithPlantMagnifier: 'https://maticinsurance.sirv.com/olb/ui/document-with-plant-magnifier.png',
  carriersCarouselSlider: 'https://maticinsurance.sirv.com/olb/ui/carriers-carousel-slider.gif',
  carriersSACarouselSlider: 'https://maticinsurance.sirv.com/olb/ui/carriers-carousel-sa.gif',
};

export default SIRV_IMAGES;
