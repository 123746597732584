import { GoogleReCaptchaProvider as GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { usePublicConfig } from '../../api/publicConfig';
import type { GoogleReCaptchaWrapperProps } from './GoogleReCaptchaWrapper.type';
import type { FC } from 'react';

const GoogleReCaptchaWrapper: FC<GoogleReCaptchaWrapperProps> = ({ children }) => {
  const configQuery = usePublicConfig(false);
  const recaptchaSiteKey = configQuery.data?.config.integrations.recaptcha?.site_key_v3;

  return (
    <>
      {recaptchaSiteKey ? (
        <GoogleReCaptcha
          reCaptchaKey={recaptchaSiteKey}
          container={{
            parameters: {
              badge: 'bottomright',
            },
          }}
        >
          {children}
        </GoogleReCaptcha>
      ) : (
        children
      )}
    </>
  );
};

export default GoogleReCaptchaWrapper;
