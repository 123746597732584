import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text24CSS, text40CSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  ${mq[1]} {
    padding: 40px 56px;
  }
`;

export const containerInnerCSS = (containerIndex: number): SerializedStyles => css`
  padding: 24px 16px;
  ${containerIndex === 1 &&
  css`
    padding-top: 20px;
  `}
  ${containerIndex === 2 &&
  css`
    border-top: 1px solid ${theme.colors.gray30};
    border-bottom: 1px solid ${theme.colors.gray30};
  `}
  ${containerIndex === 3 &&
  css`
    padding-bottom: 40px;
  `}
`;

export const basicDetailsHeaderCSS = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const baseDetailItemCSS = css`
  margin-top: 8px;

  & span:first-of-type {
    color: ${theme.colors.black};
  }

  & span:last-of-type {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 700;
  }
`;

export const logoCSS = css`
  justify-content: center;
  margin-bottom: 24px;

  ${mq[1]} {
    margin-bottom: 0;
  }
`;

export const savingsAndDiscountsBlockCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  text-align: center;
  font-size: 14px;

  ${mq[1]} {
    justify-content: flex-start;
    margin-bottom: 0;
    font-size: 16px;
  }
`;

export const dotCSS = css`
  display: block;
  width: 4px;
  height: 4px;
  background-color: ${theme.colors.positiveGreen27};
  margin: 8px;
  border-radius: 50%;
  flex-shrink: 0;
`;

export const savingsBadgeCSS = css`
  color: ${theme.colors.positiveGreen27};
  font-weight: 700;
`;

export const priceCSS = css`
  color: ${theme.colors.positiveGreen27};
  ${text40CSS};
  font-weight: 700;

  ${mq[1]} {
    font-size: 56px;
    line-height: 60px;
    font-weight: 900;
    margin-bottom: 8px;

    span {
      ${text24CSS};
      line-height: 32px;
    }
  }
`;

export const priceWrapperCSS = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;

  ${mq[1]} {
    flex-direction: row;
  }
`;

export const actionButtonCSS = css`
  ${mq[1]} {
    min-width: 230px;
  }
`;

export const annualCSS = css`
  ${mq[1]} {
    font-size: 20px;
    line-height: 1.4;
    font-weight: 500;
    white-space: nowrap;
  }
`;

export const topLineCSS = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const logoWrapperCSS = css`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const bottomLineCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`;

export const editableDetailsContainerCSS = css`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  border: 2px solid ${theme.colors.gray10};
  border-radius: 8px;
  padding: 24px;
`;

export const editableDetailCSS = css`
  color: ${theme.colors.gray60};
  font-size: 14px;

  strong {
    color: ${theme.colors.black};
    font-weight: 700;
    font-size: 20px;
    line-height: 1.4;
    margin-left: 12px;
  }
`;
