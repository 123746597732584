import { css } from '@emotion/react';
import theme from '../../../styles/theme';

export const testModeIndicatorCSS = css`
  width: 100%;
  text-align: center;
  background: linear-gradient(123.38deg, ${theme.colors.cherise61} 11.91%, ${theme.colors.peachPink} 100.01%);
  font-size: 12px;
  line-height: 16px;
  box-sizing: border-box;
  padding: 3px 0;
  position: fixed;
  color: white;
  font-weight: 700;
  z-index: 99999;
  top: 0;
`;
