import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import { text20CSS, text24CSS, text48CSS } from '../../../../../styles/typography.style';

export const cardCSS = css`
  padding: 16px 24px 24px;
  ${mq[0]} {
    padding: 16px 30px 30px;
  }
`;

export const buttonContainerCSS = css`
  text-align: center;
`;

export const priceCSS = css`
  ${text48CSS};
  font-weight: 900;
  ${mq[0]} {
    font-size: 64px;
    line-height: 90px;
    margin-bottom: -10px;
  }
`;

export const quoteDurationCSS = css`
  font-weight: 700;
  margin-bottom: 8px;
  ${mq[0]} {
    ${text24CSS};
    margin-bottom: 16px;
  }
`;

export const buttonCSS = css`
  margin-bottom: 16px;
  min-width: 178px;
  ${mq[0]} {
    margin-bottom: 19px;
    min-width: 210px;
  }
  ${mq[1]} {
    min-width: 268px;
  }
`;

export const effectiveDateCSS = css`
  ${mq[0]} {
    ${text20CSS};
  }
`;

export const coveragesCSS = css`
  margin-top: 40px;
`;
