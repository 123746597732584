import { DevTool } from '@hookform/devtools';
import { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Question from '../../../../../questionsForm/components/Question/Question';
import { scrollToError } from '../../../../../questionsForm/utils/questions-form.util';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import noop from '../../../../../shared/utils/noop';
import { buttonCSS, formCSS } from './CoInsuredForm.style';
import { CO_INSURED_QUESTIONS } from './CoInsuredForm.util';
import type { CoInsuredFormProps } from './CoInsuredForm.type';
import type { CoInsured } from '../../../../../types/co-insured.type';
import type { FC } from 'react';

const CoInsuredForm: FC<CoInsuredFormProps> = ({ customCSS, coInsuredData, onSubmit = noop }) => {
  const methods = useForm<Omit<CoInsured, 'gid'>>({ defaultValues: coInsuredData });
  const initData = useRef<Omit<CoInsured, 'gid'> | Record<string, never>>();

  const isDataEqual = (data1: Record<string, string>, data2: Record<string, string>): boolean => {
    return Object.keys(data1).every((key) => data1[key] === data2[key]);
  };

  useEffect(() => {
    if (coInsuredData && (!initData.current || !isDataEqual(coInsuredData, initData.current))) {
      methods.reset(coInsuredData);
      initData.current = coInsuredData;
    }
  }, [methods, coInsuredData]);

  return (
    <div css={customCSS}>
      <FormProvider {...methods}>
        <form id="co-insured-form" css={formCSS} onSubmit={methods.handleSubmit(onSubmit, scrollToError)}>
          {CO_INSURED_QUESTIONS.map((q) => (
            <Question key={q.questionKey} {...q} />
          ))}
        </form>
        <Button customCSS={buttonCSS} type="submit" form="co-insured-form" fullWidth size={ButtonSize.Large}>
          Move to Next Step
        </Button>
      </FormProvider>
      {import.meta.env.DEV && <DevTool control={methods.control} />}
    </div>
  );
};

export default CoInsuredForm;
