import SkipPageButton from '../../../components/SkipPageButton/SkipPageButton';
import useResponsive from '../../../hooks/use-responsive';
import { containerCSS, descriptionCSS, headingCSS, iconCSS, skipButtonCSS } from './QuestionPageHeading.style';
import type { QuestionPageHeadingProps } from './QuestionPageHeading.types';
import type { FC } from 'react';

const QuestionPageHeading: FC<QuestionPageHeadingProps> = ({
  customCSS,
  heading,
  description,
  icon,
  showSkipButton,
}) => {
  const { isDesktop } = useResponsive();
  const skipButton = showSkipButton && <SkipPageButton customCSS={skipButtonCSS} />;

  return (
    <div css={[containerCSS(!!icon), customCSS]}>
      {!!icon && <img css={iconCSS} src={icon} alt="" />}
      <div>
        <div css={headingCSS}>
          {heading} {isDesktop && skipButton}
        </div>
        <div css={descriptionCSS}>
          {description}
          {!isDesktop && skipButton}
        </div>
      </div>
    </div>
  );
};

export default QuestionPageHeading;
