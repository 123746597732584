import { useQuery } from '@tanstack/react-query';
import QUERY_CACHE_KEYS from '../../../constants/query-cache-keys';
import useDigitalProfileUtmParams from '../../hooks/use-digital-profile-utm-params';
import publicSessionService from './public-session.service';
import type { PublicSessionResponse } from '../../../types/public-session.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const usePublicSession = (gid?: string): QueryObserverResult<PublicSessionResponse> => {
  const queryParams = useDigitalProfileUtmParams();

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.publicSession, gid],
    queryFn: gid
      ? async () => await publicSessionService.getPublicSession(gid, queryParams as Record<string, string>)
      : undefined,
    enabled: Boolean(gid),
  });
};

export default usePublicSession;
