export enum FeatureVisibilityOption {
  Hidden = 'hidden',
  Visible = 'visible',
}

export interface FeatureCondition {
  feature_flag: string;
  variation: string;
  visibility: FeatureVisibilityOption;
}
