import { useSearchParams } from 'react-router-dom';
import { InitSessionSearchParams } from '../../components/SessionInit/SessionInit.type';
import { compactObject } from '../../shared/utils/object-helpers.util';
import type { IObject } from '../../shared/utils/object-helpers.util';

const useDigitalProfileUtmParams = (): IObject => {
  const [searchParams] = useSearchParams();

  const dpSource = searchParams.get(InitSessionSearchParams.dpSource) ?? '';
  const dpCampaign = searchParams.get(InitSessionSearchParams.dpCampaign) ?? '';
  const dpMedium = searchParams.get(InitSessionSearchParams.dpMedium) ?? '';

  return compactObject({
    dp_source: dpSource,
    dp_campaign: dpCampaign,
    dp_medium: dpMedium,
  });
};

export default useDigitalProfileUtmParams;
