import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';
import theme from '../../styles/theme';
import { text14CSS, text20CSS, textCSS } from '../../styles/typography.style';

export const spamFreeBlockCSS = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.azure50};
`;

export const tooltipSpamFreeBlockCSS = css`
  ${spamFreeBlockCSS};
  color: ${theme.colors.black};
  ${textCSS};

  ${mq[0]} {
    ${text20CSS};
  }
`;

export const shieldTooltipCheckIconCSS = css`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const shieldCheckIconCSS = css`
  width: 18px;
  height: 18px;
  margin-right: 6px;
`;

export const spamFreeTextCSS = css`
  font-weight: 700;
  border-bottom: 2px solid ${theme.colors.azure50};
`;

export const tooltipSpamFreeTextCSS = css`
  ${textCSS};
  font-weight: 700;
`;

export const tooltipContentCSS = css`
  margin-top: 13px;
  margin-bottom: 16px;
`;

export const tooltipMessagesWrapperCSS = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid ${theme.colors.gray10};
  border-radius: 4px;
  padding: 8px;
  gap: 16px;
  ${mq[0]} {
    flex-wrap: nowrap;
    gap: 8px;
    justify-content: space-between;
  }
`;

export const tooltipButtonCSS = css`
  background-color: ${theme.colors.white};
  width: auto;
  margin-top: 25px;
  &:hover {
    background-color: ${theme.colors.white};
  }
`;

export const checkIconCSS = css`
  background-color: ${theme.colors.positiveGreen27};
  width: 8px;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
`;

export const messageCSS = css`
  display: flex;
  align-items: center;
  ${text14CSS};
  font-weight: 700;
  position: relative;
  ${mq[0]} {
    white-space: nowrap;
  }
`;
