import { REQUIRED_VALIDATION } from '../../../../../questionsForm/utils/question-validations/question-validations.util';
import { QuestionType } from '../../../../../types/question.type';
import type { QuestionProps } from '../../../../../questionsForm/components/Question/Question.type';

export const PERSONAL_INFO_EMAIL_QUESTION_SCHEMA: QuestionProps = {
  questionKey: 'email',
  type: QuestionType.Email,
  label: 'Email',
  placeholder: 'email@email.com',
  validations: [REQUIRED_VALIDATION],
  gridWidth: 12,
};

export const PERSONAL_INFO_PHONE_QUESTION_SCHEMA: QuestionProps = {
  questionKey: 'phone',
  type: QuestionType.Phone,
  label: 'Phone number',
  placeholder: '(xxx) xxx-xxxx',
  validations: [REQUIRED_VALIDATION],
  gridWidth: 12,
};

export const PERSONAL_INFO_ADDRESS_QUESTION_SCHEMA: QuestionProps = {
  questionKey: 'address',
  type: QuestionType.Address,
  label: 'Mailing address',
  placeholder: 'street address, city, state, zip code',
  validations: [REQUIRED_VALIDATION],
  gridWidth: 12,
};
