import axios from 'axios';
import { HTTPMethod } from '../types/api.type';
import type { Api, ApiCallParams } from '../types/api.type';

const call = async <T = unknown>(
  method: HTTPMethod,
  url: string,
  { headers = {}, body = {}, params = {} }: ApiCallParams = {}
): Promise<T> =>
  await axios({
    method,
    url,
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    data: JSON.stringify(body),
    params,
  }).then((res) => res.data);

const api: Api = {
  async get(url, params = {}) {
    return await call(HTTPMethod.get, url, params);
  },
  async post(url, params = {}) {
    return await call(HTTPMethod.post, url, params);
  },
  async put(url, params = {}) {
    return await call(HTTPMethod.put, url, params);
  },
  async patch(url, params = {}) {
    return await call(HTTPMethod.patch, url, params);
  },
  async head(url, params = {}) {
    return await call(HTTPMethod.head, url, params);
  },
  async delete(url, params = {}) {
    return await call(HTTPMethod.delete, url, params);
  },
};

export default api;
