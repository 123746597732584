import { containerCSS, imageCSS } from './SirvImage.style';
import { getImgUrlWithParams } from './SirvImage.util';
import type { SirvImageProps } from './SirvImage.type';
import type { FC } from 'react';

const SirvImage: FC<SirvImageProps> = (props) => {
  const { className, alt, customCSS, isLazyLoad = true } = props;
  const imgSrcX1 = getImgUrlWithParams(props);
  const imgSrcX2 = getImgUrlWithParams({ ...props, multiplier: 2 });

  return (
    <div className={className} css={[containerCSS, customCSS]}>
      <img
        css={imageCSS}
        src={imgSrcX1}
        srcSet={`${imgSrcX1} 1x, ${imgSrcX2} 2x`}
        alt={alt}
        {...(isLazyLoad && { loading: 'lazy' })}
      />
    </div>
  );
};

export default SirvImage;
