import { css } from '@emotion/react';
import theme from '../../../../styles/theme';

export const tooltipButtonCSS = css`
  background-color: transparent;
  width: auto;
  padding: 0;
  &:hover {
    background-color: transparent;
  }
`;

export const discountsTextCSS = css`
  font-weight: 700;
  color: ${theme.colors.positiveGreen27};
  transition: color 0.3s ease-in-out;
  border-bottom: 2px solid ${theme.colors.positiveGreen27};
  &:hover {
    color: ${theme.colors.azure68};
    border-color: ${theme.colors.azure68};
  }
`;

export const discountsContentCSS = css`
  font-size: 14px;
`;
