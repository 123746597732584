import { useState, type FC, type ReactElement } from 'react';
import CheckIcon from '../../../../assets//check-mark.svg?react';
import PersonIcon from '../../../../assets/person.svg?react';
import VehicleIcon from '../../../../assets/vehicle.svg?react';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../constants/full-story';
import Button from '../../../../shared/components/Button/Button';
import Loader from '../../../../shared/components/Loader/Loader';
import { noopAsync } from '../../../../shared/utils/noop';
import { buttonCSS, checkIconCSS, iconCSS, listItemWrapperCSS, loaderCSS, titleCSS } from './FormListItem.style';
import type { FormListItemProps } from './FormListItem.type';

const FormListItem: FC<FormListItemProps> = ({
  customCSS,
  type,
  label,
  showRemoveButton,
  trackForbidden,
  onRemoveClick = noopAsync,
}) => {
  const [isRemoving, setIsRemoving] = useState(false);

  const onRemoveClickHandler = async (): Promise<void> => {
    setIsRemoving(true);
    await onRemoveClick();
    setIsRemoving(false);
  };

  const renderIcon = (): ReactElement | null => {
    switch (type) {
      case 'driver':
        return <PersonIcon css={iconCSS()} width={24} />;
      case 'vehicle':
        return <VehicleIcon css={iconCSS()} width={24} />;
      default:
        return null;
    }
  };

  return (
    <div css={[listItemWrapperCSS, customCSS]}>
      {renderIcon()}
      <div css={titleCSS} className={trackForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}>
        {label} <CheckIcon css={checkIconCSS} />
      </div>

      {isRemoving && (
        <span>
          <Loader customCSS={loaderCSS} colored />
        </span>
      )}
      {showRemoveButton && !isRemoving && (
        <Button customCSS={buttonCSS} variant="text" onClick={onRemoveClickHandler}>
          Remove
        </Button>
      )}
    </div>
  );
};

export default FormListItem;
