import AgentIcon from '../../../../../assets/agent.svg?react';
import CheckIcon from '../../../../../assets/check-mark.svg?react';
import {
  agentIconCSS,
  checkIconCSS,
  containerCSS,
  infoBlockCSS,
  infoItemCSS,
  titleCSS,
} from './CalendlyBundleScheduledMessage.style';
import type { CalendlyBundleScheduledMessageProps } from './CalendlyBundleScheduledMessage.type';
import type { FC } from 'react';

const CalendlyBundleScheduledMessage: FC<CalendlyBundleScheduledMessageProps> = ({ customCSS }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <div css={agentIconCSS}>
        <AgentIcon />
      </div>
      <div css={titleCSS}>What you need to prepare before the call</div>
      <div css={infoBlockCSS}>
        <div css={infoItemCSS}>
          <CheckIcon css={checkIconCSS} />
          <span>
            <strong>Driver’s license</strong> of everyone of driving age in your home
          </span>
        </div>
        <div css={infoItemCSS}>
          <CheckIcon css={checkIconCSS} />
          <span>
            The provided auto insurance quote <strong>is an estimate</strong> for the cars and drivers you entered. Your
            final price can change based on a number of factors such as your driving record, prior claims, credit
            history, and your current insurance. Please have this information prepared prior to the call.
          </span>
        </div>
        <div css={infoItemCSS}>
          <CheckIcon css={checkIconCSS} />
          <span>
            You may also be eligible for <strong>additional discounts and savings.</strong>
          </span>
        </div>
      </div>
    </div>
  );
};

export default CalendlyBundleScheduledMessage;
