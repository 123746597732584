import CalendarIcon from '../../../../../assets/calendar-icon.svg?react';
import CheckMarkIcon from '../../../../../assets/check-mark.svg?react';
import GlobeIcon from '../../../../../assets/globe.svg?react';
import MaticHeartIcon from '../../../../../assets/matic-heart.svg?react';
import { CALENDLY_PREVIEW_DETAILS_ID } from '../../../../../hooks/use-init-calendly/use-init-calendly.util';
import Button from '../../../../../shared/components/Button/Button';
import { Card } from '../../../../../shared/components/Card/Card';
import { getScheduledCallTime, getScheduledCallTimezone } from '../../../../../utils/scheduled-time-util';
import {
  containerCSS,
  titleCSS,
  subtitleCSS,
  scheduledCallInfoCSS,
  cardCSS,
  checkIconCSS,
  circleCSS,
  detailsHeadingCSS,
  detailsItemCSS,
  detailsListCSS,
  detailsSubheadingCSS,
  heartIconCSS,
  actionButtonCSS,
} from './QuotePageScheduledCallBlock.style';
import type { QuotePageScheduledCallBlockProps } from './QuotePageScheduledCallBlock.type';
import type { FC } from 'react';

const QuotePageScheduledCallBlock: FC<QuotePageScheduledCallBlockProps> = ({
  hideHeading,
  scheduledTime,
  timeZoneName,
  callScheduledInfoBlock,
  allowReschedule = true,
  onScheduleAnotherTimeClick,
}) => {
  const scheduledTimeFormatted = getScheduledCallTime(scheduledTime, timeZoneName);
  const timeZoneFormatted = getScheduledCallTimezone(scheduledTime, timeZoneName);

  return (
    <div css={containerCSS(hideHeading)}>
      {!hideHeading && (
        <>
          <div css={titleCSS}>You’re all set!</div>
          <div css={subtitleCSS}>
            An agent will contact you on the previously scheduled date. Call details are provided below.
          </div>
        </>
      )}
      <div id={CALENDLY_PREVIEW_DETAILS_ID} data-testid="scroll-point" />
      {callScheduledInfoBlock && <Card customCSS={scheduledCallInfoCSS}>{callScheduledInfoBlock}</Card>}
      <Card customCSS={cardCSS}>
        <MaticHeartIcon css={heartIconCSS} width={58} />
        <div css={detailsHeadingCSS}>
          <CheckMarkIcon css={checkIconCSS} />
          <span>You are scheduled</span>
        </div>
        <div css={detailsSubheadingCSS}>A calendar invitation has been sent to your email address</div>

        <div css={detailsListCSS}>
          <div css={detailsItemCSS}>
            <span css={circleCSS} />
            <span>Quote Review</span>
          </div>
          <div css={detailsItemCSS}>
            <CalendarIcon />
            <span>{scheduledTimeFormatted}</span>
          </div>
          <div css={detailsItemCSS}>
            <GlobeIcon />
            <span>{timeZoneFormatted}</span>
          </div>
        </div>

        {allowReschedule && (
          <Button customCSS={actionButtonCSS} variant="text" onClick={onScheduleAnotherTimeClick}>
            Want to schedule for another time?
          </Button>
        )}
      </Card>
    </div>
  );
};

export default QuotePageScheduledCallBlock;
