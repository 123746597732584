export const HEADERS = [
  'Reviewing details...',
  'Gathering your info...',
  'Processing your information...',
  'Almost done...',
  'Preparing your policy...',
];

export const SUBHEADERS = [
  'We’re finalizing your quote. This can take up to 60 seconds.',
  'Sit tight. We’re gathering your information.',
  'Connecting with carrier to finalize details.',
  'Carrier is wrapping up and your policy offer is on the way.',
  'One moment while we tidy up.',
];

export const INFO_BLOCK_CONTENT = `To finalize your policy, our agents need the driver's
  license of everyone of driving age in your home.
  Please have this information available before your call.`;
