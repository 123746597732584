import { StripeInputType } from './StripeInputElement.type';

export const getLabel = (type: StripeInputType): string => {
  switch (type) {
    case StripeInputType.CardNumber:
      return 'Card number';
    case StripeInputType.CardExpiry:
      return 'Card expiration date';
    case StripeInputType.CardCvc:
      return 'CVC';
    default:
      return '';
  }
};
