import { css } from '@emotion/react';
import theme from '../../../styles/theme';

export const containerCSS = css`
  padding: 0;
  margin: 0;
  border: unset;
  iframe {
    width: 100%;
    height: ${theme.sizes.controlHeight}px;
  }
`;
