import { type FC } from 'react';

import { usePublicSession } from '../../api/public/session';
import { usePublicConfig } from '../../api/publicConfig';
import Button from '../../shared/components/Button/Button';
import { activeAdminButtonCSS } from './ActiveAdminButton.style';

interface ActiveAdminButtonProps {
  gid: string;
}

const ActiveAdminButton: FC<ActiveAdminButtonProps> = ({ gid }) => {
  const { data: publicSessionData } = usePublicSession(gid);
  const configQuery = usePublicConfig(false);

  const onClick = (): void => {
    if (publicSessionData?.session?.link_to_active_admin) {
      window.open(publicSessionData.session.link_to_active_admin, '_blank');
    }
  };

  if (configQuery.data?.config?.environment === 'staging' && gid) {
    return (
      <Button customCSS={activeAdminButtonCSS} onClick={onClick}>
        AA
      </Button>
    );
  } else {
    return null;
  }
};

export default ActiveAdminButton;
