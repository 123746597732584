import type { SerializedStyles } from '@emotion/react';
import type { ChangeEvent, Ref } from 'react';

export enum RadioGroupVariant {
  Default = 'default',
  List = 'list',
  PhotoGrid = 'photo_grid',
}

export interface RadioButtonOption {
  label: string;
  value: string;
  description?: string;
  imageUrl?: string;
}

export interface RadioGroupProps {
  customCSS?: SerializedStyles;
  name: string;
  value?: string;
  options: RadioButtonOption[];
  disabled?: boolean;
  componentRef?: Ref<HTMLInputElement>;
  variant?: RadioGroupVariant;
  hasError?: boolean;
  onBlur?: () => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}
