import { nameCSS, descriptionCSS, headerCSS, valueCSS, containerCSS, iconCSS } from './QuoteCoverage.style';
import type { QuoteCoverageProps } from './QuoteCoverage.type';
import type { FC } from 'react';

const QuoteCoverage: FC<QuoteCoverageProps> = ({ customCSS, title, value, description, iconUrl }) => {
  return (
    <div css={[containerCSS(!!iconUrl), customCSS]}>
      {iconUrl && <img css={iconCSS} src={iconUrl} alt="" />}
      <div css={headerCSS}>
        <div css={nameCSS}>{title}</div>
        {value && <div css={valueCSS}>{value}</div>}
      </div>
      {description && <div css={descriptionCSS}>{description}</div>}
    </div>
  );
};

export default QuoteCoverage;
