import { css } from '@emotion/react';
import { textCSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const groupCSS = (first?: boolean): SerializedStyles => css`
  margin-top: ${first ? '0' : '32px'};
`;

export const buttonCSS = css`
  ${textCSS};
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 4px;
`;
