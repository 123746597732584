import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries';
import theme from '../../styles/theme';
import type { ContainerVariant } from './PageContainer.type';
import type { SerializedStyles } from '@emotion/react';

export const layoutContainerCSS = (variant?: ContainerVariant): SerializedStyles => css`
  ${variant === 'default' && defaultLayoutContainerCSS};
  ${variant === 'wide' && wideLayoutContainerCSS};
`;

export const defaultLayoutContainerCSS = css`
  width: 100%;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
`;

export const wideLayoutContainerCSS = css`
  max-width: 1240px;
`;

export const fixedHeaderCSS = css`
  position: fixed;
  width: 100%;
  z-index: 3;
`;

export const mainFullHeightWrapperCSS = css`
  min-height: 100vh;
  padding-left: ${theme.pageMarginHorizontal.mobile}px;
  padding-right: ${theme.pageMarginHorizontal.mobile}px;
  padding-top: var(--header-height);
  box-sizing: border-box;
  ${mq[0]} {
    min-height: calc(100vh - var(--header-height));
    padding-left: ${theme.pageMarginHorizontal.desktop}px;
    padding-right: ${theme.pageMarginHorizontal.desktop}px;
  }
`;
