import { Controller } from 'react-hook-form';
import { OTHER_OPTION_KEY } from '../../../../constants/schema-hardcoded-keys';
import Select from '../../../../shared/components/Select/Select';
import { FormElementTag } from '../../../../types/form-element.type';
import { getValidationRules } from '../../../utils/question-validations/question-validations.util';
import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper';
import type { SpecificQuestionProps } from '../Question.type';
import type { FC } from 'react';

const SelectQuestion: FC<SpecificQuestionProps> = ({
  questionKey,
  label,
  hideLabel,
  placeholder,
  description,
  hint,
  icon,
  workflowHeading,
  validations,
  variants,
  isDisabled,
  gridWidth,
  dense,
  tags = [],
}) => (
  <Controller
    name={questionKey}
    rules={getValidationRules(validations)}
    render={({ field: { onChange, value, onBlur, ref }, fieldState: { isTouched } }) => {
      const isPrefilled = Boolean(value && !isTouched);
      const onSelectChange = (v: string): void => {
        onChange(v);
        onBlur();
      };

      return (
        <QuestionWrapper
          questionKey={questionKey}
          label={label}
          hideLabel={hideLabel}
          hint={hint}
          description={description}
          icon={icon}
          workflowHeading={workflowHeading}
          isDisabled={isDisabled}
          gridWidth={gridWidth}
          inputProps={{
            inputId: questionKey,
            value,
            placeholder,
            options: variants,
            isDisabled,
            isSearchable: true,
            isPrefilled,
            dense,
            renderDropdownInBody: tags.includes(FormElementTag.RenderDropDownInBody),
            otherOptionKey: tags?.includes(FormElementTag.ShowOtherOptionAlways) ? OTHER_OPTION_KEY : null,
            onBlur,
            onValidEntry: onSelectChange,
            componentRef: ref,
          }}
          inputComponent={Select}
        />
      );
    }}
  />
);

export default SelectQuestion;
