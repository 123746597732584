import { css } from '@emotion/react';
import { maxCalendlyLikeWidthContainerCSS } from '../../../../../styles/common.style';

export const loadingContainerCSS = css`
  ${maxCalendlyLikeWidthContainerCSS};
  width: 100%;
  margin: 56px 0 24px;
  display: flex;
  flex-direction: column;
  gap: 18px;
`;
