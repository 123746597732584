import queryString from 'query-string';

export const appendQueryParams = (url: string, queryParams?: Record<string, string>): string => {
  if (!queryParams) {
    return url;
  }
  const newSearch = queryString.stringify(queryParams);

  return newSearch ? `${url}?${newSearch}` : url;
};
