import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { FeatureFlagsResponse } from '../../types/feature-flag.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useFeatureFlags = (sessionGid: string): QueryObserverResult<FeatureFlagsResponse> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.featureFlags, sessionGid],
    queryFn: async () =>
      await api
        .get<HTTPResponse<FeatureFlagsResponse>>(getApiUrl(API_PATH.featureFlags), {
          params: { session_gid: sessionGid },
        })
        .then((res) => res.data),
    enabled: !!sessionGid,
    refetchOnMount: 'always', // TODO: Set this to false when after new Quote response release
  });

export default useFeatureFlags;
