import { pageHeadingCSS } from '../../../../../styles/layout.style';
import { containerCSS, headingCSS, subheadingCSS } from './HomeQuotePageHeading.style';
import type { HomeQuotePageHeadingProps } from './HomeQuotePageHeading.type';
import type { FC } from 'react';

const HomeQuotePageHeading: FC<HomeQuotePageHeadingProps> = ({ customCSS, heading, subheading }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <div css={[pageHeadingCSS, headingCSS]}>{heading}</div>
      {subheading && <div css={subheadingCSS}>{subheading}</div>}
    </div>
  );
};

export default HomeQuotePageHeading;
