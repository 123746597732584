import { FormElementTag } from '../../../types/form-element.type';
import { QuestionType } from '../../../types/question.type';
import { REQUIRED_VALIDATION } from '../../utils/question-validations/question-validations.util';
import type { QuestionProps } from '../Question/Question.type';

export const SERVICER_QUESTION_SCHEMA: QuestionProps = {
  questionKey: 'servicer_gid',
  type: QuestionType.SingleSelect,
  label: 'What’s your lender’s name?',
  placeholder: 'type in or choose an option',
  validations: [REQUIRED_VALIDATION],
  gridWidth: 12,
};

export const LOAN_QUESTION_SCHEMA: QuestionProps = {
  questionKey: 'loan_number',
  type: QuestionType.Text,
  label: 'What’s your loan number?',
  placeholder: 'XX-XXX-XXX-XXXXXXXXXX',
  validations: [REQUIRED_VALIDATION],
  gridWidth: 12,
  tags: [FormElementTag.TrackForbidden],
};
