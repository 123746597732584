import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text20CSS, text24CSS, text40CSS } from '../../../../../styles/typography.style';

export const detailsCSS = css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 16px;

  ${mq[1]} {
    flex-direction: row;
    align-items: center;
    padding: 35px 95px;
  }
`;

export const priceCSS = css`
  color: ${theme.colors.positiveGreen27};
  ${text40CSS};
  font-weight: 700;

  ${mq[1]} {
    font-size: 56px;
    line-height: 90px;
    font-weight: 900;

    span {
      ${text24CSS};
      line-height: 32px;
    }
  }
`;

export const priceWrapperCSS = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 0 0 30px;

  ${mq[1]} {
    flex-direction: row;
    padding: 0;
  }
`;

export const dividerCSS = css`
  margin: 0 12px;
  border-left-color: ${theme.colors.gray30};
  border-left-width: 2px;

  ${mq[1]} {
    margin: 0 20px;
  }
`;

export const annualCSS = css`
  ${mq[1]} {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    white-space: nowrap;
  }
`;

export const deductibleCSS = css`
  margin-bottom: 12px;
  width: 100%;
`;

export const deductibleLabelCSS = css`
  font-weight: 400;
`;

export const deductibleInputCSS = css`
  .select__control {
    font-weight: 700;
  }
`;

export const logoCSS = css`
  justify-content: center;
  margin-bottom: 20px;

  ${mq[1]} {
    padding-bottom: 35px;
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

export const effectiveDateCSS = css`
  ${text20CSS};
  color: ${theme.colors.gray80};
  padding-bottom: 12px;
`;

export const buttonCSS = css`
  ${mq[1]} {
    margin-top: 12px;
  }
`;

export const mobileListCSS = css`
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  strong {
    ${text20CSS};
  }
`;

export const savingsAndDiscountsBlockCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  text-align: center;

  ${mq[1]} {
    justify-content: flex-start;
    margin-bottom: 0;
  }
`;

export const dotCSS = css`
  display: block;
  width: 4px;
  height: 4px;
  background-color: ${theme.colors.positiveGreen27};
  margin: 8px;
  border-radius: 50%;
  flex-shrink: 0;
`;

export const savingsBadgeCSS = css`
  color: ${theme.colors.positiveGreen27};
  font-weight: 700;
`;
