import Divider from '../../../../assets/curvy-divider.svg?react';
import { containerCSS, dividerCSS, subtitleCSS, titleCSS } from './PageSectionHeading.style';
import type { PageSectionHeadingProps } from './PageSectionHeading.type';
import type { FC } from 'react';

const PageSectionHeading: FC<PageSectionHeadingProps> = ({ customCSS, title, subtitle, showDivider }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      {showDivider && <Divider css={dividerCSS} role="img" aria-label="Divider" />}
      <div css={titleCSS}>{title}</div>
      <div css={subtitleCSS}>{subtitle}</div>
    </div>
  );
};

export default PageSectionHeading;
