import { memo } from 'react';
import TooltipQuestionButton from '../../../../shared/components/TooltipQuestionButton/TooltipQuestionButton';
import Wrapper from '../../../../shared/components/Wrapper/Wrapper';
import { labelCSS, tooltipButtonCSS } from './QuestionLabel.style';
import { QuestionLabelWrapper } from './QuestionLabel.type';
import type { QuestionLabelProps } from './QuestionLabel.type';
import type { FC } from 'react';

const QuestionLabel: FC<QuestionLabelProps> = ({
  customCSS,
  children,
  wrapperElement = QuestionLabelWrapper.Label,
  keyName,
  tooltipText,
}) => {
  const wrapperComponentProps = wrapperElement === QuestionLabelWrapper.Label ? { htmlFor: keyName } : {};

  return (
    <Wrapper
      tag={wrapperElement === QuestionLabelWrapper.Label ? 'label' : 'div'}
      css={[labelCSS, customCSS]}
      {...wrapperComponentProps}
    >
      {children}
      {tooltipText && <TooltipQuestionButton customCSS={tooltipButtonCSS} id={keyName} content={tooltipText} />}
    </Wrapper>
  );
};

export default memo(QuestionLabel);
