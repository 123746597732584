import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ActiveAdminButton from '../../components/ActiveAdminButton/ActiveAdminButton';
import { SEGMENT } from '../../constants/analytics';
import { useSegment } from '../../hooks/use-segment';
import useTrackFullStorySession from '../../hooks/use-track-full-story-session';
import { fullHeightPageCSS } from '../../styles/layout.style';
import SessionLocked from './components/SessionLocked/SessionLocked';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const SessionLockedLayout: FC = () => {
  const { gid } = useParams() as DefaultLocation;

  const { page } = useSegment();
  const pageViewedTracked = useRef(false);

  useTrackFullStorySession(gid);

  useEffect(() => {
    if (!pageViewedTracked.current) {
      page(SEGMENT.pages.sessionLocked.name);
      pageViewedTracked.current = true;
    }
  }, [page]);

  return (
    <div css={fullHeightPageCSS}>
      <main>
        <ActiveAdminButton gid={gid} />
        <SessionLocked />
      </main>
    </div>
  );
};

export default SessionLockedLayout;
