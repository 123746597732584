import { Fragment, forwardRef } from 'react';
import addIcon from '../../../../assets/add.svg';
import subtractIcon from '../../../../assets/subtract.svg';
import { buttonCSS, inputCSS, inputWrapperCSS } from './CounterInputInner.style';
import type { CounterInputInnerProps } from './CounterInputInner.types';

const CounterInputInner = forwardRef<HTMLInputElement, CounterInputInnerProps>(function CounterInputInner(
  { disabled, hasError, inputId, value, onFocus, onInputChange, onButtonClick, onInputBlur },
  ref
) {
  return (
    <Fragment>
      <button
        css={buttonCSS}
        type="button"
        aria-label="Decrease"
        tabIndex={-1}
        disabled={disabled}
        onClick={() => onButtonClick(-1)}
        onFocus={() => onFocus(true)}
        onBlur={onInputBlur}
      >
        <img src={subtractIcon} alt="Decrease" />
      </button>
      <div css={inputWrapperCSS}>
        <input
          css={inputCSS(hasError)}
          id={inputId}
          ref={ref}
          type="number"
          value={value ?? ''}
          disabled={disabled}
          onChange={onInputChange}
          onFocus={() => onFocus(true)}
          onBlur={onInputBlur}
        />
      </div>
      <button
        css={buttonCSS}
        type="button"
        aria-label="Increase"
        tabIndex={-1}
        disabled={disabled}
        onClick={() => onButtonClick(1)}
        onFocus={() => onFocus(true)}
        onBlur={onInputBlur}
      >
        <img src={addIcon} alt="Increase" />
      </button>
    </Fragment>
  );
});

export default CounterInputInner;
