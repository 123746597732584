import { matchRoutes, useLocation, useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../api/carrier';
import { usePublicFlowConfig } from '../../api/public/flow-config';
import { useSession } from '../../api/session';
import { SEGMENT } from '../../constants/analytics';
import usePhoneNumber from '../../hooks/use-phone-number';
import { useSegment } from '../../hooks/use-segment';
import Header from '../../shared/components/Header/Header';
import { StrategyType } from '../../types/public-flow-config.type';
import { Flows } from '../../types/route-params.type';
import ContactReminderToggletip from '../ContactReminderToggletip/ContactReminderToggletip';
import FlowNavigationHorizontal from '../FlowNavigationHorizontal/FlowNavigationHorizontal';
import LiveHelpButton from '../LiveHelpButton/LiveHelpButton';
import { HIDE_NAVIGATION_ROUTES } from './OLBHeader.util';
import type { OLBHeaderProps } from './OLBHeader.type';
import type { Carrier } from '../../types/carrier.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const OLBHeader: FC<OLBHeaderProps> = ({ customCSS, subHeaderComponent }) => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { data } = useSelectedCarrier<Carrier | null>(gid);
  const { data: flowConfig } = usePublicFlowConfig(gid);
  const sessionQuery = useSession(gid);
  const { phoneNumber } = usePhoneNumber();

  const carrierLogo =
    flowConfig?.flow_config.strategy === StrategyType.OnlineBind ? data?.carrier?.logo_url : undefined;

  const isTestMode = sessionQuery.data?.session.test_mode;

  const { track } = useSegment();

  const currentLocation = useLocation();
  const routeWithoutNavigation = matchRoutes(HIDE_NAVIGATION_ROUTES, currentLocation);

  const hideNavigation = !!routeWithoutNavigation || flow === Flows.Auto;

  const onPhoneClicked = (): void =>
    track(SEGMENT.events.phoneConversationInitiated, {
      page: sessionQuery.data?.session.current_page_key,
      location: SEGMENT.locations.header,
    });

  if (!data) {
    return null;
  }

  return (
    <Header
      customCSS={customCSS}
      carrierLogo={carrierLogo}
      carrierName={data?.carrier?.name}
      reverseLogo={flow === Flows.Auto}
      phoneNumber={phoneNumber}
      fullscreen
      denseContainer
      isTestMode={isTestMode}
      navigation={hideNavigation ? null : <FlowNavigationHorizontal />}
      subHeader={subHeaderComponent}
      onPhoneClick={onPhoneClicked}
    >
      <div>
        <LiveHelpButton />
        <ContactReminderToggletip />
      </div>
    </Header>
  );
};

export default OLBHeader;
