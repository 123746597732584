import { css } from '@emotion/react';
import theme from '../../../styles/theme';

export const tooltipButtonCSS = css`
  background-color: ${theme.colors.white};
  width: auto;
  &:hover {
    background-color: ${theme.colors.white};
  }
  padding: 0;
`;

export const tooltipLabelCSS = css`
  display: flex;
  align-items: center;
  color: ${theme.colors.azure68};
  text-decoration: underline;
`;

export const tooltipContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
