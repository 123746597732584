import { css } from '@emotion/react';
import { buttonCSS as buttonBaseCSS } from '../../../../../shared/components/Button/Button.style';
import { mq } from '../../../../../styles/media-queries';
import { text24CSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  display: flex;
  padding-top: 64px;
  flex-direction: column;
  align-items: center;

  ${mq[1]} {
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    padding: 110px 32px;
    gap: 56px;
  }
`;

export const contentCSS = css`
  max-width: 380px;
  text-align: center;

  ${mq[1]} {
    max-width: 440px;
    text-align: left;
  }
`;

export const titleCSS = css`
  ${text24CSS};
  font-weight: 700;
  margin-top: 16px;

  ${mq[1]} {
    margin-top: 32px;
  }
`;

export const descriptionCSS = css`
  margin-top: 24px;
  font-weight: 500;

  ${mq[1]} {
    margin-top: 16px;
  }
`;

export const buttonCSS = css`
  ${buttonBaseCSS(false, 'secondary')};
  display: inline-block;
  text-decoration: unset;
  margin-top: 24px;
  padding-left: 20px;
  padding-right: 20px;
`;
