import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text14CSS, text20CSS, textCSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  padding: 12px;
  align-items: center;

  ${mq[0]} {
    flex-direction: row;
    align-items: flex-start;
    padding: 32px;
  }
`;

export const headerTextCSS = css`
  font-weight: 700;
  ${textCSS};
  margin-bottom: 8px;

  ${mq[0]} {
    ${text20CSS};
  }
`;

export const descriptionCSS = css`
  ${text14CSS};
  color: ${theme.colors.gray60};
  width: 80%;

  ${mq[0]} {
    ${textCSS};
    color: ${theme.colors.gray60};
  }
`;

export const linkCSS = css`
  margin-bottom: 8px;
  font-weight: 700;
  border: none;
  display: flex;
  align-items: center;
`;

export const infoBlockCSS = css`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex: 100%;

  ${mq[0]} {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
    margin-right: 20px;
    flex: 55%;
  }
`;

export const maticTeamCSS = css`
  margin-right: 20px;
  width: 70%;

  ${mq[0]} {
    width: 50%;
  }
`;

export const contactBlockCSS = css`
  display: flex;
  flex-direction: column;
  flex: 100%;
  margin-left: 20px;

  ${mq[0]} {
    flex: 30%;
    margin-left: 0;
  }
`;

export const iconCSS = css`
  color: ${theme.colors.gray60};
  margin-right: 4px;
  padding-top: 2px;
`;
