import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicFlowConfig } from '../../../../../api/public/flow-config';
import { usePublicPrimaryInsured } from '../../../../../api/public/primary-insured';
import { usePublicSession } from '../../../../../api/public/session';
import { usePublicConfig } from '../../../../../api/publicConfig';
import useInitCalendly from '../../../../../hooks/use-init-calendly/use-init-calendly';
import { CALENDLY_ID, CALL_SCHEDULED_STEP } from '../../../../../hooks/use-init-calendly/use-init-calendly.util';
import { Card } from '../../../../../shared/components/Card/Card';
import LoadingDots from '../../../../../shared/components/LoadingDots/LoadingDots';
import noop from '../../../../../shared/utils/noop';
import { calendlyWidgetCSS } from '../../../../../styles/common.style';
import { StrategyType } from '../../../../../types/public-flow-config.type';
import {
  headingContainerCSS,
  scheduledCallDetailsCSS,
  scheduledCallInfoCSS,
  subtitleCSS,
  titleCSS,
  widgetCSS,
  widgetLoaderCSS,
} from './QuotePageCalendlyBlock.style';
import type { QuotePageCalendlyBlockProps } from './QuotePageCalendlyBlock.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC, ReactElement } from 'react';

const QuotePageCalendlyBlock: FC<QuotePageCalendlyBlockProps> = ({
  heading,
  subHeading,
  hideHeading = false,
  callScheduledInfoBlock,
  callScheduledDetailsBlock,
  isShadowAgentRunning,
  onCallScheduled = noop,
}) => {
  const { gid } = useParams() as DefaultLocation;
  const { data: configData } = usePublicConfig();
  const { data: publicFlowConfigData, isLoading, isPending } = usePublicFlowConfig(gid);
  const { data: publicSessionPrimaryInsuredData } = usePublicPrimaryInsured(gid);
  const { data: publicSessionData } = usePublicSession(gid);
  const [calendlyUrl, setCalendlyUrl] = useState<string | null>(null);

  const prefillData = {
    firstName: publicSessionPrimaryInsuredData?.primary_insured.first_name,
    lastName: publicSessionPrimaryInsuredData?.primary_insured.last_name,
    email: publicSessionPrimaryInsuredData?.primary_insured.email,
  };

  const { step, calendlyInit, isLoaded } = useInitCalendly({
    leadGid: publicSessionData?.session.engagement_gid,
    url: calendlyUrl,
    prefillData,
    strategy: publicFlowConfigData?.flow_config.strategy,
    carrierKey: publicFlowConfigData?.flow_config.carrier_key,
  });

  const isAllowInit = (!isLoading && isPending) || (!isLoading && !isPending);
  const isCallScheduled = useRef(false);

  useEffect(() => {
    if (configData?.config && publicSessionPrimaryInsuredData?.primary_insured && publicFlowConfigData?.flow_config) {
      const url =
        publicFlowConfigData.flow_config.strategy === StrategyType.DigitalProfile &&
        publicSessionPrimaryInsuredData.primary_insured.is_policyholder
          ? configData.config.integrations.calendly.policyholders_url
          : configData.config.integrations.calendly.primary_url;
      setCalendlyUrl(url);
    }
  }, [configData, publicSessionPrimaryInsuredData, publicFlowConfigData]);

  useEffect(() => {
    if (calendlyUrl && isAllowInit) {
      calendlyInit();
    }
  }, [calendlyUrl, calendlyInit, isAllowInit]);

  useEffect(() => {
    if (!isCallScheduled.current && CALL_SCHEDULED_STEP === step) {
      isCallScheduled.current = true;
      onCallScheduled();
    }
  }, [step, onCallScheduled, isCallScheduled]);

  const renderHeading = (): ReactElement | null => {
    if (hideHeading) {
      return null;
    }

    if (step === 3) {
      return (
        <div css={headingContainerCSS(isShadowAgentRunning)}>
          <div css={titleCSS(isShadowAgentRunning)}>You’re all set!</div>
          <div css={subtitleCSS(isShadowAgentRunning)}>
            A confirmation email with your appointment details has been sent.
          </div>
          {callScheduledInfoBlock && <Card customCSS={scheduledCallInfoCSS}>{callScheduledInfoBlock}</Card>}
        </div>
      );
    }

    return (
      <div css={headingContainerCSS(isShadowAgentRunning)}>
        <div css={titleCSS(isShadowAgentRunning)}>{heading}</div>
        <div css={subtitleCSS(isShadowAgentRunning)}>{subHeading ?? 'Schedule a call or let us call you.'}</div>
      </div>
    );
  };

  return (
    <>
      {renderHeading()}
      {callScheduledDetailsBlock && step !== 3 && (
        <Card customCSS={scheduledCallDetailsCSS}>{callScheduledDetailsBlock}</Card>
      )}
      <div css={[calendlyWidgetCSS(step), widgetCSS]} id={CALENDLY_ID} data-testid="calendly-widget">
        {!isLoaded && <LoadingDots customCSS={widgetLoaderCSS} />}
      </div>
    </>
  );
};

export default QuotePageCalendlyBlock;
