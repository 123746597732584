import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';

export const headingCSS = css`
  padding: 4px 0;
  margin-bottom: 8px;
  ${mq[0]} {
    margin-bottom: 16px;
  }
`;

export const labelCSS = css`
  padding: 2px 0;
  margin-bottom: 4px;
`;

export const inputCSS = css`
  margin-bottom: 48px;
`;
