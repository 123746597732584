import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const tooltipButtonCSS = (size: number): SerializedStyles => css`
  border: none;
  cursor: pointer;
  display: inline-block;
  font-size: ${size - 4}px;
  line-height: ${size}px;
  height: ${size}px;
  width: ${size}px;
  margin-left: 6px;
  padding: 0;
  vertical-align: middle;
  position: relative;
  flex-shrink: 0;
  color: ${theme.colors.gray60};
  background-color: transparent;

  &:hover svg {
    color: ${theme.colors.azure68};
  }

  ${mq[0]} {
    margin-left: 8px;
  }
`;

export const questionMarkCSS = (size: number): SerializedStyles => css`
  width: ${size}px;
  height: ${size}px;
`;
