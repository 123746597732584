import SCHEMA_KEYS from '../../../../constants/schema-hardcoded-keys';
import { questionHasAnswer } from '../../../../questionsForm/utils/answers/answers.util';
import { isAllowSendNullAnswer } from '../../../../questionsForm/utils/form-element-tags.util';
import isSensitiveQuestion from '../../../../questionsForm/utils/is-sensitive-question.util';
import {
  type WorkflowSectionSchema,
  type SectionContainer,
  SectionPresentationType,
} from '../../../../types/section.type';
import { isDisclosureElement, isQuestionElement } from '../../../../utils/schema.util';
import isVisibleByAnswers from '../../../../utils/visibility-conditions.util';
import type { Answers } from '../../../../types/answer.type';
import type { QuestionElementContent, DisclosureContent, FormElementSchema } from '../../../../types/form-element.type';

export const getVisibleSections = <T extends { key: string }>(sections: T[], currentSectionKey: string | null): T[] => {
  const sectionsKeys = sections?.map((s) => s.key) || [];
  return sections.filter((_, index) => currentSectionKey !== null && index <= sectionsKeys.indexOf(currentSectionKey));
};

export const getQuestionsKeysFromSections = (sections: WorkflowSectionSchema[]): string[] =>
  sections
    .map((s) => {
      if (s.presentation_type === SectionPresentationType.Workflow) {
        return getQuestionsKeysFromContainers(s.containers);
      }
      return [];
    })
    .flat(1);

export const getQuestionsKeysFromContainers = (containers: SectionContainer[]): string[] => {
  return containers.reduce<string[]>((acc, curr) => {
    curr.elements.forEach((el) => {
      if (isQuestionElement(el)) {
        acc.push(el.content.key);
        el.sub_elements.forEach((subElement) => isQuestionElement(subElement) && acc.push(subElement.content.key));
      }
      if (isDisclosureElement(el)) {
        acc.push(el.key);
      }
    });

    return acc;
  }, []);
};

export const getAnswersByKeys = (answers: Answers, keys: string[]): Answers => {
  return keys.reduce((acc, key) => ({ ...acc, [key]: answers[key] }), {});
};

export const removeSensitiveAnswers = (answers: Answers): Answers => {
  return Object.keys(answers).reduce((acc, key) => {
    const skipUpdate =
      isSensitiveQuestion(key) && typeof answers[key] === 'string' && (answers[key] as string).includes('*');
    return skipUpdate ? { ...acc } : { ...acc, [key]: answers[key] };
  }, {});
};

export const prepareAnswers = (
  answers: Answers,
  sections: WorkflowSectionSchema[],
  currentContainerKey?: string
): Answers => {
  const onlySectionsAnswers = sections.reduce<Answers>((acc, section) => {
    const isLastSection = section.key === sections[sections.length - 1].key;
    const workflowSectionAnswers = prepareWorkflowSectionAnswers(
      answers,
      section,
      isLastSection ? currentContainerKey : undefined
    );
    return { ...acc, ...workflowSectionAnswers };
  }, {});

  return removeSensitiveAnswers(onlySectionsAnswers);
};

const getVisibleContainersFromSection = (
  containers: SectionContainer[],
  currentContainerKey: string
): SectionContainer[] => {
  const containersKeys = containers.map((c) => c.key);
  const currentContainerIndex = containersKeys.indexOf(currentContainerKey);
  return currentContainerIndex >= 0 ? containers.filter((_, index) => index <= currentContainerIndex) : containers;
};

const prepareWorkflowSectionAnswers = (
  answers: Answers,
  section: WorkflowSectionSchema,
  currentContainerKey?: string
): Answers => {
  const containers = currentContainerKey
    ? getVisibleContainersFromSection(section.containers, currentContainerKey)
    : section.containers;
  const questionsKeys = getQuestionsKeysByVisibilityConditions(containers, answers);
  const notVisibleAnswers = questionsKeys.notVisibleQuestions.reduce<Answers>(
    (acc, key) => ({ ...acc, [key]: null }),
    {}
  );
  return questionsKeys.visibleQuestions.reduce<Answers>((acc, key) => {
    const answer = answers[key];
    return questionHasAnswer(answer) ? { ...acc, [key]: answer } : acc;
  }, notVisibleAnswers);
};

const getNotVisibleQuestions = (container: SectionContainer): string[] => {
  return container.elements.reduce<string[]>((acc, el) => {
    if (isQuestionElement(el) && isAllowSendNullAnswer(el.tags)) {
      acc.push(el.content.key);
      el.sub_elements.forEach((subElement) => isQuestionElement(subElement) && acc.push(subElement.content.key));
    }
    if (isDisclosureElement(el)) {
      acc.push(el.key);
    }
    return acc;
  }, []);
};

export const getQuestionsKeysByVisibilityConditions = (
  containers: SectionContainer[],
  answers: Answers
): { visibleQuestions: string[]; notVisibleQuestions: string[] } => {
  return containers.reduce<{ visibleQuestions: string[]; notVisibleQuestions: string[] }>(
    (acc, container) => {
      const isBundleContainer = container.key === SCHEMA_KEYS.bundleContainerKey;
      if (!isVisibleByAnswers(answers, container.visibility_conditions) && !isBundleContainer) {
        return {
          visibleQuestions: [...acc.visibleQuestions],
          notVisibleQuestions: [...acc.notVisibleQuestions, ...getNotVisibleQuestions(container)],
        };
      }

      const questionableElements = container.elements.reduce<
        Array<FormElementSchema<QuestionElementContent | DisclosureContent>>
      >((acc, curr) => {
        if (isQuestionElement(curr) || isDisclosureElement(curr)) {
          acc.push(curr);
        }

        curr.sub_elements.forEach((subElement) => {
          if (isQuestionElement(subElement) || isDisclosureElement(subElement)) {
            acc.push(subElement);
          }
        });

        return acc;
      }, []);

      const visibleQuestions: string[] = [];
      const notVisibleQuestions: string[] = [];

      questionableElements.forEach((element) => {
        if (isQuestionElement(element)) {
          if (isVisibleByAnswers(answers, element.visibility_conditions)) {
            visibleQuestions.push(element.content.key);
          } else if (isAllowSendNullAnswer(element.tags)) {
            notVisibleQuestions.push(element.content.key);
          }
        }
        if (isDisclosureElement(element)) {
          if (isVisibleByAnswers(answers, element.visibility_conditions)) {
            visibleQuestions.push(element.key);
          } else {
            notVisibleQuestions.push(element.key);
          }
        }
      });

      return {
        visibleQuestions: [...acc.visibleQuestions, ...visibleQuestions],
        notVisibleQuestions: [...acc.notVisibleQuestions, ...notVisibleQuestions],
      };
    },
    { visibleQuestions: [], notVisibleQuestions: [] }
  );
};
