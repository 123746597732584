import { useQueryClient } from '@tanstack/react-query';
import { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import { usePublicConfig } from '../../../../../api/publicConfig';
import {
  useCreateQuoteRequest,
  useLastQuoteRequest,
  useMutateQuote,
  useQuoteRequestsSubscription,
} from '../../../../../api/quote';
import { useSession } from '../../../../../api/session';
import { useSessionFlowConfig } from '../../../../../api/session-flow-config';
import QUERY_CACHE_KEYS from '../../../../../constants/query-cache-keys';
import useResponsive from '../../../../../hooks/use-responsive';
import { SWITCH_VALUE } from '../../../../../questionsForm/components/Question/SwitchQuestion/SwitchInput/SwitchInput.util';
import { isAnswersEqual } from '../../../../../questionsForm/utils/answers/answers.util';
import { prepareCustomAnswers } from '../../../../../questionsForm/utils/answers/custom-schema-answers.util';
import Button from '../../../../../shared/components/Button/Button';
import SkeletonLoader from '../../../../../shared/components/SkeletonLoader/SkeletonLoader';
import Wrapper from '../../../../../shared/components/Wrapper/Wrapper';
import { getPrimaryQuote } from '../../../../../utils/quote.util';
import { getOptionalCoverageComponents } from '../../../../../utils/schema.util';
import isVisibleByAnswers from '../../../../../utils/visibility-conditions.util';
import QuoteCoverage from '../../QuoteCoverage/QuoteCoverage';
import OptionalCoverageModal from '../OptionalCoverageModal/OptionalCoverageModal';
import {
  accordionArrowCSS,
  buttonCSS,
  containerCSS,
  containerLoaderCSS,
  contentCSS,
  coverageCSS,
  descriptionCSS,
  headerCSS,
  titleCSS,
} from './QuoteOptionalCoverage.style';
import {
  ADDED_COVERAGE,
  NOT_ADDED_COVERAGE,
  interpolateCoverageDescription,
  isBooleanCoverage,
  isPercentageCoverage,
  prepareCoverageAnswersForSubmit,
} from './QuoteOptionalCoverage.util';
import type { Answers } from '../../../../../types/answer.type';
import type { OptionalCoverageSchema } from '../../../../../types/form-component.type';
import type { PageResponse } from '../../../../../types/page.type';
import type { HomeQuote } from '../../../../../types/quote.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { FC } from 'react';

const QuoteOptionalCoverage: FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const { data: configData } = usePublicConfig();
  const { data: sessionConfig } = useSessionFlowConfig(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const queryClient = useQueryClient();
  const { data: pageData } = usePage<CustomSectionSchema>(gid, currentPageKey);

  const { mutateAsync: updateQuote } = useMutateQuote(gid);
  const { mutateAsync: createRequest } = useCreateQuoteRequest(gid);

  const [isExpanded, setIsExpanded] = useState(true);
  const [isEditing, setIsEditing] = useState(false);

  const { isMobile } = useResponsive();

  const webSocketUrl = configData?.config.websocket_url;
  const { isFinished, startSubscription } = useQuoteRequestsSubscription({ gid, webSocketUrl });

  const { data: quoteRequestData } = useLastQuoteRequest<HomeQuote>(gid);
  const quotes = quoteRequestData?.quotes_request?.groups[0]?.quotes;

  const quote = getPrimaryQuote<HomeQuote>(quotes, sessionConfig?.flow_config.carrier_key);

  const components = useMemo(
    () =>
      pageData &&
      getOptionalCoverageComponents(pageData.page.sections).filter((c) =>
        isVisibleByAnswers(pageData.answers, c.visibility_conditions)
      ),
    [pageData]
  );

  const [isUpdating, setIsUpdating] = useState(false);

  const onToggle = (): void => {
    setIsExpanded((s) => !s);
  };

  const accordionProps = {
    'aria-controls': 'optional-coverage',
    'aria-expanded': isExpanded,
    onClick: onToggle,
  };

  useEffect(() => {
    const call = async (): Promise<void> => {
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.quoteRequest, gid] });

      // Refetching the session to update session lock timer
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.session, gid] });
      // Update cache to display new optional coverages values that we get from answers
      await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.page, gid, currentPageKey] });

      setIsUpdating(false);
    };

    if (isUpdating && isFinished) {
      call();
    }
  }, [isUpdating, isFinished, queryClient, gid, currentPageKey]);

  if (!pageData) {
    return (
      <div css={[containerCSS, containerLoaderCSS]}>
        <SkeletonLoader absoluteHeight={50} width={91} />
        <SkeletonLoader absoluteHeight={50} width={55} />
        <SkeletonLoader absoluteHeight={50} width={75} />
        <SkeletonLoader absoluteHeight={50} width={85} />
      </div>
    );
  }

  if (!components?.length || !quote) {
    return null;
  }

  const onCoveragesUpdate = async (data: Answers): Promise<void> => {
    setIsEditing(false);

    const answers = prepareCoverageAnswersForSubmit(data, components);
    if (isAnswersEqual(answers, prepareCustomAnswers(pageData))) {
      return;
    }

    setIsUpdating(true);

    await updateQuote(answers as Record<string, string>);
    startSubscription();
    await createRequest({});
  };

  const getCoverageValue = (pageData: PageResponse, question: OptionalCoverageSchema): string | undefined => {
    const value = (pageData.answers[question.key] ?? question.default_answer_value) as string | undefined;
    if (value) {
      if (isBooleanCoverage(value)) {
        return SWITCH_VALUE.on === value ? ADDED_COVERAGE : NOT_ADDED_COVERAGE;
      }
      return isPercentageCoverage(value) ? `${(parseFloat(value) + 1) * 100}%` : `$${value}`;
    } else return NOT_ADDED_COVERAGE;
  };

  return (
    <div css={containerCSS}>
      <div css={headerCSS}>
        <div>
          {isMobile ? (
            <Wrapper css={titleCSS} tag={'button'} {...accordionProps}>
              <span>Add optional coverages</span>
              <div css={accordionArrowCSS(isExpanded)} />
            </Wrapper>
          ) : (
            <div css={titleCSS}>Add optional coverages</div>
          )}
          <div css={descriptionCSS}>
            Below are some additions you can choose. For further details, and to add other optional coverages, talk to
            one of our agents after you finish purchasing a policy.
          </div>
        </div>
        <Button customCSS={buttonCSS} variant="secondary" onClick={() => setIsEditing(true)}>
          Add Coverages
        </Button>
      </div>
      {isExpanded && (
        <div css={contentCSS}>
          {components?.map((question) => (
            <QuoteCoverage
              key={question.key}
              title={question.label}
              description={interpolateCoverageDescription(question.description, quote.coverages)}
              iconUrl={question.small_icon_url}
              value={getCoverageValue(pageData, question)}
              customCSS={coverageCSS}
            />
          ))}
        </div>
      )}
      <OptionalCoverageModal
        isOpen={isEditing || isUpdating}
        isLoading={isUpdating}
        components={components}
        coverages={quote.coverages}
        onSubmit={onCoveragesUpdate}
        onClose={() => setIsEditing(false)}
      />
    </div>
  );
};
export default QuoteOptionalCoverage;
