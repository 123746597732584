import { useEffect, useRef, Fragment } from 'react';
import ChevronIcon from '../../../../assets/chevron.svg';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../constants/full-story';
import { addressOptionCSS, caretCSS, entriesCSS } from './AddressOption.style';
import type { AddressSuggestion } from '../../../../types/smarty-streets.type';
import type { FC } from 'react';

export const getOptionString = ({
  street_line: streetLine,
  secondary,
  city,
  state,
  zipcode,
  entries,
}: AddressSuggestion): string => {
  const secondaryStr = secondary ? ` ${secondary}` : '';
  const entriesStr = +entries > 1 ? ` (${entries})` : '';

  return `${streetLine}${secondaryStr}${entriesStr} ${city}, ${state} ${zipcode}`;
};

const renderOptionLabel = (inputValue = '', optionString: string, entries: number): JSX.Element => {
  const inputLow = inputValue.toLowerCase().trim();
  const optionLow = optionString.toLowerCase().trim();
  let i = 0;

  for (; i < optionString.length; i++) if (inputLow[i] === undefined || inputLow[i] !== optionLow[i]) break;

  const common = optionString.slice(0, i);
  const suggested: Array<string | JSX.Element> = optionString.slice(i).split(`(${entries})`);

  if (entries > 1) {
    suggested.splice(
      1,
      0,
      <span css={entriesCSS} key="entries">
        ({entries} entries)
      </span>
    );
  }

  return (
    <Fragment>
      <strong data-testid="address-bold">{common}</strong>
      {suggested.map((sub) => sub)}
      {entries > 1 && (
        <svg viewBox="0 0 24 24" xmlnsXlink="http://www.w3.org/1999/xlink" css={caretCSS}>
          <use xlinkHref={`${ChevronIcon}#right`} x="0" y="24" />
        </svg>
      )}
    </Fragment>
  );
};

interface Props {
  inputValue?: string;
  option: AddressSuggestion;
  index: number;
  isFocused?: boolean;
  onSelect: (option: AddressSuggestion, optionString: string) => void;
  onHover: (i: number) => void;
  isSelected?: boolean;
  trackForbidden?: boolean;
}

const AddressOption: FC<Props> = ({
  inputValue = '',
  option,
  index,
  trackForbidden,
  isFocused,
  onHover,
  onSelect,
  isSelected = false,
}) => {
  const optionString = getOptionString(option);
  const optionEl = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (isFocused && optionEl.current) {
      optionEl.current.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
    }
  }, [isFocused]);

  return (
    <button
      type="button"
      ref={optionEl}
      css={addressOptionCSS(isFocused, isSelected)}
      onClick={() => onSelect(option, optionString)}
      onMouseEnter={() => onHover(index)}
      title={optionString}
      role="option"
      aria-selected={isSelected}
      className={trackForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
    >
      {renderOptionLabel(inputValue, optionString, +option.entries)}
    </button>
  );
};

export default AddressOption;
