import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useCoInsured, useCreateCoInsured, useMutateCoInsured, useRemoveCoInsured } from '../../../../../api/coinsured';
import noop from '../../../../../shared/utils/noop';
import CheckoutStepTitle from '../CheckoutStepTitle/CheckoutStepTitle';
import { StepTitleButtonType } from '../CheckoutStepTitle/CheckoutStepTitle.type';
import CoInsuredForm from '../CoInsuredForm/CoInsuredForm';
import CoInsuredPreview from '../CoInsuredPreview/CoInsuredPreview';
import CoInsuredQuestion from '../CoInsuredQuestion/CoInsuredQuestion';
import { CoInsuredQuestionOptions } from '../CoInsuredQuestion/CoInsuredQuestion.type';
import { contentCSS } from './CheckoutCoInsuredStep.style';
import type { CheckoutCoInsuredStepProps } from './CheckoutCoInsuredStep.type';
import type { CoInsured } from '../../../../../types/co-insured.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC, ReactNode } from 'react';

const CheckoutCoInsuredStep: FC<CheckoutCoInsuredStepProps> = ({
  customCSS,
  stepNumber,
  isCurrent,
  onSetCurrent = noop,
  onGoToNextStep = noop,
}) => {
  const [wantToAdd, setWantToAdd] = useState<CoInsuredQuestionOptions>();
  const { gid } = useParams() as DefaultLocation;
  const coInsuredQuery = useCoInsured(gid);
  const coInsured = coInsuredQuery.data?.co_insureds[0];
  const { gid: coInsuredGid, ...coInsuredData } = coInsured ?? {};
  const { mutateAsync: createCoInsured, isPending: isCreatePending } = useCreateCoInsured(gid);
  const { mutateAsync: updateCoInsured, isPending: isUpdatePending } = useMutateCoInsured(gid);
  const { mutateAsync: removeCoInsured, isPending: isRemovePending } = useRemoveCoInsured(gid);
  const isSkipped = Boolean(coInsuredQuery.data && !isCurrent && !coInsured);
  const isFinished = Boolean(coInsuredQuery.data && !isCurrent && coInsured);

  const onSelectMainQuestion = (value: CoInsuredQuestionOptions): void => {
    setWantToAdd(value);
    if (value === CoInsuredQuestionOptions.No) {
      onGoToNextStep();
    }
  };

  const onTitleButtonClick = async (): Promise<void> => {
    if (coInsuredQuery.isPending || isCreatePending || isRemovePending || isUpdatePending) {
      return;
    }

    const actionType = getButtonType();

    if (actionType === StepTitleButtonType.AddCoInsured) {
      setWantToAdd(CoInsuredQuestionOptions.Yes);
      onSetCurrent();
    }

    if (actionType === StepTitleButtonType.Edit) {
      onSetCurrent();
    }

    if (actionType === StepTitleButtonType.RemoveCoInsured) {
      setWantToAdd(CoInsuredQuestionOptions.No);
      onGoToNextStep();
      if (coInsuredGid) {
        await removeCoInsured({ gid: coInsuredGid });
      }
    }
  };

  const onSubmit = async (data: Omit<CoInsured, 'gid'>): Promise<void> => {
    onGoToNextStep();
    coInsured ? await updateCoInsured({ ...data, gid: coInsured.gid }) : await createCoInsured(data);
  };

  const getButtonType = (): StepTitleButtonType | null => {
    if (!coInsuredQuery.data) {
      return null;
    }

    switch (true) {
      case isFinished:
        return StepTitleButtonType.Edit;
      case isSkipped:
        return StepTitleButtonType.AddCoInsured;
      case !!coInsured || wantToAdd === CoInsuredQuestionOptions.Yes:
        return StepTitleButtonType.RemoveCoInsured;
      default:
        return null;
    }
  };

  const renderContent = (): ReactNode => {
    if (!coInsuredQuery.data || isRemovePending) {
      return null;
    }

    if (isCreatePending || isUpdatePending) {
      return <CoInsuredPreview customCSS={contentCSS} />;
    }

    if (!isCurrent) {
      return coInsured ? <CoInsuredPreview customCSS={contentCSS} coInsured={coInsured} /> : null;
    }

    if (coInsured) {
      return <CoInsuredForm customCSS={contentCSS} coInsuredData={coInsuredData} onSubmit={onSubmit} />;
    }

    if (!wantToAdd) {
      return <CoInsuredQuestion customCSS={contentCSS} onSelectOption={onSelectMainQuestion} />;
    }

    if (wantToAdd === CoInsuredQuestionOptions.Yes) {
      return <CoInsuredForm customCSS={contentCSS} coInsuredData={coInsuredData} onSubmit={onSubmit} />;
    }

    return null;
  };

  return (
    <div css={customCSS}>
      <CheckoutStepTitle
        stepNumber={stepNumber}
        title="Co-Insured Info"
        isSkipped={isSkipped}
        isFinished={isFinished}
        buttonType={getButtonType()}
        onButtonClick={onTitleButtonClick}
      />
      {renderContent()}
    </div>
  );
};

export default CheckoutCoInsuredStep;
