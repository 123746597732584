import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { Answers } from '../../types/answer.type';
import type { HTTPResponse } from '../../types/api.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useMutateAuth = (gid: string): UseBaseMutationResult<Record<string, never>, HTTPResponse, Answers> => {
  return useMutation({
    mutationFn: async (data: Answers) =>
      await api
        .post<HTTPResponse<Record<string, never>>>(getApiUrl(API_PATH.authorize, gid), {
          body: { data: { answers: data } },
        })
        .then((res) => res.data),
    throwOnError: false,
  });
};

export default useMutateAuth;
