import type { DigitalProfileQuote } from '../../../../../types/quote.type';

export const AUTO_PREMIUM_TOOLTIP_CONTENT =
  'This auto insurance quote is an estimate for the cars and drivers you ' +
  'entered. Your final price can change based on a number of factors ' +
  'such as your driving record, prior claims, credit history, and your ' +
  'current insurance. You may also be eligible for additional discounts ' +
  'and savings.';

export const getAnnualPremium = (quote?: DigitalProfileQuote, withMpd?: boolean): number => {
  if (!quote) {
    return 0;
  }

  if (withMpd && quote.multi_policy_discount_premium) {
    return (Number(quote.multi_policy_discount_premium) / quote.policy_term) * 12;
  }

  return (Number(quote.premium) / quote.policy_term) * 12;
};
