import CarIcon from '../../../../../assets/quote-hero-car.svg?react';
import CarIconSmall from '../../../../../assets/vehicle.svg?react';
import Button from '../../../../../shared/components/Button/Button';
import noop from '../../../../../shared/utils/noop';
import { buttonCSS, containerCSS, headerCSS, iconCSS, imageCSS } from './HomeOnlyMessage.style';
import type { HomeOnlyMessageProps } from './HomeOnlyMessage.type';
import type { FC } from 'react';

const HomeOnlyMessage: FC<HomeOnlyMessageProps> = ({ customCSS, callIsScheduled, onActionClick = noop }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <CarIconSmall css={iconCSS} />
      <div css={headerCSS}>
        <div>Talk to an agent</div>
        <CarIcon css={imageCSS} />
      </div>
      <div>
        {callIsScheduled
          ? 'We need to confirm a few details in order to search for auto quotes. ' +
            'Our agent will discuss those during the call.'
          : 'We need to confirm a few details in order to search for auto quotes. Schedule a call to talk to an agent.'}
      </div>
      <Button customCSS={buttonCSS} variant="text" onClick={onActionClick}>
        {callIsScheduled ? 'Appointment details' : 'Schedule a call'}
      </Button>
    </div>
  );
};

export default HomeOnlyMessage;
