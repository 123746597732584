import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text20CSS } from '../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = css`
  min-height: 300px;
  position: relative;

  ${mq[0]} {
    display: flex;
    flex-direction: column;
    flex-basis: 290px;
    justify-content: space-between;
    min-height: 328px;
  }
`;

export const imageCSS = (imageUrl: string): SerializedStyles => css`
  content: '';
  width: 100%;
  height: 168px;
  background-image: url(${imageUrl});
  background-size: cover;
  background-position: center;
  margin-bottom: 16px;

  ${mq[0]} {
    margin-bottom: 12px;
  }
`;

export const titleCSS = css`
  ${text20CSS};
  font-weight: 700;
  margin-bottom: 16px;
  padding: 11px;
  text-align: left;
  color: ${theme.colors.black};

  ${mq[0]} {
    margin-bottom: 8px;
  }
`;

export const descriptionCSS = css`
  padding: 11px;
  color: ${theme.colors.black};

  ${mq[0]} {
    padding: 16px;
  }
`;

export const hrefCSS = css`
  text-decoration: none;
`;

export const tagsWrapperCSS = css`
  position: absolute;
  bottom: 16px;
  left: 14px;
`;

export const tagCSS = css`
  padding: 4px 8px;
  background-color: ${theme.colors.gray10};
  border-radius: 40px;
  color: ${theme.colors.black};
`;
