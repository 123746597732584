import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';
import { text32CSS, text48CSS } from '../../../../../styles/typography.style';

export const containerCSS = css`
  ${mq[1]} {
    display: flex;
    justify-content: space-between;
  }
`;

export const formContainerCSS = css`
  padding-top: 24px;
  ${mq[0]} {
    padding-top: 56px;
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
  }
  ${mq[1]} {
    flex-grow: 1;
  }
`;

export const checkoutSummaryCSS = css`
  margin-bottom: 32px;
  position: sticky;
  top: calc(var(--header-height) + 8px);
  z-index: 2;
  &:before {
    position: absolute;
    content: '';
    top: -12px;
    bottom: -12px;
    left: -4px;
    right: -4px;
    background: #fff;
    z-index: -1;
  }
`;

export const policySummaryTitleCSS = css`
  ${text32CSS};
  font-weight: 700;
  margin-bottom: 10px;
`;

export const pageTitleCSS = css`
  ${text32CSS};
  font-weight: 700;
  margin-bottom: 24px;
  ${mq[0]} {
    ${text48CSS};
    font-weight: 900;
    margin-bottom: 32px;
  }
  span {
    vertical-align: middle;
  }
  svg {
    vertical-align: middle;
    margin-left: 8px;
    width: 24px;
    height: 24px;
    ${mq[0]} {
      width: 32px;
      height: 32px;
    }
  }
`;
