import { Global } from '@emotion/react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicConfig } from '../../../../api/publicConfig';
import { useInitQuoteRequestSubscription } from '../../../../api/quote';
import { useSession } from '../../../../api/session';
import { useSessionFlowConfig } from '../../../../api/session-flow-config';
import QuotePageLoader from '../../../../components/QuotePageLoader/QuotePageLoader';
import SIRV_IMAGES from '../../../../constants/sirv-images';
import { hideFooterCSS } from '../../../../shared/shared.style';
import { isCurrentKnockOutSection } from '../../../../utils/navigation.util';
import { resetScroll } from '../../../../utils/scroll.util';
import CheckoutHome from './CheckoutHome/CheckoutHome';
import { containerCSS, loaderCSS, loaderImageCSS } from './CheckoutHomePage.style';
import KnockoutQuestionsForm from './KnockoutQuestionsForm/KnockoutQuestionsForm';
import type { CheckoutHomePageProps } from './CheckoutHomePage.type';
import type { HomeQuote } from '../../../../types/quote.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const CheckoutHomePage: FC<CheckoutHomePageProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultLocation;
  const sessionQuery = useSession(gid);
  const { data: configData } = usePublicConfig();
  const { isPending: isFlowConfigLoading } = useSessionFlowConfig(gid);
  const currentSectionKey = sessionQuery.data?.session.current_section_key;

  const webSocketUrl = configData?.config.websocket_url;
  const { isPending: isQuoting } = useInitQuoteRequestSubscription<HomeQuote>({ gid, webSocketUrl });

  useEffect(() => {
    resetScroll();
  }, [currentSectionKey]);

  if (isQuoting) {
    return (
      <>
        <Global styles={hideFooterCSS} />
        <QuotePageLoader customCSS={loaderCSS} customImageCSS={loaderImageCSS} imgUrl={SIRV_IMAGES.homeLoader} />
      </>
    );
  }

  if (!sessionQuery.data || isFlowConfigLoading) {
    return null;
  }

  return (
    <div css={[containerCSS, customCSS]}>
      {isCurrentKnockOutSection(sessionQuery.data.session.current_section_key) ? (
        <KnockoutQuestionsForm />
      ) : (
        <CheckoutHome />
      )}
    </div>
  );
};

export default CheckoutHomePage;
