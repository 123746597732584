import { create } from 'zustand';
import type { Driver } from '../../../types/driver.type';
import type { Vehicle } from '../../../types/vehicle.type';

interface EditingState {
  key: string;
  hasError: boolean;
}
interface State {
  editingState: EditingState[];
  vehicles: Vehicle[];
  drivers: Driver[];
}

interface Action {
  actions: {
    setEditingState: (value: EditingState[]) => void;
    setVehicles: (value: Vehicle[]) => void;
    setDrivers: (value: Driver[]) => void;
    triggerValidation: () => void;
  };
}

export const useAutoInformation = create<State & Action>((set) => ({
  editingState: [],
  isError: false,
  vehicles: [],
  drivers: [],
  actions: {
    setEditingState: (value) => set(() => ({ editingState: value })),
    setVehicles: (value) => set(() => ({ vehicles: value })),
    setDrivers: (value) => set(() => ({ drivers: value })),
    triggerValidation: () =>
      set((state) => ({ editingState: state.editingState.map((el) => ({ ...el, hasError: true })) })),
  },
}));

export const useAutoInformationData = (): State =>
  useAutoInformation((state) => ({
    editingState: state.editingState,
    vehicles: state.vehicles,
    drivers: state.drivers,
  }));
export const useAutoInformationActions = (): Action['actions'] => useAutoInformation((state) => state.actions);
