export const arrayContainsArray = (superset: string[], subset: string[]): boolean => {
  if (subset.length === 0) return false;

  return subset.every((value) => superset.includes(value));
};

export const arrayHasValueInArray = (superset: string[], subset: string[]): boolean => {
  if (superset.length === 0 || subset.length === 0) return false;

  return subset.some((value) => superset.includes(value));
};

export const isArraysEqual = <T>(arr: T[], comparedArr: T[]): boolean => {
  if (arr.length !== comparedArr.length) return false;

  const sortedArr = arr.sort((a: T, b: T) => (a > b ? 1 : -1));
  const sortedComparedArr = comparedArr.sort((a: T, b: T) => (a > b ? 1 : -1));

  return sortedArr.every((value) => sortedComparedArr.includes(value));
};
