import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text12CSS, text20CSS, textCSS } from '../../../../styles/typography.style';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const saveTextCSS = css`
  color: ${theme.colors.positiveGreen27};
`;

export const bundleCardCSS = css`
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
  margin-top: 44px;
  flex-wrap: wrap;
  width: 280px;
  padding: 32px 24px 24px;

  ${mq[0]} {
    flex-wrap: nowrap;
    margin-top: 65px;
    width: 520px;
    padding: 44px 32px 32px;
  }
`;

export const discountCSS = css`
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: 700;
  background-color: ${theme.colors.positiveGreen27};
  color: ${theme.colors.white};
  border-radius: 50px;
  ${text12CSS};
  padding: 0;
  width: 140px;

  ${mq[0]} {
    top: -22px;
    border-radius: 54px;
    ${textCSS};
    padding: 12px 10px;
    width: auto;
  }
`;

export const buttonCSS = css`
  margin-top: 0;

  ${mq[0]} {
    width: 220px;
    margin-top: 20px;
  }
`;

export const bundleTextCSS = css`
  ${textCSS};
  font-weight: 700;
  flex-basis: 45%;
  text-align: left;

  ${mq[0]} {
    ${text20CSS};
  }
`;

export const pageHeadingCSS = css`
  margin-top: 50px;
`;

export const iconCSS = css`
  width: 100px;

  ${mq[0]} {
    width: auto;
  }
`;

export const buttonWrapperCSS = css`
  width: 100%;
`;
