import { css } from '@emotion/react';
import { maxCalendlyLikeWidthContainerCSS } from '../../../../../styles/common.style';
import { mq, mqCalendly } from '../../../../../styles/media-queries';
import theme from '../../../../../styles/theme';
import { text20CSS, text24CSS, text40CSS, textCSS } from '../../../../../styles/typography.style';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (noHeading?: boolean): SerializedStyles => css`
  ${maxCalendlyLikeWidthContainerCSS};
  width: 100%;
  text-align: center;
  margin-top: ${noHeading ? '8' : '56'}px;

  ${mq[0]} {
    margin-top: ${noHeading ? '26' : '80'}px;
  }
`;

export const titleCSS = css`
  ${text24CSS};
  line-height: 28px;
  font-weight: 700;

  ${mq[0]} {
    ${text40CSS};
  }
`;

export const subtitleCSS = css`
  color: ${theme.colors.gray80};
  margin-top: 4px;
  ${textCSS};

  ${mq[0]} {
    ${text20CSS};
  }
`;

export const scheduledCallInfoCSS = css`
  ${maxCalendlyLikeWidthContainerCSS};
  margin-top: 24px;

  ${mqCalendly[0]} {
    margin-top: 32px;
  }
`;

export const cardCSS = css`
  ${maxCalendlyLikeWidthContainerCSS};
  margin-top: 24px;
  margin-bottom: 24px;
  padding: 24px 16px;

  ${mqCalendly[0]} {
    margin-top: 32px;
    margin-bottom: 24px;
    padding: 86px 16px;
  }
`;

export const heartIconCSS = css`
  margin-bottom: 16px;
`;

export const checkIconCSS = css`
  background-color: ${theme.colors.positiveGreen27};
  width: 8px;
  padding: 4px;
  border-radius: 50%;
  margin-right: 8px;
  flex-shrink: 0;
`;

export const detailsHeadingCSS = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;

  svg,
  span {
    vertical-align: baseline;
  }

  ${mq[0]} {
    margin-bottom: 14px;
  }
`;

export const detailsSubheadingCSS = css`
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const detailsListCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;

  ${mq[0]} {
    border: 1px solid ${theme.colors.gray30};
    border-radius: 8px;
    padding: 20px;
    max-width: 350px;
    margin: 0 auto;
  }
`;

export const detailsItemCSS = css`
  text-align: left;
  color: ${theme.colors.gray60};
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;

  span {
    vertical-align: middle;
  }

  svg {
    width: 20px;
    height: 20px;
    vertical-align: middle;
    margin-right: 12px;
  }
`;

export const circleCSS = css`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${theme.colors.azure62};
  margin-right: 12px;
`;

export const actionButtonCSS = css`
  margin-top: 32px;
`;
