import { useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../api/carrier';
import ActiveAdminButton from '../../components/ActiveAdminButton/ActiveAdminButton';
import { SEGMENT } from '../../constants/analytics';
import usePhoneNumber from '../../hooks/use-phone-number';
import { useSegment } from '../../hooks/use-segment';
import Container from '../../shared/components/Container/Container';
import Footer from '../../shared/components/Footer/Footer';
import Header from '../../shared/components/Header/Header';
import { errorPageCenterContainerCSS, errorPageSectionCSS } from '../../styles/common.style';
import { fullHeightPageCSS } from '../../styles/layout.style';
import { Flows } from '../../types/route-params.type';
import ErrorAgentPage from './components/ErrorAgentPage/ErrorAgentPage';
import type { Carrier } from '../../types/carrier.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const ErrorAgentLayout: FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const { phoneNumber } = usePhoneNumber();

  const { data: carrierData } = useSelectedCarrier<Carrier | null>(gid);
  const carrier = carrierData?.carrier
    ? {
        name: carrierData.carrier.name,
        logo: carrierData.carrier.logo_url,
      }
    : {};

  const { track } = useSegment();

  const onPhoneClicked = (location: string): void => {
    track(SEGMENT.events.phoneConversationInitiated, { page: SEGMENT.pages.error.name, location });
  };

  return (
    <div css={fullHeightPageCSS}>
      <ActiveAdminButton gid={gid} />
      <Header
        phoneNumber={phoneNumber}
        carrierName={carrier.name}
        carrierLogo={carrier.logo}
        reverseLogo={flow === Flows.Auto}
        showContacts
        denseContainer
        onPhoneClick={() => onPhoneClicked(SEGMENT.locations.header)}
      />
      <main>
        <section css={errorPageSectionCSS}>
          <Container dense>
            <div css={errorPageCenterContainerCSS}>
              <ErrorAgentPage />
            </div>
          </Container>
        </section>
      </main>
      <Footer phone={phoneNumber} denseContainer onSocialLinkClick={() => onPhoneClicked(SEGMENT.locations.footer)} />
    </div>
  );
};

export default ErrorAgentLayout;
