import { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { usePage } from '../../../../../api/page';
import { useSession } from '../../../../../api/session';
import useResponsive from '../../../../../hooks/use-responsive';
import { prepareCustomAnswers } from '../../../../../questionsForm/utils/answers/custom-schema-answers.util';
import Modal from '../../../../../shared/components/Modal/Modal';
import OptionalCoverageForm from '../OptionalCoverageForm/OptionalCoverageForm';
import WaitingLoadingModal from '../WaitingLoadingModal/WaitingLoadingModal';
import type { OptionalCoverageModalProps } from './OptionalCoverageModal.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { CustomSectionSchema } from '../../../../../types/section.type';
import type { FC } from 'react';

const OptionalCoverageModal: FC<OptionalCoverageModalProps> = ({
  isOpen,
  isLoading,
  components,
  coverages,
  onClose,
  onSubmit,
}) => {
  const methods = useForm();

  const { gid } = useParams() as DefaultLocation;
  const { data: sessionData } = useSession(gid);
  const currentPageKey = sessionData?.session.current_page_key;
  const { data: pageData } = usePage<CustomSectionSchema>(gid, currentPageKey);

  const formAnswers = useWatch({ control: methods.control });
  const answers = (pageData && { ...prepareCustomAnswers(pageData), ...formAnswers }) ?? {};

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (pageData?.answers) {
      methods.reset(prepareCustomAnswers(pageData), { keepDirty: true, keepTouched: true });
    }
  }, [methods, pageData]);

  return isLoading ? (
    <WaitingLoadingModal title="Calculating new price..." description="This might take up to 30 seconds" open />
  ) : (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Add Coverages"
      isFullScreen={isMobile}
      actionButtons={[
        { text: 'Cancel', variant: 'secondary', onClick: onClose },
        {
          text: 'Apply',
          type: 'submit',
          onClick: () => methods.handleSubmit(onSubmit)(),
        },
      ]}
    >
      <FormProvider {...methods}>
        <form>
          <OptionalCoverageForm answers={answers} components={components} coverages={coverages} />
        </form>
      </FormProvider>
    </Modal>
  );
};

export default OptionalCoverageModal;
