import LoadingDots from '../../../../../shared/components/LoadingDots/LoadingDots';
import Modal from '../../../../../shared/components/Modal/Modal';
import { containerCSS, contentCSS, descriptionCSS, loadingDotsCustomCSS, titleCSS } from './WaitingLoadingModal.style';
import type { WaitingLoadingModalProps } from './WaitingLoadingModal.type';
import type { FC } from 'react';

const WaitingLoadingModal: FC<WaitingLoadingModalProps> = ({ title, description, open = true }) => {
  return (
    <Modal customCSS={containerCSS} isOpen={open} isNotActionable>
      <div css={contentCSS}>
        <LoadingDots steps={[0, 0.15, 0.3]} customCSS={loadingDotsCustomCSS} />
        <div css={titleCSS}>{title}</div>
        <div css={descriptionCSS}>{description}</div>
      </div>
    </Modal>
  );
};

export default WaitingLoadingModal;
