import { useCallback, useEffect, useRef, useState } from 'react';
import { SEGMENT } from '../../constants/analytics';
import CALENDLY from '../../constants/calendly';
import { useSegment } from '../use-segment';
import { UTM_MEDIUM, filterCalendlyEvents, getUtmSource, CALENDLY_ID } from './use-init-calendly.util';
import type { CalendlyData, InitProps } from './use-init-calendly.type';

const useInitCalendly = ({
  strategy,
  carrierKey,
  leadGid,
  url,
  isPopup = false,
  prefillData,
}: InitProps): CalendlyData => {
  const [step, setStep] = useState(0);
  const isAddedCalendly = useRef(false);

  const { track } = useSegment();

  const initCalendlyWidget = useCallback(() => {
    if (isAddedCalendly.current) return;

    const parentElement = document.getElementById(CALENDLY_ID) ?? null;
    const utmSource = getUtmSource(strategy, carrierKey);

    const calendlyAction = isPopup ? CALENDLY.calendlyInitWidgetPopup : CALENDLY.calendlyInitWidgetInline;

    (window as any).Calendly[calendlyAction]({
      ...(parentElement && { parentElement }),
      url,
      prefill: {
        name: `${prefillData?.firstName ?? ''} ${prefillData?.lastName ?? ''}`,
        email: prefillData?.email ?? '',
      },
      utm: {
        utmMedium: UTM_MEDIUM,
        utmTerm: leadGid,
        utmSource,
      },
    });
    isAddedCalendly.current = true;
  }, [leadGid, isPopup, url, prefillData, carrierKey, strategy]);

  const onCalendlyEvent = useCallback(
    (eventName: string) => {
      switch (eventName) {
        case CALENDLY.calendlyEventTypeViewed:
          setStep(1);
          break;
        case CALENDLY.calendlyTimeSelected:
          track(SEGMENT.events.scheduleCallTimeSelected);
          setStep(2);
          break;
        case CALENDLY.calendlyEventScheduled:
          setStep(3);
          track(SEGMENT.events.conversationSchedulingFinished);
          break;
        default:
          break;
      }
    },
    [track]
  );

  const addCalendlyLinkTag = (): void => {
    const link = document.createElement('link');
    link.href = CALENDLY.calendlyStylesLinkUrl;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  };

  const removeCalendlyLinkTag = (): void => {
    const linkTag = document?.querySelector('link');
    if (linkTag && linkTag.href === CALENDLY.calendlyStylesLinkUrl) {
      document?.head.removeChild(linkTag);
    }
  };

  const addCalendlyScriptTag = useCallback(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = CALENDLY.calendlyWidgetScriptUrl;
    script.onload = initCalendlyWidget;
    document.head.appendChild(script);
  }, [initCalendlyWidget]);

  const calendlyInit = useCallback(() => {
    if (isAddedCalendly.current || !document) return;

    !(window as any).Calendly && isPopup && addCalendlyLinkTag();
    !(window as any).Calendly && addCalendlyScriptTag();
    window.addEventListener('message', filterCalendlyEvents(onCalendlyEvent));
    (window as any).Calendly && initCalendlyWidget();

    const timer = setTimeout(() => {
      document
        ?.querySelector('iframe')
        ?.setAttribute('aria-label', 'Calendly widget to select date and time for a chat.');
    }, 500);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [addCalendlyScriptTag, initCalendlyWidget, isPopup, onCalendlyEvent]);

  useEffect(() => {
    if (!isPopup) {
      removeCalendlyLinkTag();
    }
  }, [isPopup]);

  return { calendlyInit, step, isLoaded: isAddedCalendly.current };
};

export default useInitCalendly;
