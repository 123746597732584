import { Fragment, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import SCHEMA_KEYS from '../../../../../constants/schema-hardcoded-keys';
import { Card } from '../../../../../shared/components/Card/Card';
import DashedRow from '../../../../../shared/components/DashedRow/DashedRow';
import { getCents, removeCents } from '../../../../../shared/utils/money.util';
import formatUtil from '../../../../../utils/format.util';
import PaymentsSchedule from '../PaymentsSchedule/PaymentsSchedule';
import {
  arrowCSS,
  containerCSS,
  dashedRowCSS,
  dashedRowsContainerCSS,
  expandableButtonCSS,
  expandableContentCSS,
  expandableHeadingCSS,
  expandableTotalCSS,
  headingCSS,
  paymentScheduleCSS,
  subheadingCSS,
  totalCSS,
  totalValueCSS,
} from './CheckoutAutoSummary.style';
import { getSummaryValues } from './CheckoutSummary.util';
import type { CheckoutAutoSummaryProps, CheckoutAutoSummaryState } from './CheckoutAutoSummary.type';
import type { FC, ReactElement } from 'react';

const EXPANDER_ID = 'checkout-summary';

const CheckoutAutoSummary: FC<CheckoutAutoSummaryProps> = ({
  customCSS,
  isExpandable = false,
  finalQuotePremium,
  paymentPlans,
}) => {
  const [summaryValues, setSummaryValues] = useState<CheckoutAutoSummaryState | null>();
  const [expanded, setExpanded] = useState(false);
  const { watch } = useFormContext();
  const billingPaymentPlan = watch(SCHEMA_KEYS.billingPaymentPlan);
  const totalValue = summaryValues?.total ?? finalQuotePremium;
  const total = `${formatUtil.money(String(removeCents(totalValue)), 0, '$')}.${getCents(totalValue)}`;
  const premiumValue = summaryValues?.premiumCost ?? finalQuotePremium;
  const premiumCost = formatUtil.money(String(removeCents(premiumValue)), 0, '$');
  const feesTotal = `$${summaryValues?.feesTotal ?? 0}`;

  useEffect(() => {
    setSummaryValues(getSummaryValues(paymentPlans, billingPaymentPlan));
  }, [paymentPlans, billingPaymentPlan]);

  const onExpand = (): void => {
    setExpanded((s) => !s);
  };

  const renderSubheading = (): ReactElement => (
    <div css={subheadingCSS}>The total cost may differ, because of some standard fees.</div>
  );

  const renderDashedRows = (): ReactElement => (
    <div css={dashedRowsContainerCSS}>
      <DashedRow customCSS={dashedRowCSS} label="Premium Cost:" content={`${premiumCost}.${getCents(premiumValue)}`} />
      <DashedRow customCSS={dashedRowCSS} label="Fees Total:" content={feesTotal} />
    </div>
  );

  const renderPaymentSchedule = (): ReactElement | null => {
    if (!summaryValues?.paymentsSchedule.length) {
      return null;
    }
    return <PaymentsSchedule customCSS={paymentScheduleCSS} paymentSchedule={summaryValues.paymentsSchedule} />;
  };

  return (
    <Card customCSS={[containerCSS, customCSS]}>
      {isExpandable ? (
        <Fragment>
          <div css={expandableHeadingCSS}>
            <button css={expandableButtonCSS} aria-controls={EXPANDER_ID} aria-expanded={expanded} onClick={onExpand}>
              <div>Summary</div> <div css={arrowCSS} />
            </button>
            <div css={expandableTotalCSS}>{total}</div>
          </div>
          {expanded && (
            <div id={EXPANDER_ID} css={expandableContentCSS}>
              {renderSubheading()}
              {renderDashedRows()}
              {renderPaymentSchedule()}
            </div>
          )}
        </Fragment>
      ) : (
        <Fragment>
          <div css={headingCSS}>Summary</div>
          {renderSubheading()}
          {renderPaymentSchedule()}
          {renderDashedRows()}
          <div css={totalCSS}>
            <span>Total:</span>
            <span css={totalValueCSS}>{total}</span>
          </div>
        </Fragment>
      )}
    </Card>
  );
};

export default CheckoutAutoSummary;
