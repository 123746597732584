import AutoIcon from '../../../../assets/insurance-types/auto-inshurance.svg?react';
import EarthquakeIcon from '../../../../assets/insurance-types/earthquake-inshurance.svg?react';
import FloodIcon from '../../../../assets/insurance-types/flood-inshurance.svg?react';
import HomeIcon from '../../../../assets/insurance-types/home-inshurance.svg?react';
import JeweleryIcon from '../../../../assets/insurance-types/jewelery-inshurance.svg?react';
import PetIcon from '../../../../assets/insurance-types/pet-inshurance.svg?react';
import RVIcon from '../../../../assets/insurance-types/rv-inshurance.svg?react';
import UmbrellaIcon from '../../../../assets/insurance-types/umbrella-inshurance.svg?react';
import type { InsuranceType } from './CongratulationsPage.type';

export const INSURANCE_SECTION_TITLE = 'Simply your entire world of insurance.';
export const INSURANCE_SECTION_SUBTITLE =
  'We do more than just homeowner’s insurance. Get quotes and lean more about other ways to protect ' +
  'what is most important to you and your family.';

export const BLOG_SECTION_TITLE = 'Want to learn more?';
export const BLOG_SECTION_SUBTITLE =
  'Matic is here to help you navigate the sometimes complicated world of insurance. Our knowledge center is ' +
  'here to help make sense for a number of insurance topics. ';

export const INSURANCE_TYPES: InsuranceType[] = [
  {
    name: 'Homeowners\nInsurance',
    icon: HomeIcon,
    url: 'https://matic.com/blog/homeowners-insurance-101/',
  },
  {
    name: 'Auto\nInsurance',
    icon: AutoIcon,
    url: 'https://matic.com/blog/auto-insurance-101/',
  },
  {
    name: 'Umbrella\nInsurance',
    icon: UmbrellaIcon,
    url: 'https://matic.com/blog/umbrella-insurance-101/',
  },
  {
    name: 'Pet\nInsurance',
    icon: PetIcon,
    url: 'https://matic.com/pet-insurance/',
  },
  {
    name: 'Flood\nInsurance',
    icon: FloodIcon,
    url: 'https://matic.com/blog/flood-insurance-101/',
  },
  {
    name: 'Earthquake\nInsurance',
    icon: EarthquakeIcon,
    url: 'https://matic.com/blog/earthquake-insurance-101/',
  },
  {
    name: 'RV/Toy\nInsurance',
    icon: RVIcon,
    url: 'https://matic.com/blog/rv-insurance-101/',
  },
  {
    name: 'Jewelry\nInsurance',
    icon: JeweleryIcon,
    url: 'https://my.jewelersmutual.com/jewelry-insurance-quote-apply/t/AHPz4G4nJkeTxDNJOgrNdQ',
  },
];

export const BLOG_POSTS = [
  {
    title: '17 Common Homeowners Insurance Questions',
    description:
      'Homeowners insurance can be a somewhat complicated subject, especially if this is your first time buying or ' +
      'switching policies. But it doesn’t need to be.',
    image: 'https://maticinsurance.sirv.com/olb/blog_posts/blog-post-1.jpg',
    imageAlt: 'Couple with laptop',
    url: 'https://matic.com/blog/homeowners-insurance-questions/',
  },
  {
    title: '6 Ways To Help Your Pet Avoid Separation Anxiety',
    description:
      'As more companies wind down their work-from-home policies and kids head back to school, pets can have a hard ' +
      'time adjusting to a newly empty house.',
    image: 'https://maticinsurance.sirv.com/olb/blog_posts/blog-post-2.jpg',
    imageAlt: 'Dog near the window',
    url: 'https://matic.com/blog/pet-anxiety-tips/',
  },
  {
    title: 'Buying a Car vs. Leasing: Which is Better?',
    description:
      'If you’re in the market for a new car, you might be torn between buying or leasing. They represent two ' +
      'different ways to secure a new ride. ',
    image: 'https://maticinsurance.sirv.com/olb/blog_posts/blog-post-3.jpg',
    imageAlt: 'Couple choosing a car',
    url: 'https://matic.com/blog/buying-vs-leasing/',
  },
];

export const HOME_POLICY_DATA_RENDER = {
  showNumber: true,
  showDate: true,
};

export const AUTO_POLICY_DATA_RENDER = {
  ...HOME_POLICY_DATA_RENDER,
  showTerm: true,
};
