import { add } from 'date-fns/add';
import { enUS } from 'date-fns/locale/en-US';
import { toZonedTime, format } from 'date-fns-tz';

const DEFAULT_TIME_ZONE = 'US/Eastern';

export const getScheduledCallTime = (scheduledTime: string, timeZoneName?: string | null): string => {
  const timeZone = timeZoneName || DEFAULT_TIME_ZONE;
  const date = toZonedTime(scheduledTime, timeZone);
  const startHour = format(date, 'HH:mm', { timeZone });
  const endHour = format(add(date, { minutes: 10 }), 'HH:mm', { timeZone });
  const time = format(date, 'EEEE, MMMM d, yyyy', { timeZone });

  return `${startHour} - ${endHour}, ${time}`;
};

export const getScheduledCallTimezone = (scheduledTime: string, timeZoneName?: string | null): string => {
  const timeZone = timeZoneName || DEFAULT_TIME_ZONE;
  const date = toZonedTime(scheduledTime, timeZone);
  return format(date, 'zzzz (XXX)', { timeZone, locale: enUS });
};
