import { css, keyframes } from '@emotion/react';
import theme from '../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

const pulls = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

const centeredCSS = css`
  margin-left: auto;
  margin-right: auto;
`;

export const loaderCSS = (height = 20, width = 100, absoluteWidth?: number, centered = false): SerializedStyles => css`
  content: '';
  background-color: ${theme.colors.gray10};
  height: ${height}px;
  border-radius: 4px;
  width: ${absoluteWidth ? `${absoluteWidth}px` : `${width}%`};
  transition: width 1s;
  animation: ${pulls} 3s ease-in-out infinite;
  ${centered && centeredCSS};
`;
