import { forwardRef } from 'react';
import { isOtherIcon } from '../../../../utils/icons.util';
import noop from '../../../utils/noop';
import PreventDefaultWrapper from '../../PreventDefaultWrapper/PreventDefaultWrapper';
import RadioPhotoView from '../RadioPhotoView/RadioPhotoView';
import {
  containerCSS,
  labelCSS,
  inputCSS,
  labelContainerCSS,
  radioContainerInnerCSS,
  radioButtonDotCSS,
  radioDescriptionCSS,
  radioIconCSS,
  iconCSS,
  contentInnerCSS,
  nestedContentCSS,
} from './RadioButton.style';
import { RadioButtonVariant } from './RadioButton.type';
import { RADIO_BUTTON_CLASSNAMES } from './RadioButton.util';
import type { RadioButtonProps } from './RadioButton.type';
import type { ReactNode } from 'react';

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      customCSS,
      index,
      name,
      value,
      label,
      boldLabel,
      description,
      disabled,
      checked,
      imageUrl,
      variant = RadioButtonVariant.Default,
      children,
      hasError,
      onChange = noop,
      onBlur = noop,
      onFocus = noop,
      ...props
    },
    ref
  ) => {
    const renderRadioVew = (): ReactNode => {
      switch (variant) {
        case RadioButtonVariant.Photo:
          return (
            <RadioPhotoView photoUrl={imageUrl} label={label} description={description} hasError={hasError}>
              {children && <PreventDefaultWrapper>{children}</PreventDefaultWrapper>}
            </RadioPhotoView>
          );
        default:
          return (
            <div className={RADIO_BUTTON_CLASSNAMES.inner} css={radioContainerInnerCSS(hasError)}>
              <div css={contentInnerCSS}>
                <div className={RADIO_BUTTON_CLASSNAMES.dot} css={radioButtonDotCSS()} />
                <div css={labelContainerCSS}>
                  <div css={labelCSS(boldLabel ?? !!description)}>{label}</div>
                  {description && <div css={radioDescriptionCSS}>{description}</div>}
                </div>
                {imageUrl && (
                  <img
                    className={RADIO_BUTTON_CLASSNAMES.image}
                    css={[radioIconCSS, iconCSS(isOtherIcon(imageUrl))]}
                    src={imageUrl}
                    aria-hidden
                    alt=""
                  />
                )}
              </div>
              {children && <PreventDefaultWrapper customCSS={nestedContentCSS}>{children}</PreventDefaultWrapper>}
            </div>
          );
      }
    };

    return (
      <label css={[containerCSS(disabled), customCSS]} htmlFor={`${name}[${index}]`}>
        <input
          type="radio"
          css={inputCSS(hasError)}
          disabled={disabled}
          id={`${name}[${index}]`}
          name={name}
          value={value}
          checked={checked}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          {...props}
          ref={ref}
        />
        {renderRadioVew()}
      </label>
    );
  }
);

RadioButton.displayName = 'RadioButton';

export default RadioButton;
