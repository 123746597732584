import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { Driver, DriverResponse } from '../../types/driver.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useCreateDriver = (gid: string): UseBaseMutationResult<DriverResponse, HTTPResponse, Driver> => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (driver) =>
      await api
        .post<HTTPResponse<DriverResponse>>(getApiUrl(API_PATH.drivers, gid), {
          body: { data: driver },
        })
        .then((res) => res.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.vehiclesAndDriversData] });
      await queryClient.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.drivers, gid] });
    },
  });
};

export default useCreateDriver;
