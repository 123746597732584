import { DevTool } from '@hookform/devtools';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutateAnswers } from '../../../../../api/page';
import DisclosureComponent from '../../../../../questionsForm/components/DisclosureComponent/DisclosureComponent';
import { scrollToError } from '../../../../../questionsForm/utils/questions-form.util';
import Button from '../../../../../shared/components/Button/Button';
import { ButtonSize } from '../../../../../shared/components/Button/Button.type';
import noop, { noopAsync } from '../../../../../shared/utils/noop';
import CheckoutStepTitle from '../CheckoutStepTitle/CheckoutStepTitle';
import { buttonCSS, disclosureCSS, formCSS, textCSS } from './CheckoutBindStep.style';
import { BIND_STEP_LABEL } from './CheckoutBindStep.util';
import type { CheckoutBindStepProps } from './CheckoutBindStep.type';
import type { Answers } from '../../../../../types/answer.type';
import type { DefaultLocation } from '../../../../../types/route-params.type';
import type { FC } from 'react';

const CheckoutBindStep: FC<CheckoutBindStepProps> = ({
  customCSS,
  currentStepNumber,
  stepNumber,
  isBindDisabled = false,
  disclosures,
  actionLabel,
  onBeforeStepSubmit = noop,
  onFinishStep = noopAsync,
}) => {
  const { gid } = useParams() as DefaultLocation;
  const { mutateAsync: updateAnswers } = useMutateAnswers(gid);
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm<Answers>();

  const onActionClick = async (data: Answers): Promise<void> => {
    if (isLoading) return;

    setIsLoading(true);
    onBeforeStepSubmit();
    await updateAnswers(data);
    await onFinishStep();
    setIsLoading(false);
  };

  if (currentStepNumber < 3) {
    return (
      <div css={customCSS}>
        <CheckoutStepTitle stepNumber={stepNumber} title={BIND_STEP_LABEL} isDisabled />
      </div>
    );
  }

  return (
    <div css={customCSS}>
      <CheckoutStepTitle stepNumber={stepNumber} title={BIND_STEP_LABEL} isDisabled={currentStepNumber < 3} />
      <div css={textCSS}>
        Please <strong>read through and accept</strong> disclosures below to finalize checkout.
      </div>

      <FormProvider {...methods}>
        <form
          css={formCSS}
          id="checkout-disclosures-form"
          onSubmit={methods.handleSubmit(onActionClick, scrollToError)}
        >
          <div>
            {disclosures.map((disclosure) => (
              <DisclosureComponent
                customCSS={disclosureCSS}
                key={disclosure.key}
                componentKey={disclosure.key}
                content={disclosure.content}
                disclosureGid={disclosure.disclosure_gid}
                validations={disclosure.validations}
                isLineHidden
              />
            ))}
          </div>
        </form>
        {import.meta.env.DEV && <DevTool control={methods.control} />}
      </FormProvider>

      <Button
        customCSS={buttonCSS}
        type="submit"
        form="checkout-disclosures-form"
        fullWidth
        size={ButtonSize.Large}
        isLoading={isLoading}
        disabled={isBindDisabled}
      >
        {actionLabel}
      </Button>
    </div>
  );
};

export default CheckoutBindStep;
