import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import App from './App';
import QUERY_CLIENT_CONFIG from './constants/query-client-config';
import theme from './styles/theme';

if (import.meta.env.DEV && MSW) {
  const { worker } = await import('./test-helpers/mocks/browser');
  worker.start({
    onUnhandledRequest: 'bypass',
  });
}

const muiTheme = createTheme({
  typography: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 16,
  },
});

const queryClient = new QueryClient(QUERY_CLIENT_CONFIG);

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  // TODO: We need to remove react-input-mask dep to use strict mode
  // <React.StrictMode>
  <ThemeProvider theme={muiTheme}>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </ThemeProvider>

  // </React.StrictMode>
);
