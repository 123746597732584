import { useEffect, useState } from 'react';

const useScrollPoint = (scrollPoint: number = 0): boolean => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const onScroll = (): void => {
      const lastKnownScrollPosition = window?.scrollY;
      if (lastKnownScrollPosition > scrollPoint) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window?.addEventListener('scroll', onScroll);

    return (): void => {
      window?.removeEventListener('scroll', onScroll);
    };
  }, [scrollPoint]);

  return isScrolled;
};

export default useScrollPoint;
