const CALENDLY = {
  calendlyTimeSelected: 'calendly.date_and_time_selected',
  calendlyEventScheduled: 'calendly.event_scheduled',
  calendlyEventTypeViewed: 'calendly.event_type_viewed',
  calendlyWidgetScriptUrl: 'https://assets.calendly.com/assets/external/widget.js',
  calendlyStylesLinkUrl: 'https://assets.calendly.com/assets/external/widget.css',
  calendlyInitWidgetInline: 'initInlineWidget',
  calendlyInitWidgetPopup: 'initPopupWidget',
};

export default CALENDLY;
