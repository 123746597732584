import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCreateAllFunnelFlow } from '../../../../api/all-funnel-flow';
import { useSelectedCarrier } from '../../../../api/carrier';
import { usePublicErrors } from '../../../../api/public/errors';
import { usePublicFlowConfig } from '../../../../api/public/flow-config';
import { usePublicSession } from '../../../../api/public/session';
import { SEGMENT } from '../../../../constants/analytics';
import QUERY_CACHE_KEYS from '../../../../constants/query-cache-keys';
import { useSegment } from '../../../../hooks/use-segment';
import { StrategyType, PolicyType } from '../../../../types/public-flow-config.type';
import ErrorAgentLoader from '../ErrorAgentLoader/ErrorAgentLoader';
import ErrorAgentPageContent from '../ErrorAgentPageContent/ErrorAgentPageContent';
import type { Carrier } from '../../../../types/carrier.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC } from 'react';

const ErrorAgentPage: FC = () => {
  const { gid } = useParams() as DefaultLocation;
  const { page } = useSegment();
  const client = useQueryClient();

  const { data: carrierData, isPending: carrierDataIsPending } = useSelectedCarrier<Carrier | null>(gid);
  const { data: publicSessionData, isPending: publicSessionDataIsPending } = usePublicSession(gid);
  const { data: publicErrors, isPending: publicErrorsIsPending } = usePublicErrors(gid);
  const { data: publicFlowConfigData, isPending: publicFlowConfigIsPending } = usePublicFlowConfig(gid);

  const isAllFunnelFlows =
    publicFlowConfigData?.flow_config &&
    [PolicyType.Home, PolicyType.Condo].includes(publicFlowConfigData?.flow_config.policy_type) &&
    publicFlowConfigData.flow_config.strategy === StrategyType.OnlineBind;

  const allFunnelUrlQuery = useCreateAllFunnelFlow(gid, isAllFunnelFlows);

  const isPending =
    carrierDataIsPending ||
    publicSessionDataIsPending ||
    publicErrorsIsPending ||
    publicFlowConfigIsPending ||
    (isAllFunnelFlows && allFunnelUrlQuery.isPending);

  useEffect(() => {
    page(SEGMENT.pages.error.name);
  }, [page]);

  useEffect(() => {
    client.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.publicErrors, gid] });
    client.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.publicFlowConfig, gid] });
    client.invalidateQueries({ queryKey: [QUERY_CACHE_KEYS.publicSession, gid] });
  }, [client, gid]);

  if (isPending) {
    return <ErrorAgentLoader />;
  }

  return (
    <ErrorAgentPageContent
      failureReason={publicSessionData?.session.failure_reason}
      errors={publicErrors?.errors}
      allFunnelUrl={allFunnelUrlQuery.data?.url}
      carrierName={carrierData?.carrier?.name}
      qualificationStatus={publicSessionData?.session.qualification_status}
    />
  );
};

export default ErrorAgentPage;
