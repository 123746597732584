import type { Carrier } from './carrier.type';
import type { CustomComponentPresentationType } from './custom-components.type';
import type { Policy } from './policy.type';
import type { QuestionSchema, QuestionVariant } from './question.type';
import type { Validation } from './validation.type';
import type { VisibilityCondition } from './visibility-condition.type';

interface BaseFormComponentSchema {
  key: string;
  type: ComponentTypes;
  visibility_conditions: VisibilityCondition[][];
}

export interface TextBlockSchema extends BaseFormComponentSchema {
  heading: string;
  subheading?: string | null;
  heading_icon_url?: string | null;
  description?: string | null;
  block_icon_url?: string | null;
}

export interface WorkflowSchema extends BaseFormComponentSchema {
  heading: string;
  subheading?: string;
  description?: string;
  questions: QuestionSchema[];
}

export interface DeductibleSchema extends BaseFormComponentSchema {
  label: string;
  validations: Validation[];
  variants: Array<{ label: string; value: string | number }>;
  placeholder: string;
  presentation_type: CustomComponentPresentationType;
}

export interface EffectiveDateSchema extends BaseFormComponentSchema {
  label: string;
  validations: Validation[];
  placeholder: string;
  presentation_type: CustomComponentPresentationType;
}

export interface DisclosureSchema extends BaseFormComponentSchema {
  disclosure_gid: string;
  content: string;
  validations: Validation[];
}

export interface OptionalCoverageSchema extends BaseFormComponentSchema {
  presentation_type: CustomComponentPresentationType;
  variants?: QuestionVariant[];
  label: string;
  description: string;
  icon_url?: string;
  small_icon_url?: string;
  default_answer_value?: string;
}

export interface PaymentsSchedule {
  date: string;
  amount: number;
}

export interface PaymentPlanCondition {
  savings: number;
  down_payment: number;
  total_cost: number;
  installment_amount: number;
  installment_fee: number;
  description: string;
  payments_count: number;
  payments_schedule?: PaymentsSchedule[];
}

export interface BillingInformationSchema extends BaseFormComponentSchema {
  heading: string;
  questions: QuestionSchema[];
  final_quote_premium_amount: number;
  payment_plans: Record<string, PaymentPlanCondition>;
}

export interface PaymentMethodInformationSchema extends BaseFormComponentSchema {
  heading: string;
  questions: QuestionSchema[];
}

export interface PolicySummaryDetailsSchema extends BaseFormComponentSchema {
  policy: Policy;
  carrier: Carrier;
}

export interface InstructionStepSchema extends BaseFormComponentSchema {
  heading: string;
  description: string;
  icon_url: string;
  email?: string | null;
  phone?: string | null;
  url?: {
    prefix_text?: string;
    label: string;
    value: string;
  } | null;
}

export interface QuestionCollectionSchema extends BaseFormComponentSchema {
  heading: string;
  questions: QuestionSchema[];
}

export type FormComponentSchema =
  | TextBlockSchema
  | WorkflowSchema
  | DisclosureSchema
  | BillingInformationSchema
  | PaymentMethodInformationSchema
  | PolicySummaryDetailsSchema
  | InstructionStepSchema
  | DeductibleSchema
  | OptionalCoverageSchema
  | QuestionCollectionSchema;

export enum ComponentTypes {
  TextBlock = 'TextBlock',
  Workflow = 'Workflow',
  Disclosure = 'Disclosure',
  Deductible = 'Deductible',
  EffectiveDate = 'EffectiveDate',
  BillingInformation = 'BillingInformation',
  CCPaymentMethodInformation = 'CCPaymentMethodInformation',
  EFTPaymentMethodInformation = 'EFTPaymentMethodInformation',
  PolicyDetails = 'PolicyDetails',
  InstructionStep = 'InstructionStep',
  OptionalCoverage = 'OptionalCoverage',
  QuestionCollection = 'QuestionCollection',
}
