import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelectedCarrier } from '../../api/carrier';
import { usePublicPrimaryInsured } from '../../api/public/primary-insured';
import ActiveAdminButton from '../../components/ActiveAdminButton/ActiveAdminButton';
import { SEGMENT } from '../../constants/analytics';
import usePhoneNumber from '../../hooks/use-phone-number';
import { useSegment } from '../../hooks/use-segment';
import Container from '../../shared/components/Container/Container';
import Footer from '../../shared/components/Footer/Footer';
import Header from '../../shared/components/Header/Header';
import { fullHeightPageCSS } from '../../styles/layout.style';
import { Flows } from '../../types/route-params.type';
import { CongratulationsErrorPage } from './components/CongratulationsErrorPage/CongratulationsErrorPage';
import type { Carrier } from '../../types/carrier.type';
import type { DefaultLocation } from '../../types/route-params.type';
import type { FC } from 'react';

const PolicySoldLayout: FC = () => {
  const { gid, flow } = useParams() as DefaultLocation;
  const carrierQuery = useSelectedCarrier<Carrier>(gid);
  const { data: publicPrimaryInsured } = usePublicPrimaryInsured(gid);
  const { phoneNumber } = usePhoneNumber();

  const { page, track } = useSegment();

  useEffect(() => {
    page(SEGMENT.pages.error.name);
  }, [page]);

  const onPhoneClick = (location: string): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: SEGMENT.pages.error.name,
      location,
    });
  };

  return (
    <div css={fullHeightPageCSS}>
      <ActiveAdminButton gid={gid} />
      <Header
        phoneNumber={phoneNumber}
        carrierName={carrierQuery.data?.carrier?.name}
        carrierLogo={carrierQuery.data?.carrier?.logo_url}
        onPhoneClick={() => onPhoneClick(SEGMENT.locations.header)}
        reverseLogo={flow === Flows.Auto}
        showContacts
        denseContainer
      />
      <main>
        <Container dense>
          <CongratulationsErrorPage firstName={publicPrimaryInsured?.primary_insured.first_name} />
        </Container>
      </main>
      <Footer phone={phoneNumber} denseContainer onSocialLinkClick={() => onPhoneClick(SEGMENT.locations.footer)} />
    </div>
  );
};

export default PolicySoldLayout;
