import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import theme from '../../../../styles/theme';
import { text12CSS } from '../../../../styles/typography.style';

export const headingCSS = css`
  ${mq[0]} {
    text-align: center;
  }
`;

export const wrapperCSS = css`
  margin: 0 auto;
  ${mq[0]} {
    max-width: 890px;
  }
`;

export const loaderCSS = css`
  padding-top: 48px;
  width: 100%;
  ${mq[0]} {
    padding-top: 72px;
  }
`;

export const disclaimerCSS = css`
  position: relative;
  ${text12CSS};
  color: ${theme.colors.gray60};
  padding-top: 65px;
  &:before {
    position: absolute;
    content: '';
    top: 32px;
    left: -16px;
    right: -16px;
    height: 1px;
    display: block;
    background-color: ${theme.colors.gray10};
  }
  ${mq[0]} {
    margin-top: 24px;
    padding-top: 0;
    &:before {
      display: none;
    }
  }
`;

export const loaderImageCSS = css`
  width: 280px;
  height: 195px;

  ${mq[0]} {
    width: 335px;
    height: 250px;
  }
`;
