import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';
import theme from '../../../styles/theme';
import { text14CSS, textCSS } from '../../../styles/typography.style';
import { InfoFormBlockType } from './InfoFormBlock.type';
import type { SerializedStyles } from '@emotion/react';

export const containerCSS = (type: InfoFormBlockType, gridMode?: boolean, withIcon?: boolean): SerializedStyles => css`
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: ${type === InfoFormBlockType.warning ? theme.colors.white : theme.colors.azure95};
  border-radius: 8px;
  margin-bottom: 16px;
  flex-basis: 100%;
  box-sizing: border-box;
  ${text14CSS};
  min-height: 56px;

  ${withIcon &&
  css`
    padding-left: 56px;
  `}

  ${type === InfoFormBlockType.warning &&
  css`
    border: 1px solid ${theme.colors.errorRed};
  `}
  ${mq[0]} {
    ${textCSS};
    margin-bottom: 24px;
    margin-right: ${gridMode ? theme.sizes.inputHorizontalSpacing : 0}px;
  }
`;

export const iconCSS = css`
  position: absolute;
  top: calc(50% - 12px);
  left: 16px;

  img {
    width: 24px;
    height: 24px;
  }
`;
