import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from '../../../../api/session';
import BundleIcon from '../../../../assets/bundle-offer.svg?react';
import { SEGMENT } from '../../../../constants/analytics';
import ROUTES from '../../../../constants/routes';
import useResponsive from '../../../../hooks/use-responsive';
import { useSegment } from '../../../../hooks/use-segment';
import Button from '../../../../shared/components/Button/Button';
import { Card } from '../../../../shared/components/Card/Card';
import PageSectionHeading from '../PageSectionHeading/PageSectionHeading';
import {
  bundleCardCSS,
  bundleTextCSS,
  buttonCSS,
  buttonWrapperCSS,
  containerCSS,
  discountCSS,
  iconCSS,
  pageHeadingCSS,
  saveTextCSS,
} from './CongratulationsBundleOffer.style';
import type { CongratulationsBundleOfferProps } from './CongratulationsBundleOffer.type';
import type { DefaultLocation } from '../../../../types/route-params.type';
import type { FC, ReactElement } from 'react';

const CongratulationsBundleOffer: FC<CongratulationsBundleOfferProps> = ({ customCSS }) => {
  const { flow, gid } = useParams() as DefaultLocation;
  const sessionQuery = useSession(gid);
  const currentPageKey = sessionQuery.data?.session.current_page_key;

  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  const { track } = useSegment();

  const onScheduleCall = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: currentPageKey,
    });
    navigate(ROUTES.sessionScheduleCall.replace(':gid', gid).replace(':flow', flow));
  };

  const renderMobileCard = (): ReactElement => (
    <Card customCSS={bundleCardCSS}>
      <div css={discountCSS}>Save Up to 15% Today</div>

      <BundleIcon css={iconCSS} width={15} />
      <div css={bundleTextCSS}>Bundle and Save Today!</div>

      <div css={buttonWrapperCSS}>
        <Button customCSS={buttonCSS} onClick={onScheduleCall}>
          Schedule a Call
        </Button>
      </div>
    </Card>
  );

  const renderDesktopCard = (): ReactElement => (
    <Card customCSS={bundleCardCSS}>
      <div css={discountCSS}>Save Up to 15% Today</div>
      <BundleIcon css={iconCSS} />
      <div>
        <div css={bundleTextCSS}>Bundle and Save Today!</div>
        <Button customCSS={buttonCSS} onClick={onScheduleCall}>
          Schedule a Call
        </Button>
      </div>
    </Card>
  );
  return (
    <div css={[containerCSS, customCSS]}>
      <PageSectionHeading
        customCSS={pageHeadingCSS}
        title={
          <div>
            Want to Bundle Auto and Save<span css={saveTextCSS}> Up to 15%?</span>
          </div>
        }
        subtitle={<div>We analyzed your data and can offer you savings for bundling your home and auto insurance.</div>}
        showDivider
      />
      {isMobile ? renderMobileCard() : renderDesktopCard()}
    </div>
  );
};

export default CongratulationsBundleOffer;
