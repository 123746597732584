import { getNavigationPathByAPIError } from '../../../../utils/error.util';
import type { ApiErrorResponse } from '../../../../utils/error.util';
import type { AxiosError } from 'axios';
import type { NavigateProps } from 'react-router-dom';

export const getNavigateProps = (apiError: AxiosError<ApiErrorResponse>, flow: string, gid: string): NavigateProps => {
  const path = getNavigationPathByAPIError(apiError);
  const navigateRoute = path.replace(':gid', gid).replace(':flow', flow);

  return {
    to: navigateRoute,
  };
};
