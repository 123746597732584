import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { Carrier, CarrierResponse } from '../../types/carrier.type';
import type { QueryObserverResult } from '@tanstack/react-query';

const useSelectedCarrier = <T = Carrier | null>(gid: string, enabled = true): QueryObserverResult<CarrierResponse<T>> =>
  useQuery({
    queryKey: [QUERY_CACHE_KEYS.carrier, gid],
    queryFn: async () =>
      await api.get<HTTPResponse<CarrierResponse<T>>>(getApiUrl(API_PATH.carrier, gid)).then((res) => res.data),
    enabled,
  });

export default useSelectedCarrier;
