import { css } from '@emotion/react';
import theme from '../../../../styles/theme';
import type { SerializedStyles } from '@emotion/react';

export const addressOptionCSS = (isFocused?: boolean, isSelected?: boolean): SerializedStyles => css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  padding: 10px 20px;
  font-variant: lining-nums;

  ${isFocused &&
  css`
    background-color: ${theme.colors.gray10};
  `}

  &:hover {
    background-color: ${theme.colors.gray10};
  }

  ${isSelected &&
  css`
    &,
    &:hover {
      background-color: ${theme.colors.azure50};
      color: ${theme.colors.white};
    }
  `}
`;

export const entriesCSS = css`
  color: ${theme.colors.azure50};
`;

export const caretCSS = css`
  height: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
`;
