import type { EffectivePolicy } from '../types/policy.type';
import type { QuoteType, Quote } from '../types/quote.type';

export const getPrimaryQuote = <T = QuoteType>(quotes: T[] = [], carrierKey?: string): T | null => {
  return quotes.find((quote) => (quote as QuoteType).carrier_key === carrierKey) ?? null;
};

export const getQuoteSavings = (quote?: Quote, withMpd?: boolean, effectivePolicy?: EffectivePolicy): number => {
  if (!quote || !effectivePolicy?.premium) {
    return 0;
  }

  let savings = 0;

  if (withMpd && quote.multi_policy_discount_premium) {
    savings = Number(effectivePolicy.premium) - Number(quote.multi_policy_discount_premium);
  } else if (quote.premium) {
    savings = Number(effectivePolicy.premium) - Number(quote.premium);
  }

  return savings >= 10 ? savings : 0;
};
