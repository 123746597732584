import { css } from '@emotion/react';
import { mq } from './media-queries';
import theme from './theme';
import { text24CSS } from './typography.style';

export const indicatorLineCSS = css`
  content: '';
  background-color: ${theme.colors.gray60};
  position: absolute;
  top: 19px;
  left: -18.5px;
  width: 2px;
  height: 100%;

  ${mq[0]} {
    top: 22px;
    left: -29.5px;
  }
`;

export const checkoutComponentsHeadingCSS = css`
  ${text24CSS};
  font-weight: 700;
  margin-bottom: 8px;
  ${mq[0]} {
    margin-bottom: 16px;
  }
`;

export const formInputFocusOutlineCSS = css`
  content: '';
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  border-radius: 7px;
  border: 2px solid transparent;
  transition: border-color 0.2s ease;
`;

export const formWithGridCSS = css`
  display: flex;
  flex-wrap: wrap;
  ${mq[0]} {
    margin-right: -${theme.sizes.inputHorizontalSpacing}px;
  }
`;

export const sectionFormActionsCSS = css`
  position: relative;
  margin-top: 8px;
  text-align: center;
  z-index: 1;

  ${mq[0]} {
    margin-top: 28px;
  }
`;
