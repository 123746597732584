import currencyFormatter from 'currency-formatter';
import { format as formatDate } from 'date-fns';
import { oneMB } from '../shared/constants/measures';
import type { Address } from '../types/address.type';

type MoneyFormatter = (
  value: string | number,
  cutFromK?: number,
  prefix?: string | null,
  suffix?: string | null
) => string;

const money: MoneyFormatter = (value, cutFromK = 0, prefix = '', suffix = '') => {
  prefix = prefix ?? '';
  suffix = suffix ?? '';

  if (typeof value === 'number' || !Number.isNaN(Number.parseInt(value))) {
    const thousands = +value / 1000;

    if (cutFromK && thousands >= cutFromK) {
      const precision = thousands % 1 > 0.099 ? 1 : 0;
      return `${prefix}${currencyFormatter.format(thousands, { precision })}k${suffix}`;
    }

    return `${prefix}${currencyFormatter.format(Math.round(+value), { precision: 0 })}${suffix}`;
  }

  return '';
};

type SnakeFormatter = (string: string, separator?: string) => string;

const snake: SnakeFormatter = (snakeCase, separator = ' ') =>
  snakeCase
    .toLowerCase()
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(separator);

const phone = (phoneNumberString: string): string => {
  const cleaned = phoneNumberString.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return phoneNumberString;
};

const maskNumbers = (numbersString: string, limit: number): string => {
  if (!numbersString) {
    return '';
  }
  let maskedNumber = 0;
  return Array.from(numbersString)
    .map((letter) => {
      if (letter.match(/\d/) && maskedNumber < limit) {
        maskedNumber++;
        return '*';
      }
      return letter;
    })
    .join('');
};

const date = (value: string): string => {
  if (!value) {
    return '';
  }
  return formatDate(new Date(value), 'L/d/y');
};

const size = (bytes: number): string => `${(bytes / oneMB).toFixed(1)} Mb`;

const address = (value: Omit<Address, 'gid'>): string =>
  value?.line1 ? `${value.line1}, ${value.city}, ${value.state} ${value.zip}` : '';

const format = {
  money,
  snake,
  phone,
  maskNumbers,
  date,
  size,
  address,
};

export default format;
