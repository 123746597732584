import AutoIcon from '../../assets/vehicle.svg?react';
import useResponsive from '../../hooks/use-responsive';
import SkipPageButton from '../SkipPageButton/SkipPageButton';
import { buttonCSS, containerCSS, iconCSS, leftColCSS } from './SkipToNextPageWrapper.style';
import type { SkipToNextPageWrapperProps } from './SkipToNextPageWrapper.type';
import type { FC } from 'react';

const SkipToNextPageWrapper: FC<SkipToNextPageWrapperProps> = ({ customCSS }) => {
  const { isMobile } = useResponsive();
  return (
    <div css={[containerCSS, customCSS]}>
      <div css={leftColCSS}>
        <AutoIcon css={iconCSS} />
        <div>Auto Information</div>
      </div>
      <div>
        <SkipPageButton customCSS={buttonCSS} buttonVariant={isMobile ? 'text' : 'secondary'} />
      </div>
    </div>
  );
};

export default SkipToNextPageWrapper;
