import { ComponentTypes } from '../../../../types/form-component.type';
import CongratulationsInstructionStep from '../CongratulationsInstructionStep/CongratulationsInstructionStep';
import CongratulationsTextBlock from '../CongratulationsTextBlock/CongratulationsTextBlock';
import PolicySummaryDetails from '../PolicySummaryDetails/PolicySummaryDetails';
import type {
  FormComponentSchema,
  TextBlockSchema,
  InstructionStepSchema,
  PolicySummaryDetailsSchema,
} from '../../../../types/form-component.type';
import type { PolicySummaryRenderList } from '../PolicySummaryDetails/PolicySummaryDetails.types';
import type { ReactElement } from 'react';

export const getComponentByType = (
  { key, type, ...c }: FormComponentSchema,
  index: number,
  visibleComponents: FormComponentSchema[],
  policyDataToRender: PolicySummaryRenderList
): ReactElement | null => {
  switch (type) {
    case ComponentTypes.TextBlock: {
      const textBlockSchema = c as Omit<TextBlockSchema, 'key' | 'type'>;
      return <CongratulationsTextBlock key={key} heading={textBlockSchema.heading} />;
    }
    case ComponentTypes.PolicyDetails: {
      const policyDetailsSchema = c as Omit<PolicySummaryDetailsSchema, 'key' | 'type'>;
      return (
        <PolicySummaryDetails
          key={key}
          policy={policyDetailsSchema.policy}
          carrier={policyDetailsSchema.carrier}
          renderSummaryList={policyDataToRender}
        />
      );
    }
    case ComponentTypes.InstructionStep: {
      const instructionStepSchema = c as Omit<InstructionStepSchema, 'key' | 'type'>;
      return (
        <CongratulationsInstructionStep
          key={key}
          showLine={visibleComponents[index + 1]?.type === ComponentTypes.InstructionStep}
          stepNumber={index}
          heading={instructionStepSchema.heading}
          description={instructionStepSchema.description}
          iconUrl={instructionStepSchema.icon_url}
          email={instructionStepSchema.email ?? ''}
          phone={instructionStepSchema.phone ?? ''}
          url={instructionStepSchema.url}
        />
      );
    }
    default:
      return null;
  }
};
