import Loader from '../../../../shared/components/Loader/Loader';
import { LoaderSizeEnum } from '../../../../shared/components/Loader/Loader.type';
import { layerCSS, loaderCSS } from './FormListAddLoader.style';
import type { FC } from 'react';

const FormAddListLoader: FC = () => (
  <>
    <div css={layerCSS}></div>
    <Loader customCSS={loaderCSS} colored size={LoaderSizeEnum.Medium} />
  </>
);

export default FormAddListLoader;
