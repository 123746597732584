import { css } from '@emotion/react';
import { mq } from '../../../../../styles/media-queries';

export const labelCSS = css`
  margin-top: 10px;
  margin-bottom: 4px;
`;

export const paymentMethodCSS = css`
  margin-bottom: 16px;

  ${mq[0]} {
    margin-bottom: 12px;
  }
`;
