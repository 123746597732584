import { FormElementTag, FormElementType } from '../../types/form-element.type';
import { InfoFormBlockType } from '../components/InfoFormBlock/InfoFormBlock.type';
import { IconSize } from '../components/TextFormBlock/TextFormBlock.type';
import type { FormElementSchema } from '../../types/form-element.type';

export const isNavigationPointTextBoxElement = (element: FormElementSchema): boolean => {
  return element.kind === FormElementType.TextBox && element.tags.includes(FormElementTag.NavigationPoint);
};

export const shouldHideQuestionLabel = (element: FormElementSchema): boolean => {
  return element.tags.includes(FormElementTag.HideLabel);
};

export const isQuestionsGroupLabel = (element: FormElementSchema): boolean => {
  return element.kind === FormElementType.TextBox && element.tags.includes(FormElementTag.QuestionsGroupLabel);
};

export const getTextFormBlockIconSize = (element: FormElementSchema): IconSize => {
  if (element.tags.includes(FormElementTag.TextBoxIconSmall)) {
    return IconSize.Small;
  }

  return IconSize.Default;
};

export const getInfoBlockType = (element: FormElementSchema): InfoFormBlockType | undefined => {
  if (element.kind === FormElementType.InfoBlock) {
    if (element.tags.includes(FormElementTag.InfoBlockWarning)) {
      return InfoFormBlockType.warning;
    }

    return InfoFormBlockType.info;
  }
};

export const isTrackForbidden = (tags: FormElementTag[]): boolean => {
  return tags.includes(FormElementTag.TrackForbidden);
};

// https://maticinsurance.atlassian.net/browse/OLB-1536 - we should not send null answers for questions with tags "forbid_send_null_answer"
export const isAllowSendNullAnswer = (tags: FormElementTag[]): boolean => {
  return !tags.includes(FormElementTag.ForbidSendNullAnswer);
};
