import { Controller } from 'react-hook-form';
import MaskedInput from 'react-input-mask';
import { FS_SENSITIVE_DATA_CLASS } from '../../../../../constants/full-story';
import { focusHelperCSS, inputCSS, inputWrapperCSS } from '../../../../../shared/components/Input/Input.style';
import { isValueSpecificType } from '../../../../utils/answers/answers.util';
import { getValidationRules } from '../../../../utils/question-validations/question-validations.util';
import { CARD_EXPIRATION_DATE_VALIDATION, normalizeCardExpirationValue } from './CardExpirationInput.util';
import type { MaskInputProps } from './CardExpirationInput.type';
import type { FC } from 'react';

const CARD_EXPIRATION_MASK = '99 / 9999';

const CardExpirationInput: FC<MaskInputProps> = ({
  name,
  isDisabled,
  hasError = false,
  inputId,
  type = 'text',
  placeholder,
  validations = [],
  trackingForbidden = false,
}) => {
  return (
    <Controller
      name={name}
      render={({ field: { onChange, value, onBlur, name, ref } }) => {
        const inputValue = isValueSpecificType<string | string[] | number>(value, ['string', 'array', 'number'])
          ? value
          : '';
        return (
          <div css={inputWrapperCSS}>
            <MaskedInput
              className={trackingForbidden ? FS_SENSITIVE_DATA_CLASS.mask : ''}
              css={inputCSS(hasError, isDisabled)}
              id={inputId}
              mask={CARD_EXPIRATION_MASK}
              name={name}
              type={type}
              onChange={(e) => {
                onChange({ target: { value: normalizeCardExpirationValue(e.target.value) } });
              }}
              value={inputValue}
              disabled={isDisabled}
              onBlur={onBlur}
              inputRef={(r: HTMLInputElement) => {
                ref(r);
              }}
              placeholder={placeholder}
              autoComplete="off"
            />
            <div css={focusHelperCSS} className="input-focus-helper" />
          </div>
        );
      }}
      rules={getValidationRules([CARD_EXPIRATION_DATE_VALIDATION, ...validations])}
      defaultValue=""
    />
  );
};

export default CardExpirationInput;
