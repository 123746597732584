import { useId, useState } from 'react';
import { Card } from '../../../../../shared/components/Card/Card';
import { accordionArrowCSS, containerCSS, contentCSS, toggleCSS, toggleWrapperCSS } from './QuoteCardWrapper.style';
import type { QuoteCardWrapperProps } from './QuoteCardWrapper.type';
import type { FC } from 'react';

const QuoteCardWrapper: FC<QuoteCardWrapperProps> = ({ customCSS, children, toggleContent, clearPadding = false }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const id = useId();

  return (
    <Card customCSS={[containerCSS(clearPadding), customCSS]}>
      {children}
      {isExpanded && (
        <div css={contentCSS} id={id}>
          {toggleContent}
        </div>
      )}
      <div css={toggleWrapperCSS}>
        <button css={toggleCSS} aria-controls={id} aria-expanded={isExpanded} onClick={() => setIsExpanded((s) => !s)}>
          View Coverage Details
          <div css={accordionArrowCSS(isExpanded)} />
        </button>
      </div>
    </Card>
  );
};

export default QuoteCardWrapper;
