import { css } from '@emotion/react';
import theme from '../../../styles/theme';

export const containerCSS = css`
  width: 40px;
  height: 40px;
  border: 2px solid ${theme.colors.azure68};
  border-radius: 50%;
  box-sizing: border-box;
  background-color: ${theme.colors.white};
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0;
  transition:
    border-color 0.2s ease,
    background-color 0.2s ease,
    color 0.2s ease;
  cursor: pointer;

  :hover,
  :focus {
    background-color: ${theme.colors.azure68};
    border-color: ${theme.colors.azure68};
    svg {
      path {
        fill: ${theme.colors.white};
      }
    }
  }
`;

export const containerSingleColorCSS = css`
  border: 4px solid ${theme.colors.black};
  background-color: ${theme.colors.black};

  :hover,
  :focus {
    background-color: ${theme.colors.azure50};
    border-color: ${theme.colors.azure50};
  }
`;

export const imageCSS = css`
  width: 100%;
  svg {
    display: block;
    height: auto;
    margin: auto;
    max-height: 20px;
    max-width: 20px;
    path {
      fill: ${theme.colors.azure50};
    }
  }
`;

export const imageSingleColorCSS = css`
  svg {
    path {
      fill: ${theme.colors.gray5};
    }
  }
`;
