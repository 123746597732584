import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import Chevron from '../../../assets/dropdown-chevron.svg?react';
import noop from '../../utils/noop';
import Button from '../Button/Button';
import { ButtonSize } from '../Button/Button.type';
import { buttonCSS, menuCSS, selectIndicatorCSS } from './ButtonSelect.style';
import type { ButtonSelectProps } from './ButtonSelect.type';
import type { FC, MouseEvent } from 'react';

const randomId = String(Math.floor(Math.random() * 9999));

const ButtonSelect: FC<ButtonSelectProps> = ({
  customCSS,
  children,
  options,
  onSelectOption = noop,
  ...buttonProps
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = useState<string>('auto');
  const open = Boolean(anchorEl);
  const id = buttonProps.id ?? randomId;

  const handleClick = (event: MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
    setMenuWidth(`${event.currentTarget.offsetWidth}px`);
  };

  const handleClose = (option?: string): void => {
    setAnchorEl(null);
    if (option) {
      onSelectOption(option);
    }
  };

  return (
    <>
      <Button
        css={[buttonCSS(buttonProps.size === ButtonSize.Large), customCSS]}
        id={id}
        aria-controls={open ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        {...buttonProps}
      >
        {children}
        <Chevron css={selectIndicatorCSS(open, buttonProps.size === ButtonSize.Large)} />
      </Button>
      <Menu
        id={`${id}-menu`}
        anchorEl={anchorEl}
        open={open}
        css={menuCSS(menuWidth)}
        onClose={() => handleClose()}
        MenuListProps={{
          'aria-labelledby': id,
        }}
      >
        {options.map(({ key, label }) => (
          <MenuItem key={key} onClick={() => handleClose(key)}>
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonSelect;
