import { css } from '@emotion/react';

export const checkboxInputWrapperCSS = css`
  display: flex;
  align-items: center;
`;

export const labelTextCSS = css`
  padding-top: 15px;
  font-weight: 700;
`;

export const checkboxIconCSS = css`
  position: absolute;
  content: '';
  left: 20px;
  top: 16px;
  z-index: 2;
`;
