import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import { getApiUrl } from '../../utils/api.util';
import api from '../api';
import type { HTTPResponse } from '../../types/api.type';
import type { UseBaseMutationResult } from '@tanstack/react-query';

const useMutatePolicyBind = (
  gid: string
): UseBaseMutationResult<Record<string, never>, HTTPResponse, Record<string, never>> => {
  return useMutation({
    mutationFn: async () =>
      await api.post<HTTPResponse<Record<string, never>>>(getApiUrl(API_PATH.policy, gid)).then((res) => res.data),
  });
};

export default useMutatePolicyBind;
